import React, { useEffect, useState, useRef } from 'react';
import { useGetList, Button, useDataProvider } from 'react-admin';
import { exportButtonCss } from '../../shared/Styles/buttonStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ExportAllPartecipantiButton = ({ istanzeEscursioniId, titoloricerca, data, fields }) => {
    const { data: ordiniData, isLoading: isLoadingOrdini, error: errorOrdini } = useGetList(
        'ordini',
        {
            pagination: false,
            filter: {
                istanzeEscursioniId: istanzeEscursioniId
            }
        }
    );

    const dataProvider = useDataProvider();
    const [partecipantiData, setPartecipantiData] = useState([]);
    const [loadingPartecipanti, setLoadingPartecipanti] = useState(true);
    const [errorPartecipanti, setErrorPartecipanti] = useState(null);
    const [open, setOpen] = useState(false);
    const printRef = useRef();

    useEffect(() => {
        const fetchAllPartecipanti = async () => {
            if (ordiniData && ordiniData.length > 0) {
                try {
                    let allPartecipanti = [];
                    for (let ordine of ordiniData) {
                        const { data } = await dataProvider.getList('ordini-partecipanti', {
                            pagination: false,
                            filter: { ordiniId: ordine.id }
                        });
                        allPartecipanti = allPartecipanti.concat(data);
                    }
                    setPartecipantiData(allPartecipanti);
                    setLoadingPartecipanti(false);
                } catch (error) {
                    setErrorPartecipanti(error);
                    setLoadingPartecipanti(false);
                }
            } else {
                setLoadingPartecipanti(false);
            }
        };
        fetchAllPartecipanti();
    }, [ordiniData, dataProvider]);

    const handleExport = () => {
        if (!partecipantiData || partecipantiData.length === 0) {
            setOpen(true);
            return;
        }

        // Open the print dialog
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Lista Partecipanti</title>');
        printWindow.document.write('<style>');
        printWindow.document.write(`
            table {
                width: 100%;
                border-collapse: collapse;
            }
            th, td {
                border: 1px solid #000;
                padding: 8px;
                text-align: left;
            }
            th {
                background-color: #f2f2f2;
            }
        `);
        printWindow.document.write('</style></head><body>');
        printWindow.document.write(`<h1>Lista Partecipanti</h1>`);
        printWindow.document.write(`<h2>${titoloricerca}</h2>`);
        printWindow.document.write(`<h2>${data}</h2>`);
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderTable = () => {
        return (
            <table>
                <thead>
                    <tr>
                        {fields.map((field) => (
                            <th key={field}>{field}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {partecipantiData.map((row, index) => (
                        <tr key={index}>
                            {fields.map((field) => (
                                <td key={field}>{row[field]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <>
            <div ref={printRef} style={{ display: 'none' }}>
                {/* <h1>Lista Partecipanti - ID: {istanzeEscursioniId}</h1> */}
                {renderTable()}
            </div>
            <Button
                onClick={handleExport}
                label="Export PDF"
                disabled={isLoadingOrdini || loadingPartecipanti || errorOrdini || errorPartecipanti}
                sx={exportButtonCss}
            >
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Nessun partecipante trovato"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Non ci sono partecipanti da esportare.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ExportAllPartecipantiButton;
