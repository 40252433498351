import { Loading, useGetList, Button } from 'react-admin';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import ListaPartecipanti from './ListaPartecipanti';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
// import ExportPartecipantiButton from './ExportPartecipantiButton';

const ListaOrdiniPartecipanti = ({ istanzeEscursioniId }) => {

    const { data, total, isLoading, error } = useGetList(
        'ordini',
        {
            pagination: false,
            // sort: { field: 'published_at', order: 'DESC' }
            filter: {
                istanzeEscursioniId: istanzeEscursioniId
            }
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Box display="flex" justifyContent="top" alignItems="top" minHeight="100vh">
            <Box width="70%">
                {data.map((ordine) => {

                    return (
                        <Card sx={{ minWidth: 275, margin: 2 }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                                <Typography color="textPrimary" gutterBottom>
                                    Id Ordine : {ordine.id}&nbsp;&nbsp;
                                    <Button
                                        component={Link}
                                        to={`/ordini/${ordine.id}/show`}
                                        style={{ minWidth: 'auto', padding: 0 }}
                                    >
                                        Dettaglio&nbsp;<VisibilityIcon style={{ color: 'inherit' }} />
                                    </Button>
                                </Typography>
                                <p>
                                    <ListaPartecipanti ordiniId={ordine.id} ordini={data}></ListaPartecipanti>
                                </p>
                                {/* <ExportPartecipantiButton ordiniId={ordine.id} /> */}
                            </CardContent>
                        </Card>
                    );
                })}

            </Box>
        </Box>
    );

};

export default ListaOrdiniPartecipanti;