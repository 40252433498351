import * as React from "react";
import {
    List,
    Datagrid,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    RichTextField,
    useRecordContext,
    FilterList,
    FilterListItem,
    required,
    ShowButton,
    TopToolbar,
    Toolbar,
    SaveButton,
    CreateButton,
    useRedirect,
    useNotify,
    useRefresh,
    TabbedForm,
    SingleFieldList,
    ChipField,
    SelectArrayInput,
    WrapperField
} from 'react-admin';

import { ReferenceManyToManyField, ReferenceManyToManyInput } from '@react-admin/ra-relationships';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { RichTextInput } from 'ra-input-rich-text';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import Typography from "@material-ui/core/Typography";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ContentFilter from '@mui/icons-material/FilterList';
import CardContent from '@mui/material/CardContent';
import NoteIcon from '@mui/icons-material/Note';
import TopicIcon from '@mui/icons-material/Topic';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import Avatar from '@mui/material/Avatar';

// Import gestori immagini
import GestoreCoverCategoria from '../macro-categorie/cover/GestoreCoverMacroCategoria';

// Importo il css per i Bottoni
import { listCss } from '../shared/Styles/listStyles';
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, createButtonCss, chipsCss } from '../shared/Styles/buttonStyles';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={createButtonCss} />
    </TopToolbar>
);

/* TODO: da creare componente custom */

const CustomAvatarImage = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Box height="80%" width="80%" display="flex" justifyContent="left" alignItems="top">
            {record[source] ? (
                // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                <Avatar alt={label} style={{ width: '100px', height: '100px' }} src={record[source]} />
            ) : (
                <p><Avatar alt="Immagine mancante" style={{ width: '100px', height: '100px' }}><ImageNotSupportedIcon /></Avatar></p>
            )}
        </Box>
    );
};

const CustomAvatarImageOnlyFileName = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Box height="80%" width="80%" display="flex" justifyContent="left" alignItems="top">
            {record[source] ? (
                // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                <Avatar alt={label} style={{ width: '100px', height: '100px' }} src={record[source]} />
            ) : (
                <p><Avatar alt="Immagine mancante" style={{ width: '100px', height: '100px' }}><ImageNotSupportedIcon /></Avatar></p>
            )}
        </Box>
    );
};


export const MacroCategorieList = props => {

    return (
        <List title={<>
            <Button>
                &nbsp;Lista delle Macro Categorie
            </Button>
        </>}
            perPage={100}
            sort={{ field: 'id', order: 'DESC' }}
            actions={<ListActions />}
            exporter={false}
            {...props}>
            <Datagrid
                sx={listCss}
                noDelete
                bulkActionButtons={false}
            >
                {<CustomAvatarImage source="imageUrl" label="Cover Image" ></CustomAvatarImage>
                }
                {<TextField label="Nome" source="nome" />}
                {<TextField label="Descrizione" source="descrizione" />}
                <ReferenceManyToManyField
                    reference="categorie"
                    through="macro-categorie-categorie"
                    using="macroCategorieId,categorieId"
                    label="Categorie associate"
                >
                    <SingleFieldList>
                        <ChipField sx={chipsCss} source="titoloricerca" />
                    </SingleFieldList>
                </ReferenceManyToManyField>
                {<EditButton sx={editButtonCss} label="Modifica nome - descr - categorie" textAlign="center" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" textAlign="center" />}
                <WrapperField label="Elimina" textAlign="center">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Macro Categoria"}
                        messaggio={"Sei sicuro di vole cancellare questa macro categoria ?"}
                        servizio={"macro-categorie"} />
                </WrapperField>
            </Datagrid>
        </List>
    );
}

// TODO : da rendere questo componente come riutilizzabile
const MacroCategorieCreateToolbar = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    return (
        <Toolbar>
            {/* <SaveButton
                label="post.action.save_and_show"
            /> */}
            <SaveButton sx={createButtonCss} label="Crea Macro Categoria" /* alwaysEnable */
                // label="post.action.save_and_add"
                mutationOptions={{
                    onSuccess: data => {
                        notify('Macro Categoria creata.'/* 'ra.notification.created' */, {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                        });
                        // redirect(false);
                        redirect('list', 'macro-categorie');
                    }
                }
                }
                type="button"
                variant="text"
            />
        </Toolbar>
    );
};

export const MacroCategorieCreate = (props) => (
    <Create    {...props}>
        <SimpleForm toolbar={<MacroCategorieCreateToolbar></MacroCategorieCreateToolbar>} redirect="show">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {<TextInput label="Nome" source="nome" validate={[required()]} />}
                                <br></br>
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {<TextInput label="Descrizione" source="descrizione" validate={[required()]} />}
                                <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica Titolo / Associazione Categoria" />
    </TopToolbar>
);

const MacroCategorieLingueRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput fullWidth label="Nome" source="nome" />
        <TextInput fullWidth label="Descrizione" source="descrizione" />
        {/* <TextInput label="Url file pdf" source="urlPdf" /> */}
    </RowForm>
);

export const MacroCategorieShow = (props) => {

    const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>

                &nbsp;Dettaglio Macro Categoria
            </Button>
        </>} actions={<ShowActions />}>


            {<TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Immagine / Nome / Descrizione" icon={<TopicIcon></TopicIcon>}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <GestoreCoverCategoria refresh={refresh}></GestoreCoverCategoria>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            {<NoteIcon />}
                                            Nome Macro Categoria : {<TextField label="Nome Macro Categoria" source="nome" />}
                                        </Box>
                                    </Typography>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            {<NoteIcon />}
                                            Descrizione Macro Categoria : {<TextField label="Descrizione" source="descrizione" />}
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Lingue Macro Categorie" icon={<TopicIcon></TopicIcon>}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={10}>
                            <ReferenceManyField
                                reference="macro-categorie-lingue"
                                target="macroCategorieId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <EditableDatagrid noDelete
                                    bulkActionButtons={false}
                                    editForm={<MacroCategorieLingueRowForm />}
                                >
                                    <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                    <TextField fullWidth label="Nome" source="nome" />
                                    <TextField fullWidth label="Descrizione" source="descrizione" />
                                </EditableDatagrid>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Categorie Associate" icon={<TopicIcon></TopicIcon>}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={10}>
                            <ReferenceManyToManyField
                                reference="categorie"
                                through="macro-categorie-categorie"
                                using="macroCategorieId,categorieId"
                                label=""
                            >
                                <SingleFieldList>
                                    <ChipField sx={chipsCss} source="titoloricerca" />
                                </SingleFieldList>
                            </ReferenceManyToManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            </TabbedForm>}



        </Show>
    );
}

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

export const ServiziRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput label="Nome" source="nome" />
        <TextInput label="Descrizione" source="titoloricerca" />
        <TextInput label="Descrizione breve" source="titoloricercaBreve" />
    </RowForm>
);

export const MacroCategorieEdit = (props) => (
    <Edit title={<>
        <Button>

            &nbsp;Modifica Macro Categoria
        </Button>
    </>} actions={<ShowActionsForEdit />}  {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {<Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {<TextInput label="Nome" source="nome" validate={[required()]} />}
                                <br></br>
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {<TextInput label="Descrizione" source="descrizione" validate={[required()]} />}
                                <br></br>
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">

                                {<ReferenceManyToManyInput
                                    source="id"
                                    reference="categorie"
                                    through="macro-categorie-categorie"
                                    using="macroCategorieId,categorieId"
                                >
                                    {<SelectArrayInput
                                        label="Categorie"
                                        validate={required()}
                                        optionText="titoloricerca"
                                        fullWidth
                                    />}
                                </ReferenceManyToManyInput>} <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>}
            </Grid>
        </SimpleForm>
    </Edit>
);

const ValutaBandieraLingua = ({ source }) => {
    const record = useRecordContext();
    let lingua = "";

    switch (record[source]) {
        case 1:
            lingua = "http://imgm.btechitalia.it/flags/flagita.png"
            break;
        case 2:
            lingua = "http://imgm.btechitalia.it/flags/flaguk.png"
            break;
        case 3:
            lingua = "http://imgm.btechitalia.it/flags/flagpol.png"
            break;
        case 4:
            lingua = "http://imgm.btechitalia.it/flags/flagger.png"
            break;
        case 5:
            lingua = "http://imgm.btechitalia.it/flags/flagpor.png"
            break;
        case 6:
            lingua = "http://imgm.btechitalia.it/flags/flagspa.png"
            break;
        case 7:
            lingua = "http://imgm.btechitalia.it/flags/flagfra.png"
            break;
        default:
            lingua = ""
            break;
    }


    return (
        <><div>
            <img src={lingua} width="20" height="20" />
        </div></>
    );
};
// http://imgm.btechitalia.it/testita.png
export const MacroCategorieExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={10}>
                        <h2>Lista titoli</h2>
                        <h3>{<TextField label="Titolo per ricerca" source="titoloricerca" />}
                        </h3><br></br>
                        <ReferenceManyField
                            reference="servizi-aggiuntivi-lingue"
                            target="serviziAggiuntiviId"

                            // perPage={1}
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <EditableDatagrid noDelete
                                bulkActionButtons={false}
                                editForm={<MacroCategorieLingueRowForm />}
                            >

                                {/* <TextField  label="Id Lingua" source="lingueId" /> */}
                                <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                {/* <ImageField  source="urlBandierina" label="Nazione" /> */}
                                <TextField label="Titolo" source="nome" />
                            </EditableDatagrid>
                        </ReferenceManyField>
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={10}>
                        <h2>Lista Descrizioni</h2>
                        <ReferenceManyField
                            reference="servizi-aggiuntivi-lingue"
                            target="serviziAggiuntiviId"

                            // perPage={1}
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <EditableDatagrid noDelete
                                bulkActionButtons={false}
                                editForm={<MacroCategorieDescrizioniRowForm />} >
                                <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                <RichTextField label="Descrizione" source="titoloricerca" />
                            </EditableDatagrid>
                        </ReferenceManyField>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>

                        {/* {<RichTextField label="Consegna" source="consegna" />} */}

                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}

const MacroCategorieRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput label="Titolo" source="nome" />
    </RowForm>
);


export const MacroCategorieDescrizioniRowForm = (props) => (
    <RowForm {...props}>
        {/* <TextInput label="Descrizione" source="titoloricerca" /> */}
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="titoloricerca"></RichTextInput>
    </RowForm>
);

// Filtri

const MacroCategorieFilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <MacroCategorieFilter />
            </CardContent>
        </Card>
    </Box>
);

/// Filtri
const MacroCategorieFilter = () => (
    <Grid container spacing={2}>
        <Grid>
            <Box >
                {/*         <Box sx={{
        width: '12em',
    }}><h3></h3>
        <Card>
    <CardContent><FilterLiveSearch source="id" />
    </CardContent>
    </Card></Box> */}

                <FilterList label="Zone geografiche" icon={<ContentFilter />}>
                    <FilterListItem
                        label="Nord"
                        value={{
                            zona: "n"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Ovest"
                        value={{
                            zona: "no"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Est"
                        value={{
                            zona: "ne"
                        }}
                    />
                    <FilterListItem
                        label="Est"
                        value={{
                            zona: "e"
                        }}
                    />
                    <FilterListItem
                        label="Sud"
                        value={{
                            zona: "s"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Ovest"
                        value={{
                            zona: "so"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Est"
                        value={{
                            zona: "se"
                        }}
                    />
                    <FilterListItem
                        label="Ovest"
                        value={{
                            zona: "o"
                        }}
                    />
                </FilterList>
            </Box>
        </Grid>
        {/* {  <Grid>
    <Box>
    <FilterList label="Trimestre" icon={<ContentFilter />}>
    <FilterListItem
            label="Primo"
            value={{trimestre: 1
            }}
        />
         <FilterListItem
            label="Secondo"
            value={{trimestre: 2
            }}
        />
    <FilterListItem
            label="Terzo"
            value={{trimestre: 3
            }}
        />
         <FilterListItem
            label="Quarto"
            value={{trimestre: 4
            }}
        />
    </FilterList>
    </Box>
  </Grid>} */}
    </Grid>

);

const escursioniFilters = [
    // <TextInput label="Cerca per nome" source="nome" alwaysOn />,
    {/* <TextInput label="Cerca per id" source="id" />,
    <ReferenceInput perPage={100} source="id" reference="servizi-aggiuntivi" label="Titolo escursione">
        <SelectInput optionText="titoloricerca" />
    </ReferenceInput>, */}
    // <TextInput label="Cerca per titolo" source="titoloricerca" /* alwaysOn */ />
];

