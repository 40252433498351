import React, { useState } from 'react';
import {
    Button,
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    FunctionField,
    useListContext,
    TextInput,
    NumberInput,
    DateInput,
    BooleanField,
    BooleanInput,
    SelectInput
} from 'react-admin';
import PostBulkActionButtons from './PostBulkActionButtons';

import { deleteButtonCss } from "../../shared/Styles/buttonStyles";
import { listCss } from '../../shared/Styles/listStyles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const OrderDialog = ({ record, recordRighi, fornitoriId, onClose, ...props }) => {

    const [open, setOpen] = useState(false);
    const { filterValues } = useListContext();

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        onClose(); // Chiude anche il dialog genitore
    };

    return (
        <>
            <Button onClick={handleOpen}>Apri la lista ordini</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth={true}
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: '80%',  // Imposta la larghezza massima al 100%
                            margin: 0,  // Rimuove i margini
                            height: '100%',  // Imposta l'altezza al 100%
                            maxHeight: 'none',  // Rimuove l'altezza massima
                            borderRadius: 0,  // Rimuove gli angoli arrotondati
                        },
                    },
                }}
            >
                <DialogTitle>Lista ordini filtrata</DialogTitle>
                <DialogContent>
                    <List
                        basePath="/wapp-per-selezione-ordini-transfer"
                        resource="wapp-per-selezione-ordini-transfer"
                        // filter={{ ...filterValues, ...{ data: filterValues.data?.toISOString() } }} // Converti la data in formato ISOString
                        perPage={100}
                        exporter={false}
                        filters={[
                            <TextInput label="Id Ordine" source="id" />,
                            <TextInput label="Referente" source="referente" />,
                            <TextInput label="Hotel" source="nomeHotel" />,
                            <TextInput label="Tipo escursione" source="tipoEscursione" />,
                            <NumberInput label="Id Istanza Escursione" source="istanzeEscursioniId" />,
                            <DateInput label="Data Istanza" source="data" />,
                            <SelectInput source="haTransfer" label="Usato" choices={[
                                { id: '0', name: 'Disponibile' },
                                { id: '1', name: 'Usata' }
                            ]}></SelectInput>
                        ]}
                        filterDefaultValues={{ haTransfer: '0' }}
                    >
                        <Datagrid
                            // sx={listCss}
                            sx={{
                                '& .RaDatagrid-headerCell': {
                                    backgroundColor: 'lightgray'
                                },
                                '& .RaDatagrid-row': {
                                    '&:hover': {
                                        backgroundColor: 'lightblue',
                                    },
                                },
                            }}
                            bulkActionButtons={<PostBulkActionButtons
                                record={record}
                                recordRighi={recordRighi}
                                fornitoriId={fornitoriId}
                                onClose={handleClose}
                            />}
                        >
                            {/* {<TextField label="Id" source="id" />}  */}{/* ATTENZIONE: questo è l'id della vista */}
                            {<TextField label="Id" source="id" />}
                            {<DateField label="Data prenotazione" source="dataPrenotazione" />}
                            {<DateField label="Data istanza" source="data" />}
                            {<TextField label="istanzeEscursioniId" source="istanzeEscursioniId" />}
                            {<ReferenceField label="Istanza Escursione" source="istanzeEscursioniId" reference="istanze-escursioni">
                                <FunctionField render={record => record && `${record.titoloricerca}`} />
                            </ReferenceField>}
                            {<ReferenceField label="User" source="userId" reference="users">
                                <FunctionField render={record => record && `${record.nome} ${record.cognome} - ${record.mail} - ${record.telefono} `} />
                            </ReferenceField>}
                            {<ReferenceField label="Hotel" source="hotelId" reference="hotels">
                                <FunctionField render={record => record && `${record.nome} ${record.zona} `} />
                            </ReferenceField>}
                            {<TextField label="Numer camera" source="numeroCamera" />}
                            {<TextField label="Referente" source="referente" />}
                            {<TextField label="Referente Contatto" source="referenteContatto" />}
                            {<TextField label="Infant" source="infant" />}
                            {<TextField label="Child" source="child" />}
                            {<TextField label="Adult" source="adult" />}
                            {<TextField label="Totale" source="totale" />}
                            {<TextField label="Usato" source="haTransfer"></TextField>}
                            {<TextField label="Note Infant" source="note" />}
                            {<TextField label="Note variazioni" source="note_variazioni" />}
                        </Datagrid>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={deleteButtonCss}>Chiudi</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};




export default OrderDialog;
