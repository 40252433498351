import React, { useState, useEffect } from 'react';
import { useGetList } from 'react-admin';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

const GetTipoPuntoMenu = ({ onTipoPuntoChange, defaultValue }) => {
    const { data, isLoading, error } = useGetList(
        'tipo-puntiincontro',
        { pagination: { page: 1, perPage: 100 } }
    );

    const [selectedValue, setSelectedValue] = useState('');

    // Imposta il valore selezionato al valore di default quando il componente viene montato
    // o quando il defaultValue cambia.
    useEffect(() => {
        setSelectedValue(defaultValue || '');
        // Chiama la callback per impostare il valore di default nel componente padre,
        // se la callback è stata fornita e il defaultValue è valido.
        if (defaultValue) {
            onTipoPuntoChange(defaultValue);
        }
    }, [defaultValue, onTipoPuntoChange]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        if (onTipoPuntoChange) {
            onTipoPuntoChange(newValue);
        }
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <p>ERROR</p>;
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="tipo-punto-label">Tipologia Punto</InputLabel>
            <Select
                labelId="tipo-punto-label"
                id="tipo-punto-select"
                value={selectedValue}
                onChange={handleChange}
                label="Tipologia Punto"
            >
                {data.map(record => (
                    <MenuItem key={record.id} value={record.id}>
                        {record.descrizione}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default GetTipoPuntoMenu;
