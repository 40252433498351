import * as React from "react";
import { useMemo } from 'react';
import {
    useRefresh,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    Button,
    TopToolbar,
    ExportButton,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    DateInput,
    NumberInput,
    useDataProvider,
    Loading,
    Error,
    RichTextField,
    required,
    useGetOne,
    useGetMany,
    useGetList,
    localStorageStore,
    DateTimeInput,
    TabbedForm,
    Datagrid,
    FunctionField,
    usePermissions,
    ReferenceManyCount,
    ImageField,
    AutocompleteArrayInput,
    List,
    ChipField,
    SingleFieldList,
    EditButton,
    downloadCSV,
    ReferenceOneField,
    useNotify
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import { useState, useEffect } from 'react';
import jsonExport from 'jsonexport/dist';

import { ReferenceManyToManyInput } from "@react-admin/ra-relationships";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import Chip from '@material-ui/core/Chip';
import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import FolderIcon from '@mui/icons-material/Folder';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import TopicIcon from '@mui/icons-material/Topic';
import CardHeader from '@mui/material/CardHeader';
import { blue, red } from '@mui/material/colors';
import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text";
import Alert from '@mui/material/Alert';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import IconButton from '@mui/material/IconButton';
import { Divider } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useFormContext } from 'react-hook-form';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import WorkIcon from '@mui/icons-material/Work';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DirectionsCarSharp from '@mui/icons-material/DirectionsCarSharp';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

import { exportButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';

import ListaPartecipanti from "./partecipanti/ListaPartecipanti";

import { editButtonCss } from "../shared/Styles/buttonStyles";

import ExportAllPartecipantiButton from "./partecipanti/ExportAllPartecipantiButton";
import ExportAllPartecipantiPdf from "./partecipanti/ExportAllPartecipantiPdf";
import ExportPdfVoucherFromService from "./voucher/ExportPdfVoucherFromService";

// Importo componenti input custom
import CustomSelectMezziPuntiincontro from '../shared/FormFields/CustomSelectMezziPuntiincontro';
import CopyToClipboardButton from '../shared/FormFields/CopyToClipboardButton'
import { InitPuntiPartenzaButtonCustom } from "../shared/InitPuntiPartenzaButtonCustom";
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import ModificaCapienzaMax from '../istanze-escursioni/modificaCapienzaMax';
import ModificaAggiungiPuntoGeografico from '../istanze-escursioni/modificaAggiungiPuntoGeografico';
import ModificaReferente from '../istanze-escursioni/modificaReferente';
import ModificaNoteArrivo from '../istanze-escursioni/modificaNoteArrivo';
import ModificaOraInizio from '../istanze-escursioni/modificaOraInizio';

// Import dei componenti custom
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import { ConfirmDialogUpdateButtonCustom } from "../shared/UpdateButtonCustom";

import { createButtonCss } from "../shared/Styles/buttonStyles";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { fieldAccordion } from '../../components/shared/Styles/fieldsStyles';

// Crea un link verso google maps
import GoogleMapsLink from "../shared/GoogleMapsLink";


import { showButtonCss } from '../shared/Styles/buttonStyles';

import EventIcon from '@mui/icons-material/Event'; // Importa l'icona Eve
import ModificaOraFine from "./modificaOraFine";
import ListaOrdiniPartecipanti from "./partecipanti/ListaOrdiniPartecipanti";

// Init store
const storeGenerale = localStorageStore();

const SetCurrentIstanzaEscursione = ({ source, data }) => {
    const record = useRecordContext();
    // storeGenerale.setItem('profile.currentIstanzaEscursione', res);
    storeGenerale.setItem('profile.currentIstanzaEscursione', 0);
    storeGenerale.setItem('profile.currentIstanzaEscursioneData', 0);
    storeGenerale.setItem('profile.currentIstanzaEscursione', record[source]);
    storeGenerale.setItem('profile.currentIstanzaEscursioneData', record[data]);
    return record ? (
        <>
            {/* {record[source]} */}</>

    ) : <>
    </>;
}

const FormatNote = ({ source }) => {

    const record = useRecordContext();
    return record ? (
        <div dangerouslySetInnerHTML={{ __html: record[source] }} />

    ) : <>
    </>;
}

/* ****************** */


const MezziShowDisponibiliRowForm = ({ istanzaEscursione, escursione, ...props }) => {
    const record = useRecordContext();
    // console.log('Form record:', record);
    // console.log('istanzaEscursione:', istanzaEscursione);
    // console.log('escursione:', escursione);

    const { data: usersFornitori, isLoading: isLoadingUsersFornitori } = useGetList('users-fornitori', {
        filter: { sottoruolo: 'CHECKVOUCHER', fornitoriId: escursione?.fornitoriId },
        pagination: { page: 1, perPage: 100 },
    });

    const userIds = useMemo(() => usersFornitori ? usersFornitori.map(uf => uf.usersId) : [], [usersFornitori]);

    const { data: users, isLoading: isLoadingUsers } = useGetMany('users', { ids: userIds });

    if (isLoadingUsersFornitori || isLoadingUsers) return null;

    const usersMap = users ? users.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
    }, {}) : {};

    return (
        <RowForm {...props}>
            <TextInput source="istanzeEscursioniId" defaultValue={record?.istanzeEscursioniId || istanzaEscursione?.id} disabled />
            <TextInput source="fornitoriId" defaultValue={record?.fornitoriId || escursione?.fornitoriId} disabled />
            <TextInput source="mezziId" defaultValue={record?.mezziId || escursione?.mezziId} disabled />
            <SelectInput
                source="usersFornitoriId"
                choices={usersFornitori}
                optionText={(record) => {
                    const user = usersMap[record.usersId];
                    return user ? `${record.id} - ${user.email}` : `${record.id}`;
                }}
            />
            <DateInput source="dataIstanza" defaultValue={record?.dataIstanza || istanzaEscursione?.data} disabled />
        </RowForm>
    );
};

const UsersManagementTab = () => {
    const istanzaEscursione = useRecordContext();
    // console.log('UsersManagementTab istanzaEscursione:', istanzaEscursione);
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { data: escursione } = useGetOne('escursioni', { id: istanzaEscursione?.escursioniId });

    if (!istanzaEscursione) return null;

    const handleSave = async (data) => {
        try {
            const saveData = {
                ...data,
                istanzeEscursioniId: data.istanzeEscursioniId || istanzaEscursione.id,
                fornitoriId: data.fornitoriId || escursione?.fornitoriId,
                mezziId: data.mezziId || escursione?.mezziId,
            };

            let result;
            if (data.id) {
                result = await dataProvider.update('istanze-escursioni-mezzi-users', { id: data.id, data: saveData });
            } else {
                result = await dataProvider.create('istanze-escursioni-mezzi-users', { data: saveData });
            }
            notify('Operazione completata con successo', { type: 'success' });
            refresh();
            return result.data;
        } catch (error) {
            console.error(error);
            notify('Errore durante l\'operazione: ' + error.message, { type: 'error' });
        }
    };

    return (
        <ReferenceManyField
            reference="istanze-escursioni-mezzi-users"
            target="istanzeEscursioniId"
            sort={{ field: 'id', order: 'ASC' }}
        >
            <EditableDatagrid
                mutationMode="pessimistic"
                createForm={<MezziShowDisponibiliRowForm istanzaEscursione={istanzaEscursione} escursione={escursione} />}
                editForm={<MezziShowDisponibiliRowForm istanzaEscursione={istanzaEscursione} escursione={escursione} />}
                onSave={handleSave}
                hasEdit={false}
            >
                {/* <TextField source="id" /> */}
                <ReferenceField source="usersFornitoriId" reference="users-fornitori" link={false}>
                    <ReferenceField source="usersId" reference="users" link={false}>
                        <FunctionField render={record => `${record.email} - ${record.cognome} ${record.nome} - Telefono ${record.telefono}  `} />
                    </ReferenceField>
                </ReferenceField>
                <TextField source="dataIstanza" />
            </EditableDatagrid>
        </ReferenceManyField>
    );
};

/* ****************** */

/* TODO: da creare componente custom */

const CustomAvatarImage = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;



    return (
        <Box height="80%" width="80%" display="flex" justifyContent="left" alignItems="top">
            {record[source] ? (
                // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                <Avatar alt={label} style={{ width: '100px', height: '100px' }} src={record[source]} />
            ) : (
                <p><Avatar alt="Immagine mancante" style={{ width: '100px', height: '100px' }}><ImageNotSupportedIcon /></Avatar></p>
            )}
        </Box>
    );
};


const LaLista = () => {
    /*      const { data, total, loading, error } = useQuery({
             type: 'getList',
             resource: 'items',
             payload
         }); */
    const { data, isLoading, error } = useGetList(
        'istanze-servizi-aggiuntivi',
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <div>
            {/* <p>Total items: {total}</p>
             <ul>
                 {data.map(item => <li>
                {item.nome}
                 </li>)}
             </ul> */}
        </div>
    );
};

const ListaServiziAggiuntivi = ({ istanzeEscursioniId, offSetData, operatoriId }) => {
    // const redirect = useRedirect();
    const record = useRecordContext();
    const { data, isLoading, error } = useGetList(
        'istanze-servizi-aggiuntivi',
        {
            pagination: { page: 1, perPage: 15 },
            sort: { field: 'id', order: 'ASC' },
            filter: { istanzeEscursioniId: record.id }
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return (

        <><h2>Lista Servizi Aggiuntivi</h2>
            {data.map(record =>
                <ul>

                    <ListItem
                        secondaryAction={
                            <IconButton onClick={() => {
                                {
                                    /*                                    // redirect('calendario-turni/' + record.id +'/show'); // TODO: da rivedere, sarebbe meglio puntare al dettaglio turno
                                                                       let tmp = 'calendario-turni/?filter=%7B"impiantiId"%3A' + impiantiId + '%7D';
                                                                       redirect(tmp); */
                                }
                            }} aria-label="continue">

                            </IconButton>
                        }
                    >

                        <ListItemAvatar>
                            <Avatar>
                                <FolderIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={new Date(record.data).toLocaleDateString("it-IT", options) + "- serviziAggiuntiviId: " + record.serviziAggiuntiviId}
                            secondary={<p>
                                <strong>istanzeEscursioniId: {(record.istanzeEscursioniId) ? record.istanzeEscursioniId : ""}</strong>

                                <br></br>
                                <strong>{/* Lista operatori */}</strong>
                                <br></br>
                                {/* <TurniDelGiornoOperatoriViewer calendarioTurniId={record.id} ></TurniDelGiornoOperatoriViewer> */}
                                <br></br>
                                <strong>{/* Consegna: {(record.consegna) ? <div dangerouslySetInnerHTML={{ __html: record.consegna }} /> : ""} */}</strong>

                                {/*  {(operatoriId != record.id) 
                              ? 
                              "Sei di turno"
                              : 
                              "Non in turno"
                              }   */}
                            </p>}

                        // multiline={"Settore: " + record.nome}
                        />
                    </ListItem>
                </ul>

            )}
        </>);
}

const ListaServiziAggiuntiviCard = ({ istanzeEscursioniId, offSetData, operatoriId }) => {
    // const redirect = useRedirect();
    const record = useRecordContext();
    const { data, isLoading, error } = useGetList(
        'istanze-servizi-aggiuntivi',
        {
            pagination: { page: 1, perPage: 15 },
            sort: { field: 'id', order: 'ASC' },
            filter: { istanzeEscursioniId: record.id }
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return (

        <><h2>Lista Servizi Aggiuntivi</h2>
            {data.map(record =>
                <ul>
                    <Card mx={{ maxWidth: 345 }}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                                    S
                                </Avatar>
                            }
                            title="TITOLO SERVIZIO AGGIUNTIVO"
                            subheader={new Date(record.data).toLocaleDateString("it-IT", options)}
                        />
                        {/*                         <CardMedia
                            component="img"
                            height="194"
                            image="PATH IMG SERVIZIO AGGIUNTIVO"
                            alt="TESTO ALTERNATIVO"
                        /> */}
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                Id servizio aggiuntivo {record.serviziAggiuntiviId}
                                <br></br>
                                {record.serviziAggiuntiviId}
                                <br></br>
                                {record.costoAdult}
                                <br></br>
                                {record.costoInfant}
                                <br></br>
                                {record.costoChild}
                            </Typography>
                        </CardContent>
                    </Card>
                </ul>
            )}
        </>);
}

const ValutaBandieraLingua = ({ source }) => {
    const record = useRecordContext();
    let lingua = "";

    switch (record[source]) {
        case 1:
            lingua = "http://imgm.btechitalia.it/flags/flagita.png"
            break;
        case 2:
            lingua = "http://imgm.btechitalia.it/flags/flaguk.png"
            break;
        case 3:
            lingua = "http://imgm.btechitalia.it/flags/flagpol.png"
            break;
        case 4:
            lingua = "http://imgm.btechitalia.it/flags/flagger.png"
            break;
        case 5:
            lingua = "http://imgm.btechitalia.it/flags/flagpor.png"
            break;
        case 6:
            lingua = "http://imgm.btechitalia.it/flags/flagspa.png"
            break;
        case 7:
            lingua = "http://imgm.btechitalia.it/flags/flagfra.png"
            break;
        default:
            lingua = ""
            break;
    }


    return (
        <><div>
            <img src={lingua} width="20" height="20" />
        </div></>
    );
};

/* ***************************** */

/* GESTIONE PUNTI INCONTRO */

/* Tipo raduno STATICO */
const tipoRadunoDescrizione = {
    0: 'Punto d\'incontro',
    1: 'Punto di partenza',
    2: 'Incontro/partenza',
};

const EscursioniPuntiincontroLingueRowForm = ({ id, ...props }) => {
    return (
        <RowForm {...props}>
            <RichTextInput
                toolbar={<RichTextInputToolbar size="large" />}
                label="Descrizione" source="descrizione" />
        </RowForm>
    );
}

const EscursioniPuntiincontroInShow = ({ id, ...props }) => {

    const { permissions } = usePermissions();
    const record = useRecordContext();
    if (!record) return null;

    var redirectPath = '/istanze-escursioni/' + record.id + '/show/puntiincontropartenza';

    return (
        <ReferenceManyField
            reference="istanze-escursioni-puntiincontro"
            target="istanzeEscursioniId"
            sort={{ field: 'id', order: 'ASC' }}
            label=""
        >
            {/* TASTO PER L'AGGIUNTA DI UN PUNTO */}

            <EditableDatagrid
                noDelete
                bulkActionButtons={false}
                editForm={null}
            /* header={<AddPunto IstanzeEscursioniId={record.id} mezziId={record.mezziId} redirectPath={redirectPath}></AddPunto>} */
            >

                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            Dettaglio punto
                            {/* <br></br>
                            <TextField source="id" />
                            <br></br>
                            <TextField source="puntiincontroId" /> */}
                        </Typography>

                        {/* <div><strong>"Tipo: "<strong>{tipoRadunoDescrizione[record.tipoRaduno]}</strong> */}

                        <FunctionField
                            render={record => (<div>TIPO :<strong>{tipoRadunoDescrizione[record.tipoRaduno]}</strong></div>)}
                        />
                        <ReferenceField source="puntiincontroId" reference="puntiincontro">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>

                                    <Chip
                                        label={
                                            <Typography color="textSecondary" variant="h6" component="div" gutterBottom>
                                                <TextField source="nome" />
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" gutterBottom>
                                        <Box sx={{
                                            display: 'flex', // Usa flexbox per allineare gli elementi
                                            alignItems: 'center', // Allinea gli elementi verticalmente al centro
                                            gap: 1, // Aggiunge uno spazio tra gli elementi
                                        }}>
                                            Visualizza su Google Maps
                                            <GoogleMapsLink />
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" gutterBottom>
                                        Lat: <TextField source="latitudine" />
                                        <br></br>
                                        Lon: <TextField source="longitudine" />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ReferenceField>
                        <Grid container spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item>
                                {/* {record && <DeleteButton label="Rimuovi" basePath="/" record={record} undoable={false} />} */}
                                {(permissions === 'admin') ?? <ConfirmDialogUpdateButtonCustom
                                    titolo={"Punto d\'incontro"}
                                    messaggio={"Sei sicuro di aggiornare il punto dalla lista ?"}
                                    fieldToUpdate={"tipoRaduno"}
                                    fieldValue={0}
                                    servizio={"istanze-escursioni-puntiincontro"}
                                    redirectPath={redirectPath}
                                />}
                            </Grid>
                            <Grid item>
                                {/* {record && <DeleteButton label="Rimuovi" basePath="/" record={record} undoable={false} />} */}
                                {(permissions === 'admin') ?? <ConfirmDialogUpdateButtonCustom
                                    titolo={"Punto di partenza"}
                                    messaggio={"Sei sicuro di aggiornare il punto dalla lista ?"}
                                    fieldToUpdate={"tipoRaduno"}
                                    fieldValue={1}
                                    servizio={"istanze-escursioni-puntiincontro"}
                                    redirectPath={redirectPath}
                                />}
                            </Grid>
                            <Grid item>
                                {/* {record && <DeleteButton label="Rimuovi" basePath="/" record={record} undoable={false} />} */}
                                {(permissions === 'admin') ?? <ConfirmDialogUpdateButtonCustom
                                    titolo={"Incontro/partenza"}
                                    messaggio={"Sei sicuro di aggiornare il punto dalla lista ?"}
                                    fieldToUpdate={"tipoRaduno"}
                                    fieldValue={2}
                                    servizio={"istanze-escursioni-puntiincontro"}
                                    redirectPath={redirectPath}
                                />}
                            </Grid>
                        </Grid>
                        {(permissions === 'admin') ?? <Grid container spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item>
                                {/* {record && <DeleteButton label="Rimuovi" basePath="/" record={record} undoable={false} />} */}
                                <ConfirmDialogDeleteButtonCustom
                                    titolo={"Cancella punto"}
                                    messaggio={"Sei sicuro di voler togliere il punto dalla lista ?"}
                                    servizio={"istanze-escursioni-puntiincontro"}
                                    redirectPath={redirectPath}
                                />
                            </Grid>
                        </Grid>}

                    </CardContent>
                </Card>
                <ReferenceManyField
                    reference="istanze-escursioni-puntiincontro-lingue"
                    target="istanzeEscursioniPuntiincontroId"
                    sort={{ field: 'id', order: 'ASC' }}
                >

                    {(permissions === 'admin') ?
                        <EditableDatagrid noDelete
                            bulkActionButtons={false}
                            editForm={<EscursioniPuntiincontroLingueRowForm />}
                        /* NB: non serve un create */
                        >
                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                            <RichTextField label="Descrizione" source="descrizione" />

                        </EditableDatagrid> :
                        <Datagrid
                            bulkActionButtons={false}
                        >
                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                            <RichTextField label="Descrizione" source="descrizione" />
                        </Datagrid>}
                </ReferenceManyField>
            </EditableDatagrid>
        </ReferenceManyField>);
}

/* const AddPunto = ({ IstanzeEscursioniId, mezziId, redirectPath }) => {
    const record = useRecordContext(); // Record escursione
    console.log(record);
    const notify = useNotify();
    const refresh = useRefresh();
    const [selectedValue, setSelectedValue] = useState({ puntiincontroId: null, tipoRaduno: 0 }); // Aggiungi lo stato per memorizzare il valore selezionato

    // const payload = { IstanzeEscursioniId: 1, puntiincontroId: 17, tipoRaduno: 0 };
    const payload = {
        IstanzeEscursioniId: 1, // Assumo che questo possa essere statico o dinamico
        puntiincontroId: selectedValue.puntiincontroId,
        tipoRaduno: selectedValue.tipoRaduno, // Assumo che questo valore possa essere determinato in qualche modo
    };
    const [create, { isLoading, error }] = useCreate('istanze-escursioni-puntiincontro', { data: payload },
        {
            onSuccess: () => {
                notify('Punto aggiunto.', 'info');
                console.log(payload);
                refresh();
            },
            onFailure: (error) => {
                notify(`Errore: ${error.message}`, 'warning');
            }
        });
    const handleClick = () => {
        const updatedPayload = {
            IstanzeEscursioniId: IstanzeEscursioniId,
            puntiincontroId: selectedValue.puntiincontroId,
            tipoRaduno: selectedValue.tipoRaduno,
        };
        console.log('Payload prima di create:', updatedPayload);
        create('istanze-escursioni-puntiincontro', { data: updatedPayload },
            {
                onSuccess: () => {
                    notify('Punto aggiunto.', 'info');
                    console.log(payload);
                    refresh();
                },
                onFailure: (error) => {
                    notify(`Errore: ${error.message}`, 'warning');
                }
            }).catch((e) => {
                notify(`Errore: ${e}`, 'warning');
            });
    }


    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleValueChange = (newValue) => {
        console.log("--------------------------------");
        console.log(newValue);
        setSelectedValue(prevState => ({
            ...prevState,
            puntiincontroId: newValue.puntiincontroId,
            tipoRaduno: newValue.tipoRaduno
        }));
    };



    if (error) {
        // return <div></div>;
        console.log("ERRORE");
    }
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button sx={createButtonCss} disabled={isLoading} onClick={handleClick}>
            Seleziona {selectedValue.puntiincontroId}
        </Button>
        <CustomSelectMezziPuntiincontro mezziId={mezziId} onValueChange={handleValueChange} />
    </div>
        ;

}; */

const EscursioniShowServiziLingueDisponibiliRowForm = ({ id, ...props }) => (
    <RowForm {...props}>

        <TextInput source="istanzeEscursioniId" defaultValue={parseInt(id)} disabled ></TextInput>
        <CustomAutocompleInput source="serviziId" reference="servizi" optionProperty="descrizione" label="" ></CustomAutocompleInput>

    </RowForm>
);

const GestioneServziDisponibiliInShow = ({ id, ...props }) => {

    const { permissions } = usePermissions();
    const record = useRecordContext();
    if (!record) return null;

    return ((permissions === 'admin') ?
        <EditableDatagrid /* noDelete */
            bulkActionButtons={false}
            editForm={<EscursioniShowServiziLingueDisponibiliRowForm />}
            createForm={<EscursioniShowServiziLingueDisponibiliRowForm id={record.id} />}>
            {<ReferenceField link={null} source="serviziId" reference="servizi" >
                <TextField source="descrizione"></TextField>
            </ReferenceField>}
        </EditableDatagrid> :
        <Datagrid /* noDelete */
            bulkActionButtons={false}>
            {<ReferenceField link={null} source="serviziId" reference="servizi" >
                <TextField source="descrizione"></TextField>
            </ReferenceField>}
        </Datagrid>);

}




/* ORDINI - inizio */
const GestioneOrdiniInShow = ({ id, ...props }) => {

    const { permissions } = usePermissions();
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Datagrid
            bulkActionButtons={false}>

            <TextField source="id"></TextField>

        </Datagrid>);

}
/* ORDINI - fine */

const GestioneIstanzeLingueGuidaDisponibiliRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="istanzeEscursioniId" defaultValue={parseInt(id)} disabled ></TextInput>

        {/*         {<ReferenceInput source="lingueGuidaId" reference="lingue" >
            <SelectInput source="descrizioneLingua"></SelectInput>
        </ReferenceInput>} */}
        <CustomAutocompleInput source="lingueGuidaId" reference="lingue" optionProperty="descrizioneLingua" label="" ></CustomAutocompleInput>

    </RowForm>
);

const GestioneIstanzeLingueDisponibiliInShow = ({ id, ...props }) => {

    const { permissions } = usePermissions();
    const record = useRecordContext();
    if (!record) return null;

    return ((permissions === 'admin') ?
        <EditableDatagrid /* noDelete */
            bulkActionButtons={false}
            editForm={<GestioneIstanzeLingueGuidaDisponibiliRowForm />}
            createForm={<GestioneIstanzeLingueGuidaDisponibiliRowForm id={record.id} />}>
            {<ReferenceField link={null} source="lingueGuidaId" reference="lingue" >
                <FunctionField render={record => <div>
                    <img src={record.urlBandierina} width="20" height="20" />
                </div>} ></FunctionField>
                <TextField source="descrizioneLingua"></TextField>
            </ReferenceField>}
        </EditableDatagrid> :
        <Datagrid /* noDelete */
            bulkActionButtons={false}>
            {<ReferenceField link={null} source="lingueGuidaId" reference="lingue" >
                <ImageField source="urlBandierina" label="Nazione" />
                <TextField source="descrizioneLingua"></TextField>
            </ReferenceField>}
        </Datagrid>);

}

/* ******************************** FORNITORE */
const GetFornitore = ({ source, reference }) => {

    const record = useRecordContext();
    const { data: data, isLoading, error } = useGetOne(reference, { id: record[source] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <strong>{data.id}</strong>;

}

/* ***************************** */

const ListActions = () => (
    <TopToolbar>
        <ExportButton label="Esporta Dati Ordine" sx={exportButtonCss} />
    </TopToolbar>
);

/* 
<TextField source="nome_completo"></TextField>
                                            <br></br>
                                            <TextField source="nr_cellulare"></TextField>
                                            <br></br>
                                            <TextField source="email"></TextField>
                                            <br></br>
                                            <TextField source="data_nascita"></TextField>
*/

const mioExporter = ordinipartecipantis => {

    // TODO: da prevedere il recupero dell'id dell'istanza
    /* const record = useRecordContext();
    if (!record) return null; */

    // console.log(ordinipartecipantis);
    // var istanzeEscursioniId = record.istanzeEscursioniId;
    const idsForExport = ordinipartecipantis.map(partecipante => {
        return {
            ordiniId: partecipante.ordiniId,
            id: partecipante.id,
            // istanzeEscursioniId: istanzeEscursioniId,
            nome_completo: partecipante.nome_completo,
            nr_cellulare: partecipante.nr_cellulare,
            email: partecipante.email,
            data_nascita: partecipante.data_nascita
        }; // Specifica i campi che devono essere inseriti
    });

    console.log(idsForExport);

    jsonExport(idsForExport, {
        headers: [/* 'Id Ordine', 'Id Partecipante', 'Nome Completo', 'Cellulare', 'Email', 'Data di nascita' */] // Lista degli headers
    }, (err, csv) => {
        downloadCSV(csv, 'lista-partecipanti-ordine-'/*  + istanzeEscursioniId */); // il seconndo argomento è il nome del file .csv
    });
};

/* ***************************** */

export const IstanzeEscursioniShow = ({ id, ...props }) => {


    // const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>
                &nbsp;Dettaglio Istanza Escursione
            </Button>
            <TextField source="id"></TextField>
        </>} {...props} id={id} actions={null} /* aside={<Aside />} */>

            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dati base escursione" icon={<TopicIcon></TopicIcon>}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>

                            <Card sx={{ minWidth: 275, boxShadow: 3, mb: 2 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 25, mb: 1, fontWeight: 'medium' }} color="text.secondary">
                                        Data Istanza Escursione
                                    </Typography>
                                    <FunctionField
                                        source="data"
                                        render={record => {
                                            const date = new Date(record.data);
                                            const day = String(date.getDate()).padStart(2, '0');
                                            const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi partono da 0
                                            const year = date.getFullYear();
                                            const formattedDate = `${day}/${month}/${year}`;

                                            return (
                                                <Grid container alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <EventIcon sx={{ fontSize: 40, color: 'primary.main', verticalAlign: 'middle', color: '#01b5d0' }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                                                            <strong>{formattedDate}</strong>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }}
                                    />
                                </CardContent>
                            </Card>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Card sx={{ minWidth: 275, boxShadow: 3, mb: 2 }}>
                                        <CardContent>
                                            <ModificaOraInizio />
                                            <Typography sx={{ fontSize: 25, mb: 1, fontWeight: 'medium' }} color="text.secondary">
                                                Ora Inizio
                                            </Typography>
                                            <FunctionField
                                                source="dataOraInizio"
                                                render={record => {
                                                    const date = new Date(record.dataOraInizio);
                                                    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
                                                    const formattedTime = date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });

                                                    return (
                                                        <Grid container alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                <AccessTimeIcon sx={{ fontSize: 40, color: 'primary.main', verticalAlign: 'middle', color: '#01b5d0' }} />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                                                                    <strong>{`${formattedTime}`}</strong>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card sx={{ minWidth: 275, boxShadow: 3, mb: 2 }}>
                                        <CardContent>
                                            <ModificaOraFine />
                                            <Typography sx={{ fontSize: 25, mb: 1, fontWeight: 'medium' }} color="text.secondary">
                                                Ora Fine
                                            </Typography>
                                            <FunctionField
                                                source="dataOraFine"
                                                render={record => {
                                                    const date = new Date(record.dataOraFine);
                                                    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
                                                    const formattedTime = date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });

                                                    return (
                                                        <Grid container alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                <AccessTimeIcon sx={{ fontSize: 40, color: 'primary.main', verticalAlign: 'middle', color: '#01b5d0' }} />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                                                                    <strong>{`${formattedTime}`}</strong>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                            <Card sx={{ minWidth: 275, mb: 2 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                        <strong>Titolo ricerca</strong>
                                        <CopyToClipboardButton source="titoloricerca" />
                                    </Typography>
                                    <hr></hr>
                                    <Box display="flex" alignItems="center" justifyContent="flex-start" mb={1.5}>
                                        <ModificaReferente />
                                        <Box display="flex" alignItems="center" ml={1}>
                                            <Typography sx={{ fontSize: 24 }} color="text.secondary">
                                                <strong>Nominativo Referente: </strong>
                                            </Typography>
                                            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                                            <CopyToClipboardButton source="nominativoReferente" />
                                        </Box>
                                    </Box>
                                    <hr />
                                    <Box display="flex" alignItems="center" justifyContent="flex-start" mb={1.5}>
                                        <ModificaNoteArrivo />
                                        <Box display="flex" alignItems="center" ml={1}>
                                            <Typography sx={{ fontSize: 24 }} color="text.secondary">
                                                <strong>Note Arrivo: </strong>
                                            </Typography>
                                            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                                            <CopyToClipboardButton source="note_arrivo" />
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 275, boxShadow: 3, mb: 2 }}>
                                <CardContent>
                                    <ReferenceField source="escursioniId" reference="escursioni" link={false}>
                                        <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                                            <strong>Mezzo: </strong>

                                            <ReferenceField source="mezziId" reference="mezzi" link={false}>
                                                <Typography variant="subtitle1" component="div">
                                                    <FunctionField label="Tipo" sortBy="tipoMezzo" render={record => (
                                                        <>
                                                            {record.tipoMezzo === 0 ?
                                                                <DirectionsBoat sx={{ width: 56, height: 56, marginRight: 2, color: 'blue' }} /> :
                                                                <DirectionsCarSharp sx={{ width: 56, height: 56, marginRight: 2, color: 'green' }} />
                                                            }
                                                        </>
                                                    )} />
                                                </Typography>
                                                <FunctionField source="nome" label="Name"
                                                    render={record => record && <strong>{record.nome}</strong>}
                                                />
                                            </ReferenceField>
                                        </Typography>
                                        <hr></hr>
                                        <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                                            <strong>Fornitore: </strong>
                                            <ReferenceField source="fornitoriId" reference="fornitori" link={false}>
                                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                    <Grid item xs={12}>
                                                        <Card sx={{ minWidth: 275 }}>
                                                            <CardContent>
                                                                <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                                        <LocalOfferIcon />
                                                                        {/* Tipo Fornitore: <TextField label="Nome" source="nome" /> */}
                                                                        <ReferenceField source="tipoFornitoreId" reference="tipo-fornitore">
                                                                            <TextField source="descrizione" />
                                                                        </ReferenceField>
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                                        <HomeIcon />
                                                                        Ragione Sociale: <TextField label="Nome" source="nome" />
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                                                        <PersonIcon />
                                                                        Referente: <TextField label="Referente" source="referente" />
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                                        <WorkIcon />
                                                                        Partita Iva: <TextField label="Partita Iva" source="partitaiva" />
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                                        <PhoneIcon />
                                                                        Telefono: <TextField label="Telefono" source="telefono" />
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                                        <PhoneIcon />
                                                                        Cellulare: <TextField label="Cellulare" source="cellulare" />
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                                        <EmailIcon />
                                                                        Email: <TextField label="Email" source="email" />
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                                        <HomeIcon />
                                                                        Indirizzo: <TextField label="Indirizzo" source="indirizzo" />
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                                        <NoteIcon />
                                                                        Note: <RichTextField label="Note" source="note" />
                                                                    </Box>
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </ReferenceField>
                                        </Typography>
                                        <hr></hr>
                                    </ReferenceField>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Punti d'incontro e di partenza" path="puntiincontropartenza" icon={<TopicIcon></TopicIcon>}
                    count={<ReferenceManyCount
                        reference="istanze-escursioni-puntiincontro"
                        target="IstanzeEscursioniId" />}>
                    <Card sx={{ minWidth: 275, boxShadow: 3, mb: 2 }}>
                        <CardContent>
                            <ReferenceField source="escursioniId" reference="escursioni" link={false}>
                                <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                                    <strong>Mezzo: </strong>

                                    <ReferenceField source="mezziId" reference="mezzi" link={false}>
                                        <Typography variant="subtitle1" component="div">
                                            <FunctionField label="Tipo" sortBy="tipoMezzo" render={record => (
                                                <>
                                                    {record.tipoMezzo === 0 ?
                                                        <DirectionsBoat sx={{ width: 56, height: 56, marginRight: 2, color: 'blue' }} /> :
                                                        <DirectionsCarSharp sx={{ width: 56, height: 56, marginRight: 2, color: 'green' }} />
                                                    }
                                                </>
                                            )} />
                                        </Typography>
                                        <FunctionField source="nome" label="Name"
                                            render={record => record && <strong>{record.nome}</strong>}
                                        />
                                    </ReferenceField>
                                </Typography>
                                <hr></hr>
                            </ReferenceField>
                        </CardContent>
                    </Card>

                    <Accordion expanded={true} sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>
                                <PostAddIcon></PostAddIcon>&nbsp;Punti d'incontro e di partenza
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    {/* <InitPuntiPartenzaButtonCustom
                                        titolo={"Preleva Punti associati al Mezzo"}
                                        messaggio={"Vuoi inizializzare i punti d'incontro ?"}
                                        fieldToUpdate={"tipoRaduno"}
                                        fieldValue={0}
                                        servizio={"istanze-escursioni-puntiincontro"}
                                        redirectPath={"puntiincontropartenza"}
                                    /> */}
                                    {/* <ModificaAggiungiPuntoGeografico mezziId={7}></ModificaAggiungiPuntoGeografico> */}
                                    <ReferenceManyField
                                        reference="istanze-escursioni-puntiincontro"
                                        target="IstanzeEscursioniId"
                                        sort={{ field: 'id', order: 'ASC' }}
                                        filter={{ visibile: true }}
                                    >

                                        <EscursioniPuntiincontroInShow></EscursioniPuntiincontroInShow>

                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Servizi" path="servizi" icon={<TopicIcon></TopicIcon>}
                    count={<ReferenceManyCount
                        reference="istanze-escursioni-servizi"
                        target="istanzeEscursioniId" />}>
                    <Accordion expanded={true} sx={fieldAccordion}>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="istanze-escursioni-servizi"
                                        target="istanzeEscursioniId"
                                        sort={{ field: 'id', order: 'ASC' }}
                                    // filter={{ visibile: true }}
                                    >

                                        <GestioneServziDisponibiliInShow />

                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Lingue disponibili" path="linguedisponibili" icon={<TopicIcon></TopicIcon>}
                    count={<ReferenceManyCount
                        reference="lingue-istanze-escursioni"
                        target="istanzeEscursioniId" />}>
                    <Accordion expanded={true} sx={fieldAccordion}>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField label="Lingue Guida Disponibili" reference="lingue-istanze-escursioni" target="istanzeEscursioniId">
                                        {/*   <Datagrid>
                                            <TextField source="id" />
                                            <TextField source="lingueGuidaId" />
                                        </Datagrid> */}
                                        <GestioneIstanzeLingueDisponibiliInShow></GestioneIstanzeLingueDisponibiliInShow>
                                    </ReferenceManyField>
                                    {/* <GestioneIstanzeLingueDisponibiliInShow></GestioneIstanzeLingueDisponibiliInShow> */}
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Posti disponibili" path="postiDisponibili" icon={<TopicIcon></TopicIcon>} >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={10}>
                            <Card sx={{ minWidth: 275, mb: 2 }}>
                                <CardContent>
                                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                                        <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary" style={{ marginRight: '8px' }}>
                                            <strong>Partecipanti Max : </strong>
                                            <FunctionField
                                                source="partecipantiMax"
                                                label="Name"
                                                render={record => record && `${record.partecipantiMax ?? 0}`}
                                            />
                                        </Typography>
                                        <ModificaCapienzaMax></ModificaCapienzaMax>
                                    </Box>
                                    <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                        {/* <strong>Partecipanti Totali : </strong> */}
                                        {/*  <FunctionField
                                            source="partecipanti"
                                            label="Name"
                                            render={record => record && `${record.partecipanti ?? 0}`}
                                        /> */}
                                        <ReferenceOneField label="" target="istanzeEscursioniId" reference="wapp-istanze-posti-occupati">


                                            <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                                <strong>Totale Partecipanti : </strong>
                                                <TextField source="totalePartecipanti" />
                                            </Typography>
                                            <hr></hr>
                                            <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                                <strong>Adult : </strong>
                                                <TextField source="adult" />
                                            </Typography>
                                            <hr></hr>
                                            <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                                <strong>Child : </strong>
                                                <TextField source="child" />
                                            </Typography>
                                            <hr></hr>
                                            <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                                <strong>Infant : </strong>
                                                <TextField source="infant" />
                                            </Typography>
                                            <hr></hr>
                                            <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                                <strong>Posti disponibili ( ordini ) : </strong>
                                                <TextField source="postiDisponibili" />
                                            </Typography>
                                            <hr></hr>
                                            <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                                <strong>Ordini count : </strong>
                                                <TextField source="numOrdini" />
                                            </Typography>


                                        </ReferenceOneField>
                                    </Typography>
                                    <hr />
                                    {/*                                     <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                        <strong>Posti disponibili ( istanza ) : </strong>
                                        <FunctionField
                                            source="partecipanti"
                                            label="Name"
                                            render={record => record && `${((record.partecipantiMax ?? 0) - (record.partecipanti ?? 0)) ?? 0}`}
                                        />
                                    </Typography> */}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Alert severity="info">N.B. : Se non ci sono ordini d'acquisto la disponibilità è pari a Partecipanti Max. </Alert>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Partecipanti" path="elencopersone" icon={<TopicIcon></TopicIcon>}
                /* TODO: da completare */
                /* count={

                    <ReferenceManyField
                        reference="ordini"
                        target="istanzeEscursioniId"
                        sort={{ field: 'id', order: 'ASC' }}
                    >
                        <ReferenceManyCount
                            reference="ordini-partecipanti"
                            target="ordiniId" />
                    </ReferenceManyField>} */
                >
                    {/* <Grid item xs={12}>
                        <Alert severity="info">Elenco persone determinato dagli ordini.</Alert>
                    </Grid> */}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={10}>

                            {<FunctionField
                                source="id"
                                label=""
                                render={record => record && <ExportAllPartecipantiButton
                                    istanzeEscursioniId={record.id}
                                    fields={[
                                        'id',
                                        'ordiniId',
                                        'nome_completo',
                                        'email',
                                        'nr_cellulare'
                                    ]} />}
                            />}
                            <br></br>
                            <br></br>
                            {<FunctionField
                                source="id"
                                label=""
                                render={record => record && <ExportAllPartecipantiPdf istanzeEscursioniId={record.id}

                                    titoloricerca={record.titoloricerca}
                                    data={record.data}
                                    fields={[
                                        'id',
                                        'ordiniId',
                                        'nome_completo',
                                        'email',
                                        'nr_cellulare'
                                    ]} />}
                            />}
                            <br></br>
                            {/* TODO: componente che chiama servizio generazione pdf esterno - da completare */}
                            {/*  {<FunctionField
                                source="id"
                                label=""
                                render={record => record && <ExportPdfVoucherFromService
                                    istanzeEscursioniId={record.id}
                                    fields={[
                                        'id',
                                        'ordiniId',
                                        'nome_completo',
                                        'email',
                                        'nr_cellulare'
                                    ]} />}
                            />}
                            <br></br> */}


                            {/* <ListaPartecipanti ordiniId={141}></ListaPartecipanti> */}
                            {/* <ListaOrdiniPartecipanti istanzeEscursioniId={724}></ListaOrdiniPartecipanti> */}
                            {<FunctionField
                                source="id"
                                label=""
                                render={record => record && <ListaOrdiniPartecipanti istanzeEscursioniId={record.id}></ListaOrdiniPartecipanti>}
                            />}
                            {/* <ReferenceManyField
                                reference="ordini"
                                target="istanzeEscursioniId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <Datagrid

                                    bulkActionButtons={false}
                                ><ReferenceManyField
                                    reference="ordini-partecipanti"
                                    target="ordiniId"
                                    sort={{ field: 'id', order: 'ASC' }}
                                >
                                        <TextField source="id"></TextField>
                                        <FunctionField
                                            source="id"
                                            label=""
                                            render={record => record && <ListaPartecipanti ordiniId={id}></ListaPartecipanti>}
                                        />
                                     

                                    </ReferenceManyField>
                                </Datagrid>
                            </ReferenceManyField> */}
                            {/* <List
                                            pagination={false}
                                            exporter={mioExporter}
                                            filters={null}> */}
                        </Grid>
                        {/*                         <Grid item xs={10}>
                            <ReferenceManyField
                                reference="ordini"
                                target="istanzeEscursioniId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <List
                                    pagination={false}
                                    exporter={mioExporter}
                                    filters={null}>
                                    <Datagrid

                                        bulkActionButtons={false}
                                    >
                                        <ReferenceField label="Cliente" link={false} source="userId" reference="users" >
                                            <TextField source="cognome"></TextField>
                                            <br></br>
                                            <TextField source="nome"></TextField>
                                            <br></br>
                                            <TextField source="email"></TextField>
                                            <br></br>
                                        </ReferenceField>
                                        <FunctionField
                                            source="userId"
                                            label="Dettaglio Cliente"
                                            render={record => {
                                                return (<Grid container alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <Button
                                                            component={Link}
                                                            to={`/users/${record.userId}/show`}
                                                            style={{ minWidth: 'auto', padding: 0 }}
                                                        >
                                                            Dettaglio&nbsp;<VisibilityIcon style={{ color: 'inherit' }} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>)
                                            }}
                                        />
                                    </Datagrid>
                                </List>
                            </ReferenceManyField>
                        </Grid> */}
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Ordini" path="ordini" icon={<TopicIcon></TopicIcon>}
                    count={<ReferenceManyCount
                        reference="ordini"
                        target="istanzeEscursioniId" />}
                >

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={10}>
                            <ReferenceManyField
                                reference="ordini"
                                target="istanzeEscursioniId"

                            >
                                <FunctionField
                                    source="id"
                                    label=""
                                    render={record => record && <>
                                        <List title={<>
                                        </>} actions={<ListActions />} filter={{ istanzeEscursioniId: record.id }} /*  exporter={mioExporter}  */>
                                            <Datagrid

                                                bulkActionButtons={false}
                                            >
                                                <FunctionField
                                                    source="id"
                                                    label="Id Ordine"
                                                    render={record => {
                                                        return (<Grid container alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <h2><span>{record.id}</span></h2>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button
                                                                    component={Link}
                                                                    to={`/ordini/${record.id}/show`}
                                                                    style={{ minWidth: 'auto', padding: 0 }}
                                                                >
                                                                    Dettaglio&nbsp;<VisibilityIcon style={{ color: 'inherit' }} />
                                                                </Button>
                                                            </Grid>
                                                        </Grid>)
                                                    }}
                                                />
                                            </Datagrid>
                                        </List>
                                    </>}
                                />


                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Gestione Users del Mezzo" path="users-mezzo" icon={<TopicIcon />}>
                    <Card sx={{ minWidth: 275, boxShadow: 3, mb: 2 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Operatore di verifica Voucher - Associazione Users al Mezzo per questa Istanza
                            </Typography>
                            <UsersManagementTab />
                        </CardContent>
                    </Card>
                </TabbedForm.Tab>
            </TabbedForm>
        </Show >
    );
}

// const redirect = (basePath, id, data) => `/author/${data.author_id}/show`;
const redirect = () => `/istanze-escursioni`;

const MyPage = () => {
    const { setValue } = useFormContext();

    const [fornitoriId, setFornitoriId] = useState(null);
    const [motonaviFilter, setMotonaviFilter] = useState(null);
    const [motonaviVisible, setMotonaviVisible] = useState(false);

    function handleChangeFornitoriId(value) {

        setMotonaviFilter("");
        setMotonaviFilter(value);
        setMotonaviVisible(true);
    }

    useEffect(() => {
        if (fornitoriId) {
            console.log(fornitoriId);
            setMotonaviFilter({ fornitoriId });
        } else {
            setMotonaviFilter({});
        }
    }, [fornitoriId]);

    return (
        <>
            <ReferenceInput
                perPage={100}
                source="fornitoriId"
                reference="fornitori"
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <SelectInput validate={[required()]} optionText="nome"
                    onChange={(event, key, newValue) => {
                        // console.log(event.target.value);
                        // setFornitoriId(newValue);
                        handleChangeFornitoriId(event.target.value)
                    }}

                />
            </ReferenceInput>


            {motonaviVisible && (
                <>
                    <br />
                    <ReferenceInput
                        perPage={100}
                        source="motonaviId"
                        reference="motonavi"
                        filter={{ fornitoriId: motonaviFilter }}
                        sort={{ field: 'nome', order: 'ASC' }}
                    >
                        <SelectInput
                            resettable
                            validate={[required()]} optionText="nome" />
                    </ReferenceInput>
                </>
            )}
        </>
    );
};

export const IstanzeEscursioniCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={redirect}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {/* Input per la data ed ora */}
                                {<DateTimeInput source="dataOraInizio" title="Data Inizio escursione" validate={[required()]} />}
                                <br></br>
                                {<DateTimeInput source="dataOraFine" title="Data Fine escursione" validate={[required()]} />}
                                <br></br>
                                {<DateInput source="data" title="Data Giornaliera escursione" validate={[required()]} />}
                                <br></br>
                                {/* {<TextInput source="fornitore" label="Fornitore" validate={[required()]} />} */}
                                {/*   {<ReferenceInput
                                    perPage={100}
                                    source="fornitoriId"
                                    reference="fornitori"
                                    // filter={{ lingueId: 1 }}
                                    sort={{ field: 'nome', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="nome" />

                                </ReferenceInput>}
                                <br></br>
                                {<ReferenceInput
                                    perPage={100}
                                    source="motonaviId"
                                    reference="motonavi"
                                    filter={{ fornitoriId: 3 }}
                                    sort={{ field: 'nome', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="nome" />

                                </ReferenceInput>} */}
                                <br></br>
                                <MyPage></MyPage>
                                <br></br>
                                {<TextInput source="note_arrivo" label="Note Arrivo" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Partecipanti" source="partecipanti" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={1} label="PartecipantiMax" source="partecipantiMax" helperText="Valore minimo 1" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Adulti" source="tarAdulti" defaultValue={0} validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Infanti" source="tarInfanti" defaultValue={0} validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Bambini" source="tarBambini" defaultValue={0} validate={[required()]} />}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {/*                         <ReferenceOneField label="Escursione" reference="istanze-escursioni" target="idEscursione">
                            <TextField source="genre" />
                        </ReferenceOneField> */}
                                {/*  <ReferenceInput label="Escursione" source="escursioneId" reference="istanze-escursioni">
                            <SelectInput optionText="id" />
                        </ReferenceInput> */}
                                {/* <ReferenceInput perPage={100} source="IstanzeEscursioniId" reference="escursioni">
                        <SelectInput  optionText="citta" />
                        </ReferenceInput> */}
                                {<ReferenceInput
                                    perPage={100}
                                    source="IstanzeEscursioniId"
                                    reference="escursioni"
                                    // filter={{ lingueId: 1 }}
                                    sort={{ field: 'titoloricerca', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="titoloricerca" />
                                    {/* <AutocompleteInput source="titoloricerca" optionValue="titoloricerca" /> */}
                                </ReferenceInput>}
                                {/* {<TextInput source="IstanzeEscursioniId" label="Fornitore" validate={[required()]} />} */}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

// const record = useRecordContext();

export const IstanzeEscursioniEdit = ({ id, ...props }) => {
    return (
        <Edit {...props} id={id}>
            <SimpleForm>
                <SimpleShowLayout>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {/* Input per la data ed ora */}
                                        {<DateTimeInput source="dataOraInizio" title="Data Inizio escursione" validate={[required()]} />}
                                        <br></br>
                                        {<DateTimeInput source="dataOraFine" title="Data Fine escursione" validate={[required()]} />}
                                        <br></br>
                                        {<DateInput source="data" title="Data Giornaliera escursione" validate={[required()]} />}
                                        <br></br>
                                        {<TextInput source="fornitore" label="Fornitore" validate={[required()]} />}
                                        <br></br>
                                        {<TextInput source="note_arrivo" label="Note Arrivo" validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} label="Partecipanti" source="partecipanti" validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={1} label="PartecipantiMax" source="partecipantiMax" helperText="Valore minimo 1" validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} options={{ style: 'currency', currency: 'EUR' }} label="Tariffa Adulti" source="tarAdulti" defaultValue={0} validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} label="Tariffa Infanti" source="tarInfanti" defaultValue={0} validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} label="Tariffa Bambini" source="tarBambini" defaultValue={0} validate={[required()]} />}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        {<Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <br></br>
                                        <CercaTitolo id="id" testo="Titolo"></CercaTitolo>


                                        <CercaDescrizione id="id" testo="Descrizione"></CercaDescrizione>
                                        {/*                         <ReferenceInput perPage={100} source="IstanzeEscursioniId" reference="escursioni">
                        <SelectInput optionText="id" />
                        </ReferenceInput> */}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>}
                        <Grid item xs={10}>
                            {/* <h2>ID istanzeEscursioniId corrente</h2> */}
                            {/* <TextField source="id"></TextField> */}
                            <SetCurrentIstanzaEscursione source="id" data="data"></SetCurrentIstanzaEscursione>
                            <h2>Lista Servizi Aggiuntivi</h2>
                            {/* {<ReferenceInput
                        perPage={100}
                        source="istanze-servizi-aggiuntivi"
                        reference="escursioni"
                    >
                        <SelectInput validate={[required()]} optionText="nome" />
                    </ReferenceInput>} */}
                            <ReferenceManyField
                                reference="istanze-servizi-aggiuntivi"
                                target="istanzeEscursioniId"
                                fullWidth
                                perPage={10}
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                {/* <List filter={{ istanzeEscursioniId: 35 }}> */}
                                <EditableDatagrid /* noDelete */
                                    bulkActionButtons={false}
                                    editForm={<ServiziAggiuntiviRowFormCard defaultValues={{ serviziAggiuntiviId: id }} />}
                                    createForm={<ServiziAggiuntiviRowFormCard defaultValues={{ serviziAggiuntiviId: id }} />}

                                >
                                    {/* <TextField source="id"></TextField> */}
                                    {/* <TextField source="serviziAggiuntiviId"></TextField> */}
                                    {/* Creo una card per ogni istanza servizio aggiuntivo */}

                                    <Card mx={{ maxWidth: 345 }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                                                    S
                                                </Avatar>
                                            }
                                            title="DETTAGLIO SERVIZIO AGGIUNTIVO"
                                        // subheader={new Date(record.data).toLocaleDateString("it-IT", options)}
                                        />
                                        {/*                         <CardMedia
                            component="img"
                            height="194"
                            image="PATH IMG SERVIZIO AGGIUNTIVO"
                            alt="TESTO ALTERNATIVO"
                        /> */}
                                        <CardContent>
                                            {<ReferenceField
                                                perPage={100}
                                                source="serviziAggiuntiviId"
                                                reference="servizi-aggiuntivi"
                                            // filter={{ lingueId: 1 }}
                                            // sort={{ field: 'titoloRicerca', order: 'ASC' }}
                                            >
                                                Titolo servizio : <TextField source="titoloricerca" />
                                            </ReferenceField>}
                                            <Typography variant="body2" color="text.primary">
                                                {/* <TextField source="note"></TextField> */}
                                                <FormatNote source="note"></FormatNote>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {/* istanzeEscursioniId : <TextField source="istanzeEscursioniId"></TextField>
                                                <br></br>
                                                serviziAggiuntiviId : <TextField source="serviziAggiuntiviId"></TextField>
                                                <br></br> */}
                                                Costo Adulto : <TextField source="costoAdult"></TextField>
                                                <br></br>
                                                Costo Infante : <TextField source="costoInfant"></TextField>
                                                <br></br>
                                                Costo Child : <TextField source="costoChild"></TextField>
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                </EditableDatagrid>

                            </ReferenceManyField>

                            {/* <ListaServiziAggiuntivi istanzeEscursioniId="istanzeEscursioniId"></ListaServiziAggiuntivi> */}
                            {/* <ListaServiziAggiuntiviCard></ListaServiziAggiuntiviCard> */}
                        </Grid>
                    </Grid>
                </SimpleShowLayout>
            </SimpleForm>
        </Edit>
    );
}

export const ServiziAggiuntiviRowForm = (props) => (
    <RowForm {...props}>
        {<DateInput source="data" title="Data escursione" validate={[required()]} />}
        {<TextInput source="istanzeEscursioniId" label="istanzeEscursioniId" validate={[required()]} />}
        {<TextInput source="serviziAggiuntiviId" label="serviziAggiuntiviId" validate={[required()]} />}
        {/*         {<ReferenceInput
            perPage={100}
            source="serviziAggiuntiviId"
            reference="servizi-aggiuntivi"
        >
            <SelectInput validate={[required()]} optionText="titoloricerca" />
        </ReferenceInput>} */}
        {<NumberInput min={0} label="Tariffa Adulti" source="costoAdult" defaultValue={0} validate={[required()]} />}
        {<NumberInput min={0} label="Tariffa Infanti" source="costoInfant" defaultValue={0} validate={[required()]} />}
        {<NumberInput min={0} label="Tariffa Bambini" source="costoChild" defaultValue={0} validate={[required()]} />}
    </RowForm>
);

// const record = useRecordContext();

export const ServiziAggiuntiviRowFormCard = ({ istanzeEscursioniId, serviziAggiuntiviId, ...props }) => {

    // console.log(storeGenerale.getItem('profile.currentIstanzaEscursione'));;
    const idRecordIstanzeEscursioni = storeGenerale.getItem('profile.currentIstanzaEscursione');
    const dataRecordIstanzeEscursioni = storeGenerale.getItem('profile.currentIstanzaEscursioneData');
    return (
        <RowForm {...props}>
            <Card mx={{ maxWidth: 345 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            S
                        </Avatar>
                    }
                    title="MODIFICA SERVIZIO AGGIUNTIVO"
                // subheader={new Date(record.data).toLocaleDateString("it-IT", options)}
                />

                {/*                         <CardMedia
                            component="img"
                            height="194"
                            image="PATH IMG SERVIZIO AGGIUNTIVO"
                            alt="TESTO ALTERNATIVO"
                        /> */}
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {<ReferenceInput
                            perPage={100}
                            source="serviziAggiuntiviId"
                            reference="servizi-aggiuntivi"
                        // filter={{ lingueId: 1 }}
                        // sort={{ field: 'titoloRicerca', order: 'ASC' }}
                        >
                            <SelectInput validate={[required()]} optionText="titoloricerca" />
                        </ReferenceInput>}
                        {<RichTextInput source="note" title="Note" defaultValue={""} /* validate={[required()]} */ />}
                        <br></br>
                        <br></br>
                        {<DateInput disabled source="data" title="Data escursione" defaultValue={dataRecordIstanzeEscursioni} validate={[required()]} />}
                        <br></br>
                        {<TextInput disabled source="istanzeEscursioniId" label="istanzeEscursioniId" defaultValue={idRecordIstanzeEscursioni} validate={[required()]} />}
                        <br></br>
                        {<TextInput disabled source="serviziAggiuntiviId" label="serviziAggiuntiviId" defaultValue={serviziAggiuntiviId} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Adulti" source="costoAdult" defaultValue={0} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Infanti" source="costoInfant" defaultValue={0} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Bambini" source="costoChild" defaultValue={0} validate={[required()]} />}
                    </Typography>
                </CardContent>
            </Card>

        </RowForm>
    );
}

// Verifica della Checklist
const CercaTitolo = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <p><h3>{testo}</h3>
            <h3>{risultato.short_title}</h3>
            {risultato.titolo}</p>
    )
}

const CercaDescrizione = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <>
            <p><h3>{testo}</h3>
                {/* {risultato.descrizione} */}
                {(risultato.descrizione) ? <div dangerouslySetInnerHTML={{ __html: risultato.descrizione }} /> : ""}
                {/* <RichTextField source={record[id].descrizione} ></RichTextField> */}
            </p></>
    )
}

/* 

                    label="Servizi aggiuntivi"
                    reference="servizi-aggiuntivi"
                    target="istanzeEscursioniId"
*/

const GetTitoloEscursione = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span><CercaServizioAggiuntivo id={record.id}></CercaServizioAggiuntivo></span>;
};

const CercaServizioAggiuntivo = ({ id }) => {
    const { data: servizi, isLoading, error } = useGetOne('servizi-aggiuntivi-lingue', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {servizi.nome}
                </Typography>
            </CardContent>
        </Card>
    </div>;
};

const CercaIstanzaServizioAggiuntivo = ({ id }) => {
    const { data: servizi, isLoading, error } = useGetOne('istanze-servizi-aggiuntivi', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {servizi.nome}
                </Typography>
            </CardContent>
        </Card>
    </div>;
};


/* INIZIO */
const DettagliIstanzeServiziAggiuntivi = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{/* {record.id} */}<IstanzeServiziAggiuntiviProfile id={record.id}></IstanzeServiziAggiuntiviProfile></span>;
};

const IstanzeServiziAggiuntiviProfile = ({ id }) => {
    const { data, isLoading, error } = useGetMany(
        'istanze-servizi-aggiuntivi',
        { ids: [1, 2, 3] }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <ul>
            {data.map(tag => (
                <li key={tag.id}>{tag.id}</li>
            ))}
        </ul>
    );
};

/* FINE */

export const IstanzeEscursioniExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* <Grid item xs={6}> */}
                    {/* <TextField source="id" label="Id"></TextField> */}
                    {/* Cerco in : istanze_servizi_aggiuntivi che possiede istanzeEscursioniId  */}
                    {/* <DettagliIstanzeServiziAggiuntivi></DettagliIstanzeServiziAggiuntivi> */}
                    {/* </Grid> */}
                    <Grid item xs={6}>
                        <CercaTitolo id="id" testo="Titolo"></CercaTitolo>
                    </Grid>
                    <Grid item xs={6}>
                        <CercaDescrizione id="id" testo="Descrizione"></CercaDescrizione>
                        {/* <h2>Descrizione</h2>
                {<ReferenceManyField
                    reference="escursioni-lingue"
                    target="IstanzeEscursioniId"
                    
                    perPage={1}
                    sort={{ field: 'id', order: 'ASC' }}
                >
           
                    
                    
                    <SingleFieldList linkType={false}>
                    <TextField  label="Descrizione" source="descrizione" />
                </SingleFieldList>
                   
                </ReferenceManyField>  }   */}
                    </Grid>
                    <br></br>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}


const IstanzeEscursioniFilters = [
    <ReferenceInput perPage={100} source="IstanzeEscursioniId" reference="escursioni" label="Titolo escursione">
        <SelectInput optionText="titoloricerca" />
        {/* <TextInput label="Titolo escursione" source="titoloricerca" alwaysOn />, */}
    </ReferenceInput>,
    // <TextInput label="Cerca titolo escursione" source="id" alwaysOn />,
    /*  <ReferenceOneField label="ISBN" reference="escursioni" target="IstanzeEscursioniId">
         <TextField source="titoloricerca" />
     </ReferenceOneField>, */
    <DateInput source="data" label="Seleziona la data" /* defaultValue={getDataOdierna()} */ />,
    // <TextInput label="Titolo escursione" source="titoloricercaInEscursioni" alwaysOn />
];

