import React from 'react';
import { Loading, useGetList } from 'react-admin';
import { Typography, Button, Box } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';

const ListaPartecipanti = ({ ordiniId }) => {
    const { data, total, isLoading, error } = useGetList(
        'wapp-ordini-partecipanti-voucher',
        {
            pagination: false,
            filter: {
                ordiniId: ordiniId
            }
        }
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <>
            {data.length === 0 ? (
                <Typography color="textPrimary" gutterBottom>
                    Nessun Partecipante
                </Typography>
            ) : (
                <Box display="flex" justifyContent="top" alignItems="top">
                    <Box width="100%">
                        {data.map((partecipante) => {
                            if (!partecipante) return null; // Skip if partecipante is null or undefined

                            return (
                                <React.Fragment key={partecipante.id}>
                                    <hr />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <Box>
                                            <Typography color="textPrimary" gutterBottom>
                                                {partecipante.cliente_principale === 0 ? <strong>Partecipante</strong> : <strong>Cliente/Partecipante</strong>}
                                            </Typography>
                                            <Typography color="textPrimary" gutterBottom>
                                                Nome completo: {partecipante.nome_completo}
                                            </Typography>
                                            <Typography color="textPrimary" gutterBottom>
                                                Email: {partecipante.email}
                                            </Typography>
                                            <Typography color="textPrimary" gutterBottom>
                                                Telefono: {partecipante.nr_cellulare}
                                            </Typography>
                                        </Box>
                                        {partecipante.urlVoucher ? (
                                            <Button
                                                variant="contained"
                                                color="info"
                                                startIcon={<DownloadIcon />}
                                                href={partecipante.urlVoucher.replace(/[?&]withBase64=true$/, '')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    backgroundColor: '#00bcd4',
                                                    '&:hover': {
                                                        backgroundColor: '#00a0b4',
                                                    },
                                                }}
                                            >
                                                Scarica Voucher
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="info"
                                                disabled
                                                sx={{
                                                    backgroundColor: '#ccc',
                                                }}
                                            >
                                                Voucher non disponibile
                                            </Button>
                                        )}
                                    </Box>
                                    <hr />
                                </React.Fragment>
                            );
                        })}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ListaPartecipanti;