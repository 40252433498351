import React from 'react';
import { Loading, useGetList, usePermissions } from 'react-admin';
import { Grid, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { starButtonCss } from '../../shared/Styles/buttonStyles';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import CustomDeleteImage from '../galleria-immagini/CustomDeleteImage';
import CustomImpostaCopertina from '../galleria-immagini/CustomImpostaCopertina';

const CustomImageGallery = ({ escursioniId }) => {

    const { permissions } = usePermissions();

    const { data, isLoading, error } = useGetList(
        'galleriaescursioni',
        {
            pagination: { page: 1, perPage: 100 },
            filter: { escursioniId: escursioniId },
        }
    );

    if (isLoading) return <Loading />;
    if (error) return <p>ERROR</p>;

    // Funzione di esempio per gestire la modifica
    const handleEdit = (imageId) => {
        console.log(`Modifica immagine con id: ${imageId}`);
        // Qui puoi aggiungere la logica per gestire la modifica
    };

    const ids = data.map(obj => obj.id);
    console.log(ids);

    return (
        <Grid container spacing={4}>
            {data.map((image) => (
                <Grid item xs={12} sm={6} md={4} key={image.id}>
                    <Card>
                        <CardMedia
                            component="img"
                            height="140"
                            image={image.urlFoto} // Assumi che ogni immagine abbia una proprietà `url`
                            alt="Immagine galleria"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {image.nome} {/* Assumi che ogni immagine abbia una proprietà `nome` */}
                            </Typography>
                            {/* Qui puoi aggiungere altri dettagli per ogni immagine */}
                        </CardContent>
                        {(permissions === 'admin') ? <CardActions>
                            {(image.copertina === 1) ?

                                <Button
                                    sx={starButtonCss}
                                    startIcon={<StarIcon />}
                                    disabled={isLoading}
                                    color="error"
                                >
                                </Button>
                                :
                                <CustomImpostaCopertina recordId={image.id} ids={ids}></CustomImpostaCopertina>
                            }

                            {<CustomDeleteImage recordId={image.id} record={image} urlFoto={image.urlFoto} escursioniId={escursioniId} />}
                        </CardActions> : <></>}
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default CustomImageGallery;
