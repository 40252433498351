import React, { useState } from 'react';
import { useUpdate, Confirm, useNotify, useRefresh, useUpdateMany } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { editButtonCss } from '../../shared/Styles/buttonStyles';

const CustomImpostaCopertina = ({ recordId, ids }) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const [update, { isLoading, error }] = useUpdate();
    const [updateMany, { isLoadingM, errorM }] = useUpdateMany();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = () => {

        updateMany(
            'galleriaescursioni',
            { ids: ids, data: { copertina: 0 } }
        ).then(() => {


            let data = {
                copertina: 1
            };

            // Introduzione del ritardo qui
            setTimeout(() => {
                let data = { copertina: 1 };

                update(
                    'galleriaescursioni',
                    { id: recordId, data: data },
                    {
                        onSuccess: ({ data }) => {
                            handleClose();
                            notify('Copertina impostata con successo');
                            refresh();
                        },
                        onFailure: (error) => {
                            notify(error.message, 'warning');
                        }
                    }
                );
            }, 2000); // 2000 millisecondi = 2 secondi
        })

    };

    return (
        <>
            <Button
                sx={editButtonCss}
                startIcon={<EditIcon />}
                onClick={handleClickOpen}
                disabled={isLoading}
                color="error"
            >
            </Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Conferma Copertina"
                content="Sei sicuro di voler impostare questa foto come copertina?"
                onConfirm={handleUpdate}
                onClose={handleClose}
                confirm="Sì"
                cancel="No"
            />
            {error && <p>ERROR</p>} {/* Gestione dell'errore al di fuori del dialogo di conferma */}
        </>
    );
};

export default CustomImpostaCopertina;
