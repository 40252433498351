import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import configHost from '../../../config/config.json';

const useStyles = makeStyles({
    select: {
        // Definisci qui gli stili custom per la Select
        // Questo esempio limita gli stili al componente Select per prevenire conflitti
        minWidth: 200, // Larghezza minima per evitare restringimenti
        margin: '10px 0', // Margini per evitare sovrapposizioni
    },
    // Puoi aggiungere altri stili se necessario
});


const CustomSelectMezziPuntiincontro = ({ mezziId, onValueChange, ...rest }) => {
    const [items, setItems] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null); // Stato per tracciare il valore selezionato

    const jwt = localStorage.getItem("feathers-jwt");
    console.log(jwt);

    const classes = useStyles(); // Utilizza useStyles per caricare gli stili

    useEffect(() => {
        const fetchData = async () => {
            try {
                let config = {
                    method: 'get',
                    url: `${configHost.production.host}/wapp-mezzi-mezzi-puntiincontro?mezziId=${mezziId}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    }
                };

                const response = await axios.request(config);
                setItems(response.data.data); // Ora setItems riceve l'array corretto
            } catch (error) {
                console.error("Errore nel fetch dei dati: ", error);
            }
        };

        fetchData();
    }, [mezziId]); // Dipendenze di useEffect

    const handleChange = (event) => {
        setSelectedValue(event.target.value); // Aggiorna il valore selezionato
        onValueChange(event.target.value); // Passa il valore selezionato al componente padre
    };

    return (
        <Select
            value={selectedValue} // Rendi la Select un componente controllato
            onChange={handleChange} // Aggiorna lo stato del valore selezionato al cambiamento
            displayEmpty
            className={classes.select} // Applica gli stili custom alla Select
            inputProps={{ 'aria-label': 'Without label' }}
            {...rest}>
            <MenuItem value={null} disabled>
                Seleziona un'opzione
            </MenuItem>
            {items.map((item) => (
                <MenuItem key={item.id} value={{
                    puntiincontroId: item.puntiincontroId,
                    tipoRaduno: 0
                }}>
                    {item.puntiincontroNome}
                </MenuItem>
            ))}
        </Select>
    );
};

export default CustomSelectMezziPuntiincontro;
