import React from 'react';
import { useListContext, useNotify, Button } from 'react-admin';
import SendIcon from '@mui/icons-material/Send';
import axiosBackendInstance from "../../../utils/axiosBackend";

import { saveButtonCss } from "../../shared/Styles/buttonStyles";

const PostBulkActionButtons = ({ record, recordRighi, fornitoriId, onClose, ...props }) => {
    const { selectedIds, data } = useListContext();
    const notify = useNotify();

    const jwt = localStorage.getItem("feathers-jwt");

    const SalvaEChiudiDialog = async (event) => {
        console.log("Salva e refresh");
        console.log("trasferId : " + record.id);
        console.log("transferRighiId : " + recordRighi);
        console.log("Lista ordiniId selezionati : " + selectedIds);

        var listaOrdini = data.filter(elementx => selectedIds.includes(elementx.id));

        console.log("Array listaOrdini : ", listaOrdini);

        var config = {
            maxBodyLength: Infinity,
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/json'
            }
        };

        try {
            for (let element of listaOrdini) {
                console.log("GENERA RECORD in ordini_transfer_righi");
                let postioccupati = element.adult + element.child + element.infant;

                let payload = {
                    transferId: record.id,
                    transferRighiId: recordRighi,
                    fornitoriId: fornitoriId,
                    istanzeEscursioniId: element.istanzeEscursioniId,
                    ordiniId: element.ordiniId,
                    postioccupati: postioccupati
                };

                console.log(payload);

                await axiosBackendInstance.post("/ordini-transfer-righi/", payload, config);
            }

            notify('Ordini aggiunti con successo', { type: 'success' });
            onClose(); // Chiude il dialog dopo aver completato tutte le operazioni
        } catch (error) {
            console.log("Errore durante l'aggiunta degli ordini:", error);
            notify('Errore durante l\'aggiunta degli ordini', { type: 'error' });
        }
    };

    return (
        <>
            <Button
                sx={saveButtonCss}
                variant="contained"
                endIcon={<SendIcon />}
                onClick={SalvaEChiudiDialog}>
                Aggiungi Ordini Selezionati
            </Button>
        </>
    );
};

export default PostBulkActionButtons;