import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { Card, CardContent, Typography, Box, Divider, List, ListItem } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const TariffeCountCard = ({ resource, filter }) => {
    const dataProvider = useDataProvider();
    const [status, setStatus] = useState('loading');
    const [counts, setCounts] = useState({
        total: 0,
        validRecords: 0,
        invalidRecords: 0
    });
    const [invalidRecordNames, setInvalidRecordNames] = useState([]);

    useEffect(() => {
        dataProvider.getList(resource, {
            filter: filter,
            pagination: { page: 1, perPage: 1000 },
        })
            .then(response => {
                const records = response.data;
                const total = records.length;
                const invalidRecords = records.filter(record => record.numTariffe === 0);
                const validRecords = total - invalidRecords.length;

                setCounts({
                    total,
                    validRecords,
                    invalidRecords: invalidRecords.length
                });

                setInvalidRecordNames(invalidRecords.map(record => record.nome || 'Nome non disponibile'));

                setStatus(total === 0 || validRecords === 0 ? 'error' : 'success');
            })
            .catch(error => {
                console.error(error);
                setStatus('error');
            });
    }, [resource, filter, dataProvider]);

    const getStatusColor = () => status === 'error' ? 'error.main' : 'success.main';
    const getStatusIcon = () => status === 'error'
        ? <ErrorOutlineIcon color="error" />
        : <CheckCircleOutlineIcon color="success" />;

    return (
        <Card sx={{ minWidth: 275, maxWidth: 400 }}>
            <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                    {getStatusIcon()}
                    <Typography variant="h5" component="div" ml={1} color={getStatusColor()}>
                        {status === 'error' ? 'Attenzione' : 'Successo'}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Totale Punti Geografici : {counts.total}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Totale Punti Geografici Validi : {counts.validRecords}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Totale Punti Geografici non validi: {counts.invalidRecords}
                </Typography>
                {invalidRecordNames.length > 0 && (
                    <>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="body2" color="error" gutterBottom>
                            Crea almeno una tariffa per:
                        </Typography>
                        <List dense>
                            {invalidRecordNames.map((name, index) => (
                                <ListItem key={index}>
                                    <Typography variant="body2">
                                        {name}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default TariffeCountCard;