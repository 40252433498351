
import { TransferList } from '../transfer/transferList';
// import { TransferDetails } from '../transfer/transferDetails';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import { CreateTransfer } from '../transfer/transferCreate';
import { TransferEdit } from '../transfer/transferEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: TransferList,
    // show: TransferDetails,
    create: CreateTransfer,
    edit: TransferEdit,
    icon: DirectionsBusIcon
};