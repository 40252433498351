import React, { useState, useEffect } from 'react';
import { useDataProvider, AutocompleteInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Typography, Paper, Box } from '@mui/material';

const FornitoreSelectInput = props => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const { setValue, watch } = useFormContext();
    const selectedFornitoreId = watch('fornitoriId');

    useEffect(() => {
        const fetchData = async () => {
            const { data: tariffeExc } = await dataProvider.getList('tariffe-exc', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'id', order: 'ASC' },
            });

            const uniqueFornitoriIds = [...new Set(tariffeExc.map(t => t.fornitoriId))];

            const { data: fornitori } = await dataProvider.getMany('fornitori', {
                ids: uniqueFornitoriIds,
            });

            setChoices(fornitori);
        };

        fetchData();
    }, [dataProvider]);

    const handleChange = (value) => {
        setValue('fornitoriId', value);
        setValue('mezzoSelezionato', null);
    };

    const renderOptionText = (record) => (
        <div>
            <Typography variant="subtitle1">{record.nome}</Typography>
            <Typography variant="body2">Referente: {record.referente || 'N/A'}</Typography>
            <Typography variant="body2">P.IVA: {record.partitaiva || 'N/A'}</Typography>
            <Typography variant="body2">Tel: {record.telefono || record.cellulare || 'N/A'}</Typography>
        </div>
    );

    const selectedFornitore = choices.find(f => f.id === selectedFornitoreId);

    return (
        <>
            <AutocompleteInput
                {...props}
                choices={choices}
                optionText={renderOptionText}
                inputText={(record) => record?.nome || ''}
                onChange={handleChange}
            />
            {selectedFornitore && (
                <Paper elevation={3} sx={{ mt: 2, p: 2, bgcolor: '#f0f4f8' }}>
                    <Typography variant="h6" gutterBottom color="primary">
                        Dettagli Fornitore
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography><strong>Nome:</strong> {selectedFornitore.nome}</Typography>
                        <Typography><strong>Referente:</strong> {selectedFornitore.referente || 'N/A'}</Typography>
                        <Typography><strong>Partita IVA:</strong> {selectedFornitore.partitaiva || 'N/A'}</Typography>
                        <Typography><strong>Telefono:</strong> {selectedFornitore.telefono || 'N/A'}</Typography>
                        <Typography><strong>Cellulare:</strong> {selectedFornitore.cellulare || 'N/A'}</Typography>
                        <Typography><strong>Email:</strong> {selectedFornitore.email || 'N/A'}</Typography>
                        <Typography><strong>Indirizzo:</strong> {selectedFornitore.indirizzo || 'N/A'}</Typography>
                        {selectedFornitore.note && (
                            <Typography>
                                <strong>Note:</strong>
                                <Box component="span" sx={{ display: 'inline-block', ml: 1, p: 1, bgcolor: '#e0e0e0', borderRadius: 1 }}>
                                    {selectedFornitore.note}
                                </Box>
                            </Typography>
                        )}
                    </Box>
                </Paper>
            )}
        </>
    );
};

export default FornitoreSelectInput;