import * as React from "react";
import {
    List,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    BooleanInput,
    RichTextField,
    FilterList,
    FilterListItem,
    FunctionField,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    Show,
    ShowButton,
    WrapperField,
    CreateButton,
    BooleanField,
    Toolbar,
    SaveButton,
    useRecordContext,
    useRefresh,
    TabbedForm,
    ReferenceManyField,
    ReferenceField,
    ReferenceManyCount,
    required
} from 'react-admin';

import { useNavigate } from 'react-router-dom';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import {
    numberFilter
} from '@react-admin/ra-form-layout';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import GetNomeCatenaAlberghiera from "../hotels/GetNomeCatenaAlberghiera";

import { RichTextInput } from 'ra-input-rich-text';

import Typography from "@material-ui/core/Typography";

// Impost Custom List Page 
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';

import Box from '@mui/material/Box';
import ContentFilter from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { useFormContext } from 'react-hook-form';
import { useState } from "react";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import BusinessIcon from '@mui/icons-material/Business';
import PublicIcon from '@mui/icons-material/Public';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import Avatar from '@mui/material/Avatar';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import MapIcon from '@mui/icons-material/Map';
import GestoreLogoHotel from "../hotels/GestoreLogoHotel";
import Alert from '@mui/material/Alert';

// Importo il css per i Bottoni
import { editButtonCss, saveButtonCss, showButtonCss, columnsButtonCss, createButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';

// Importo componenti input custom
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import CustomAutocompleInputUsers from '../shared/FormFields/CustomAutocompleInputUsers';

// Crea un link verso google maps
import GoogleMapsLink from "../shared/GoogleMapsLink";

/* Parametri di default per il componente che si occupa della posizinoe gps  */
import MapPicker from "react-google-map-picker";
const DefaultLocation = { lat: 41.0523108, lng: 8.2095084 };
const DefaultZoom = 10;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

/* TODO: da creare componente custom */

const CustomAvatarImage = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;



    return (
        <Box height="80%" width="80%" display="flex" justifyContent="left" alignItems="top">
            {record[source] ? (
                // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                <Avatar alt={label} style={{ width: '100px', height: '100px' }} src={record[source]} />
            ) : (
                <p><Avatar alt="Immagine mancante" style={{ width: '100px', height: '100px' }}><ImageNotSupportedIcon /></Avatar></p>
            )}
        </Box>
    );
};

function ResponsiveDialog2() {

    const { setValue } = useFormContext();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    function handleChangeLocation(lat, lng) {
        setValue('latitudine', lat.toString());
        setValue('longitudine', lng.toString());
        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    return (
        <>
            <div>
                <Button onClick={handleOpen}>Apri mappa e seleziona il punto di escursione</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <label>Latitudine:</label><input type='text' value={location.lat} disabled />
                        <label>Longitudine:</label><input type='text' value={location.lng} disabled />
                        <MapPicker defaultLocation={defaultLocation}
                            zoom={zoom}
                            mapTypeId="roadmap"
                            style={{ height: '700px' }}
                            onChangeLocation={handleChangeLocation}
                            onChangeZoom={handleChangeZoom}
                            /* apiKey='AIzaSyBNi7XqlJRL4aW-GiWKA_g3ssmM3OO8kqM'  */
                            apiKey='AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY'
                        />
                    </Box>
                </Modal>
            </div>

        </>
    );
}

const listFiltersHotels = {
    'id': numberFilter({ operators: ["eq", "neq", "gt", "lt"] }),
};

const ListActions = () => (
    <TopToolbar>

        <CreateButton label="Crea" sx={createButtonCss}></CreateButton>
        {/* <ExportButton label="Esporta" sx={exportButtonCss} /> */}
        <SelectColumnsButton sx={columnsButtonCss} />
        {/* <StackedFilters sx={filterButtonCss} config={listFiltersHotels} /> */}

    </TopToolbar>
);

export const HotelsList = props => {

    return (
        <List title={<>
            <Button>
                &nbsp;Hotels
            </Button>
        </>}
            exporter={false}
            aside={<HotelsFilterSidebar />}
            {...props}
            actions={<ListActions />}
            perPage={100}
            filters={hotelsFilters}
            empty={<EmptyListCustom titolo="Lista hotels"></EmptyListCustom>}
            filterDefaultValues={{ id: { $ne: 0 } }}
        >
            <DatagridConfigurable
                sx={listCss}
                bulkActionButtons={false}>
                <WrapperField label="Logo Hotel">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                        <CustomAvatarImage source="urlLogo" label="Logo" ></CustomAvatarImage>
                    </Box>
                </WrapperField>
                {<TextField label="Nome" source="nome" />}
                {<GetNomeCatenaAlberghiera source="catenaAlberghieraId"></GetNomeCatenaAlberghiera>}
                {<TextField label="Indirizzo" source="indirizzo" textAlign="center" />}
                {<TextField label="Stato" source="statoHotel" textAlign="center" />}
                {<TextField label="Zona" source="zona" textAlign="center" />}
                <WrapperField label="Link Google Maps" textAlign="center">
                    {<GoogleMapsLink ></GoogleMapsLink>}
                </WrapperField>
                {<RichTextField label="Note" source="note" />}
                {<EditButton sx={editButtonCss} label="Modifica" textAlign="center" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" textAlign="center" />}
                <WrapperField label="Elimina" textAlign="center">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Hotel"}
                        messaggio={"Sei sicuro di vole cancellare questo hotel ?"}
                        servizio={"hotels"} />
                </WrapperField>
            </DatagridConfigurable>
        </List>
    );
}

export const HotelsCreate = (props) => (
    <Create {...props}>
        <SimpleForm
        /*  defaultValues={{
             nome: "Pippo"
         }} */
        >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent><br></br>
                            {<CustomAutocompleInput source="catenaAlberghieraId" reference="catena-alberghiera" optionProperty="nome" label="Catena Alberghiera" ></CustomAutocompleInput>}
                            <br></br>
                            {<TextInput label="Nome" source="nome" validate={[required()]} />}
                            <br></br>
                            {<TextInput label="Ragione Sociale Completa" source="ragioneSociale" />}
                            <br></br>
                            {<TextInput label="Partita Iva" source="partitaIva" />}
                            <br></br>
                            {<TextInput label="Nome referente" source="nomeReferente" />}
                            <br></br>
                            {<TextInput label="Telefono" source="telefono" />}
                            <br></br>
                            {<TextInput label="Cellulare" source="cellulare" />}
                            <br></br>
                            {<TextInput label="Email" source="email" />}
                            <br></br>
                            {<TextInput label="Indirizzo" source="indirizzo" />}
                            <br></br>
                            <SelectInput source="zona" label="Zona" choices={[
                                { id: 'n', name: 'n' },
                                { id: 'no', name: 'no' },
                                { id: 'ne', name: 'ne' },
                                { id: 'e', name: 'e' },
                                { id: 'o', name: 'o' },
                                { id: 'so', name: 'so' },
                                { id: 'se', name: 'se' },
                                { id: 's', name: 's' },
                            ]}></SelectInput>
                            <br></br>
                            <BooleanInput source="statoHotel" label="Stato" />
                            <br></br>
                            {<RichTextInput label="Note" source="note" />}
                            <br></br>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {<TextInput label="Longitudine" source="longitudine" />}
                                <br></br>
                                {<TextInput label="Latitudine" source="latitudine" />}
                                <ResponsiveDialog2></ResponsiveDialog2>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <br></br>
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
        </SimpleForm>
    </Create>
);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

const EscursioniShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="hotelsId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInput source="escursioniId" reference="escursioni" optionProperty="titoloricerca" label="" ></CustomAutocompleInput>

        </RowForm>
    );

}

const UsersShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="hotelsId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInputUsers readOnly={true} isFiltered={true} filterField="roles" filterValue="Hotel" source="usersId" reference="users" optionProperty="email" label="" ></CustomAutocompleInputUsers>
            <SelectInput source="sottoruolo" label="Sotto Ruolo" choices={[
                { id: 'RESPONSABILE', name: 'RESPONSABILE' },
                { id: 'VENDITORE', name: 'VENDITORE' },
            ]}></SelectInput>
        </RowForm>
    );

}

const UsersShowDisponibiliRowFormCreate = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="hotelsId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInputUsers isFiltered={true} filterField="roles" filterValue="Hotel" source="usersId" reference="users" optionProperty="email" label="" ></CustomAutocompleInputUsers>
            <SelectInput source="sottoruolo" label="Sotto Ruolo" choices={[
                { id: 'RESPONSABILE', name: 'RESPONSABILE' },
                { id: 'VENDITORE', name: 'VENDITORE' },
            ]}></SelectInput>
        </RowForm>
    );

}

/* ESCURSIONI */

const NavigateButton = ({ recordId, service, message }) => {
    const handleNavigate = () => {

        const url = `#/${service}/${recordId}/show`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Button sx={showButtonCss} onClick={handleNavigate}>{message}</Button>
    );
};



const GestioneEscursioniInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (
        <EditableDatagrid
            bulkActionButtons={false}
            editForm={<EscursioniShowDisponibiliRowForm />}
            createForm={<EscursioniShowDisponibiliRowForm id={record.id} />}
        >
            <Card variant="outlined"
                sx={{ margin: 1, borderRadius: 2 }}
            >
                <CardContent>
                    <ReferenceField source="escursioniId" reference="Escursioni" link={false}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>
                                <MapIcon /> {/* Utilizza l'icona Map dentro l'Avatar */}
                            </Avatar>
                            <Typography variant="subtitle1" component="div">
                                ID Escursione : <TextField source="id" />
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                            <Typography variant="subtitle1" component="div">
                                Titolo ricerca : <TextField source="titoloricerca" />
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                            <Typography variant="subtitle1" component="div">
                                Città : <TextField source="citta" />
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                            <Typography variant="subtitle1" component="div">
                                {<FunctionField
                                    source="escursioniId"
                                    render={record => {
                                        console.log(record);
                                        return <NavigateButton recordId={record.id} service="escursioni" message="Dettaglio escursione"></NavigateButton>;
                                    }}
                                />}
                            </Typography>
                        </Box>

                    </ReferenceField>
                </CardContent>
            </Card>
        </EditableDatagrid>);

}

const GestioneUsersInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        editForm={<UsersShowDisponibiliRowForm />}
        createForm={<UsersShowDisponibiliRowFormCreate id={record.id} />}
    >
        <Card variant="outlined"
            sx={{ margin: 1, borderRadius: 2 }}
        >
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                    <Typography variant="subtitle1" component="div">
                        <FunctionField
                            source="sottoruolo" // used for sorting
                            label=""
                            render={record => record && <>
                                Sotto Ruolo : <strong> {record.sottoruolo}</strong>
                            </>}
                        />
                    </Typography>
                </Box>
                <ReferenceField source="usersId" reference="users" link={false}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>

                        </Avatar>
                        <Typography variant="subtitle1" component="div">
                            ID User : <TextField source="id" />
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Email : <strong><TextField source="email" /></strong>
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            {<FunctionField
                                source="usersId"
                                render={record => {
                                    console.log(record);
                                    return <NavigateButton recordId={record.id} service="users" message="Dettaglio User"></NavigateButton>;
                                }}
                            />}
                        </Typography>
                    </Box>

                </ReferenceField>
            </CardContent>
        </Card>
    </EditableDatagrid>);

}


export const HotelsShow = (props) => {

    const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>

                &nbsp;Dettaglio Hotel
            </Button>
            <TextField label="Nome" source="nome" />
        </>} actions={<ShowActions />}>
            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dati Hotel">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <GestoreLogoHotel refresh={refresh}></GestoreLogoHotel>
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <PersonIcon />
                                            Nome: <FunctionField source="nome" render={record => record && `${record.nome}`} />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Ragione Sociale Completa: <TextField label="Ragione Sociale Completa" source="ragioneSociale" />
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Partita Iva: {<TextField label="Partita Iva" source="partitaIva" />}
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PersonIcon />
                                            Nome referente: {<TextField label="Nome referente" source="nomeReferente" />}
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Telefono: <TextField label="Telefono" source="telefono" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Cellulare: <TextField label="Cellulare" source="cellulare" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <EmailIcon />
                                            Email: <TextField label="Email" source="email" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Indirizzo: <TextField label="Indirizzo" source="indirizzo" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <GpsFixedIcon />
                                            Zona: <TextField source="zona" label="Zona" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            Stato: <BooleanField source="statoHotel" label="Stato" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <NoteIcon />
                                            Note: <RichTextField label="Note" source="note" />
                                        </Box>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <GpsFixedIcon />
                                                Longitudine: <TextField label="Longitudine" source="longitudine" />
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <GpsFixedIcon />
                                                Latitudine: <TextField label="Latitudine" source="latitudine" />
                                            </Box>
                                        </Typography>
                                        <Typography color="textSecondary" gutterBottom>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}>
                                                Visualizza su Google Maps
                                                <GoogleMapsLink />
                                            </Box>
                                        </Typography>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Escursioni"
                    count={<ReferenceManyCount
                        reference="escursioni-hotels"
                        target="hotelsId" />}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <ReferenceManyField
                                reference="escursioni-hotels"
                                target="hotelsId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <GestioneEscursioniInShow></GestioneEscursioniInShow>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Users" count={<ReferenceManyCount reference="users-hotels"
                    target="hotelsId" />}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">Attenzione, in questo tab puoi selezionare solo user con ruolo <strong>Hotel</strong></Alert>
                            <ReferenceManyField
                                reference="users-hotels"
                                target="hotelsId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <GestioneUsersInShow></GestioneUsersInShow>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Accordi">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">In costruzione.</Alert>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            </TabbedForm>
        </Show>
    );
}

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

export const HotelsEdit = (props) => (
    <Edit actions={<ShowActionsForEdit />} title="Modifica Hotel" redirect="show" {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275, boxShadow: 3 }}>
                        <CardContent>
                            <Typography component="div">
                                <Grid container spacing={2}>
                                    {[
                                        { icon: PersonIcon, label: 'Nome', source: 'nome', component: TextInput },
                                        { icon: BusinessIcon, label: 'Ragione Sociale Completa', source: 'ragioneSociale', component: TextInput },
                                        { icon: BusinessIcon, label: 'Partita Iva', source: 'partitaIva', component: TextInput },
                                        { icon: PersonIcon, label: 'Nome referente', source: 'nomeReferente', component: TextInput },
                                        { icon: PhoneIcon, label: 'Telefono', source: 'telefono', component: TextInput },
                                        { icon: PhoneIcon, label: 'Cellulare', source: 'cellulare', component: TextInput },
                                        { icon: EmailIcon, label: 'Email', source: 'email', component: TextInput },
                                        { icon: HomeIcon, label: 'Indirizzo', source: 'indirizzo', component: TextInput },
                                        {
                                            icon: PublicIcon, label: 'Zona', source: 'zona', component: SelectInput, choices: [
                                                { id: 'n', name: 'Nord' }, { id: 'no', name: 'Nord-Ovest' }, { id: 'ne', name: 'Nord-Est' },
                                                { id: 'e', name: 'Est' }, { id: 'o', name: 'Ovest' }, { id: 'so', name: 'Sud-Ovest' },
                                                { id: 'se', name: 'Sud-Est' }, { id: 's', name: 'Sud' },
                                            ]
                                        },
                                        { icon: ToggleOnIcon, label: 'Stato', source: 'statoHotel', component: BooleanInput },
                                        { icon: NoteIcon, label: 'Note', source: 'note', component: RichTextInput },
                                        { icon: GpsFixedIcon, label: 'Longitudine', source: 'longitudine', component: TextInput },
                                        { icon: GpsFixedIcon, label: 'Latitudine', source: 'latitudine', component: TextInput },
                                    ].map((field, index) => (
                                        <Grid item xs={12} sm={index < 8 ? 6 : 12} key={field.label}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                {React.createElement(field.icon, { sx: { color: 'action.active', mr: 1, my: 0.5, fontSize: '1.25rem' } })}
                                                {React.createElement(field.component, {
                                                    label: field.label,
                                                    source: field.source,
                                                    ...(field.choices ? { choices: field.choices } : {}),
                                                    sx: { width: '100%' },
                                                })}
                                            </Box>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                        <Box sx={{ pt: 2 }}>
                                            <ResponsiveDialog2 />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

// Filtri

const HotelsFilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <HotelsFilter />
            </CardContent>
        </Card>
    </Box>
);

/// Filtri
const HotelsFilter = () => (
    <Grid container spacing={2}>
        <Grid>
            <Box >
                {/*         <Box sx={{
        width: '12em',
    }}><h3></h3>
        <Card>
    <CardContent><FilterLiveSearch source="id" />
    </CardContent>
    </Card></Box> */}

                <FilterList label="Zone geografiche" icon={<ContentFilter />}>
                    <FilterListItem
                        label="Nord"
                        value={{
                            zona: "n"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Ovest"
                        value={{
                            zona: "no"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Est"
                        value={{
                            zona: "ne"
                        }}
                    />
                    <FilterListItem
                        label="Est"
                        value={{
                            zona: "e"
                        }}
                    />
                    <FilterListItem
                        label="Sud"
                        value={{
                            zona: "s"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Ovest"
                        value={{
                            zona: "so"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Est"
                        value={{
                            zona: "se"
                        }}
                    />
                    <FilterListItem
                        label="Ovest"
                        value={{
                            zona: "o"
                        }}
                    />
                </FilterList>
            </Box>
        </Grid>
        {/* {  <Grid>
    <Box>
    <FilterList label="Trimestre" icon={<ContentFilter />}>
    <FilterListItem
            label="Primo"
            value={{trimestre: 1
            }}
        />
         <FilterListItem
            label="Secondo"
            value={{trimestre: 2
            }}
        />
    <FilterListItem
            label="Terzo"
            value={{trimestre: 3
            }}
        />
         <FilterListItem
            label="Quarto"
            value={{trimestre: 4
            }}
        />
    </FilterList>
    </Box>
  </Grid>} */}
    </Grid>);


const hotelsFilters = [
    <TextInput label="Cerca per nome" source="nome" alwaysOn />/* ,
    <TextInput label="Cerca per id" source="id" alwaysOn /> */
];