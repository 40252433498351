export const fieldBaseForShow = {
    margin: '10px', // Definisce i margini intorno al campo
    color: 'grey', // Imposta il colore del testo
    bgcolor: 'white', // Imposta il colore dello sfondo
    padding: '5px', // Imposta il padding interno
    borderRadius: '4px', // Aggiunge bordi arrotondati
};


export const fieldAccordion = {
    width: '100%',
    // bgcolor: 'cyan', // Imposta il colore dello sfondo
};

export const fieldAccordionColor1 = {
    width: '100%',
    bgcolor: '#01b5d0', // Imposta il colore dello sfondo
};