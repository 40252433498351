import * as React from "react";
import {
    useRefresh,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    Button,
    Confirm,
    useListContext,
    useUpdateMany,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    DateInput,
    NumberInput,
    useDataProvider,
    Loading,
    Error,
    RichTextField,
    required,
    useGetOne,
    useGetMany,
    useGetList,
    localStorageStore,
    DateTimeInput,
    TabbedForm,
    Datagrid,
    FunctionField,
    usePermissions
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import { useState, useEffect } from 'react';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import Chip from '@material-ui/core/Chip';
import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import FolderIcon from '@mui/icons-material/Folder';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import TopicIcon from '@mui/icons-material/Topic';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import { blue, red } from '@mui/material/colors';
import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text";
import Alert from '@mui/material/Alert';

import PostAddIcon from '@mui/icons-material/PostAdd';
import { useFormContext } from 'react-hook-form';

// Importo componenti input custom
import CustomSelectMezziPuntiincontro from '../../shared/FormFields/CustomSelectMezziPuntiincontro';
import CopyToClipboardButton from '../../shared/FormFields/CopyToClipboardButton'
import { InitPuntiPartenzaButtonCustom } from "../../shared/InitPuntiPartenzaButtonCustom";
import CustomAutocompleInput from '../../shared/FormFields/CustomAutocompleInput';

// Import dei componenti custom
import { ConfirmDialogDeleteButtonCustom } from "../../shared/DeleteButtonCustom";
import { ConfirmDialogUpdateButtonCustom } from "../../shared/UpdateButtonCustom";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { fieldAccordion } from '../../shared/Styles/fieldsStyles';

// Crea un link verso google maps
import GoogleMapsLink from "../../shared/GoogleMapsLink";

import EventIcon from '@mui/icons-material/Event'; // Importa l'icona Eve

// Init store
const storeGenerale = localStorageStore();

const SetCurrentIstanzaEscursione = ({ source, data }) => {
    const record = useRecordContext();
    // storeGenerale.setItem('profile.currentIstanzaEscursione', res);
    storeGenerale.setItem('profile.currentIstanzaEscursione', 0);
    storeGenerale.setItem('profile.currentIstanzaEscursioneData', 0);
    storeGenerale.setItem('profile.currentIstanzaEscursione', record[source]);
    storeGenerale.setItem('profile.currentIstanzaEscursioneData', record[data]);
    return record ? (
        <>
            {/* {record[source]} */}</>

    ) : <>
    </>;
}

const FormatNote = ({ source }) => {

    const record = useRecordContext();
    return record ? (
        <div dangerouslySetInnerHTML={{ __html: record[source] }} />

    ) : <>
    </>;
}





const LaLista = () => {
    /*      const { data, total, loading, error } = useQuery({
             type: 'getList',
             resource: 'items',
             payload
         }); */
    const { data, isLoading, error } = useGetList(
        'istanze-servizi-aggiuntivi',
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <div>
            {/* <p>Total items: {total}</p>
             <ul>
                 {data.map(item => <li>
                {item.nome}
                 </li>)}
             </ul> */}
        </div>
    );
};

const ValutaBandieraLingua = ({ source }) => {
    const record = useRecordContext();
    let lingua = "";

    switch (record[source]) {
        case 1:
            lingua = "http://imgm.btechitalia.it/flags/flagita.png"
            break;
        case 2:
            lingua = "http://imgm.btechitalia.it/flags/flaguk.png"
            break;
        case 3:
            lingua = "http://imgm.btechitalia.it/flags/flagpol.png"
            break;
        case 4:
            lingua = "http://imgm.btechitalia.it/flags/flagger.png"
            break;
        case 5:
            lingua = "http://imgm.btechitalia.it/flags/flagpor.png"
            break;
        case 6:
            lingua = "http://imgm.btechitalia.it/flags/flagspa.png"
            break;
        case 7:
            lingua = "http://imgm.btechitalia.it/flags/flagfra.png"
            break;
        default:
            lingua = ""
            break;
    }


    return (
        <><div>
            <img src={lingua} width="20" height="20" />
        </div></>
    );
};

/* ***************************** */

/* GESTIONE PUNTI INCONTRO */

/* Tipo raduno STATICO */
const tipoRadunoDescrizione = {
    0: 'Punto d\'incontro',
    1: 'Punto di partenza',
    2: 'Incontro/partenza',
};

const EscursioniPuntiincontroLingueRowForm = ({ id, ...props }) => {
    return (
        <RowForm {...props}>
            <RichTextInput
                toolbar={<RichTextInputToolbar size="large" />}
                label="Descrizione" source="descrizione" />
        </RowForm>
    );
}

const EscursioniPuntiincontroInShow = ({ id, ...props }) => {

    const { permissions } = usePermissions();
    const record = useRecordContext();
    if (!record) return null;

    var redirectPath = '/istanze-escursioni/' + record.id + '/show/puntiincontropartenza';

    return (
        <ReferenceManyField
            reference="istanze-escursioni-puntiincontro"
            target="istanzeEscursioniId"
            sort={{ field: 'id', order: 'ASC' }}
            label=""
        >
            {/* TASTO PER L'AGGIUNTA DI UN PUNTO */}

            <Datagrid
                noDelete
                bulkActionButtons={false}
                editForm={null}
            /* header={<AddPunto IstanzeEscursioniId={record.id} mezziId={record.mezziId} redirectPath={redirectPath}></AddPunto>} */
            >

                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            Dettaglio punto
                            {/* <br></br>
                            <TextField source="id" />
                            <br></br>
                            <TextField source="puntiincontroId" /> */}
                        </Typography>

                        {/* <div><strong>"Tipo: "<strong>{tipoRadunoDescrizione[record.tipoRaduno]}</strong> */}

                        <FunctionField
                            render={record => (<div>TIPO :<strong>{tipoRadunoDescrizione[record.tipoRaduno]}</strong></div>)}
                        />
                        <ReferenceField source="puntiincontroId" reference="puntiincontro">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>

                                    <Chip
                                        label={
                                            <Typography color="textSecondary" variant="h6" component="div" gutterBottom>
                                                <TextField source="nome" />
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" gutterBottom>
                                        <Box sx={{
                                            display: 'flex', // Usa flexbox per allineare gli elementi
                                            alignItems: 'center', // Allinea gli elementi verticalmente al centro
                                            gap: 1, // Aggiunge uno spazio tra gli elementi
                                        }}>
                                            Visualizza su Google Maps
                                            <GoogleMapsLink />
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" gutterBottom>
                                        Lat: <TextField source="latitudine" />
                                        <br></br>
                                        Lon: <TextField source="longitudine" />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ReferenceField>
                        <Grid container spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item>
                                {/* {record && <DeleteButton label="Rimuovi" basePath="/" record={record} undoable={false} />} */}
                                {(permissions === 'admin') ?? <ConfirmDialogUpdateButtonCustom
                                    titolo={"Punto d\'incontro"}
                                    messaggio={"Sei sicuro di aggiornare il punto dalla lista ?"}
                                    fieldToUpdate={"tipoRaduno"}
                                    fieldValue={0}
                                    servizio={"istanze-escursioni-puntiincontro"}
                                    redirectPath={redirectPath}
                                />}
                            </Grid>
                            <Grid item>
                                {/* {record && <DeleteButton label="Rimuovi" basePath="/" record={record} undoable={false} />} */}
                                {(permissions === 'admin') ?? <ConfirmDialogUpdateButtonCustom
                                    titolo={"Punto di partenza"}
                                    messaggio={"Sei sicuro di aggiornare il punto dalla lista ?"}
                                    fieldToUpdate={"tipoRaduno"}
                                    fieldValue={1}
                                    servizio={"istanze-escursioni-puntiincontro"}
                                    redirectPath={redirectPath}
                                />}
                            </Grid>
                            <Grid item>
                                {/* {record && <DeleteButton label="Rimuovi" basePath="/" record={record} undoable={false} />} */}
                                {(permissions === 'admin') ?? <ConfirmDialogUpdateButtonCustom
                                    titolo={"Incontro/partenza"}
                                    messaggio={"Sei sicuro di aggiornare il punto dalla lista ?"}
                                    fieldToUpdate={"tipoRaduno"}
                                    fieldValue={2}
                                    servizio={"istanze-escursioni-puntiincontro"}
                                    redirectPath={redirectPath}
                                />}
                            </Grid>
                        </Grid>
                        {(permissions === 'admin') ?? <Grid container spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item>
                                {/* {record && <DeleteButton label="Rimuovi" basePath="/" record={record} undoable={false} />} */}
                                <ConfirmDialogDeleteButtonCustom
                                    titolo={"Cancella punto"}
                                    messaggio={"Sei sicuro di voler togliere il punto dalla lista ?"}
                                    servizio={"istanze-escursioni-puntiincontro"}
                                    redirectPath={redirectPath}
                                />
                            </Grid>
                        </Grid>}

                    </CardContent>
                </Card>
                <ReferenceManyField
                    reference="istanze-escursioni-puntiincontro-lingue"
                    target="istanzeEscursioniPuntiincontroId"
                    sort={{ field: 'id', order: 'ASC' }}
                >

                    {(permissions === 'admin') ?
                        <EditableDatagrid noDelete
                            bulkActionButtons={false}
                            editForm={<EscursioniPuntiincontroLingueRowForm />}
                        /* NB: non serve un create */
                        >
                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                            <RichTextField label="Descrizione" source="descrizione" />

                        </EditableDatagrid> :
                        <Datagrid
                            bulkActionButtons={false}
                        >
                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                            <RichTextField label="Descrizione" source="descrizione" />
                        </Datagrid>}
                </ReferenceManyField>
            </Datagrid>
        </ReferenceManyField>);
}

/* const AddPunto = ({ IstanzeEscursioniId, mezziId, redirectPath }) => {
    const record = useRecordContext(); // Record escursione
    console.log(record);
    const notify = useNotify();
    const refresh = useRefresh();
    const [selectedValue, setSelectedValue] = useState({ puntiincontroId: null, tipoRaduno: 0 }); // Aggiungi lo stato per memorizzare il valore selezionato

    // const payload = { IstanzeEscursioniId: 1, puntiincontroId: 17, tipoRaduno: 0 };
    const payload = {
        IstanzeEscursioniId: 1, // Assumo che questo possa essere statico o dinamico
        puntiincontroId: selectedValue.puntiincontroId,
        tipoRaduno: selectedValue.tipoRaduno, // Assumo che questo valore possa essere determinato in qualche modo
    };
    const [create, { isLoading, error }] = useCreate('istanze-escursioni-puntiincontro', { data: payload },
        {
            onSuccess: () => {
                notify('Punto aggiunto.', 'info');
                console.log(payload);
                refresh();
            },
            onFailure: (error) => {
                notify(`Errore: ${error.message}`, 'warning');
            }
        });
    const handleClick = () => {
        const updatedPayload = {
            IstanzeEscursioniId: IstanzeEscursioniId,
            puntiincontroId: selectedValue.puntiincontroId,
            tipoRaduno: selectedValue.tipoRaduno,
        };
        console.log('Payload prima di create:', updatedPayload);
        create('istanze-escursioni-puntiincontro', { data: updatedPayload },
            {
                onSuccess: () => {
                    notify('Punto aggiunto.', 'info');
                    console.log(payload);
                    refresh();
                },
                onFailure: (error) => {
                    notify(`Errore: ${error.message}`, 'warning');
                }
            }).catch((e) => {
                notify(`Errore: ${e}`, 'warning');
            });
    }


    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleValueChange = (newValue) => {
        console.log("--------------------------------");
        console.log(newValue);
        setSelectedValue(prevState => ({
            ...prevState,
            puntiincontroId: newValue.puntiincontroId,
            tipoRaduno: newValue.tipoRaduno
        }));
    };



    if (error) {
        // return <div></div>;
        console.log("ERRORE");
    }
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button sx={createButtonCss} disabled={isLoading} onClick={handleClick}>
            Seleziona {selectedValue.puntiincontroId}
        </Button>
        <CustomSelectMezziPuntiincontro mezziId={mezziId} onValueChange={handleValueChange} />
    </div>
        ;

}; */

const EscursioniShowServiziLingueDisponibiliRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="istanzeEscursioniId" defaultValue={parseInt(id)} disabled ></TextInput>

        {/*         {<ReferenceInput source="lingueGuidaId" reference="lingue" >
            <SelectInput source="descrizioneLingua"></SelectInput>
        </ReferenceInput>} */}
        <CustomAutocompleInput source="serviziId" reference="servizi" optionProperty="descrizione" label="" ></CustomAutocompleInput>

    </RowForm>
);

const GestioneServziDisponibiliInShow = ({ id, ...props }) => {

    const { permissions } = usePermissions();
    const record = useRecordContext();
    if (!record) return null;

    return ((permissions === 'admin') ?
        <EditableDatagrid /* noDelete */
            bulkActionButtons={false}
            editForm={<EscursioniShowServiziLingueDisponibiliRowForm />}
            createForm={<EscursioniShowServiziLingueDisponibiliRowForm id={record.id} />}>
            {<ReferenceField link={null} source="serviziId" reference="servizi" >
                <TextField source="descrizione"></TextField>
            </ReferenceField>}
        </EditableDatagrid> :
        <Datagrid /* noDelete */
            bulkActionButtons={false}>
            {<ReferenceField link={null} source="serviziId" reference="servizi" >
                <TextField source="descrizione"></TextField>
            </ReferenceField>}
        </Datagrid>);

}

/* ***************************** */

export const IstanzeEscursioniShowNoEdit = ({ id, ...props }) => {


    // const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>
                &nbsp;Dettaglio Istanza sEscursione
            </Button>
        </>} {...props} id={id} actions={null} /* aside={<Aside />} */>

            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dati base escursione" icon={<TopicIcon></TopicIcon>}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275, boxShadow: 3 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 18, mb: 1, fontWeight: 'medium' }} color="text.secondary">
                                        Data Istanza Escursione
                                    </Typography>
                                    <FunctionField
                                        source="data"
                                        render={record => (
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <EventIcon sx={{ fontSize: 50, color: 'primary.main', verticalAlign: 'middle' }} />
                                                    <Typography sx={{ fontSize: 20, color: 'primary.main', fontWeight: 'bold', ml: 0.5 }}>
                                                        {record.data}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 275, mb: 2 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                        Titolo ricerca
                                        <CopyToClipboardButton source="titoloricerca" />
                                    </Typography>
                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Punti d'incontro e di partenza" path="puntiincontropartenza" icon={<TopicIcon></TopicIcon>}>
                    <Accordion expanded={true} sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>
                                <PostAddIcon></PostAddIcon>&nbsp;Punti d'incontro e di partenza
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    {/* <InitPuntiPartenzaButtonCustom
                                        titolo={"Preleva Punti associati al Mezzo"}
                                        messaggio={"Vuoi inizializzare i punti d'incontro ?"}
                                        fieldToUpdate={"tipoRaduno"}
                                        fieldValue={0}
                                        servizio={"istanze-escursioni-puntiincontro"}
                                        redirectPath={"puntiincontropartenza"}

                                    /> */}
                                    <ReferenceManyField
                                        reference="istanze-escursioni-puntiincontro"
                                        target="IstanzeEscursioniId"
                                        sort={{ field: 'id', order: 'ASC' }}
                                        filter={{ visibile: true }}
                                    >

                                        <EscursioniPuntiincontroInShow></EscursioniPuntiincontroInShow>

                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Servizi" path="servizi" icon={<TopicIcon></TopicIcon>}>
                    <Accordion expanded={true} sx={fieldAccordion}>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="istanze-escursioni-servizi"
                                        target="istanzeEscursioniId"
                                        sort={{ field: 'id', order: 'ASC' }}
                                    // filter={{ visibile: true }}
                                    >

                                        <GestioneServziDisponibiliInShow />

                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabbedForm.Tab>
            </TabbedForm>
        </Show >
    );
}

// const redirect = (basePath, id, data) => `/author/${data.author_id}/show`;
const redirect = () => `/istanze-escursioni`;

const MyPage = () => {
    const { setValue } = useFormContext();

    const [fornitoriId, setFornitoriId] = useState(null);
    const [motonaviFilter, setMotonaviFilter] = useState(null);
    const [motonaviVisible, setMotonaviVisible] = useState(false);

    function handleChangeFornitoriId(value) {

        setMotonaviFilter("");
        setMotonaviFilter(value);
        setMotonaviVisible(true);
    }

    useEffect(() => {
        if (fornitoriId) {
            console.log(fornitoriId);
            setMotonaviFilter({ fornitoriId });
        } else {
            setMotonaviFilter({});
        }
    }, [fornitoriId]);

    return (
        <>
            <ReferenceInput
                perPage={100}
                source="fornitoriId"
                reference="fornitori"
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <SelectInput validate={[required()]} optionText="nome"
                    onChange={(event, key, newValue) => {
                        // console.log(event.target.value);
                        // setFornitoriId(newValue);
                        handleChangeFornitoriId(event.target.value)
                    }}

                />
            </ReferenceInput>


            {motonaviVisible && (
                <>
                    <br />
                    <ReferenceInput
                        perPage={100}
                        source="motonaviId"
                        reference="motonavi"
                        filter={{ fornitoriId: motonaviFilter }}
                        sort={{ field: 'nome', order: 'ASC' }}
                    >
                        <SelectInput
                            resettable
                            validate={[required()]} optionText="nome" />
                    </ReferenceInput>
                </>
            )}
        </>
    );
};

export const IstanzeEscursioniCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={redirect}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {/* Input per la data ed ora */}
                                {<DateTimeInput source="dataOraInizio" title="Data Inizio escursione" validate={[required()]} />}
                                <br></br>
                                {<DateTimeInput source="dataOraFine" title="Data Fine escursione" validate={[required()]} />}
                                <br></br>
                                {<DateInput source="data" title="Data Giornaliera escursione" validate={[required()]} />}
                                <br></br>
                                {/* {<TextInput source="fornitore" label="Fornitore" validate={[required()]} />} */}
                                {/*   {<ReferenceInput
                                    perPage={100}
                                    source="fornitoriId"
                                    reference="fornitori"
                                    // filter={{ lingueId: 1 }}
                                    sort={{ field: 'nome', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="nome" />

                                </ReferenceInput>}
                                <br></br>
                                {<ReferenceInput
                                    perPage={100}
                                    source="motonaviId"
                                    reference="motonavi"
                                    filter={{ fornitoriId: 3 }}
                                    sort={{ field: 'nome', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="nome" />

                                </ReferenceInput>} */}
                                <br></br>
                                <MyPage></MyPage>
                                <br></br>
                                {<TextInput source="note_arrivo" label="Note Arrivo" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Partecipanti" source="partecipanti" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={1} label="PartecipantiMax" source="partecipantiMax" helperText="Valore minimo 1" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Adulti" source="tarAdulti" defaultValue={0} validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Infanti" source="tarInfanti" defaultValue={0} validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Bambini" source="tarBambini" defaultValue={0} validate={[required()]} />}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {/*                         <ReferenceOneField label="Escursione" reference="istanze-escursioni" target="idEscursione">
                            <TextField source="genre" />
                        </ReferenceOneField> */}
                                {/*  <ReferenceInput label="Escursione" source="escursioneId" reference="istanze-escursioni">
                            <SelectInput optionText="id" />
                        </ReferenceInput> */}
                                {/* <ReferenceInput perPage={100} source="IstanzeEscursioniId" reference="escursioni">
                        <SelectInput  optionText="citta" />
                        </ReferenceInput> */}
                                {<ReferenceInput
                                    perPage={100}
                                    source="IstanzeEscursioniId"
                                    reference="escursioni"
                                    // filter={{ lingueId: 1 }}
                                    sort={{ field: 'titoloricerca', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="titoloricerca" />
                                    {/* <AutocompleteInput source="titoloricerca" optionValue="titoloricerca" /> */}
                                </ReferenceInput>}
                                {/* {<TextInput source="IstanzeEscursioniId" label="Fornitore" validate={[required()]} />} */}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

// const record = useRecordContext();

export const IstanzeEscursioniEdit = ({ id, ...props }) => {
    return (
        <Edit {...props} id={id}>
            <SimpleForm>
                <SimpleShowLayout>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {/* Input per la data ed ora */}
                                        {<DateTimeInput source="dataOraInizio" title="Data Inizio escursione" validate={[required()]} />}
                                        <br></br>
                                        {<DateTimeInput source="dataOraFine" title="Data Fine escursione" validate={[required()]} />}
                                        <br></br>
                                        {<DateInput source="data" title="Data Giornaliera escursione" validate={[required()]} />}
                                        <br></br>
                                        {<TextInput source="fornitore" label="Fornitore" validate={[required()]} />}
                                        <br></br>
                                        {<TextInput source="note_arrivo" label="Note Arrivo" validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} label="Partecipanti" source="partecipanti" validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={1} label="PartecipantiMax" source="partecipantiMax" helperText="Valore minimo 1" validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} options={{ style: 'currency', currency: 'EUR' }} label="Tariffa Adulti" source="tarAdulti" defaultValue={0} validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} label="Tariffa Infanti" source="tarInfanti" defaultValue={0} validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} label="Tariffa Bambini" source="tarBambini" defaultValue={0} validate={[required()]} />}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        {<Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <br></br>
                                        <CercaTitolo id="id" testo="Titolo"></CercaTitolo>


                                        <CercaDescrizione id="id" testo="Descrizione"></CercaDescrizione>
                                        {/*                         <ReferenceInput perPage={100} source="IstanzeEscursioniId" reference="escursioni">
                        <SelectInput optionText="id" />
                        </ReferenceInput> */}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>}
                        <Grid item xs={10}>
                            {/* <h2>ID istanzeEscursioniId corrente</h2> */}
                            {/* <TextField source="id"></TextField> */}
                            <SetCurrentIstanzaEscursione source="id" data="data"></SetCurrentIstanzaEscursione>
                            <h2>Lista Servizi Aggiuntivi</h2>
                            {/* {<ReferenceInput
                        perPage={100}
                        source="istanze-servizi-aggiuntivi"
                        reference="escursioni"
                    >
                        <SelectInput validate={[required()]} optionText="nome" />
                    </ReferenceInput>} */}
                            <ReferenceManyField
                                reference="istanze-servizi-aggiuntivi"
                                target="istanzeEscursioniId"
                                fullWidth
                                perPage={10}
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                {/* <List filter={{ istanzeEscursioniId: 35 }}> */}
                                <EditableDatagrid /* noDelete */
                                    bulkActionButtons={false}
                                    editForm={<ServiziAggiuntiviRowFormCard defaultValues={{ serviziAggiuntiviId: id }} />}
                                    createForm={<ServiziAggiuntiviRowFormCard defaultValues={{ serviziAggiuntiviId: id }} />}

                                >
                                    {/* <TextField source="id"></TextField> */}
                                    {/* <TextField source="serviziAggiuntiviId"></TextField> */}
                                    {/* Creo una card per ogni istanza servizio aggiuntivo */}

                                    <Card mx={{ maxWidth: 345 }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                                                    S
                                                </Avatar>
                                            }
                                            title="DETTAGLIO SERVIZIO AGGIUNTIVO"
                                        // subheader={new Date(record.data).toLocaleDateString("it-IT", options)}
                                        />
                                        {/*                         <CardMedia
                            component="img"
                            height="194"
                            image="PATH IMG SERVIZIO AGGIUNTIVO"
                            alt="TESTO ALTERNATIVO"
                        /> */}
                                        <CardContent>
                                            {<ReferenceField
                                                perPage={100}
                                                source="serviziAggiuntiviId"
                                                reference="servizi-aggiuntivi"
                                            // filter={{ lingueId: 1 }}
                                            // sort={{ field: 'titoloRicerca', order: 'ASC' }}
                                            >
                                                Titolo servizio : <TextField source="titoloricerca" />
                                            </ReferenceField>}
                                            <Typography variant="body2" color="text.primary">
                                                {/* <TextField source="note"></TextField> */}
                                                <FormatNote source="note"></FormatNote>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {/* istanzeEscursioniId : <TextField source="istanzeEscursioniId"></TextField>
                                                <br></br>
                                                serviziAggiuntiviId : <TextField source="serviziAggiuntiviId"></TextField>
                                                <br></br> */}
                                                Costo Adulto : <TextField source="costoAdult"></TextField>
                                                <br></br>
                                                Costo Infante : <TextField source="costoInfant"></TextField>
                                                <br></br>
                                                Costo Child : <TextField source="costoChild"></TextField>
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                </EditableDatagrid>

                            </ReferenceManyField>

                            {/* <ListaServiziAggiuntivi istanzeEscursioniId="istanzeEscursioniId"></ListaServiziAggiuntivi> */}
                            {/* <ListaServiziAggiuntiviCard></ListaServiziAggiuntiviCard> */}
                        </Grid>
                    </Grid>
                </SimpleShowLayout>
            </SimpleForm>
        </Edit>
    );
}

export const ServiziAggiuntiviRowForm = (props) => (
    <RowForm {...props}>
        {<DateInput source="data" title="Data escursione" validate={[required()]} />}
        {<TextInput source="istanzeEscursioniId" label="istanzeEscursioniId" validate={[required()]} />}
        {<TextInput source="serviziAggiuntiviId" label="serviziAggiuntiviId" validate={[required()]} />}
        {/*         {<ReferenceInput
            perPage={100}
            source="serviziAggiuntiviId"
            reference="servizi-aggiuntivi"
        >
            <SelectInput validate={[required()]} optionText="titoloricerca" />
        </ReferenceInput>} */}
        {<NumberInput min={0} label="Tariffa Adulti" source="costoAdult" defaultValue={0} validate={[required()]} />}
        {<NumberInput min={0} label="Tariffa Infanti" source="costoInfant" defaultValue={0} validate={[required()]} />}
        {<NumberInput min={0} label="Tariffa Bambini" source="costoChild" defaultValue={0} validate={[required()]} />}
    </RowForm>
);

// const record = useRecordContext();

export const ServiziAggiuntiviRowFormCard = ({ istanzeEscursioniId, serviziAggiuntiviId, ...props }) => {

    // console.log(storeGenerale.getItem('profile.currentIstanzaEscursione'));;
    const idRecordIstanzeEscursioni = storeGenerale.getItem('profile.currentIstanzaEscursione');
    const dataRecordIstanzeEscursioni = storeGenerale.getItem('profile.currentIstanzaEscursioneData');
    return (
        <RowForm {...props}>
            <Card mx={{ maxWidth: 345 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            S
                        </Avatar>
                    }
                    title="MODIFICA SERVIZIO AGGIUNTIVO"
                // subheader={new Date(record.data).toLocaleDateString("it-IT", options)}
                />

                {/*                         <CardMedia
                            component="img"
                            height="194"
                            image="PATH IMG SERVIZIO AGGIUNTIVO"
                            alt="TESTO ALTERNATIVO"
                        /> */}
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {<ReferenceInput
                            perPage={100}
                            source="serviziAggiuntiviId"
                            reference="servizi-aggiuntivi"
                        // filter={{ lingueId: 1 }}
                        // sort={{ field: 'titoloRicerca', order: 'ASC' }}
                        >
                            <SelectInput validate={[required()]} optionText="titoloricerca" />
                        </ReferenceInput>}
                        {<RichTextInput source="note" title="Note" defaultValue={""} /* validate={[required()]} */ />}
                        <br></br>
                        <br></br>
                        {<DateInput disabled source="data" title="Data escursione" defaultValue={dataRecordIstanzeEscursioni} validate={[required()]} />}
                        <br></br>
                        {<TextInput disabled source="istanzeEscursioniId" label="istanzeEscursioniId" defaultValue={idRecordIstanzeEscursioni} validate={[required()]} />}
                        <br></br>
                        {<TextInput disabled source="serviziAggiuntiviId" label="serviziAggiuntiviId" defaultValue={serviziAggiuntiviId} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Adulti" source="costoAdult" defaultValue={0} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Infanti" source="costoInfant" defaultValue={0} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Bambini" source="costoChild" defaultValue={0} validate={[required()]} />}
                    </Typography>
                </CardContent>
            </Card>

        </RowForm>
    );
}

// Verifica della Checklist
const CercaTitolo = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <p><h3>{testo}</h3>
            <h3>{risultato.short_title}</h3>
            {risultato.titolo}</p>
    )
}

const CercaDescrizione = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <>
            <p><h3>{testo}</h3>
                {/* {risultato.descrizione} */}
                {(risultato.descrizione) ? <div dangerouslySetInnerHTML={{ __html: risultato.descrizione }} /> : ""}
                {/* <RichTextField source={record[id].descrizione} ></RichTextField> */}
            </p></>
    )
}

/* 

                    label="Servizi aggiuntivi"
                    reference="servizi-aggiuntivi"
                    target="istanzeEscursioniId"
*/

const GetTitoloEscursione = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span><CercaServizioAggiuntivo id={record.id}></CercaServizioAggiuntivo></span>;
};

const CercaServizioAggiuntivo = ({ id }) => {
    const { data: servizi, isLoading, error } = useGetOne('servizi-aggiuntivi-lingue', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {servizi.nome}
                </Typography>
            </CardContent>
        </Card>
    </div>;
};

const CercaIstanzaServizioAggiuntivo = ({ id }) => {
    const { data: servizi, isLoading, error } = useGetOne('istanze-servizi-aggiuntivi', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {servizi.nome}
                </Typography>
            </CardContent>
        </Card>
    </div>;
};


/* INIZIO */
const DettagliIstanzeServiziAggiuntivi = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{/* {record.id} */}<IstanzeServiziAggiuntiviProfile id={record.id}></IstanzeServiziAggiuntiviProfile></span>;
};

const IstanzeServiziAggiuntiviProfile = ({ id }) => {
    const { data, isLoading, error } = useGetMany(
        'istanze-servizi-aggiuntivi',
        { ids: [1, 2, 3] }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <ul>
            {data.map(tag => (
                <li key={tag.id}>{tag.id}</li>
            ))}
        </ul>
    );
};

/* FINE */

export const IstanzeEscursioniExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* <Grid item xs={6}> */}
                    {/* <TextField source="id" label="Id"></TextField> */}
                    {/* Cerco in : istanze_servizi_aggiuntivi che possiede istanzeEscursioniId  */}
                    {/* <DettagliIstanzeServiziAggiuntivi></DettagliIstanzeServiziAggiuntivi> */}
                    {/* </Grid> */}
                    <Grid item xs={6}>
                        <CercaTitolo id="id" testo="Titolo"></CercaTitolo>
                    </Grid>
                    <Grid item xs={6}>
                        <CercaDescrizione id="id" testo="Descrizione"></CercaDescrizione>
                        {/* <h2>Descrizione</h2>
                {<ReferenceManyField
                    reference="escursioni-lingue"
                    target="IstanzeEscursioniId"
                    
                    perPage={1}
                    sort={{ field: 'id', order: 'ASC' }}
                >
           
                    
                    
                    <SingleFieldList linkType={false}>
                    <TextField  label="Descrizione" source="descrizione" />
                </SingleFieldList>
                   
                </ReferenceManyField>  }   */}
                    </Grid>
                    <br></br>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}


const IstanzeEscursioniFilters = [
    <ReferenceInput perPage={100} source="IstanzeEscursioniId" reference="escursioni" label="Titolo escursione">
        <SelectInput optionText="titoloricerca" />
        {/* <TextInput label="Titolo escursione" source="titoloricerca" alwaysOn />, */}
    </ReferenceInput>,
    // <TextInput label="Cerca titolo escursione" source="id" alwaysOn />,
    /*  <ReferenceOneField label="ISBN" reference="escursioni" target="IstanzeEscursioniId">
         <TextField source="titoloricerca" />
     </ReferenceOneField>, */
    <DateInput source="data" label="Seleziona la data" /* defaultValue={getDataOdierna()} */ />,
    // <TextInput label="Titolo escursione" source="titoloricercaInEscursioni" alwaysOn />
];

