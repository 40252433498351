import * as React from "react";
import {
    TextInput,
    Create,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    DateInput,
    NumberInput,
    required,
    DateTimeInput,
    useCreateSuggestionContext,
    useCreate,
    AutocompleteInput,
    useGetList,
    Loading,
    useRecordContext,
    useGetMany,
    TimeInput,
    useNotify
} from 'react-admin';
import { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import axiosBackendInstance from "../../utils/axiosBackend";

import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
    Button,
    Dialog,
    DialogActions,
    TextField,
} from '@mui/material';

// import Escursione from '../../types/Escursione';

const redirect = () => `/istanze-escursioni`;

const GetPartecipantiMax = ({ id }) => {
    const { data, isLoading, error } = useGetMany(
        'motonavi',
        { id: id }
    );
    if (isLoading) { return 0; }
    if (error) { return 0; }
    return (
        <>
            {data.map(element => (
                element.partecipantiMax
            ))}
        </>
    );
};

const FornitoriEMotonavi = () => {

    const jwt = localStorage.getItem("feathers-jwt");

    const { setValue, getValue } = useFormContext();
    const record = useRecordContext();
    const [fornitoriId, setFornitoriId] = useState(null);
    const [motonaviId, setMotonaviId] = useState(null);
    const [portiId, setPortiId] = useState(null);
    const [motonaviFilter, setMotonaviFilter] = useState(null);
    const [motonaviVisible, setMotonaviVisible] = useState(false);
    const [bottoneApplicaVisible, setBottoneApplicaVisible] = useState(false);
    const [bottoneCalcolaTariffaVisible, setBottoneCalcolaTariffaVisible] = useState(false);
    const [portiVisible, setPortiVisible] = useState(false);
    const [dataPerTariffeVisible, setDataPerTariffeVisible] = useState(false);
    const [tarAdulti, setTarAdulti] = useState("0");
    const [tarBambini, setTarBambini] = useState("0");
    const [tarInfanti, setTarInfanti] = useState("0");
    const [dataGiornalieraEscursione, setDataGiornalieraEscursione] = useState(null);

    /* VARIABILI NECESSARI PER IL DIALOG - inizio */
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [messaggioErrore, setMessaggioErrore] = useState("");
    const notify = useNotify();
    /* VARIABILI NECESSARI PER IL DIALOG - fine */

    function handleChangeFornitoriId(value) {
        setMotonaviFilter("");
        setMotonaviFilter(value);
        setMotonaviVisible(true);
        setDataPerTariffeVisible(true);

        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> FORNITORE " + value);

        // RESET STATI
        setTarAdulti("0")
        setTarBambini('0');
        setTarInfanti('0');

        // RESET FORM
        setValue('partecipantiMax', 0);
        setValue('tarAdulti', 0);
        setValue('tarBambini', 0);
        setValue('tarInfanti', 0);

    }


    function handleChangePartecipantiMax(value) {
        // TODO: crea funzione che recupera i partecipantiMax dal DB
        // value è l'id per cercare in motonavi
        // console.log("AXIOS : " + value);
        // setValue('partecipantiMax', 350);


        var config = {
            maxBodyLength: Infinity,
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/json'
            }
        };

        const responseBackend = axiosBackendInstance.get("/motonavi/" + value, config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                console.log(response.data);
                const obj = response.data;
                // console.log("portiId " + obj.portiId);
                // console.log("partecipantiMax " + obj.partecipantiMax);

                setMotonaviId(value);
                // setPortiId(obj.portiId);
                setPortiVisible(true);
                setBottoneApplicaVisible(true);
                setBottoneCalcolaTariffaVisible(true);

                // TODO: FIND sulla tabella tariffe

                // SET 
                setValue('partecipantiMax', obj.partecipantiMax);
                setTarAdulti("1")
                setTarBambini('3');
                setTarInfanti('2');

                console.log(getValue('data'));

            })
            .catch(function (error) {
                // console.log(error);
            })
    }

    function handleCalcolaTariffeAlPubblico() {


        const months = ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];

        const dateString = dataGiornalieraEscursione;
        const dateParts = dateString.split('-');
        const anno = dateParts[0];
        const mese = months[parseInt(dateParts[1]) - 1].toLowerCase();

        console.log(anno);
        console.log(mese);
        console.log(motonaviId);
        console.log(portiId);
        console.log(dataGiornalieraEscursione);

        // dataFrom[$gte]=1479664146607&dataTo[$lte]=1479664146607
        console.log(dateString);

        // http://localhost:3030/tariffe?dataFrom[$lte]=2023-05-23&dataTo[$gte]=2023-05-23
        var urlString = "tariffe?dataFrom[$lte]=" + dateString + "&dataTo[$gte]=" + dateString + "&motonaviId=" + motonaviId + "&portiId=" + portiId
        // var urlString = "/tariffe?anno=" + anno + "&mese=" + mese + "&motonaviId=" + motonaviId + "&portiId=" + portiId;

        var config = {
            maxBodyLength: Infinity,
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/json'
            }
        };

        const responseBackend = axiosBackendInstance.get(urlString, config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                // console.log(response.data);
                const obj = response.data;
                // console.log("portiId " + obj.portiId);
                // console.log("partecipantiMax " + obj.partecipantiMax);

                console.log(obj.total);
                if (obj.total === 0) {
                    setMessaggioErrore("Non risultano tariffe in archivio sulla base dei parametri selezionati: data, motonave e porto.");
                    handleClickOpen(true);
                    notify('Errore.`', { type: 'error' });
                } else {
                    notify('Calcolo eseguito con successo.`', { type: 'success' });
                }

                // SET
                setValue('tarAdulti', obj.data[0].tarAdulti);
                setValue('tarBambini', obj.data[0].tarBambini);
                setValue('tarInfanti', obj.data[0].tarInfanti);


            })
            .catch(function (error) {
                // console.log(error);
            })

        /* Query TEST per isolare i porti possibili per motonave dallwe tariffe */
        /*        var urlString2 = "/tariffe?anno=" + anno + "&mese=" + mese + "&motonaviId=" + motonaviId;
       
               const responseBackend2 = axiosBackendInstance.get(urlString2, config)
                   .then(function (response) {
                       const obj = response.data;
                       console.log("----");
                       console.log(obj);
                   })
                   .catch(function (error) {
                       // console.log(error);
                   }) */


        console.log(urlString);
    }

    function handleChangeTariffeAlPubblico(value) {
        const months = ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];

        const dateString = value;
        const dateParts = dateString.split('-');
        const anno = dateParts[0];
        const mese = months[parseInt(dateParts[1]) - 1].toLowerCase();

        console.log(anno);
        console.log(mese);
        console.log(motonaviId);

        // dataFrom[$gte]=1479664146607&dataTo[$lte]=1479664146607
        console.log(dateString);

        // http://localhost:3030/tariffe?dataFrom[$lte]=2023-05-23&dataTo[$gte]=2023-05-23
        var urlString = "tariffe?dataFrom[$lte]=" + dateString + "&dataTo[$gte]=" + dateString + "&motonaviId=" + motonaviId + "&portiId=" + portiId
        // var urlString = "/tariffe?anno=" + anno + "&mese=" + mese + "&motonaviId=" + motonaviId + "&portiId=" + portiId;

        var config = {
            maxBodyLength: Infinity,
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/json'
            }
        };

        const responseBackend = axiosBackendInstance.get(urlString, config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                // console.log(response.data);
                const obj = response.data;
                // console.log("portiId " + obj.portiId);
                // console.log("partecipantiMax " + obj.partecipantiMax);

                console.log(obj);

                // SET
                setValue('tarAdulti', obj.data[0].tarAdulti);
                setValue('tarBambini', obj.data[0].tarBambini);
                setValue('tarInfanti', obj.data[0].tarInfanti);


            })
            .catch(function (error) {
                // console.log(error);
            })

        /* Query TEST per isolare i porti possibili per motonave dallwe tariffe */
        /*        var urlString2 = "/tariffe?anno=" + anno + "&mese=" + mese + "&motonaviId=" + motonaviId;
       
               const responseBackend2 = axiosBackendInstance.get(urlString2, config)
                   .then(function (response) {
                       const obj = response.data;
                       console.log("----");
                       console.log(obj);
                   })
                   .catch(function (error) {
                       // console.log(error);
                   }) */


        console.log(urlString);
    }

    function handleChangePorto(value) {
        console.log(value);

        setPortiId(value);
    }

    useEffect(() => {
        if (fornitoriId) {
            console.log(fornitoriId);
            setMotonaviFilter({ fornitoriId });
        } else {
            setMotonaviFilter({});
        }
    }, [fornitoriId]);

    let optionRenderer = choice => `${choice.nome}`;

    return (
        <>
            <ReferenceInput
                perPage={100}
                source="fornitoriId"
                reference="fornitori"
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <SelectInput validate={[required()]} optionText={optionRenderer} /* optionText="nome" */
                    onChange={(event, key, newValue) => {
                        // console.log(event.target.value);
                        // setFornitoriId(newValue);
                        handleChangeFornitoriId(event.target.value);
                    }}

                />
            </ReferenceInput>


            {motonaviVisible && (
                <>
                    <br />
                    <ReferenceInput
                        perPage={100}
                        source="motonaviId"
                        reference="motonavi"
                        filter={{ fornitoriId: motonaviFilter }}
                        sort={{ field: 'nome', order: 'ASC' }}
                    >
                        <SelectInput
                            // resettable
                            validate={[required()]} optionText="nome"
                            onChange={(event, key, newValue) => {
                                console.log(event.target.value);
                                handleChangePartecipantiMax(event.target.value);
                            }}
                        />
                    </ReferenceInput>
                    <br></br>
                    {bottoneApplicaVisible && <Button variant="contained" onClick={() => {
                        console.log("TARIFFA SETTATA : " + tarAdulti);
                        /* setValue('tarAdulti', tarAdulti);
                        setValue('tarBambini', tarBambini);
                        setValue('tarInfanti', tarInfanti); */
                        setValue('tarAdulti', '0');
                        setValue('tarBambini', '0');
                        setValue('tarInfanti', '0');
                    }} >Azzera prezzi</Button>}
                    <br></br>
                    <br></br>
                    {bottoneCalcolaTariffaVisible && <Button variant="contained" onClick={() => {
                        console.log("Calcola Tariffa : " + tarAdulti);
                        /* setValue('tarAdulti', tarAdulti);
                        setValue('tarBambini', tarBambini);
                        setValue('tarInfanti', tarInfanti); */
                        /* setValue('tarAdulti', '123');
                        setValue('tarBambini', '123');
                        setValue('tarInfanti', '123'); */
                        handleCalcolaTariffeAlPubblico();
                    }} >Calcola tariffe</Button>}
                    <br></br>
                    <div>
                        <Dialog
                            open={open}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Calcolo non possibile"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {messaggioErrore}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Chiudi</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </>
            )}

            {portiVisible && (
                <>
                    <br />
                    <ReferenceInput
                        perPage={100}
                        source="portiId"
                        reference="porti"
                        sort={{ field: 'nome', order: 'ASC' }}
                    >
                        <SelectInput
                            validate={[required()]} optionText="nome"
                            onChange={(event, key, newValue) => {
                                console.log(event.target.value);
                                handleChangePorto(event.target.value);
                            }}
                        />
                    </ReferenceInput>

                    {/* <ReferenceManyToManyInput
        source="portiId"
        reference="porti"
        through="motonavi-porti"
        using="motonaviId,portiId"
      >
        <SelectArrayInput
          label="Performances"
          // Validation must be set on this component
          validate={required()}
          optionText="id"
          fullWidth
        />
      </ReferenceManyToManyInput> */}
                </>
            )}

            {dataPerTariffeVisible && (
                <>
                    <br />
                    {<DateInput source="data" title="Data Giornaliera escursione"
                        validate={[required()]}
                        onChange={(event, key, newValue) => {
                            console.log(event.target.value);
                            setDataGiornalieraEscursione(event.target.value);
                            // handleChangeTariffeAlPubblico(event.target.value);
                        }}
                    />}
                    <br></br>
                    {<DateTimeInput source="dataOraInizio" title="Data Inizio escursione" validate={[required()]} />}
                    <br></br>
                    {<DateTimeInput source="dataOraFine" title="Data Fine escursione" validate={[required()]} />}
                    <br></br>
                    <TimeInput fullWidth label="Orario inizio imbarco" source="orarioInizioImbarco" validate={[required()]}></TimeInput>
                    <br></br>
                    <TimeInput fullWidth label="Orario Partenza" source="orarioPartenza" validate={[required()]}></TimeInput>
                    <br></br>
                    <TimeInput fullWidth label="Orario Rientro" source="orarioRientro" validate={[required()]}></TimeInput>
                    <br></br>
                </>
            )}


        </>
    );
};

const CreateIstanzeEscursioni = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [create] = useCreate();
    const [value, setValue] = React.useState(filter || '');

    const handleSubmit = event => {
        event.preventDefault();
        create(
            'escursioni',
            { data: { titoloricerca: value } },
            {
                onSuccess: (data) => {
                    setValue('');
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="Titolo ricerca"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Salva</Button>
                    <Button onClick={onCancel}>Annulla</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const CustomAutocomplete = () => {

    const { data, isLoading, error } = useGetList(
        'escursioni',
        {
            sort: { field: 'titoloricerca', order: 'ASC' }
        }
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const optionRenderer = choice => `${choice.id} ${choice.titoloricerca}`;

    return (<>
        {/* <ReferenceInput source="escursioniId"
            reference="escursioni"> */}
        <AutocompleteInput choices={data} openText={optionRenderer} /* create={<CreateIstanzeEscursioni />}  */ />
        {/* </ReferenceInput> */}
    </>);
}

const optionRenderer = choice => `[${choice.id}] ${choice.titoloricerca}`;


export const IstanzeEscursioniCreate = (props) => (
    <Create title="Creazione istanza escursione" {...props}>
        <SimpleForm redirect={redirect}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <SelectInput source="tipoEscursione" choices={[
                                    { id: 0, name: 'Escursioni semplici' },
                                    { id: 1, name: 'Escursioni di Gruppo' }
                                ]} validate={[required()]} /><br></br>
                                {/* Input per la data ed ora */}
                                {/* {<DateTimeInput source="dataOraInizio" title="Data Inizio escursione" validate={[required()]} />}
                                <br></br>
                                {<DateTimeInput source="dataOraFine" title="Data Fine escursione" validate={[required()]} />}
                                <br></br> */}
                                {/* {<DateInput source="data" title="Data Giornaliera escursione" validate={[required()]} />} */}
                                {/* <br></br>
                                <TimeInput fullWidth label="Orario inizio imbarco" source="orarioInizioImbarco" validate={[required()]}></TimeInput>
                                <br></br>
                                <TimeInput fullWidth label="Orario Partenza" source="orarioPartenza" validate={[required()]}></TimeInput>
                                <br></br>
                                <TimeInput fullWidth label="Orario Rientro" source="orarioRientro" validate={[required()]}></TimeInput>
                                <br></br> */}
                                {/* {<TextInput source="fornitore" label="Fornitore" validate={[required()]} />} */}
                                {/*   {<ReferenceInput
                                    perPage={100}
                                    source="fornitoriId"
                                    reference="fornitori"
                                    // filter={{ lingueId: 1 }}
                                    sort={{ field: 'nome', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="nome" />

                                </ReferenceInput>}
                                <br></br>
                                {<ReferenceInput
                                    perPage={100}
                                    source="motonaviId"
                                    reference="motonavi"
                                    filter={{ fornitoriId: 3 }}
                                    sort={{ field: 'nome', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="nome" />

                                </ReferenceInput>} */}
                                <br></br>
                                <FornitoriEMotonavi></FornitoriEMotonavi>
                                <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {/*                         <ReferenceOneField label="Escursione" reference="istanze-escursioni" target="idEscursione">
                            <TextField source="genre" />
                        </ReferenceOneField> */}
                                {/*  <ReferenceInput label="Escursione" source="escursioneId" reference="istanze-escursioni">
                            <SelectInput optionText="id" />
                        </ReferenceInput> */}
                                {/* <ReferenceInput perPage={100} source="escursioniId" reference="escursioni">
                        <SelectInput  optionText="citta" />
                        </ReferenceInput> */}
                                {/*  {<ReferenceInput
                                    perPage={100}
                                    source="escursioniId"
                                    reference="escursioni"
                                    sort={{ field: 'titoloricerca', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="titoloricerca" />
                                </ReferenceInput>} */}
                                {/* <CustomAutocomplete /> */}

                                <ReferenceInput
                                    source="escursioniId"
                                    reference="escursioni"
                                    perPage={30}
                                    sort={{ field: 'titoloricerca', order: 'ASC' }}
                                >
                                    <AutocompleteInput
                                        optionText={optionRenderer} /* optionText="titoloricerca" */
                                        suggestionLimit={30}
                                    />
                                </ReferenceInput>
                                <br></br>
                                {<NumberInput min={0} label="Partecipanti" source="partecipanti" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={1} label="PartecipantiMax" source="partecipantiMax" helperText="Valore minimo 1" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Adulti" source="tarAdulti" defaultValue={0} validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Infanti" source="tarInfanti" defaultValue={0} validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Bambini" source="tarBambini" defaultValue={0} validate={[required()]} />}
                                <br></br>
                                {<TextInput source="note_arrivo" label="Note Arrivo" validate={[required()]} />}
                                <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);