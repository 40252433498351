import * as React from "react";
import {
    List,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    RichTextField,
    FilterList,
    FilterListItem,
    SelectInput,
    NumberInput,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    Show,
    ShowButton,
    WrapperField,
    CreateButton,
    Toolbar,
    SaveButton,
    required,
    ReferenceField,
    AutocompleteInput,
    ReferenceInput,
    TabbedForm,
    ReferenceManyCount,
    ReferenceManyField,
    useRecordContext,
    FunctionField,
    Datagrid,
    usePermissions,
    DateField
} from 'react-admin';

import {
    StackedFilters,
    numberFilter
} from '@react-admin/ra-form-layout';

// Importo componenti input custom
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import CustomAutocompleInputUsers from '../shared/FormFields/CustomAutocompleInputUsers';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import { RichTextInput } from 'ra-input-rich-text';

import Typography from "@material-ui/core/Typography";

import Box from '@mui/material/Box';
import ContentFilter from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import WorkIcon from '@mui/icons-material/Work';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useFormContext } from 'react-hook-form';
import { useState } from "react";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import BusinessIcon from '@mui/icons-material/Business';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import DirectionsCarSharp from '@mui/icons-material/DirectionsCarSharp';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import MapIcon from '@mui/icons-material/Map';

// Impost Custom List Page 
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';
import EmptyListCustomNoCreate from '../shared/emptyPageCustom/empryPageCustomNoCreate';

// Importo il css per i Bottoni
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, createButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';

// Crea un link verso google maps
import GoogleMapsLink from "../shared/GoogleMapsLink";

// Edit singolo Mezzo
import EditMezzoFornitore from "../fornitori/editMezzoFornitore";

/* Parametri di default per il componente che si occupa della posizinoe gps  */
import MapPicker from "react-google-map-picker";
import TariffeAccordion from "./TariffeAccordion";
const DefaultLocation = { lat: 41.0523108, lng: 8.2095084 };
const DefaultZoom = 10;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function ResponsiveDialog2() {

    const { setValue } = useFormContext();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    function handleChangeLocation(lat, lng) {
        setValue('latitudine', lat.toString());
        setValue('longitudine', lng.toString());
        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    return (
        <>
            <div>
                <Button onClick={handleOpen}>Apri mappa e seleziona il punto di escursione</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <label>Latitudine:</label><input type='text' value={location.lat} disabled />
                        <label>Longitudine:</label><input type='text' value={location.lng} disabled />
                        <MapPicker defaultLocation={defaultLocation}
                            zoom={zoom}
                            mapTypeId="roadmap"
                            style={{ height: '700px' }}
                            onChangeLocation={handleChangeLocation}
                            onChangeZoom={handleChangeZoom}
                            /* apiKey='AIzaSyBNi7XqlJRL4aW-GiWKA_g3ssmM3OO8kqM'  */
                            apiKey='AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY'
                        />
                    </Box>
                </Modal>
            </div>

        </>
    );
}

const listFiltersFornitori = {
    'id': numberFilter({ operators: ["eq", "neq", "gt", "lt"] }),
};

const ListActions = () => (
    <TopToolbar>

        <CreateButton label="Crea" sx={createButtonCss}></CreateButton>
        {/* <ExportButton label="Esporta" sx={exportButtonCss} /> */}
        <SelectColumnsButton sx={columnsButtonCss} />
        {/* <StackedFilters sx={filterButtonCss} config={listFiltersFornitori} /> */}

    </TopToolbar>
);

export const FornitoriList = props => {

    const { permissions } = usePermissions();

    return (
        <List title={<>
            <Button>
                &nbsp;Fornitori
            </Button>
        </>}
            perPage={100}
            sort={{ field: 'id', order: 'asc' }}
            exporter={false}
            filters={fornitoriFilters}
            empty={(permissions === 'admin')
                ? <EmptyListCustom titolo="Lista"></EmptyListCustom>
                : <EmptyListCustomNoCreate titolo="Lista"></EmptyListCustomNoCreate>}
            /*aside={<FornitoriFilterSidebar />}*/
            {...props}
            actions={<ListActions />}
            filter={{ id: { $ne: 0 } }}
        >
            <DatagridConfigurable sx={listCss} bulkActionButtons={false}>
                {/* {(permissions === 'admin') ?? <TextField label="Id" source="id" textAlign="right" />} */}
                {<TextField label="Ragione Sociale" source="nome" />}
                {/* {<GetNomeCatenaAlberghiera source="catenaAlberghieraId"></GetNomeCatenaAlberghiera>} */}
                {<TextField label="Referente" source="referente" />}
                {<TextField label="Indirizzo" source="indirizzo" />}
                {/* {<GoogleMapsLink ></GoogleMapsLink>}
                {<RichTextField label="Note" source="note" />} */}
                {<EditButton sx={editButtonCss} label="Modifica" textAlign="center" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" textAlign="center" />}
                <WrapperField label="Elimina" textAlign="center">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Fornitore"}
                        messaggio={"Sei sicuro di vole cancellare questo Fornitore ?"}
                        servizio={"fornitori"} />
                </WrapperField>

            </DatagridConfigurable>
        </List>
    );
}

export const FornitoriCreate = (props) => (
    <Create title={<>
        <Button>

            &nbsp;Crea Fornitore
        </Button>
    </>}  {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275, boxShadow: 3 }}>
                        <CardContent>
                            <Typography component="div">
                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <Box sx={{ width: '100%' }}>
                                            {/* <LocalOfferIcon sx={{ color: 'action.active', mr: 1, my: 0.5, fontSize: '1.25rem' }} />  */}
                                            {/* <CustomAutocompleInput source="tipoFornitoreId" reference="tipo-fornitore" optionProperty="descrizione" label="" ></CustomAutocompleInput> */}
                                            <ReferenceInput source="tipoFornitoreId" reference="tipo-fornitore">
                                                <AutocompleteInput optionText="descrizione" />
                                            </ReferenceInput>
                                        </Box>
                                    </Grid>


                                    {[

                                        { icon: BusinessIcon, label: 'Ragione Sociale', source: 'nome', component: TextInput },
                                        { icon: PersonIcon, label: 'Referente', source: 'referente', component: TextInput },
                                        { icon: WorkIcon, label: 'Partita Iva', source: 'partitaiva', component: TextInput },
                                        { icon: PhoneIcon, label: 'Telefono', source: 'telefono', component: TextInput },
                                        { icon: PhoneIcon, label: 'Cellulare', source: 'cellulare', component: TextInput },
                                        { icon: EmailIcon, label: 'Email', source: 'email', component: TextInput },
                                        { icon: HomeIcon, label: 'Indirizzo', source: 'indirizzo', component: TextInput },
                                        { icon: NoteIcon, label: 'Note', source: 'note', component: RichTextInput },
                                    ].map((field, index) => (


                                        <Grid item xs={12} sm={index < 8 ? 6 : 12} key={field.label}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                {React.createElement(field.icon, { sx: { color: 'action.active', mr: 1, my: 0.5, fontSize: '1.25rem' } })}
                                                {React.createElement(field.component, {
                                                    label: field.label,
                                                    source: field.source,
                                                    ...(field.choices ? { choices: field.choices } : {}),
                                                    sx: { width: '100%' },
                                                })}
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);


/*  */
const MezziShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <Card variant="outlined" sx={{
                m: 1,
                borderRadius: 2,
                boxShadow: 3,  // Aggiunge un'ombra per una migliore profondità
                '&:hover': {
                    boxShadow: 6,  // Ombra più pronunciata al passaggio del mouse
                }
            }}>
                <CardContent>
                    <TextInput source="fornitoriId" defaultValue={parseInt(id)} disabled ></TextInput>
                    <br></br>
                    <TextInput source="nome"></TextInput>
                    <br></br>
                    <RichTextInput fullWidth source="descrizione"></RichTextInput>
                    <br></br>
                    <SelectInput source="tipoMezzo" choices={[
                        { id: 0, name: 'Mare' },
                        { id: 1, name: 'Terra' }
                    ]} validate={[required()]} />
                    <br></br>
                    {<TextInput source="nominativoReferente" label="Nominativo referente" validate={required()} />}
                    <br></br>
                    {<NumberInput source="partecipantiMax" label="Capienza Posti" validate={required()} />}

                </CardContent>
            </Card>

        </RowForm>
    );

}

const GestioneMezziInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        noDelete
        editForm={<MezziShowDisponibiliRowForm />}
        createForm={<MezziShowDisponibiliRowForm id={record.id} />}
    >
        <Card variant="outlined" sx={{
            m: 1,
            borderRadius: 2,
            boxShadow: 3,  // Aggiunge un'ombra per una migliore profondità
            '&:hover': {
                boxShadow: 6,  // Ombra più pronunciata al passaggio del mouse
            }
        }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="subtitle1" component="div">
                        <FunctionField label="Tipo" sortBy="tipoMezzo" render={record => (
                            <>
                                {record.tipoMezzo === 0 ?
                                    <DirectionsBoat sx={{ width: 56, height: 56, marginRight: 2, color: 'blue' }} /> :
                                    <DirectionsCarSharp sx={{ width: 56, height: 56, marginRight: 2, color: 'green' }} />
                                }
                            </>
                        )} />
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        Nome Mezzo: <TextField source="nome" variant="outlined" size="small" />
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        Tipo: <FunctionField label="Tipo" sortBy="tipoMezzo" render={record => (
                            <>
                                {record.tipoMezzo === 0 ?
                                    <strong style={{ color: 'blue' }}>Mare</strong> :
                                    <strong style={{ color: 'green' }}>Terra</strong>
                                }
                            </>
                        )} />
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        Posti disponibili: <TextField source="partecipantiMax" variant="outlined" size="small" />
                    </Typography>
                    {/*                     <Typography variant="subtitle1" component="div">
                        <FunctionField label="Modifica" render={record => <EditMezzoFornitore
                            doc={record} label={"Mezzo"}
                        />} />
                    </Typography> */}
                    <br></br>
                    <Typography variant="subtitle1" component="div">
                        <FunctionField label="" render={record => <ConfirmDialogDeleteButtonCustom
                            titolo={"Cancella Mezzo"}
                            messaggio={"Sei sicuro di vole cancellare questo Mezzo ?"}
                            servizio={"mezzi"}
                            redirectPath={`/fornitori/${record.fornitoriId}/show/1`}
                        />} />
                    </Typography>
                </Box>
            </CardContent>
        </Card>

    </EditableDatagrid>);

}

const UsersShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="fornitoriId" defaultValue={parseInt(id)} disabled ></TextInput>
            <SelectInput source="sottoruolo" label="Sotto Ruolo" choices={[
                { id: 'RESPONSABILE', name: 'RESPONSABILE' },
                { id: 'CHECKVOUCHER', name: 'CHECKVOUCHER' },
            ]}></SelectInput>
            <CustomAutocompleInputUsers isFiltered={true} filterField="roles" filterValue="fornitore" source="usersId" reference="users" optionProperty="email" label="" ></CustomAutocompleInputUsers>

        </RowForm>
    );

}

const GestioneUsersInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        editForm={<UsersShowDisponibiliRowForm />}
        createForm={<UsersShowDisponibiliRowForm id={record.id} />}
    >
        <Card variant="outlined"
            sx={{ margin: 1, borderRadius: 2 }}
        >
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                    <Typography variant="subtitle1" component="div">
                        <FunctionField
                            source="sottoruolo" // used for sorting
                            label=""
                            render={record => record && <>
                                Sotto Ruolo : <strong> {record.sottoruolo}</strong>
                            </>}
                        />
                    </Typography>
                </Box>
                {/*  Attenzione a questa reference */}
                <ReferenceField source="usersId" reference="users" link={false}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>

                        </Avatar>
                        <Typography variant="subtitle1" component="div">
                            ID User : <TextField source="id" />
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Email : <strong><TextField source="email" /></strong>
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            {<FunctionField
                                source="usersId"
                                render={record => {
                                    console.log(record);
                                    return <NavigateButton recordId={record.id} service="users" message="Dettaglio User"></NavigateButton>;
                                }}
                            />}
                        </Typography>
                    </Box>
                </ReferenceField>
            </CardContent>
        </Card>
    </EditableDatagrid>);

}

const NavigateButton = ({ recordId, service, message }) => {
    const handleNavigate = () => {

        const url = `#/${service}/${recordId}/show`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Button sx={showButtonCss} onClick={handleNavigate}>{message}</Button>
    );
};

const EscursioniShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="fornitoriId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInput source="escursioniId" reference="escursioni" optionProperty="titoloricerca" label="" ></CustomAutocompleInput>

        </RowForm>
    );

}

const GestioneEscursioniInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (
        <Datagrid
            bulkActionButtons={false}
        /*   editForm={<EscursioniShowDisponibiliRowForm />}
          createForm={<EscursioniShowDisponibiliRowForm id={record.id} />} */
        >
            <Card variant="outlined"
                sx={{ margin: 1, borderRadius: 2 }}
            >
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>
                            <MapIcon /> {/* Utilizza l'icona Map dentro l'Avatar */}
                        </Avatar>
                        <Typography variant="subtitle1" component="div">
                            ID Escursione : <TextField source="id" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Titolo ricerca : <TextField source="id" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Titolo ricerca : <TextField source="titoloricerca" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Città : <TextField source="citta" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            {<FunctionField
                                source="escursioniId"
                                render={record => {
                                    return <NavigateButton recordId={record.id} service="escursioni" message="Dettaglio escursione"></NavigateButton>;
                                }}
                            />}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Datagrid>);

}

/*  */

export const FornitoriShow = (props) => (
    <Show title={<>
        <Button>
            &nbsp;Dettaglio Fornitore
        </Button>
        <TextField label="Nome" source="nome" />
    </>} actions={<ShowActions />}>

        <TabbedForm toolbar={null}>
            <TabbedForm.Tab label="Dati Fornitore">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* TODO: manca , se necessario serve implementare le logiche                   
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                        <GestoreLogoFornitori refresh={refresh}></GestoreLogoFornitori>
                                    </Box>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid> */}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <LocalOfferIcon />
                                            {/* Tipo Fornitore: <TextField label="Nome" source="nome" /> */}
                                            <ReferenceField source="tipoFornitoreId" reference="tipo-fornitore">
                                                <TextField source="descrizione" />
                                            </ReferenceField>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Ragione Sociale: <TextField label="Nome" source="nome" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <PersonIcon />
                                            Referente: <TextField label="Referente" source="referente" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <WorkIcon />
                                            Partita Iva: <TextField label="Partita Iva" source="partitaiva" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Telefono: <TextField label="Telefono" source="telefono" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Cellulare: <TextField label="Cellulare" source="cellulare" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <EmailIcon />
                                            Email: <TextField label="Email" source="email" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Indirizzo: <TextField label="Indirizzo" source="indirizzo" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <NoteIcon />
                                            Note: <RichTextField label="Note" source="note" />
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Mezzi"
                count={<ReferenceManyCount
                    reference="mezzi"
                    target="fornitoriId" />}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Alert severity="info">Tab gestione mezzi <strong>Mezzi</strong></Alert>
                        <ReferenceManyField
                            reference="mezzi"
                            target="fornitoriId"
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <GestioneMezziInShow></GestioneMezziInShow>
                        </ReferenceManyField>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Escursioni"
                count={<ReferenceManyCount
                    reference="escursioni"
                    target="fornitoriId" />}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Alert severity="info">Il fornitore può essere associato solo nel tab 'Fornitori' presente nel dettagli dell'escursione.</Alert>

                        <ReferenceManyField
                            reference="escursioni"
                            target="fornitoriId"
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <GestioneEscursioniInShow></GestioneEscursioniInShow>
                        </ReferenceManyField>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Users" count={<ReferenceManyCount reference="users-fornitori"
                target="fornitoriId" />}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Alert severity="info">Attenzione, in questo tab puoi selezionare solo user con ruolo <strong>Fornitori</strong></Alert>
                        <ReferenceManyField
                            reference="users-fornitori"
                            target="fornitoriId"
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <GestioneUsersInShow></GestioneUsersInShow>
                        </ReferenceManyField>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Tariffe">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>

                        <ReferenceManyField
                            reference="tariffe-exc"
                            target="fornitoriId"
                            label="Tariffe"
                            perPage={100} // Assicurati che corrisponda a quanto definito nel componente
                        >
                            <TariffeAccordion></TariffeAccordion>
                        </ReferenceManyField>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Accordi">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Alert severity="info">In costruzione.</Alert>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
        </TabbedForm>

    </Show>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

export const FornitoriEdit = (props) => (
    <Edit title={<>
        <Button>

            &nbsp;Modifica Fornitore
        </Button>
    </>} actions={<ShowActionsForEdit />} {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275, boxShadow: 3 }}>
                        <CardContent>
                            <Typography component="div">
                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <Box sx={{ width: '100%' }}>
                                            {/* <LocalOfferIcon sx={{ color: 'action.active', mr: 1, my: 0.5, fontSize: '1.25rem' }} />  */}
                                            {/* <CustomAutocompleInput source="tipoFornitoreId" reference="tipo-fornitore" optionProperty="descrizione" label="" ></CustomAutocompleInput> */}
                                            <ReferenceInput source="tipoFornitoreId" reference="tipo-fornitore">
                                                <AutocompleteInput optionText="descrizione" />
                                            </ReferenceInput>
                                        </Box>
                                    </Grid>


                                    {[

                                        { icon: BusinessIcon, label: 'Ragione Sociale', source: 'nome', component: TextInput },
                                        { icon: PersonIcon, label: 'Referente', source: 'referente', component: TextInput },
                                        { icon: WorkIcon, label: 'Partita Iva', source: 'partitaiva', component: TextInput },
                                        { icon: PhoneIcon, label: 'Telefono', source: 'telefono', component: TextInput },
                                        { icon: PhoneIcon, label: 'Cellulare', source: 'cellulare', component: TextInput },
                                        { icon: EmailIcon, label: 'Email', source: 'email', component: TextInput },
                                        { icon: HomeIcon, label: 'Indirizzo', source: 'indirizzo', component: TextInput },
                                        { icon: NoteIcon, label: 'Note', source: 'note', component: RichTextInput },
                                    ].map((field, index) => (


                                        <Grid item xs={12} sm={index < 8 ? 6 : 12} key={field.label}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                {React.createElement(field.icon, { sx: { color: 'action.active', mr: 1, my: 0.5, fontSize: '1.25rem' } })}
                                                {React.createElement(field.component, {
                                                    label: field.label,
                                                    source: field.source,
                                                    ...(field.choices ? { choices: field.choices } : {}),
                                                    sx: { width: '100%' },
                                                })}
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

// Filtri

const FornitoriFilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <FornitoriFilter />
            </CardContent>
        </Card>
    </Box>
);

/// Filtri
const FornitoriFilter = () => (
    <Grid container spacing={2}>
        <Grid>
            <Box >
                {/*         <Box sx={{
        width: '12em',
    }}><h3></h3>
        <Card>
    <CardContent><FilterLiveSearch source="id" />
    </CardContent>
    </Card></Box> */}

                <FilterList label="Zone geografiche" icon={<ContentFilter />}>
                    <FilterListItem
                        label="Nord"
                        value={{
                            zona: "n"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Ovest"
                        value={{
                            zona: "no"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Est"
                        value={{
                            zona: "ne"
                        }}
                    />
                    <FilterListItem
                        label="Est"
                        value={{
                            zona: "e"
                        }}
                    />
                    <FilterListItem
                        label="Sud"
                        value={{
                            zona: "s"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Ovest"
                        value={{
                            zona: "so"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Est"
                        value={{
                            zona: "se"
                        }}
                    />
                    <FilterListItem
                        label="Ovest"
                        value={{
                            zona: "o"
                        }}
                    />
                </FilterList>
            </Box>
        </Grid>
        {/* {  <Grid>
    <Box>
    <FilterList label="Trimestre" icon={<ContentFilter />}>
    <FilterListItem
            label="Primo"
            value={{trimestre: 1
            }}
        />
         <FilterListItem
            label="Secondo"
            value={{trimestre: 2
            }}
        />
    <FilterListItem
            label="Terzo"
            value={{trimestre: 3
            }}
        />
         <FilterListItem
            label="Quarto"
            value={{trimestre: 4
            }}
        />
    </FilterList>
    </Box>
  </Grid>} */}
    </Grid>);


const fornitoriFilters = [
    <TextInput label="Cerca per Ragione Sociale" source="nome" alwaysOn />/* ,
    <TextInput label="Cerca per id" source="id" alwaysOn /> */
];