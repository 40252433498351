import React, { useState, useEffect } from 'react';
import { useUpdate, useNotify, useRecordContext } from 'react-admin';
import { TextField, Button } from '@mui/material';
import Box from '@mui/material/Box';

import GetTipoPuntoMenu from "../porti/GetTipoPuntoMenu";

/* Parametri di default per il componente che si occupa della posizinoe gps  */
import MapPicker from "react-google-map-picker";
const DefaultLocation = { lat: 41.0523108, lng: 8.2095084 };
const DefaultZoom = 10;

/* TODO: crea un componente a parte - start */

const CustomTextFieldPunto = ({ id, label, value, onChange }) => {
    return (
        <TextField
            margin="dense"
            id={id}
            label={label}
            type="text"
            fullWidth
            variant="outlined"
            value={value}
            onChange={onChange}
        />
    );
};

/* stop */

const EditPuntiForm = ({ label, refresh }) => {
    const record = useRecordContext();

    // Nessuna necessità di impostare un defaultLocation statico qui
    const [defaultLocation, setDefaultLocation] = useState(null); // Inizializzato a null
    const [nome, setNome] = useState('');
    const [longitudineText, setLongitudineText] = useState('');
    const [latitudineText, setLatitudineText] = useState('');
    const [tipoPuntoPartenzaText, setTipoPuntoPartenzaText] = useState('');
    const notify = useNotify();
    const [update, { isLoading, error }] = useUpdate();

    const [location, setLocation] = useState(null); // Inizializzato a null
    const [zoom, setZoom] = useState(DefaultZoom);
    const [isMapReady, setIsMapReady] = useState(false); // Nuova variabile di stato per tracciare la prontezza della mappa

    useEffect(() => {
        setNome(record.nome || '');
        setLongitudineText(record.longitudine || '');
        setLatitudineText(record.latitudine || '');
        setTipoPuntoPartenzaText(record.tipoPuntoPartenza || '');
        if (record && record.longitudine && record.latitudine) {
            const newLocation = { lat: Number(record.latitudine), lng: Number(record.longitudine) };
            setDefaultLocation(newLocation);
            setLocation(newLocation);
            setIsMapReady(true); // Imposta la mappa come pronta solo dopo l'aggiornamento delle coordinate
        } else {
            setIsMapReady(false); // Imposta la mappa come non pronta se le coordinate non sono disponibili
        }
    }, [record]);

    if (!record || !record.id) {
        return null;
    }


    const handleClick = () => {
        const data = {
            tipoPuntoPartenza: tipoPuntoPartenzaText,
            nome: nome,
            latitudine: latitudineText,
            longitudine: longitudineText,
        };

        update(
            'puntiincontro',
            { id: record.id, data: data, previousData: record },
            {
                onSuccess: ({ data }) => {
                    refresh();
                    notify('Punto creato con successo');
                    // setNome(''); // Reset della note dopo la creazione con successo
                },
                onFailure: (error) => {
                    notify(error.message, 'warning');
                }
            }
        );
    };

    if (error) {
        // console.log(error);
        return <p>Attenzione. Problemi d'accesso alla tabella. Riavvia la pagina. </p>; // Considera una gestione degli errori più sofisticata
    }

    function handleChangeLocation(lat, lng) {
        setLatitudineText(lat.toString());
        setLongitudineText(lng.toString());
        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    const handleTipoPuntoChange = (tipoPuntoId) => {
        setTipoPuntoPartenzaText(tipoPuntoId);
    };

    return (
        <Box >
            <GetTipoPuntoMenu onTipoPuntoChange={handleTipoPuntoChange} defaultValue={tipoPuntoPartenzaText}></GetTipoPuntoMenu>
            <CustomTextFieldPunto
                id="nome"
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
            />
            <CustomTextFieldPunto
                id="latitudine"
                label="Latitudine"
                value={latitudineText}
                onChange={(e) => setLatitudineText(e.target.value)}
            />

            <CustomTextFieldPunto
                id="longitudine"
                label="Longitudine"
                value={longitudineText}
                onChange={(e) => setLongitudineText(e.target.value)}
            />

            {/* <label>Latitute:</label><input type='text' value={longitudineText} disabled /> */}
            {/* <label>Longitute:</label><input type='text' value={latitudineText} disabled /> */}
            {isMapReady && <MapPicker defaultLocation={defaultLocation}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: '500px' }}
                // onChangeLocation={handleChangeLocation}
                onChangeLocation={(lat, lng) => {
                    setLatitudineText(lat.toString());
                    setLongitudineText(lng.toString());
                    setLocation({ lat, lng });
                }}
                onChangeZoom={handleChangeZoom}
                /* apiKey='AIzaSyBNi7XqlJRL4aW-GiWKA_g3ssmM3OO8kqM'  */
                apiKey='AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY'
            />}

            <Button
                onClick={handleClick}
                disabled={isLoading || !nome || !longitudineText || !latitudineText || !tipoPuntoPartenzaText}
                variant="contained"
                color="primary"
                style={{ margin: '10px' }}
            >
                Salva modifiche
            </Button>
        </Box>
    );
};

export default EditPuntiForm;
