import React from 'react';
import { TextInput, NumberInput, FormDataConsumer } from 'react-admin';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { RichTextInput } from "ra-input-rich-text";
import { useRecordContext } from 'react-admin';
import FornitoreSelectInput from './FornitoreSelectInput';
import MezziSelectInput from './MezziSelectInput';
import { Grid, Paper, Typography, Box } from '@mui/material';

export const TransferRighiRowFormCard = ({ istanzeEscursioniId, ...props }) => {
    const record = useRecordContext();

    return (
        <RowForm {...props}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    backgroundColor: '#f0f8ff',  // Colore di sfondo azzurro chiaro
                    padding: 3,
                    borderRadius: 2,
                    border: '1px solid #b0d4ff'  // Bordo azzurro più scuro
                }}
            >

                <Paper elevation={2} sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Informazioni sul Mezzo
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FornitoreSelectInput
                                source="fornitoriId"
                                label="Seleziona Fornitore"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    formData.fornitoriId ? (
                                        <MezziSelectInput
                                            fornitoriId={formData.fornitoriId}
                                            label="Seleziona Mezzo"
                                            {...rest}
                                            fullWidth
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextInput source="descrizionemezzo" label="Descrizione" disabled fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <NumberInput source="costo" label="Costo" disabled fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextInput source="tariffeExcId" label="Tariffa exc" disabled fullWidth />
                        </Grid>
                    </Grid>
                </Paper>
                <hr></hr>
                <Paper elevation={2} sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Dettagli Autista e Guida
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextInput source="nominativoautista" label="Nominativo Autista" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextInput source="recapitoautista" label="Recapito Autista" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextInput source="nominativoguida" label="Nominativo Guida" fullWidth />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={2} sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Capienza e Occupazione
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <NumberInput source="capienzamezzo" label="Capienza" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <NumberInput source="postidisponibili" label="Posti Disponibili" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <NumberInput source="postioccupati" label="Posti Occupati" fullWidth />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={2} sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Note
                    </Typography>
                    <RichTextInput label="" source="note" fullWidth />
                </Paper>
            </Box>
        </RowForm>
    );
};

export default TransferRighiRowFormCard;