import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from '@mui/material/Tooltip';

const TariffeCountFieldOnlyIcons = ({ resource, filter, onCountChange }) => {
    const dataProvider = useDataProvider();
    const [status, setStatus] = useState('loading');
    const [tooltipText, setTooltipText] = useState('');

    const fetchData = useCallback(() => {
        dataProvider.getList(resource, {
            filter: filter,
            pagination: { page: 1, perPage: 1000 },
        })
            .then(response => {
                const records = response.data;
                const total = response.total;
                onCountChange(total);

                if (total === 0) {
                    setStatus('error');
                    setTooltipText('Nessun record trovato per questo escursioniId');
                    onCountChange(0);
                } else {
                    const hasInvalidRecord = records.some(record =>
                        record.puntiincontroId === 0 || record.numTariffe === 0
                    );

                    if (hasInvalidRecord) {
                        setStatus('error');
                        setTooltipText('Almeno un record ha puntiincontroId o numTariffe pari a zero');
                        onCountChange(0);
                    } else {
                        setStatus('success');
                        setTooltipText('Tutti i record hanno puntiincontroId e numTariffe diversi da zero');
                    }
                }
            })
            .catch(error => {
                console.error(error);
                setStatus('error');
                setTooltipText('Errore nel caricamento dei dati');
            });
    }, [resource, filter, dataProvider, onCountChange]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getIcon = () => {
        if (status === 'error') {
            return (
                <Tooltip title={tooltipText}>
                    <ErrorOutlineIcon style={{ color: 'red', margin: 0, padding: 0 }} />
                </Tooltip>
            );
        } else {
            return (
                <Tooltip title={tooltipText}>
                    <CheckCircleOutlineIcon style={{ color: 'green', margin: 0, padding: 0 }} />
                </Tooltip>
            );
        }
    };

    return (
        <span style={{ margin: 0, padding: 0, display: 'inline-flex', alignItems: 'center' }}>
            {getIcon()}
        </span>
    );
};

export default TariffeCountFieldOnlyIcons;