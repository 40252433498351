import React, { useState } from 'react';
import { useCreate, useNotify } from 'react-admin';
import { TextField, Button } from '@mui/material';
import Box from '@mui/material/Box';

import GetTipoPuntoMenu from "../porti/GetTipoPuntoMenu";

/* Parametri di default per il componente che si occupa della posizinoe gps  */
import MapPicker from "react-google-map-picker";
const DefaultLocation = { lat: 41.0523108, lng: 8.2095084 };
const DefaultZoom = 10;

/* TODO: crea un componente a parte - start */

const CustomTextFieldPunto = ({ id, label, value, onChange }) => {
    return (
        <TextField
            margin="dense"
            id={id}
            label={label}
            type="text"
            fullWidth
            variant="outlined"
            value={value}
            onChange={onChange}
        />
    );
};

/* stop */

const CreatePuntiForm = ({ tipoPuntoPartenza, recordId, userId, refresh }) => {
    const [nome, setNome] = useState('');
    const [longitudineText, setLongitudineText] = useState('');
    const [latitudineText, setLatitudineText] = useState('');
    const [tipoPuntoPartenzaText, setTipoPuntoPartenzaText] = useState('');
    const notify = useNotify();
    const [create, { isLoading, error }] = useCreate();

    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    const handleClick = () => {
        const data = {
            tipoPuntoPartenza: tipoPuntoPartenzaText,
            nome: nome,
            latitudine: latitudineText,
            longitudine: longitudineText,
        };

        create(
            'puntiincontro',
            { data },
            {
                onSuccess: ({ data }) => {
                    refresh();
                    notify('Punto creato con successo');
                    // setNome(''); // Reset della note dopo la creazione con successo
                },
                onFailure: (error) => {
                    notify(error.message, 'warning');
                }
            }
        );
    };

    if (error) {
        // console.log(error);
        return <p>Attenzione. Problemi d'accesso alla tabella. Riavvia la pagina. </p>; // Considera una gestione degli errori più sofisticata
    }

    function handleChangeLocation(lat, lng) {
        setLatitudineText(lat.toString());
        setLongitudineText(lng.toString());
        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    const handleTipoPuntoChange = (tipoPuntoId) => {
        setTipoPuntoPartenzaText(tipoPuntoId);
    };

    return (
        <Box >
            <GetTipoPuntoMenu onTipoPuntoChange={handleTipoPuntoChange}></GetTipoPuntoMenu>
            <CustomTextFieldPunto
                id="nome"
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
            />
            <CustomTextFieldPunto
                id="latitudine"
                label="Latitudine"
                value={latitudineText}
                onChange={(e) => setLatitudineText(e.target.value)}
            />

            <CustomTextFieldPunto
                id="longitudine"
                label="Longitudine"
                value={longitudineText}
                onChange={(e) => setLongitudineText(e.target.value)}
            />

            {/* <label>Latitute:</label><input type='text' value={longitudineText} disabled /> */}
            {/* <label>Longitute:</label><input type='text' value={latitudineText} disabled /> */}
            <MapPicker defaultLocation={defaultLocation}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: '500px' }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                /* apiKey='AIzaSyBNi7XqlJRL4aW-GiWKA_g3ssmM3OO8kqM'  */
                apiKey='AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY'
            />

            <Button
                onClick={handleClick}
                disabled={isLoading || !nome || !longitudineText || !latitudineText || !tipoPuntoPartenzaText}
                variant="contained"
                color="primary"
                style={{ margin: '10px' }}
            >
                Crea
            </Button>
        </Box>
    );
};

export default CreatePuntiForm;
