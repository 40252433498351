import {
    usePermissions

} from 'react-admin';
import { Layout } from 'react-admin';
import { MyMenu } from '../shared/MyMenu/MyMenu';
import { MyAppBar } from '../shared/MyAppBar/MyAppBar';
import {
    AppLocationContext,
    Breadcrumb,
    ResourceBreadcrumbItems,
} from "@react-admin/ra-navigation";



export const MyLayout = ({ children, ...props }) => {

    const { permissions } = usePermissions();

    return (
        <AppLocationContext hasDashboard>
            <Layout {...props} menu={MyMenu} appBar={MyAppBar}>
                <Breadcrumb hasDashboard={true}>
                    <ResourceBreadcrumbItems />
                </Breadcrumb>
                {children}
            </Layout>
        </AppLocationContext>
    );
}

