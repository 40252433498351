import { Card, CardContent, CardHeader, Link } from "@mui/material";
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import BedIcon from '@mui/icons-material/Bed';
import MapIcon from '@material-ui/icons/Map';
import LandscapeIcon from '@mui/icons-material/Landscape';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DirectionsCarSharp from '@mui/icons-material/DirectionsCarSharp';
import { usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
/* Foglio stile icons */
import { iconBigStyle } from '../shared/Styles/iconsCss';
import axios from 'axios';
import config from '../../config/config.json';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const DeterminaLinkCateneAlberghiere = ({ resource, userId }) => {
    const [items, setItems] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null); // Stato per tracciare il valore selezionato

    const jwt = localStorage.getItem("feathers-jwt");
    console.log("JWT:", jwt);  // Verifica del token JWT stampato

    useEffect(() => {
        const fetchData = async () => {
            try {
                let configx = {
                    method: 'get',
                    url: `${config.host}/users-catena-alberghiera?userId=${userId}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    }
                };

                const response = await axios.request(configx);
                console.log("Risposta della chiamata:", response.data); // Stampa il risultato della chiamata
                setItems(response.data.data); // Ora setItems riceve l'array corretto
            } catch (error) {
                console.error("Errore nel fetch dei dati:", error);
            }
        };

        fetchData();
    }, [userId]); // Dipendenze di useEffect

    return (
        <>CIAO</> // Puoi inserire qui gli elementi JSX per visualizzare i dati
    );
}


export const Dashboard = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();

    return (

        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        {(permissions === 'admin') && <CardHeader className={classes.paper} title="Dashboard Admin" />}
                        {(permissions === 'hotel') && <CardHeader className={classes.paper} title="Dashboard Hotel" />}
                        {(permissions === 'catenaalberghiera') && <CardHeader className={classes.paper} title="Dashboard Catena Alberghiera" />}
                        {(permissions === 'touroperator') && <CardHeader className={classes.paper} title="Dashboard Tour Operator" />}
                        {(permissions === 'fornitore') && <CardHeader className={classes.paper} title="Dashboard Fornitore" />}

                        <CardContent>
                        </CardContent>
                    </Card>
                </Grid>
                {permissions === 'admin' && <Grid item xs={6}>
                    <Card>
                        <CardHeader className={classes.paper} title="Ultimi utenti registrati" />
                        <CardContent>

                            <CardContent>SERVE VISTA</CardContent>

                        </CardContent>
                    </Card>
                </Grid>}
                {permissions === 'admin' && <Grid item xs={6}>
                    <Card>
                        <CardHeader className={classes.paper} title="Ultimi ordini creati" />
                        <CardContent>
                            <CardContent>SERVE VISTA</CardContent>
                        </CardContent>
                    </Card>
                </Grid>}
                {permissions === 'admin' && <Grid item xs={6}>
                    <Card>
                        <CardHeader className={classes.paper} title="Ultime escursioni create" />
                        <CardContent>
                            <CardContent>SERVE VISTA</CardContent>

                        </CardContent>
                    </Card>
                </Grid>}

                {permissions === 'hotel' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <MapIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/escursioni" className={classes.link}>Escursioni</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {permissions === 'hotel' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <LandscapeIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/istanze-escursioni" className={classes.link}>Istanze Escursioni</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {permissions === 'hotel' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <BedIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/hotels" className={classes.link}>Anagrafica Hotel</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {/* ********************** */}

                {permissions === 'catenaalberghiera' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <MapIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/escursioni" className={classes.link}>Escursioni</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {permissions === 'catenaalberghiera' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <LandscapeIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/istanze-escursioni" className={classes.link}>Istanze Escursioni</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {permissions === 'catenaalberghiera' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <BedIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/catena-alberghiera" className={classes.link}>Anagrafica Catena Alberghiera</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {/* ********************** */}

                {permissions === 'touroperator' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <MapIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/escursioni" className={classes.link}>Escursioni</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {permissions === 'touroperator' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <LandscapeIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/istanze-escursioni" className={classes.link}>Istanze Escursioni</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {permissions === 'touroperator' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <BedIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/tour-operator" className={classes.link}>Anagrafica Tour Operator</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {/* ********************** */}

                {permissions === 'fornitore' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <MapIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/escursioni" className={classes.link}>Escursioni</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {permissions === 'fornitore' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <LandscapeIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/istanze-escursioni" className={classes.link}>Istanze Escursioni</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {permissions === 'fornitore' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <PeopleAltIcon style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/fornitori" className={classes.link}>Anagrafica Fornitore</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {permissions === 'fornitore' &&
                    <Grid item xs={3}>

                        <Card sx={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                    <DirectionsCarSharp style={iconBigStyle} />
                                    <hr></hr>
                                    <Link href="/#/mezzi" className={classes.link}>Mezzi</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

            </Grid>
        </div>
    );

}