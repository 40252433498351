import React, { useEffect, useState } from 'react';
import { useGetList, Button, useDataProvider } from 'react-admin';
import { exportButtonCss } from '../../shared/Styles/buttonStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const ExportAllPartecipantiButton = ({ istanzeEscursioniId, fields }) => {
    const { data: ordiniData, isLoading: isLoadingOrdini, error: errorOrdini } = useGetList(
        'ordini',
        {
            pagination: false,
            filter: {
                istanzeEscursioniId: istanzeEscursioniId
            }
        }
    );

    const dataProvider = useDataProvider();
    const [partecipantiData, setPartecipantiData] = useState([]);
    const [loadingPartecipanti, setLoadingPartecipanti] = useState(true);
    const [errorPartecipanti, setErrorPartecipanti] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchAllPartecipanti = async () => {
            if (ordiniData && ordiniData.length > 0) {
                try {
                    let allPartecipanti = [];
                    for (let ordine of ordiniData) {
                        const { data } = await dataProvider.getList('ordini-partecipanti', {
                            pagination: false,
                            filter: { ordiniId: ordine.id }
                        });
                        allPartecipanti = allPartecipanti.concat(data);
                    }
                    setPartecipantiData(allPartecipanti);
                    setLoadingPartecipanti(false);
                } catch (error) {
                    setErrorPartecipanti(error);
                    setLoadingPartecipanti(false);
                }
            } else {
                setLoadingPartecipanti(false);
            }
        };
        fetchAllPartecipanti();
    }, [ordiniData, dataProvider]);

    const handleExport = () => {
        if (!partecipantiData || partecipantiData.length === 0) {
            setOpen(true);
            return;
        }

        const csvData = convertToCSV(partecipantiData, fields);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tutti_partecipanti_istanzaEscursioniId_' + istanzeEscursioniId + '.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const convertToCSV = (objArray, fields) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        const headers = fields;
        const csvRows = [
            headers.join(','), // header row first
            ...array.map(row => headers.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ];
        return csvRows.join('\r\n');
    };

    const replacer = (key, value) => value === null ? '' : value;

    return (
        <>
            <Button
                onClick={handleExport}
                label="Export CSV"
                disabled={isLoadingOrdini || loadingPartecipanti || errorOrdini || errorPartecipanti}
                sx={exportButtonCss}
            >
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Nessun partecipante trovato"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Non ci sono partecipanti da esportare.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ExportAllPartecipantiButton;
