import React, { useState, useEffect } from 'react';
import { useDataProvider, SelectInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Typography, Box, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const MezziSelectInput = ({ fornitoriId, ...props }) => {
    const dataProvider = useDataProvider();
    const [allMezzi, setAllMezzi] = useState([]);
    const [tratte, setTratte] = useState([]);
    const [selectedTratta, setSelectedTratta] = useState('');
    const [filteredMezzi, setFilteredMezzi] = useState([]);
    const { setValue, watch } = useFormContext();
    const selectedMezzoId = watch('mezzoSelezionato');

    useEffect(() => {
        if (fornitoriId) {
            dataProvider.getList('tariffe-exc', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'id', order: 'ASC' },
                filter: { fornitoriId },
            })
                .then(({ data }) => {
                    const mezzi = data.flatMap(record =>
                        Array.from({ length: 15 }, (_, i) => i + 1)
                            .filter(i => record[`descrmezzo${i}`])
                            .map(i => ({
                                id: `${record.id}-${i}`,
                                descrizione: record[`descrmezzo${i}`],
                                costo: record[`cos${i}`],
                                tariffeExcId: record.id,
                                partenza: record.partenza,
                                destinazione: record.destinazione,
                                durata: record.durata,
                                anno: record.anno,
                                datafrom: record.datafrom,
                                datato: record.datato,
                            }))
                    );
                    setAllMezzi(mezzi);

                    // Crea un array di tratte uniche
                    const uniqueTratte = Array.from(new Set(mezzi.map(m => `${m.partenza} - ${m.destinazione}`)));
                    setTratte(uniqueTratte);
                });
        } else {
            setAllMezzi([]);
            setTratte([]);
        }
    }, [fornitoriId, dataProvider]);

    const handleTrattaChange = (event) => {
        const selectedTratta = event.target.value;
        setSelectedTratta(selectedTratta);

        // Filtra i mezzi in base alla tratta selezionata
        const [partenza, destinazione] = selectedTratta.split(' - ');
        const filteredMezzi = allMezzi.filter(m => m.partenza === partenza && m.destinazione === destinazione);
        setFilteredMezzi(filteredMezzi);

        // Resetta la selezione del mezzo
        setValue('mezzoSelezionato', null);
    };

    const handleMezzoChange = (e) => {
        const selectedMezzo = filteredMezzi.find(choice => choice.id === e.target.value);
        if (selectedMezzo) {
            setValue('mezzoSelezionato', selectedMezzo.id);
            setValue('descrizionemezzo', selectedMezzo.descrizione);
            setValue('costo', selectedMezzo.costo);
            setValue('tariffeExcId', selectedMezzo.tariffeExcId);
        }
    };

    const renderMezzoOptionText = (choice) => `${choice.descrizione} - €${choice.costo}`;

    const selectedMezzo = filteredMezzi.find(choice => choice.id === selectedMezzoId);

    return (
        <>
            <FormControl fullWidth margin="normal">
                <InputLabel>Seleziona Tratta</InputLabel>
                <Select
                    value={selectedTratta}
                    onChange={handleTrattaChange}
                    label="Seleziona Tratta"
                >
                    {tratte.map((tratta) => (
                        <MenuItem key={tratta} value={tratta}>{tratta}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedTratta && (
                <SelectInput
                    {...props}
                    source="mezzoSelezionato"
                    choices={filteredMezzi}
                    optionText={renderMezzoOptionText}
                    optionValue="id"
                    onChange={handleMezzoChange}
                    label="Seleziona Mezzo"
                />
            )}

            {selectedMezzo && (
                <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                    <Typography variant="h6" gutterBottom>Dettagli Automezzo</Typography>
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography><strong>Descrizione:</strong> {selectedMezzo.descrizione}</Typography>
                        <Typography><strong>Costo:</strong> €{selectedMezzo.costo}</Typography>
                        <Typography><strong>Da:</strong> {selectedMezzo.partenza}</Typography>
                        <Typography><strong>A:</strong> {selectedMezzo.destinazione}</Typography>
                        <Typography><strong>Durata:</strong> {selectedMezzo.durata}</Typography>
                        <Typography><strong>Anno:</strong> {selectedMezzo.anno}</Typography>
                        <Typography><strong>Validità:</strong> {new Date(selectedMezzo.datafrom).toLocaleDateString()} - {new Date(selectedMezzo.datato).toLocaleDateString()}</Typography>
                    </Box>
                </Paper>
            )}
        </>
    );
};

export default MezziSelectInput;