import * as React from "react";
import {
    List,
    Datagrid,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    FunctionField,
    PasswordInput,
    Button,
    TopToolbar,
    CreateButton,
    ShowButton,
    WrapperField,
    Show,
    RichTextField,
    Toolbar,
    SaveButton,
    required,
    useNotify,
    useRedirect,
    useRefresh,
    useCreate,
} from 'react-admin';

// import RichTextInput from 'ra-input-rich-text';
// import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout'

import Typography from "@material-ui/core/Typography";

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Importo il css per i Bottoni
import { editButtonCss, showButtonCss, createButtonCss, saveButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import { RichTextInput } from "ra-input-rich-text";
import CustomAutocompleInputTipoUser from "../shared/FormFields/CustomAutocompleInputTipoUser";


const ListActions = () => (
    <TopToolbar>

        <CreateButton label="Crea" sx={createButtonCss}></CreateButton>
        {/* <ExportButton label="Esporta" sx={exportButtonCss} /> */}
        {/* <SelectColumnsButton sx={columnsButtonCss} /> */}
        {/* <StackedFilters sx={filterButtonCss} config={listFiltersHotels} /> */}

    </TopToolbar>
);

export const UsersList = props => {

    return (
        <List
            title={<>
                <Button>
                    &nbsp;Lista utenti
                </Button>
            </>}
            exporter={false}
            actions={<ListActions />}
            perPage={100}
            {...props}>
            <Datagrid
                sx={listCss}
                bulkActionButtons={false}>
                {/* {<TextField label="Id" source="id" />} */}
                {<TextField label="Nome" source="nome" />}
                {<TextField label="Cognome" source="cognome" />}
                {<TextField label="Email" source="email" />}
                {<TextField label="Ruolo" source="roles" />}
                {<TextField label="Codice rivenditore" source="codiceRivenditore" />}
                <ReferenceField label="Hotel" source="hotelId" reference="hotels">
                    <FunctionField render={record => record && `${record.nome} ${record.zona} `} />
                </ReferenceField>
                {<TextField label="Indirizzo" source="indirizzo" />}
                {<TextField label="Cap" source="cap" />}
                {<TextField label="Telefono" source="telefono" />}
                {<EditButton sx={editButtonCss} label="Modifica" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
                <WrapperField label="Elimina">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Utente"}
                        messaggio={"Sei sicuro di vole cancellare questo utente ?"}
                        servizio={"users"} />
                </WrapperField>
            </Datagrid>
        </List>
    );
}

// Funzione di validazione per email
const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) ? undefined : 'Email non valida';
};

// Funzione di validazione per codice fiscale
const validateCodiceFiscale = (value) => {
    const cfRegex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/i;
    return cfRegex.test(value) ? undefined : 'Codice Fiscale non valido';
};

// Funzione di validazione per il numero di telefono
const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]+$/;
    const minLength = 8;
    const maxLength = 15;
    if (!phoneRegex.test(value)) {
        return 'Il numero di telefono deve contenere solo cifre';
    }
    if (value.length < minLength || value.length > maxLength) {
        return `Il numero di telefono deve essere tra ${minLength} e ${maxLength} cifre`;
    }
    return undefined;
};

const requiredField = [required('Questo campo è obbligatorio')];

const UserSaveButton = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`Utente "${data.nome} ${data.cognome}" creato con successo!`, { type: 'info' });
        redirect('/users');
    };

    const onError = (error) => {
        console.log("------------------ UserSaveButton ");
        console.log(error.errors);


        notify(`Non è stato possibile creare l'utente. Motivo dal ${error.errors[0].origin} :  ${error.errors[0].message} per ${error.errors[0].value}`, { type: 'warning' });
    };

    return (
        <SaveButton
            label="Crea nuovo utente"
            sx={createButtonCss}
            type="button" // Imposta il tipo a "button"
            mutationOptions={{ onSuccess, onError }}
        />
    );
};

const UserCreateToolbar = () => (
    <Toolbar>
        <UserSaveButton />
    </Toolbar>
);

export const UsersCreate = (props) => {
    const [role, setRole] = React.useState('');

    // Specifica le voci per cui è necessario il SOTTORUOLO
    const showSubrole = ['catenaalberghiera', 'fornitore', 'touroperator', 'hotel'].includes(role);

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    return (
        <Create redirect="show" title="Crea Nuovo User" {...props}>
            <SimpleForm toolbar={<UserCreateToolbar />} redirect="show">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <SelectInput
                                        source="roles"
                                        label="Ruolo"
                                        choices={[
                                            { id: 'venditore', name: 'venditore' },
                                            { id: 'cliente', name: 'cliente' },
                                            { id: 'fornitore', name: 'fornitore' },
                                            { id: 'hotel', name: 'hotel' },
                                            { id: 'admin', name: 'admin' },
                                            { id: 'touroperator', name: 'touroperator' },
                                            { id: 'catenaalberghiera', name: 'catenaalberghiera' },
                                        ]}
                                        validate={requiredField}
                                        onChange={handleRoleChange}
                                    />
                                    {role === 'fornitore' && (
                                        <CustomAutocompleInputTipoUser source="fornitoreId" reference="fornitori" optionProperty="nome" label="Seleziona Fornitore" isRequired={true} />
                                    )}
                                    {role === 'hotel' && (
                                        <CustomAutocompleInputTipoUser source="hotelId" reference="hotels" optionProperty="nome" label="Seleziona Hotel" isRequired={true} />
                                    )}
                                    {role === 'touroperator' && (
                                        <CustomAutocompleInputTipoUser source="touroperatorId" reference="tour-operator" optionProperty="nome" label="Seleziona Tour Operator" isRequired={true} />
                                    )}
                                    {role === 'catenaalberghiera' && (
                                        <CustomAutocompleInputTipoUser source="catenaalberghieraId" reference="catena-alberghiera" optionProperty="nome" label="Seleziona Catena Alberghiera" isRequired={true} />
                                    )}
                                    {role === 'venditore' && (
                                        <CustomAutocompleInputTipoUser source="venditoriId" reference="venditori" optionProperty="nome" label="Seleziona Venditore" isRequired={true} />
                                    )}
                                    {showSubrole && (
                                        <SelectInput
                                            source="sottoruolo"
                                            label="Sottoruolo"
                                            choices={[
                                                { id: 'RESPONSABILE', name: 'RESPONSABILE' },
                                                { id: 'VENDITORE', name: 'VENDITORE' },
                                                { id: 'CHECKVOUCHER', name: 'CHECKVOUCHER' },
                                            ]}
                                            validate={required('Seleziona il sotto ruolo')}
                                        />
                                    )}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <TextInput fullWidth sx={{ mb: 2 }} label="Nome" source="nome" validate={requiredField} />
                                    <TextInput fullWidth sx={{ mb: 2 }} label="Cognome" source="cognome" validate={requiredField} />
                                    <TextInput fullWidth sx={{ mb: 2 }} label="Codice rivenditore" source="codiceRivenditore" validate={requiredField} />
                                    <TextInput fullWidth sx={{ mb: 2 }} label="Codice fiscale" source="codiceFiscale" validate={[required('Questo campo è obbligatorio'), validateCodiceFiscale]} />
                                    <ReferenceInput fullWidth sx={{ mb: 2 }} label="Hotel" source="hotelId" reference="hotels" validate={requiredField}>
                                        <SelectInput optionText="nome" />
                                    </ReferenceInput>
                                    <PasswordInput fullWidth sx={{ mb: 2 }} label="Password" source="password" validate={requiredField} />
                                    <TextInput fullWidth sx={{ mb: 2 }} label="Email" source="email" validate={[required('Questo campo è obbligatorio'), validateEmail]} />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <TextInput fullWidth sx={{ mb: 2 }} label="Commissione" source="commissione1" validate={requiredField} />
                                    <TextInput fullWidth sx={{ mb: 2 }} label="Indirizzo" source="indirizzo" validate={requiredField} />
                                    <TextInput fullWidth sx={{ mb: 2 }} label="Cap" source="cap" validate={requiredField} />
                                    <TextInput fullWidth sx={{ mb: 2 }} label="Telefono" source="telefono" validate={[required('Questo campo è obbligatorio'), validatePhoneNumber]} />
                                    <RichTextInput fullWidth sx={{ mb: 2 }} label="Note" multiline source="note" />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
}

/* Wizard CREATE - importante: i componenti sono relativi a react-admin 4.8 */
/* export const UsersCreate = (props) => (
    <Create>
        <WizardForm>
            <WizardFormStep label="Primo step">
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <br></br>
                            {<TextInput label="Nome" source="nome" />}
                            <br></br>
                            {<TextInput label="Cognome" source="cognome" />}
                            <br></br>
                            {<TextInput label="Codice rivenditore" source="codiceRivenditore" />}
                            <br></br>
                            {<TextInput label="Codice fiscale" source="codiceFiscale" />}
                            <br></br>
                            <ReferenceInput abel="Hotel" source="hotelId" reference="hotels">
                                <SelectInput optionText="nome" />
                            </ReferenceInput>
                            <br></br>
                            {<PasswordInput label="Password" source="password" />}
                            <br></br>
                            {<TextInput label="Email" source="email" />}
                            <br></br>
                        </Typography>
                    </CardContent>
                </Card>
            </WizardFormStep >
            <WizardFormStep label="Secondo step">
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <br></br>
                            <SelectInput source="roles" label="Ruolo" choices={[
                                { id: 'venditore', name: 'venditore' },
                                { id: 'user', name: 'cliente' },
                            ]}></SelectInput>
                            <br></br>
                            {<TextInput label="Commissione" source="commissione1" />}
                            <br></br>
                            {<TextInput label="Indirizzo" source="indirizzo" />}
                            <br></br>
                            {<TextInput label="Cap" source="cap" />}
                            <br></br>
                            {<TextInput label="Telefono" source="telefono" />}
                            <br></br>
                            {<TextInput multiline source="note" />}
                            <br></br>
                        </Typography>
                    </CardContent>
                </Card>
            </WizardFormStep >
            <WizardFormStep label="Terzo step">
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <SelectInput source="roles" label="Ruolo" choices={[
                                { id: 'venditore', name: 'venditore' },
                                { id: 'user', name: 'cliente' },
                            ]}></SelectInput>
                        </Typography>
                    </CardContent>
                </Card>
            </WizardFormStep >
        </WizardForm>
    </Create>
); */
/* Wizard CREATE */


const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" />
    </Toolbar>
);



export const UsersEdit = (props) => (
    <Edit title={<>
        <Button>
            &nbsp;Modifica User
        </Button>
    </>} actions={<ShowActionsForEdit />} {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />} redirect="show">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <TextInput fullWidth sx={{ mb: 2 }} label="Nome" source="nome" validate={requiredField} />
                                <TextInput fullWidth sx={{ mb: 2 }} label="Cognome" source="cognome" validate={requiredField} />
                                <TextInput fullWidth sx={{ mb: 2 }} label="Codice rivenditore" source="codiceRivenditore" validate={requiredField} />
                                <TextInput fullWidth sx={{ mb: 2 }} label="Codice fiscale" source="codiceFiscale" validate={[required('Questo campo è obbligatorio'), validateCodiceFiscale]} />
                                <ReferenceInput fullWidth sx={{ mb: 2 }} label="Hotel" source="hotelId" reference="hotels" validate={requiredField}>
                                    <SelectInput optionText="nome" />
                                </ReferenceInput>
                                <PasswordInput fullWidth sx={{ mb: 2 }} label="Password" source="password" validate={requiredField} />
                                <TextInput fullWidth sx={{ mb: 2 }} label="Email" source="email" validate={[required('Questo campo è obbligatorio'), validateEmail]} />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {/* <GestioneSelectRolesUsersInEdit></GestioneSelectRolesUsersInEdit> */}

                                <Typography><strong>Ruolo user</strong> : <TextField label="Ruolo" source="roles"></TextField></Typography>
                                <br></br>
                                <TextInput fullWidth sx={{ mb: 2 }} label="Commissione" source="commissione1" validate={requiredField} />
                                <TextInput fullWidth sx={{ mb: 2 }} label="Indirizzo" source="indirizzo" validate={requiredField} />
                                <TextInput fullWidth sx={{ mb: 2 }} label="Cap" source="cap" validate={requiredField} />
                                <TextInput fullWidth sx={{ mb: 2 }} label="Telefono" source="telefono" validate={[required('Questo campo è obbligatorio'), validatePhoneNumber]} />
                                <RichTextInput fullWidth sx={{ mb: 2 }} label="Note" multiline source="note" />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

export const UsersShow = (props) => (
    <Show title={<>
        <Button>

            &nbsp;Dettaglio User
        </Button>
    </>} actions={<ShowActions />}  {...props}>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <br></br>
                            {<TextField label="Nome" source="nome" />}
                            <br></br>
                            {<TextField label="Cognome" source="cognome" />}
                            <br></br>
                            {<TextField label="Codice rivenditore" source="codiceRivenditore" />}
                            <br></br>
                            {<TextField label="Codice fiscale" source="codiceFiscale" />}
                            <br></br>
                            <TextField abel="Hotel" source="hotelId" reference="hotels">
                                <TextField optionText="nome" />
                            </TextField>
                            <br></br>
                            {<TextField label="Email" source="email" />}
                            <br></br>
                            {<RichTextField multiline source="note" />}
                            <br></br>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <br></br>
                            {/* <GestioneSelectRolesUsersInEdit></GestioneSelectRolesUsersInEdit> */}

                            <Typography><strong>Ruolo user</strong> : <TextField label="Ruolo" source="roles"></TextField></Typography>
                            <br></br>
                            {<TextField label="Commissione" source="commissione1" />}
                            <br></br>
                            {<TextField label="Indirizzo" source="indirizzo" />}
                            <br></br>
                            {<TextField label="Cap" source="cap" />}
                            <br></br>
                            {<TextField label="Telefono" source="telefono" />}
                            <br></br>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Show>
);

/* //** Elenco ruoli **
                                // 0 - ANONIMO
                                // 1 - CLIENTE
                                // 2 - VENDITORE
                                // 3 - FORNITORE
                                // 4 - HOTEL
                                // 5 - ADMIN
                                // 6 - TOUROPERATOR
                                // 7 - CATENAALBERGHIERA
                            */
/* const GestioneSelectRolesUsersInEdit = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;



    return (<>Seleziona Ruolo : <SelectInput source="roles" label="Ruolo" choices={[
        { id: 'venditore', name: 'venditore' },
        { id: 'cliente', name: 'cliente' },
        { id: 'fornitore', name: 'fornitore' },
        { id: 'hotel', name: 'hotel' },
        { id: 'admin', name: 'admin' },
        { id: 'touroperator', name: 'touroperator' },
        { id: 'catenaalberghiera', name: 'catenaalberghiera' },
    ]}></SelectInput></>);

}

 */