
import { IstanzeEscursioniList } from '../istanze-escursioniList';
import { IstanzeEscursioniShowNoEdit } from '../NoEdit/istanze-escursioniDetailsNoEdit';
import MapIcon from '@material-ui/icons/Map';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: IstanzeEscursioniList,
    show: IstanzeEscursioniShowNoEdit,
    icon: MapIcon
};