import React, { useState, useEffect } from 'react';
import {
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button
} from '@mui/material';
import { useUpdate, useRecordContext, NumberInput, TextInput } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import { editButtonCss, saveButtonCss, cancelButtonCss } from "../shared/Styles/buttonStyles";

const ModificaNote = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [newValue, setNewValue] = useState("");
    const [update, { loading, error }] = useUpdate();

    useEffect(() => {
        if (record) {
            setNewValue(record.note ?? "");
        }
    }, [record]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        update('ordini',
            { id: record.id, data: { note: newValue }, previousData: record },
            {
                onSuccess: ({ data }) => {
                    setOpen(false);
                    // Se vuoi aggiornare il record nel genitore, potresti dover chiamare una funzione di refresh qui
                },
                onFailure: (error) => {
                    // Gestisci l'errore
                    console.error(error);
                }
            }
        );
    };

    return (
        <>
            <IconButton aria-label="Modifica" sx={editButtonCss} onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>

            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg">
                <DialogTitle>Modifica Note Ordine</DialogTitle>
                <DialogContent>
                    <TextInput
                        autoFocus
                        margin="dense"
                        source="note"
                        label="Note"
                        fullWidth
                        maxWidth="md"
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" sx={cancelButtonCss}>
                        Annulla
                    </Button>
                    <Button onClick={handleSave} color="primary" disabled={loading} sx={saveButtonCss}>
                        Salva
                    </Button>
                </DialogActions>
                {error && <Typography color="error">{error.message}</Typography>}
            </Dialog>
        </>
    );
};

export default ModificaNote;