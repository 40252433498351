import React, { useState, useEffect } from 'react';
import { useNotify, useDataProvider, useUpdate } from 'react-admin';
import { TextField, Button } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { uploadButtonCss } from '../../shared/Styles/buttonStyles';
import config from '../../../config/config.json';

const CustomCreateImage = ({ escursioniId, refresh }) => {
    const [descrizione, setDescrizione] = useState('');
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [update, { isLoading, error }] = useUpdate();
    const [file, setFile] = useState(false); // Nuovo stato per il file

    const handleFileChange = (event) => {

        const file = event.target.files[0];

        if (file) {
            const fileType = file.type;
            const validTypes = ['image/jpeg'];

            if (validTypes.includes(fileType)) {
                // console.log('File valido:', file.name);
                // Processa il file qui (ad esempio, leggi il file o caricalo)
                setFile(event.target.files[0]);
            } else {
                alert('Seleziona un file JPG.');
                event.target.value = ''; // Resetta il valore dell'input file
                setFile(false);
            }
        }
    };

    const uploadFile = async (galleriaescursioniId) => {
        let formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios({
                method: 'post',
                /* '/uploads/documenti/:escursioniId/:recordId/:documentoId */
                /* `/uploads/${escursioniId}/${recordId}/${documentoId}` */
                // url: `http://localhost:3003/uploads/escursioni/${escursioniId}/${galleriaescursioniId}`,
                url: `${config.production.hostUploads}/uploads/escursioni/${escursioniId}/${galleriaescursioniId}`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return response.data;
        } catch (error) {
            console.error("Errore nell'upload del file:", error);
            throw error;
        }
    };

    const handleClick = async () => {
        try {

            const data = {
                escursioniId: escursioniId,
                urlFoto: "",
                copertina: 0,
                visibile: 0
            };

            dataProvider.create('galleriaescursioni', { data })
                .then(response => {
                    console.log("Risposta della create:", response.data);
                    notify('Riferimento file su DB e upload completati con successo');
                    setDescrizione(''); // Reset della descrizione dopo la creazione con successo

                    uploadFile(response.data.id).then((rispostaDopoUpload) => {
                        // console.log("----------------------------------------");
                        // console.log(rispostaDopoUpload);

                        const data = {
                            urlFoto: `${config.production.hostUploads}` + rispostaDopoUpload.metadati.percorsoConEstensione /* ,
                            metadati: JSON.stringify(rispostaDopoUpload.metadati),
                            estensioneFile: rispostaDopoUpload.metadati.tipoMIME,
                            dimensione: rispostaDopoUpload.metadati.dimensione */
                        };

                        update(
                            'galleriaescursioni',
                            { id: rispostaDopoUpload.galleriaescursioniId, data: data },
                            {
                                onSuccess: ({ data }) => {
                                    // refresh();
                                    setDescrizione(''); // Resetta la descrizione
                                    // setFile(null); // Resetta il file
                                    notify('Upload eseguito con successo');
                                    //setEditText(''); // Reset della descrizione se necessario
                                },
                                onFailure: (error) => {
                                    notify(error.message, 'warning');
                                }
                            }
                        );
                    });
                    refresh();
                })
                .catch(error => {
                    notify(error.message, 'warning');
                });

        } catch (error) {
            notify(error.message, 'warning');
        }
    };

    return (
        <Box
            sx={{
                p: 2, // Applica un padding su tutti i lati del Box
                display: 'flex', // Imposta il Box come un contenitore flex
                flexDirection: 'column', // Allinea i componenti in colonna
                alignItems: 'center', // Centra gli elementi all'interno del Box sull'asse trasversale
                justifyContent: 'center', // Centra gli elementi all'interno del Box sull'asse principale
                gap: 2, // Crea spazio tra gli elementi all'interno del Box
            }}
        >
            <input
                type="file"
                accept=".jpg"
                onChange={handleFileChange}
                style={{
                    display: 'block',
                    backgroundColor: 'green',
                    color: 'white',
                }}
            />

            <Button
                onClick={handleClick}
                sx={uploadButtonCss}
                variant="contained"
                color="primary"
                disabled={!file} // Disabilita se non c'è file selezionato
            >
                Esegui upload
            </Button>
        </Box>
    );
};

export default CustomCreateImage;
