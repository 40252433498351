import * as React from "react";
import {
    List,
    Datagrid,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    ReferenceManyField,
    ImageField,
    Show,
    SimpleShowLayout,
    RichTextField,
    useRecordContext,
    FilterList,
    FilterListItem,
    required,
    NumberInput,
    ReferenceInput,
    ShowButton,
    TopToolbar,
    WrapperField,
    Toolbar,
    SaveButton,
    CreateButton,
    useRedirect,
    useNotify
} from 'react-admin';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { RichTextInput } from 'ra-input-rich-text';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import Typography from "@material-ui/core/Typography";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ContentFilter from '@mui/icons-material/FilterList';
import CardContent from '@mui/material/CardContent';

// Importo il css per i Bottoni
import { listCss } from '../shared/Styles/listStyles';
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, createButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={createButtonCss} />
    </TopToolbar>
);


export const ServiziAggiuntiviList = props => {

    return (
        <List title={<>
            <Button>
                &nbsp;Lista dei Servizi
            </Button>
        </>}
            perPage={100}
            sort={{ field: 'id', order: 'DESC' }}
            actions={<ListActions />}
            exporter={false}
            /* filters={escursioniFilters}  */
            {...props}>
            <Datagrid /* expand={<ServiziAggiuntiviExpand />} */
                sx={listCss}/*  expand={<EscursioniExpand />} */
                noDelete
                bulkActionButtons={false}
            >
                {/* {<TextField label="Id" source="id" />} */}
                {<TextField label="Descrizione Backoffice" source="descrizione" />}
                {/* <ImageField source="coverImg" title="Foto" /> */}
                {/* {<TextField label="Costo" source="Costo" />} */}
                {/* {<TextField label="Tipo servizio escursione" source="tipoServizioEscursione" />} */}
                {/* {<ImageField source="pathImage" title="pathImage" />} */}
                {/* {<TextField label="Citta" source="citta" />} */}
                {/* <ReferenceField label="Titolo" source="id" reference="escursioni-lingue"> */}
                {/* <SubReference source="escursioniId" reference="lingue"> */}
                {/* <TextField source="titolo" link={false} /> */}
                {/* </SubReference> */}
                {/* </ReferenceField> */}
                {/*                 <ReferenceManyField
                    reference="escursioni-lingue"
                    target="escursioniId"
                    
                    // perPage={1}
                    sort={{ field: 'id', order: 'ASC' }}
                >
                    <Datagrid>
                    
                    <TextField  label="Titolo" source="titolo" />
                    </Datagrid>
                </ReferenceManyField> */}
                {/* {<TextField label="Email" source="email" />} */}
                {/* {<TextField label="Ruolo" source="roles" />} */}
                {/* {<TextField label="Codice rivenditore" source="codiceRivenditore" />} */}
                {/* <ReferenceField label="Servizio" source="hotelId" reference="hotels"> */}
                {/* <FunctionField render={record => record && `${record.nome} ${record.zona} `} /> */}
                {/* </ReferenceField> */}
                {/* {<TextField label="Indirizzo" source="indirizzo" />} */}
                {/* {<TextField label="Cap" source="cap" />} */}
                {/* {<TextField label="Telefono" source="telefono" />} */}
                {/* {<EditButton sx={editButtonCss} label="Modifica Descrizione Backoffice" />} */}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
                <WrapperField label="Elimina">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Servizio"}
                        messaggio={"Sei sicuro di vole cancellare questo Servizio ?"}
                        servizio={"servizi"} />
                </WrapperField>
            </Datagrid>
        </List>
    );
}

const redirect = () => `/escursioni`;

const MyToolbarCreate = () => (
    <Toolbar>
        <CreateButton sx={createButtonCss} label="Crea servizio" alwaysEnable />
    </Toolbar>
);


// TODO : da rendere questo componente come riutilizzabile
const ServiziCreateToolbar = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    return (
        <Toolbar>
            {/* <SaveButton
                label="post.action.save_and_show"
            /> */}
            <SaveButton sx={createButtonCss} label="Crea servizio" /* alwaysEnable */
                // label="post.action.save_and_add"
                mutationOptions={{
                    onSuccess: data => {
                        notify('Servizio creato.'/* 'ra.notification.created' */, {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                        });
                        // redirect(false);
                        redirect('list', 'servizi');
                    }
                }
                }
                type="button"
                variant="text"
            />
        </Toolbar>
    );
};

export const ServiziAggiuntiviCreate = (props) => (
    <Create    {...props}>
        <SimpleForm toolbar={<ServiziCreateToolbar></ServiziCreateToolbar>} redirect="show">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <TextInput label="Descrizione backoffice" source="descrizione" required />
                                <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <br></br>
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
        </SimpleForm>
    </Create>
);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica descrizione backoffice" />
    </TopToolbar>
);

export const ServiziAggiuntiviShow = (props) => (
    <Show title={<>
        <Button>

            &nbsp;Dettaglio Servizio
        </Button>
    </>} actions={<ShowActions />}>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                {/* <HomeIcon /> */}
                                Descrizione Backoffice: <TextField label="Descrizione" source="descrizione" />
                            </Box>

                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            {<Grid item xs={12}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <ReferenceManyField
                                reference="servizi-lingue"
                                target="serviziId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <EditableDatagrid noDelete
                                    bulkActionButtons={false}
                                    editForm={<ServiziRowForm />}
                                >
                                    <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                    <TextField label="Nome" source="nome" />
                                    <TextField label="Descrizione" source="descrizione" />
                                    <TextField label="Descrizione breve" source="descrizioneBreve" />
                                </EditableDatagrid>
                            </ReferenceManyField>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>}
        </Grid>
    </Show>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

export const ServiziRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput label="Nome" source="nome" />
        <TextInput label="Descrizione" source="descrizione" />
        <TextInput label="Descrizione breve" source="descrizioneBreve" />
    </RowForm>
);

export const ServiziAggiuntiviEdit = (props) => (
    <Edit title={<>
        <Button>

            &nbsp;Modifica Servizio
        </Button>
    </>} actions={<ShowActionsForEdit />}  {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {<Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {<TextInput label="Descrizione servizio" source="descrizione" validate={[required()]} />}
                                <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>}
                {/* {<Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <ReferenceManyField
                                    reference="servizi-lingue"
                                    target="serviziId"

                                    // perPage={1}
                                    sort={{ field: 'id', order: 'ASC' }}
                                >
                                    <EditableDatagrid noDelete
                                        bulkActionButtons={false}
                                        editForm={<ServiziRowForm />}
                                    >
                                        <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                        <TextField label="Nome" source="nome" />
                                        <TextField label="Descrizione" source="descrizione" />
                                        <TextField label="Descrizione breve" source="descrizioneBreve" />
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>} */}
            </Grid>
        </SimpleForm>
    </Edit>
);

const ValutaBandieraLingua = ({ source }) => {
    const record = useRecordContext();
    let lingua = "";

    switch (record[source]) {
        case 1:
            lingua = "http://imgm.btechitalia.it/flags/flagita.png"
            break;
        case 2:
            lingua = "http://imgm.btechitalia.it/flags/flaguk.png"
            break;
        case 3:
            lingua = "http://imgm.btechitalia.it/flags/flagpol.png"
            break;
        case 4:
            lingua = "http://imgm.btechitalia.it/flags/flagger.png"
            break;
        case 5:
            lingua = "http://imgm.btechitalia.it/flags/flagpor.png"
            break;
        case 6:
            lingua = "http://imgm.btechitalia.it/flags/flagspa.png"
            break;
        case 7:
            lingua = "http://imgm.btechitalia.it/flags/flagfra.png"
            break;
        default:
            lingua = ""
            break;
    }


    return (
        <><div>
            <img src={lingua} width="20" height="20" />
        </div></>
    );
};
// http://imgm.btechitalia.it/testita.png
export const ServiziAggiuntiviExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={10}>
                        <h2>Lista titoli</h2>
                        <h3>{<TextField label="Titolo per ricerca" source="titoloricerca" />}
                        </h3><br></br>
                        <ReferenceManyField
                            reference="servizi-aggiuntivi-lingue"
                            target="serviziAggiuntiviId"

                            // perPage={1}
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <EditableDatagrid noDelete
                                bulkActionButtons={false}
                                editForm={<ServiziAggiuntiviRowForm />}
                            >

                                {/* <TextField  label="Id Lingua" source="lingueId" /> */}
                                <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                {/* <ImageField  source="urlBandierina" label="Nazione" /> */}
                                <TextField label="Titolo" source="nome" />
                            </EditableDatagrid>
                        </ReferenceManyField>
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={10}>
                        <h2>Lista Descrizioni</h2>
                        <ReferenceManyField
                            reference="servizi-aggiuntivi-lingue"
                            target="serviziAggiuntiviId"

                            // perPage={1}
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <EditableDatagrid noDelete
                                bulkActionButtons={false}
                                editForm={<ServiziAggiuntiviDescrizioniRowForm />} >
                                <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                <RichTextField label="Descrizione" source="descrizione" />
                            </EditableDatagrid>
                        </ReferenceManyField>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>

                        {/* {<RichTextField label="Consegna" source="consegna" />} */}

                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}

export const ServiziAggiuntiviRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput label="Titolo" source="nome" />
    </RowForm>
);


export const ServiziAggiuntiviDescrizioniRowForm = (props) => (
    <RowForm {...props}>
        {/* <TextInput label="Descrizione" source="descrizione" /> */}
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="descrizione"></RichTextInput>
    </RowForm>
);

// Filtri

const ServiziAggiuntiviFilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <ServiziAggiuntiviFilter />
            </CardContent>
        </Card>
    </Box>
);

/// Filtri
const ServiziAggiuntiviFilter = () => (
    <Grid container spacing={2}>
        <Grid>
            <Box >
                {/*         <Box sx={{
        width: '12em',
    }}><h3></h3>
        <Card>
    <CardContent><FilterLiveSearch source="id" />
    </CardContent>
    </Card></Box> */}

                <FilterList label="Zone geografiche" icon={<ContentFilter />}>
                    <FilterListItem
                        label="Nord"
                        value={{
                            zona: "n"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Ovest"
                        value={{
                            zona: "no"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Est"
                        value={{
                            zona: "ne"
                        }}
                    />
                    <FilterListItem
                        label="Est"
                        value={{
                            zona: "e"
                        }}
                    />
                    <FilterListItem
                        label="Sud"
                        value={{
                            zona: "s"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Ovest"
                        value={{
                            zona: "so"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Est"
                        value={{
                            zona: "se"
                        }}
                    />
                    <FilterListItem
                        label="Ovest"
                        value={{
                            zona: "o"
                        }}
                    />
                </FilterList>
            </Box>
        </Grid>
        {/* {  <Grid>
    <Box>
    <FilterList label="Trimestre" icon={<ContentFilter />}>
    <FilterListItem
            label="Primo"
            value={{trimestre: 1
            }}
        />
         <FilterListItem
            label="Secondo"
            value={{trimestre: 2
            }}
        />
    <FilterListItem
            label="Terzo"
            value={{trimestre: 3
            }}
        />
         <FilterListItem
            label="Quarto"
            value={{trimestre: 4
            }}
        />
    </FilterList>
    </Box>
  </Grid>} */}
    </Grid>

);

const escursioniFilters = [
    // <TextInput label="Cerca per nome" source="nome" alwaysOn />,
    {/* <TextInput label="Cerca per id" source="id" />,
    <ReferenceInput perPage={100} source="id" reference="servizi-aggiuntivi" label="Titolo escursione">
        <SelectInput optionText="titoloricerca" />
    </ReferenceInput>, */}
    // <TextInput label="Cerca per titolo" source="titoloricerca" /* alwaysOn */ />
];

