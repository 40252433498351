import * as React from "react";
import {
    TextInput,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    ReferenceManyField,
    SimpleShowLayout,
    DateInput,
    NumberInput,
    useDataProvider,
    Loading,
    Error,
    required,
    localStorageStore,
    DateTimeInput,
    FunctionField,
    RichTextField,
    TopToolbar,
    Toolbar,
    SaveButton,
    ShowButton,
    AutocompleteInput,
    SelectArrayInput
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import { useState, useEffect } from 'react';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';

import Box from '@mui/material/Box';
import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import { blue, red } from '@mui/material/colors';
import { RichTextInput } from "ra-input-rich-text";
import DirectionsCarSharp from '@mui/icons-material/DirectionsCarSharp';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import Button from '@mui/material/Button';

// Importo il css per i Bottoni
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, createButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';


// Init store
const storeGenerale = localStorageStore();

const SetCurrentIstanzaEscursione = ({ source, data }) => {
    const record = useRecordContext();
    // storeGenerale.setItem('profile.currentIstanzaEscursione', res);
    storeGenerale.setItem('profile.currentIstanzaEscursione', 0);
    storeGenerale.setItem('profile.currentIstanzaEscursioneData', 0);
    storeGenerale.setItem('profile.currentIstanzaEscursione', record[source]);
    storeGenerale.setItem('profile.currentIstanzaEscursioneData', record[data]);
    return record ? (
        <>
            {/* {record[source]} */}</>

    ) : <>
    </>;
}

// Verifica della Checklist
const CercaTitolo = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <p><h3>{testo}</h3>
            <h3>{risultato.short_title}</h3>
            {risultato.titolo}</p>
    )
}

const CercaDescrizione = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <>
            <p><h3>{testo}</h3>
                {/* {risultato.descrizione} */}
                {(risultato.descrizione) ? <div dangerouslySetInnerHTML={{ __html: risultato.descrizione }} /> : ""}
                {/* <RichTextField source={record[id].descrizione} ></RichTextField> */}
            </p></>
    )
}

const FornitoriEMotonavi = () => {

    // TODO: DA MIGLIORARE - SE VIENE SELEZIONATO UN NUOVO FORNITORE RIMANE LA LISTA DELLE NAVI DEL PRECEDENTE FORNITORE

    const [fornitoriId, setFornitoriId] = useState(null);
    const [motonaviFilter, setMotonaviFilter] = useState(null);
    const [motonaviVisible, setMotonaviVisible] = useState(true);

    function handleChangeFornitoriId(value) {
        setMotonaviFilter("");
        setMotonaviFilter(value);
        setMotonaviVisible(true);
    }

    useEffect(() => {
        if (fornitoriId) {
            console.log(fornitoriId);
            setMotonaviFilter({ fornitoriId });
        } else {
            setMotonaviFilter({});
        }
    }, [fornitoriId]);

    return (
        <>
            <ReferenceInput
                perPage={100}
                source="fornitoriId"
                reference="fornitori"
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <SelectInput validate={[required()]} optionText="nome"
                    onChange={(event, key, newValue) => {
                        // console.log(event.target.value);
                        // setFornitoriId(newValue);
                        handleChangeFornitoriId(event.target.value)
                    }}

                />
            </ReferenceInput>


            {motonaviVisible && (
                <>
                    <br />
                    <ReferenceInput
                        perPage={100}
                        source="motonaviId"
                        reference="motonavi"
                        filter={{ fornitoriId: motonaviFilter }}
                        sort={{ field: 'nome', order: 'ASC' }}
                    >
                        <SelectInput
                            resettable
                            validate={[required()]} optionText="nome" />
                    </ReferenceInput>
                </>
            )}
        </>
    );
};

const ServiziAggiuntiviRowFormCard = ({ istanzeEscursioniId, serviziAggiuntiviId, ...props }) => {

    // console.log(storeGenerale.getItem('profile.currentIstanzaEscursione'));;
    const idRecordIstanzeEscursioni = storeGenerale.getItem('profile.currentIstanzaEscursione');
    const dataRecordIstanzeEscursioni = storeGenerale.getItem('profile.currentIstanzaEscursioneData');
    return (
        <RowForm {...props}>
            <Card mx={{ maxWidth: 345 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            S
                        </Avatar>
                    }
                    title="MODIFICA SERVIZIO AGGIUNTIVO"
                // subheader={new Date(record.data).toLocaleDateString("it-IT", options)}
                />

                {/*                         <CardMedia
                            component="img"
                            height="194"
                            image="PATH IMG SERVIZIO AGGIUNTIVO"
                            alt="TESTO ALTERNATIVO"
                        /> */}
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {<ReferenceInput
                            perPage={100}
                            source="serviziAggiuntiviId"
                            reference="servizi-aggiuntivi"
                        // filter={{ lingueId: 1 }}
                        // sort={{ field: 'titoloRicerca', order: 'ASC' }}
                        >
                            <SelectInput validate={[required()]} optionText="titoloricerca" />
                        </ReferenceInput>}
                        {<RichTextInput source="note" title="Note" defaultValue={""} /* validate={[required()]} */ />}
                        <br></br>
                        <br></br>
                        {<DateInput disabled source="data" title="Data escursione" defaultValue={dataRecordIstanzeEscursioni} validate={[required()]} />}
                        <br></br>
                        {<TextInput disabled source="istanzeEscursioniId" label="istanzeEscursioniId" defaultValue={idRecordIstanzeEscursioni} validate={[required()]} />}
                        <br></br>
                        {<TextInput disabled source="serviziAggiuntiviId" label="serviziAggiuntiviId" defaultValue={serviziAggiuntiviId} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Adulti" source="costoAdult" defaultValue={0} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Infanti" source="costoInfant" defaultValue={0} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Bambini" source="costoChild" defaultValue={0} validate={[required()]} />}
                    </Typography>
                </CardContent>
            </Card>

        </RowForm>
    );
}

const FormatNote = ({ source }) => {

    const record = useRecordContext();
    return record ? (
        <div dangerouslySetInnerHTML={{ __html: record[source] }} />

    ) : <>
    </>;
}

const FornitoriToolbar = () => (
    <Toolbar>
        <Toolbar>
            <SaveButton sx={saveButtonCss}
                label="Salva parametri" alwaysEnable />
        </Toolbar>
    </Toolbar>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const optionRenderer = choice => `${choice.nome}`;

export const MotonaviEdit = ({ id, ...props }) => {
    return (
        <Edit title={<>
            <Button>
                &nbsp;Modifica Mezzo
            </Button>
            <TextField label="" source="nome" />
        </>} actions={<ShowActionsForEdit />} {...props} id={id}>
            <SimpleForm toolbar={<FornitoriToolbar />}>
                <SimpleShowLayout>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {/* TODO: manca , se necessario serve implementare le logiche                   
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                        <GestoreLogoMezzo refresh={refresh}></GestoreLogoMezzo>
                                    </Box>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid> */}
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1, ml: 4 }}>

                                                {<ReferenceInput
                                                    source="fornitoriId"
                                                    reference="fornitori"
                                                    perPage={100}
                                                    sort={{ field: 'nome', order: 'ASC' }}
                                                >
                                                    <AutocompleteInput
                                                        fullWidth
                                                        optionText={optionRenderer} /* optionText="titoloricerca" */
                                                        suggestionLimit={30}
                                                    />
                                                </ReferenceInput>}
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1, ml: 4 }}>
                                                <Typography variant="subtitle1" component="div">
                                                    <strong>Tipologia di mezzo : </strong>
                                                    <SelectInput source="tipoMezzo" choices={[
                                                        { id: 0, name: 'Mare' },
                                                        { id: 1, name: 'Terra' }
                                                    ]} validate={[required()]} />
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1, ml: 4 }}>
                                                <Typography variant="subtitle1" component="div">
                                                    <strong>Nome Mezzo:</strong> <TextInput fullWidth source="nome" variant="outlined" size="small" />
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main', ml: 4 }}>
                                                <PersonIcon />
                                                <strong>Referente:</strong> <TextInput label="Nominativo Referente" source="nominativoReferente" />
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1, ml: 4, mb: 3 }}>
                                                <WorkIcon />
                                                <strong>Capienza posti:</strong> {<NumberInput min={0} source="partecipantiMax" label="Capienza Posti" />}
                                            </Box>
                                            <hr></hr>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1, ml: 4, mb: 3 }}>
                                                <Typography variant="h6" component="div" gutterBottom>
                                                    Puunti geografici
                                                </Typography>
                                                <br></br>
                                                {<ReferenceManyToManyInput
                                                    source="id"
                                                    reference="puntiincontro"
                                                    through="mezzi-puntiincontro"
                                                    using="mezziId,puntiincontroId"
                                                >
                                                    {<SelectArrayInput
                                                        label="Punti geografici"
                                                        validate={required()}
                                                        optionText="nome"
                                                        fullWidth
                                                    />}
                                                </ReferenceManyToManyInput>}

                                            </Box>

                                            <hr></hr>
                                            <div>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1, ml: 4 }}>
                                                    <h2>Descrizione</h2>
                                                </Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1, ml: 4 }}>
                                                    <RichTextInput label="" source="descrizione" />
                                                </Box>
                                            </div>
                                            <hr></hr>
                                            <div>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1, ml: 4 }}>
                                                    <h2>Descrizione Breve</h2>
                                                </Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1, ml: 4 }}>
                                                    <RichTextInput label="" source="descrizioneBreve" />
                                                </Box>
                                            </div>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </SimpleShowLayout>
            </SimpleForm>
        </Edit>
    );
}
