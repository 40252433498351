import { useRecordContext, usePermissions } from 'react-admin';
import CustomGalleryList from "../galleria-immagini/CustomGalleryList";
import CustomCreateImage from "../galleria-immagini/CustomCreateImage";

const GestioneImmagini = ({ tiporecordId, refresh }) => {
    const record = useRecordContext();
    const { permissions } = usePermissions();
    // const userId = localStorage.getItem("RaStore.profile.idUser");
    // const emailUser = localStorage.getItem("RaStore.profile.email");
    if (!record) return null;
    return record ? (
        <>
            {(permissions === 'admin') && <CustomCreateImage escursioniId={record.id} /* userId={userId}  */ refresh={refresh}></CustomCreateImage>}
            {<CustomGalleryList escursioniId={record.id}/*  emailUser={emailUser} */></CustomGalleryList>}
        </>
    ) : null;
};

export default GestioneImmagini;