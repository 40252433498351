import * as React from "react";
import {
  Admin,
  Resource,
  defaultTheme,
  localStorageStore,
  CustomRoutes
} from 'react-admin';

import { Route } from 'react-router-dom';

// import { Dashboard } from './components/dashboard/Dashboard';
import { Dashboard } from './components/dashboard/dashboardBase';

import { MyLayout } from "./components/shared/Layout";

// Pagina dei settings
import SettingsPage from "./components/SettingsPage/SettingsPage";

// Pagine dei dati Hotel / Catene Alberghiere / Tour Operatore / Venditori
import { HotelCustomDatiPage, HotelCustomEditPage, HotelCustomListPage } from "./components/hotels/HotelCustomDatiPage";


/* Not Found page custom */
import NotFoundPage from './components/NotFoundPage/NotFoundPage';

///////////////////////////////
import { restClient, authClient } from 'ra-data-feathers';
import feathersClient from './feathersClient';

// Importo il tema
import { myTheme } from "./components/shared/theme";

// Importo tutti i componenti principali
import { UsersList, UsersCreate, UsersEdit, UsersShow } from './components/users/users';
import { OrdiniList, OrdiniShow, OrdiniEdit } from './components/ordini/ordini';
import { EscursioniList, EscursioniCreate, EscursioniEdit, EscursioniShow } from './components/escursioni/escursioni';
import { EscursioniListNoEdit, EscursioniShowNoEdit } from './components/escursioni/escursioniNoEdit';
import { HotelsList, HotelsCreate, HotelsEdit, HotelsShow } from './components/hotels/hotels';
import { CatenaList, CatenaCreate, CatenaEdit, CatenaShow } from './components/catena-alberghiera/catena-alberghiera';
import { CatenaListSingle, CatenaShowSingle } from './components/catena-alberghiera/catena-alberghiera-singola';
import { AziendaList, AziendaCreate, AziendaEdit, AziendaShow } from './components/azienda/azienda';
import { ServiziAggiuntiviList, ServiziAggiuntiviShow, ServiziAggiuntiviCreate, ServiziAggiuntiviEdit } from './components/servizi-aggiuntivi/servizi-aggiuntivi';
import { IstanzeServiziAggiuntiviList, IstanzeServiziAggiuntiviCreate, IstanzeServiziAggiuntiviEdit } from './components/istanze-servizi-aggiuntivi/istanze-servizi-aggiuntivi';
import { CategorieList, CategorieShow, CategorieCreate, CategorieEdit } from './components/categorie/categorie';
import { MacroCategorieList, MacroCategorieShow, MacroCategorieCreate, MacroCategorieEdit } from './components/macro-categorie/macro-categorie';
import { TourOperatorList, TourOperatorCreate, TourOperatorEdit, TourOperatorShow } from './components/tour-operator/tour-operator';
import { TourOperatorListCustom, TourOperatorEditCustom, TourOperatorShowCustom } from './components/tour-operator/tour-operator-singola';



// ADMIN
import istanzeEscursioni from "./components/istanze-escursioni";

// FORNITORI
import indexFornitore from "./components/istanze-escursioni/indexFornitore";

// NON ADMIN
import istanzeEscursioniNoEdit from "./components/istanze-escursioni/NoEdit";

import motonavi from "./components/motonavi";
// import fornitori from './components/fornitori';
import { FornitoriList, FornitoriCreate, FornitoriEdit, FornitoriShow } from './components/fornitori/fornitori';

import { VenditoriList, VenditoriCreate, VenditoriEdit, VenditoriShow } from './components/venditori/venditori';

import porti from './components/porti';
import transfer from "./components/transfer";
// import {PortiList} from './components/porti/portiList';

// Decoder per il JWT
import decodeJwt from 'jwt-decode';

// Login page
import MyLoginPage from './components/shared/MyLoginPage';
import Payments from './components/payments';
import LogsPayments from './components/logs_payments';
import { UsersListFiltered } from "./components/users/usersClientiList";

const restClientOptions = {
  id: 'id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
};

/* const authClientOptions = {
    usernameField: 'username', // Our example database might use 'username' rather than 'email'
    permissionsField: 'roles', // Use the 'userroles' field on the JWT as the users role
    redirectTo: '/#/login', // Our example login form might be at '/signin', redirect here if AUTH_CHECK fails
};
 */
const options = {
  id: '_id',
  storageKey: 'feathers-jwt', // The key in localStorage used to store the authentication token
  authenticate: { // Options included in calls to Feathers client.authenticate
    strategy: 'local', // The authentication strategy Feathers should use
  },
  permissionsKey: 'permissions', // The key in localStorage used to store permissions from decoded JWT
  permissionsField: 'roles', // The key in the decoded JWT containing the user's role
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'email', // The key used to provide the username to Feathers client.authenticate
  redirectTo: '/login', // Redirect to this path if an AUTH_CHECK fails. Uses the react-admin default of '/login' if omitted.
  logoutOnForbidden: true, // Logout when response status code is 403
  /* Allows to use custom query operators from various feathers-database-adapters in GET_MANY calls.
 * Will be merged with the default query operators ['$gt', '$gte', '$lt', '$lte', '$ne', '$sort', '$or', '$nin', '$in']
 */
  customQueryOperators: ['$gt', '$gte', '$lt', '$lte', '$ne', '$sort', '$or', '$nin', '$in']

}

// Init Store
const storeGenerale = localStorageStore();

const feathersAuthProvider = authClient(feathersClient, options);
const myAuthProviderGetEmail = async (verb, resource, params) => {
  const result = await feathersAuthProvider(verb, resource, params);

  if (typeof (resource) != "undefined") {
    try {
      if (typeof (resource['username']) != "undefined") {
        // console.log(":::: DEBUG START AUTH ::::");
        // console.log(resource);
        // console.log(resource['username']);
        storeGenerale.setItem('profile.name', "");
        storeGenerale.setItem('profile.roles', "");
        //  console.log(resource['username']);
        // const decodedToken = decodeJwt("eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJlbWFpbCI6Im9wZXJhdG9yZUBvcGVyYXRvcmUuaXQiLCJyb2xlcyI6ImFkbWluIiwiaWF0IjoxNjU4MjM5NDQ0LCJleHAiOjE2NTgzMjU4NDQsImF1ZCI6Imh0dHBzOi8veW91cmRvbWFpbi5jb20iLCJpc3MiOiJmZWF0aGVycyIsInN1YiI6IjYiLCJqdGkiOiIwNDQyNjliZS0xYzQ2LTQxY2QtYTRiMy1iZGE5ZThiNjYyNDAifQ.7a9ysieNU67vycRCzB_LkUBtZC773KKUFnifx82o-q0");
        // console.log(decodedToken);
        var test = localStorage.getItem("feathers-jwt");
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> " + test);
        const decodedToken = decodeJwt(test);

        storeGenerale.setItem('profile.name', decodedToken.username);
        storeGenerale.setItem('profile.email', decodedToken.email);
        storeGenerale.setItem('profile.roles', decodedToken.roles);
        storeGenerale.setItem('profile.idUser', decodedToken.id);
        // console.log(":::: DEBUG STOP AUTH ::::");
      }
    } catch (error) {
      console.log(error);
    }
  }


  return result;
}




// Debug per problema con ra_calendar
// const feathersDataProvider = restClient(feathersClient, restClientOptions);
/*// const myDataProvider = async (verb, resource, params) => {
   // const result = await feathersDataProvider(verb, resource, params);
    // console.log(":::: DEBUG START ::::");
    // console.log(resource, result);
    // console.log(":::: DEBUG STOP ::::");

    if (resource === 'audits') {
      console.log("::: AUDITS :::");
      
      if(verb === "UPDATE"){
        console.log("::: Metodo : UPDATE :::");
        console.log(params.data.pictures);
        console.log(params.data);
        // console.log(typeof params.data.pictures.rawFile);
        // console.log(params.data.pictures);

               // Freshly dropped pictures are File objects and must be converted to base64 strings
                 const newPictures = params.data.pictures.filter(
                  p => p.rawFile instanceof File
              );
              const formerPictures = params.data.pictures.filter(
                  p => !(p.rawFile instanceof File)
              );

              console.log(typeof newPictures);

              // let service = feathersClient.service(resource);

              return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64,
                    title: `${params.data.title}`,
                }))
            )
            .then(transformedNewPictures => 
              feathersClient.service(resource).update({
                    data: {
                        ...params.data,
                        pictures: [
                            ...transformedNewPictures,
                            ...formerPictures,
                        ],
                    },
                })
            );

      }
    }

    // Soluzione di Gildas
    if (resource === 'events') {
      console.log(":::: DEBUG START :::: EVENTS");
        const service = feathersClient.service(resource);
        const query = {}; // Build your query here
        const risultato = service.find({ query });
        console.log(resource,risultato );
        return risultato;
    }             
    return result;
} */

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
/* const convertFileToBase64 = file =>
new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      console.log("COONVERT: ");
      console.log(reader.result); // Questo è il file 

     // TODO: Qui devo decidere se inviare il contenuto verso un endpoints specifico del backend
      
     resolve(reader.result);
    };
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
}); */

/* -- */



// const feathersAuthProvider = authClient(feathersClient, options);
/* const myAuthProviderGetEmail = async (verb, resource, params) => {
  const result = await feathersAuthProvider(verb, resource, params);
        
  console.log(":::: DEBUG START ::::");
  console.log(result); // Stringa
  // localStorage.setItem("Ciao"); 
  console.log(":::: DEBUG STOP ::::");

  return result;
} */

/* --- */



const App = () => (
  <Admin
    title='GO Sardinia Backoffice'
    loginPage={MyLoginPage}
    layout={MyLayout}
    dashboard={Dashboard}
    theme={myTheme}
    dataProvider={restClient(feathersClient, restClientOptions)}
    // authProvider={authClient(feathersClient, options /* authClientOptions */)}
    authProvider={myAuthProviderGetEmail}// TEST Permessi
    catchAll={NotFoundPage}
  >

    {permissions => [
      permissions === 'admin' ?
        <>
          <Resource name="logs-payments" {...LogsPayments} />
          <Resource name="payments" {...Payments} />

          <Resource name="users"
            list={UsersList}
            create={UsersCreate}
            edit={UsersEdit}
            show={UsersShow}
          >

            <Route path="clienti" element={<UsersListFiltered rolesCode={"1"} nomeLista={"clienti"} />} />
            <Route path="venditori" element={<UsersListFiltered rolesCode={"2"} nomeLista={"venditori"} />} />
            <Route path="fornitori" element={<UsersListFiltered rolesCode={"3"} nomeLista={"fornitori"} />} />
            <Route path="hotels" element={<UsersListFiltered rolesCode={"4"} nomeLista={"hotels"} />} />
            <Route path="admin" element={<UsersListFiltered rolesCode={"5"} nomeLista={"admin"} />} />
            <Route path="touroperator" element={<UsersListFiltered rolesCode={"6"} nomeLista={"touroperator"} />} />
            <Route path="catenaalberghiera" element={<UsersListFiltered rolesCode={"7"} nomeLista={"catenaalberghiera"} />} />

          </Resource>
          <Resource name="ordini"
            list={OrdiniList}
            // create={OrdiniCreate}
            edit={OrdiniEdit}
            show={OrdiniShow}
          />
          <Resource name="hotels"
            list={HotelsList}
            create={HotelsCreate}
            edit={HotelsEdit}
            show={HotelsShow}
          />
          <Resource name="azienda"
            list={AziendaList}
            create={AziendaCreate}
            edit={AziendaEdit}
            show={AziendaShow}
          />
          {<Resource name="catena-alberghiera"
            list={CatenaList}
            create={CatenaCreate}
            edit={CatenaEdit}
            show={CatenaShow}
          />}
          {<Resource name="tour-operator"
            list={TourOperatorList}
            create={TourOperatorCreate}
            edit={TourOperatorEdit}
            show={TourOperatorShow}
          />}
          <Resource name="istanze-escursioni"  {...istanzeEscursioni} />
          <Resource name="escursioni"
            list={EscursioniList}
            create={EscursioniCreate}
            edit={EscursioniEdit}
            show={EscursioniShow}
          />
          <Resource name="escursioni-lingue" />
          <Resource name="ordini-istanze-servizi-aggiuntivi" />
          <Resource name="istanze-servizi-aggiuntivi"
            list={IstanzeServiziAggiuntiviList}
            create={IstanzeServiziAggiuntiviCreate}
            edit={IstanzeServiziAggiuntiviEdit}
          />
          <Resource name="servizi"
            list={ServiziAggiuntiviList}
            show={ServiziAggiuntiviShow}
            create={ServiziAggiuntiviCreate}
            edit={ServiziAggiuntiviEdit}
          />
          {/* <Resource name="fornitori"  {...fornitori}
          /> */}
          <Resource name="fornitori"
            list={FornitoriList}
            create={FornitoriCreate}
            edit={FornitoriEdit}
            show={FornitoriShow}
          />
          <Resource name="venditori"
            list={VenditoriList}
            create={VenditoriCreate}
            edit={VenditoriEdit}
            show={VenditoriShow}
          />
          <Resource name="mezzi"  {...motonavi}
          />
          <Resource name="puntiincontro"  {...porti}
          />
          <Resource name="mezzi-puntiincontro"
          />
          <Resource name="tipo-fornitore"
          />
          <Resource name="pagamenti"></Resource>
          <Resource name="tipo-pagamento"></Resource>
          <Resource name="stato-pagamento"></Resource>
          <Resource name="durata"></Resource>
          <Resource name="wapp-istanze-escursioni-puntiincontro"></Resource>
          <Resource name="ordini-partecipanti"></Resource>

          <Resource name="hotels-istanze-escursioni"
          />
          <Resource name="transfer"  {...transfer}
          />
          <Resource name="transfer-righi"
          />
          <Resource name="ordini-transfer-righi"
          />
          <Resource name="durata"
          />
          <Resource name="durata-lingue"
          />
          <Resource name="livellodifficolta"
          />
          <Resource name="lingue-escursioni"
          />
          <Resource name="orario"
          />
          <Resource name="categorie"
            list={CategorieList}
            show={CategorieShow}
            create={CategorieCreate}
            edit={CategorieEdit}
          />
          <Resource name="categorie-lingue"
          />
          <Resource name="macro-categorie"
            list={MacroCategorieList}
            show={MacroCategorieShow}
            create={MacroCategorieCreate}
            edit={MacroCategorieEdit}
          />
          <Resource name="macro-categorie-categorie"
          />
          <Resource name="escursioni-categorie"
          />
          <Resource name="escursioni-puntiincontro"
          />
          <Resource name="puntiincontro"
          />
          <Resource name="escursioni-puntiincontro-lingue"
          />
          <Resource name="tipo-puntiincontro"
          />
          <Resource name="wapp-mezzi-mezzi-puntiincontro"
          />
          <Resource name="tariffe"
          />
          <Resource name="servizi-lingue"
          />
          <Resource name="escursioni-servizi"
          />
          <Resource name="catena-alberghiera-hotels"
          />
          <Resource name="escursioni-catena-alberghiera"
          />
          <Resource name="escursioni-hotels"
          />
          <Resource name="escursioni-tour-operator"
          />
          <Resource name="users-hotels"
          />
          <Resource name="users-catena-alberghiera"
          />
          <Resource name="users-tour-operator"
          />
          <Resource name="users-venditori"
          />
          <Resource name="users-fornitori"
          />
          <Resource name="istanze-escursioni-mezzi-users"
          />
          <Resource name="venditori-hotels"
          />
          <Resource name="generatore-istanze"
          />
          <Resource name="istanze-escursioni-puntiincontro"
          />
          <Resource name="istanze-escursioni-puntiincontro-lingue"
          />
          <Resource name="istanze-escursioni-servizi"
          />
          <Resource name="wapp-per-selezione-ordini-transfer"
          />
          <Resource name="lingue-istanze-escursioni"
          />

          <Resource name="wapp-istanze-posti-occupati"></Resource>

          <Resource name="wapp-check-tariffe-puntiincontro"></Resource>

          <Resource name="wapp-ordini-partecipanti-voucher"></Resource>

          <Resource name="tariffe-exc"></Resource>

          <Resource name="wapp-transfer-posti-occupati-aggregate"></Resource>

          <Resource name="wapp-transfer-posti-occupati"></Resource>

          <CustomRoutes >
            <Route exact path="/settingspage" element={<SettingsPage />} />
          </CustomRoutes>

        </>
        : (permissions === 'hotel' ? <>
          {/* BLOCCO HOTELS */}
          <Resource name="escursioni"
            list={EscursioniListNoEdit}
            show={EscursioniShowNoEdit}
          />
          <Resource name="istanze-escursioni"  {...istanzeEscursioniNoEdit} />
          <Resource name="hotels"
            show={HotelCustomDatiPage}
            edit={HotelCustomEditPage}
            list={HotelCustomListPage}
          />
          <CustomRoutes >
            <Route exact path="/settingspage" element={<HotelCustomDatiPage />} />
          </CustomRoutes>
        </> : <>{/* 
          <Resource name="escursioni"
            list={EscursioniListNoEdit}
            show={EscursioniShowNoEdit}
          />
          <Resource name="istanze-escursioni"  {...istanzeEscursioniNoEdit} />
          {<Resource name="catena-alberghiera"
            list={CatenaListSingle}
            edit={CatenaEdit}
            show={CatenaShowSingle}
          />}
          <CustomRoutes >
            <Route exact path="/settingspage" element={<HotelCustomDatiPage />} />
          </CustomRoutes> */}

        </>),
      permissions === 'catenaalberghiera' ? <>
        {/* BLOCCO CATENE ALBERGHIERE */}
        <Resource name="escursioni"
          list={EscursioniListNoEdit}
          show={EscursioniShowNoEdit}
        />
        <Resource name="istanze-escursioni"  {...istanzeEscursioniNoEdit} />
        {<Resource name="catena-alberghiera"
          list={CatenaListSingle}
          edit={CatenaEdit}
          show={CatenaShowSingle}
        />}
      </> : <>
      </>, permissions === 'venditore' ? <>
        {/* BLOCCO VENDITORE */}
        <Resource name="escursioni"
          list={EscursioniListNoEdit}
          show={EscursioniShowNoEdit}
        />
        <Resource name="istanze-escursioni"  {...istanzeEscursioniNoEdit} />
        <Resource name="ordini"
          list={OrdiniList}
          // create={OrdiniCreate}
          edit={OrdiniEdit}
          show={OrdiniShow}
        />
        <Resource name="hotels"
          list={HotelsList}
          create={HotelsCreate}
          edit={HotelsEdit}
          show={HotelsShow}
        />
      </> : <>
        {/* BLOCCO FORNITORI */}
        <Resource name="escursioni"
          list={EscursioniListNoEdit}
          show={EscursioniShowNoEdit}
        />
        <Resource name="users-fornitori"
        />
        <Resource name="mezzi"  {...motonavi}
        />
        <Resource name="istanze-escursioni"  {...indexFornitore} />
        <Resource name="fornitori"
          list={FornitoriList}
          edit={FornitoriEdit}
          show={FornitoriShow}
        /></>
    ]}



  </Admin>
);

export default App;