import React, { useState } from 'react';
import {
    useListContext,
    useDataProvider,
    useRecordContext,
    useNotify,
    useRefresh
} from 'react-admin';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    CircularProgress,
    Grid,
    Box,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField as MuiTextField,
    Select,
    MenuItem,
    InputAdornment,
    FormControl,
    InputLabel,
    Divider,
    Switch,
    FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

// Funzione per formattare i numeri come valuta in euro
const formatCurrency = (value) => {
    if (value == null || isNaN(value)) return '€0.00';
    return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);
};

// Helper function to initialize createFormData with all required fields
const initializeCreateFormData = () => {
    const formData = {
        anno: '',
        datafrom: '',
        datato: '',
        partenza: '',
        destinazione: '',
        puntidipassaggio: '',
        durata: '',
        status: true, // Aggiunto il campo status con valore di default true
    };
    for (let i = 1; i <= 15; i++) {
        formData[`descrmezzo${i}`] = '';
        formData[`cos${i}`] = '';
        formData[`capienzamezzo${i}`] = '';
    }
    return formData;
};

const TariffeAccordion = () => {
    const {
        data,
        isLoading,
        error
    } = useListContext();

    const record = useRecordContext(); // Record del fornitore

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [currentField, setCurrentField] = useState('');
    const [newValue, setNewValue] = useState('');

    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [createFormData, setCreateFormData] = useState(initializeCreateFormData());

    const [recordToDelete, setRecordToDelete] = useState(null);

    const handleOpenEditDialog = (record, field) => {
        setCurrentRecord(record);
        setCurrentField(field);
        setNewValue(record[field]);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setCurrentRecord(null);
        setCurrentField('');
        setNewValue('');
    };

    const handleUpdate = async () => {
        if (!currentRecord || !currentField) return;

        if (newValue === '' || newValue === null || newValue === undefined) {
            notify('Il campo non può essere vuoto', { type: 'warning' });
            return;
        }

        const updatedRecord = {
            ...currentRecord,
            [currentField]: newValue,
        };

        try {
            await dataProvider.update('tariffe-exc', {
                id: currentRecord.id,
                data: updatedRecord,
            });
            notify('Campo aggiornato con successo', { type: 'success' });
            handleCloseEditDialog();
            refresh();
        } catch (err) {
            console.error(err);
            notify(`Errore durante l'aggiornamento: ${err.message}`, { type: 'warning' });
        }
    };

    const handleOpenCreateDialog = () => {
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
        setCreateFormData(initializeCreateFormData());
    };

    const handleCreateFormChange = (e) => {
        const { name, value, checked } = e.target;
        setCreateFormData((prev) => ({
            ...prev,
            [name]: name === 'status' ? checked : value,
        }));
    };

    const handleCreateTariffa = async () => {
        try {
            if (
                !createFormData.anno ||
                !createFormData.datafrom ||
                !createFormData.datato ||
                !createFormData.partenza ||
                !createFormData.destinazione ||
                !createFormData.puntidipassaggio ||
                !createFormData.durata
            ) {
                notify('Tutti i campi obbligatori devono essere compilati', { type: 'warning' });
                return;
            }

            const newRecord = {
                ...createFormData,
                fornitoriId: record.id,
            };

            for (let i = 1; i <= 15; i++) {
                const descr = createFormData[`descrmezzo${i}`].trim() !== '' ? createFormData[`descrmezzo${i}`].trim() : null;
                newRecord[`descrmezzo${i}`] = descr;
                newRecord[`cos${i}`] = createFormData[`cos${i}`] !== '' ? parseFloat(createFormData[`cos${i}`]) : null;
                newRecord[`capienzamezzo${i}`] = createFormData[`capienzamezzo${i}`] !== '' ? parseInt(createFormData[`capienzamezzo${i}`], 10) : null;
            }

            console.log('Nuovo Record da creare:', newRecord);

            const response = await dataProvider.create('tariffe-exc', { data: newRecord });
            console.log('Risposta del backend:', response);

            notify('Tariffa creata con successo', { type: 'success' });
            handleCloseCreateDialog();
            refresh();
        } catch (error) {
            console.error(error);
            notify(`Errore durante la creazione: ${error.message}`, { type: 'error' });
        }
    };

    const getInputComponent = (field, value) => {
        const dateFields = ['datafrom', 'datato'];
        const numberFields = [
            'cos1', 'cos2', 'cos3', 'cos4', 'cos5',
            'cos6', 'cos7', 'cos8', 'cos9', 'cos10',
            'cos11', 'cos12', 'cos13', 'cos14', 'cos15',
            'capienzamezzo1', 'capienzamezzo2', 'capienzamezzo3',
            'capienzamezzo4', 'capienzamezzo5', 'capienzamezzo6',
            'capienzamezzo7', 'capienzamezzo8', 'capienzamezzo9',
            'capienzamezzo10', 'capienzamezzo11', 'capienzamezzo12',
            'capienzamezzo13', 'capienzamezzo14', 'capienzamezzo15',
        ];

        if (dateFields.includes(field)) {
            return (
                <MuiTextField
                    type="date"
                    label="Nuova Data"
                    value={newValue ? newValue.split('T')[0] : ''}
                    onChange={(e) => setNewValue(e.target.value)}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        } else if (numberFields.includes(field)) {
            return (
                <MuiTextField
                    type="number"
                    label="Nuovo Valore"
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                />
            );
        } else if (field === 'status') {
            return (
                <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        label="Status"
                    >
                        <MenuItem value={1}>Attivo</MenuItem>
                        <MenuItem value={0}>Inattivo</MenuItem>
                    </Select>
                </FormControl>
            );
        } else {
            return (
                <MuiTextField
                    label="Nuovo Valore"
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    fullWidth
                />
            );
        }
    };

    const handleDeleteClick = (record) => {
        setRecordToDelete(record);
    };

    const handleCloseDeleteDialog = () => {
        setRecordToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (!recordToDelete) return;

        try {
            await dataProvider.delete('tariffe-exc', { id: recordToDelete.id });
            notify('Tariffa cancellata con successo', { type: 'success' });
            handleCloseDeleteDialog();
            refresh();
        } catch (error) {
            console.error(error);
            notify(`Errore durante la cancellazione: ${error.message}`, { type: 'error' });
        }
    };

    const handleStatusToggle = async (record) => {
        try {
            const updatedRecord = { ...record, status: !record.status };
            await dataProvider.update('tariffe-exc', {
                id: record.id,
                data: updatedRecord,
            });
            notify('Status aggiornato con successo', { type: 'success' });
            refresh();
        } catch (err) {
            console.error(err);
            notify(`Errore durante l'aggiornamento dello status: ${err.message}`, { type: 'warning' });
        }
    };

    if (!record) return null;
    if (error) return <div style={{ color: 'red' }}>Errore: {error.message}</div>;
    if (isLoading) return <CircularProgress />;

    return (
        <div>
            <Box sx={{ mb: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpenCreateDialog}
                >
                    Crea Tariffa
                </Button>
            </Box>

            {data.map(record => (
                <Accordion
                    key={record.id}
                    sx={{
                        '&:before': {
                            display: 'none',
                        },
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: '#1976d2' }} />}
                        aria-controls={`panel-content-${record.id}`}
                        id={`panel-header-${record.id}`}
                        sx={{
                            '& .MuiAccordionSummary-content': {
                                display: 'flex',
                                alignItems: 'center',
                            },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">ID:</Typography>
                                    <Typography variant="body2" color="textPrimary">{record.id}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">Fornitori ID:</Typography>
                                    <Typography variant="body2" color="textPrimary">{record.fornitoriId}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">Anno:</Typography>
                                    <Typography variant="body2" color="textPrimary">{record.anno}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">Data Inizio:</Typography>
                                    <Typography variant="body2" color="textPrimary">{new Date(record.datafrom).toLocaleDateString('it-IT')}</Typography>
                                    <IconButton
                                        aria-label="modifica"
                                        size="small"
                                        onClick={() => handleOpenEditDialog(record, 'datafrom')}
                                        sx={{ ml: 1 }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">Data Fine:</Typography>
                                    <Typography variant="body2" color="textPrimary">{new Date(record.datato).toLocaleDateString('it-IT')}</Typography>
                                    <IconButton
                                        aria-label="modifica"
                                        size="small"
                                        onClick={() => handleOpenEditDialog(record, 'datato')}
                                        sx={{ ml: 1 }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">Partenza:</Typography>
                                    <Typography variant="body2" color="textPrimary">{record.partenza}</Typography>
                                    <IconButton
                                        aria-label="modifica"
                                        size="small"
                                        onClick={() => handleOpenEditDialog(record, 'partenza')}
                                        sx={{ ml: 1 }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">Destinazione:</Typography>
                                    <Typography variant="body2" color="textPrimary">{record.destinazione}</Typography>
                                    <IconButton
                                        aria-label="modifica"
                                        size="small"
                                        onClick={() => handleOpenEditDialog(record, 'destinazione')}
                                        sx={{ ml: 1 }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">Punti di Passaggio:</Typography>
                                    <Typography variant="body2" color="textPrimary">{record.puntidipassaggio}</Typography>
                                    <IconButton
                                        aria-label="modifica"
                                        size="small"
                                        onClick={() => handleOpenEditDialog(record, 'puntidipassaggio')}
                                        sx={{ ml: 1 }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">Durata:</Typography>
                                    <Typography variant="body2" color="textPrimary">{record.durata}</Typography>
                                    <IconButton
                                        aria-label="modifica"
                                        size="small"
                                        onClick={() => handleOpenEditDialog(record, 'durata')}
                                        sx={{ ml: 1 }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="subtitle2" color="primary">Status:</Typography>
                                    <Switch
                                        checked={record.status === 1}
                                        onChange={() => handleStatusToggle(record)}
                                        color="primary"
                                    />
                                    <IconButton
                                        aria-label="modifica"
                                        size="small"
                                        onClick={() => handleOpenEditDialog(record, 'status')}
                                        sx={{ ml: 1 }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <IconButton
                                aria-label="cancella"
                                color="error"
                                onClick={() => handleDeleteClick(record)}
                                sx={{ ml: 'auto' }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="h6" gutterBottom color="secondary">
                                Mezzi
                            </Typography>
                            <Grid container spacing={2}>
                                {[...Array(15)].map((_, index) => {
                                    const mezzoIndex = index + 1;
                                    const descrmezzo = `descrmezzo${mezzoIndex}`;
                                    const cos = `cos${mezzoIndex}`;
                                    const capienzamezzo = `capienzamezzo${mezzoIndex}`;

                                    return (
                                        <React.Fragment key={mezzoIndex}>
                                            <Grid item xs={12} sm={4}>
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        border: '1px solid #e0e0e0',
                                                        borderRadius: '4px',
                                                        backgroundColor: '#fafafa',
                                                        transition: 'background-color 0.3s',
                                                        '&:hover': {
                                                            backgroundColor: '#f0f0f0',
                                                        },
                                                    }}
                                                >
                                                    <Typography variant="subtitle2" color="primary">
                                                        Descrizione Mezzo {mezzoIndex}:
                                                    </Typography>
                                                    <Typography variant="body2" color="textPrimary">
                                                        {record[descrmezzo] ? record[descrmezzo] : 'N/A'}
                                                    </Typography>
                                                    <IconButton
                                                        aria-label="modifica"
                                                        size="small"
                                                        onClick={() => handleOpenEditDialog(record, descrmezzo)}
                                                        sx={{ mt: 1 }}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        border: '1px solid #e0e0e0',
                                                        borderRadius: '4px',
                                                        backgroundColor: '#fafafa',
                                                        transition: 'background-color 0.3s',
                                                        '&:hover': {
                                                            backgroundColor: '#f0f0f0',
                                                        },
                                                    }}
                                                >
                                                    <Typography variant="subtitle2" color="primary">
                                                        COS {mezzoIndex}:
                                                    </Typography>
                                                    <Typography variant="body2" color="textPrimary">
                                                        {record[cos] != null ? formatCurrency(record[cos]) : 'N/A'}
                                                    </Typography>
                                                    <IconButton
                                                        aria-label="modifica"
                                                        size="small"
                                                        onClick={() => handleOpenEditDialog(record, cos)}
                                                        sx={{ mt: 1 }}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        border: '1px solid #e0e0e0',
                                                        borderRadius: '4px',
                                                        backgroundColor: '#fafafa',
                                                        transition: 'background-color 0.3s',
                                                        '&:hover': {
                                                            backgroundColor: '#f0f0f0',
                                                        },
                                                    }}
                                                >
                                                    <Typography variant="subtitle2" color="primary">
                                                        Capienza Mezzo {mezzoIndex}:
                                                    </Typography>
                                                    <Typography variant="body2" color="textPrimary">
                                                        {record[capienzamezzo] != null ? record[capienzamezzo] : 'N/A'}
                                                    </Typography>
                                                    <IconButton
                                                        aria-label="modifica"
                                                        size="small"
                                                        onClick={() => handleOpenEditDialog(record, capienzamezzo)}
                                                        sx={{ mt: 1 }}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}

            {/* Dialog per la modifica */}
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
                <DialogTitle sx={{ bgcolor: '#1976d2', color: '#fff' }}>Modifica Campo</DialogTitle>
                <DialogContent sx={{ bgcolor: '#f5f5f5', mt: 2 }}>
                    {currentRecord && currentField && (
                        <>
                            <Typography variant="subtitle2" color="primary" gutterBottom>
                                {currentField.charAt(0).toUpperCase() + currentField.slice(1)}:
                            </Typography>
                            {getInputComponent(currentField, newValue)}
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ bgcolor: '#f5f5f5' }}>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleUpdate} color="primary" variant="contained">
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog per la creazione */}
            <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog} fullWidth maxWidth="md">
                <DialogTitle sx={{ bgcolor: '#4caf50', color: '#fff' }}>
                    Crea Nuova Tariffa - Fornitore: {record.codice} {record.nome}
                </DialogTitle>
                <DialogContent sx={{ bgcolor: '#e8f5e9', mt: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="anno-label">Anno</InputLabel>
                                <Select
                                    labelId="anno-label"
                                    label="Anno"
                                    name="anno"
                                    value={createFormData.anno}
                                    onChange={handleCreateFormChange}
                                >
                                    {Array.from({ length: 28 }, (_, i) => 2023 + i).map(year => (
                                        <MenuItem key={year} value={year.toString()}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MuiTextField
                                type="date"
                                label="Data Inizio"
                                name="datafrom"
                                value={createFormData.datafrom}
                                onChange={handleCreateFormChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MuiTextField
                                type="date"
                                label="Data Fine"
                                name="datato"
                                value={createFormData.datato}
                                onChange={handleCreateFormChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MuiTextField
                                label="Partenza"
                                name="partenza"
                                value={createFormData.partenza}
                                onChange={handleCreateFormChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MuiTextField
                                label="Destinazione"
                                name="destinazione"
                                value={createFormData.destinazione}
                                onChange={handleCreateFormChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MuiTextField
                                label="Punti di Passaggio"
                                name="puntidipassaggio"
                                value={createFormData.puntidipassaggio}
                                onChange={handleCreateFormChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MuiTextField
                                label="Durata"
                                name="durata"
                                value={createFormData.durata}
                                onChange={handleCreateFormChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={createFormData.status}
                                        onChange={handleCreateFormChange}
                                        name="status"
                                        color="primary"
                                    />
                                }
                                label="Status"
                            />
                        </Grid>

                        {/* Sezione Mezzi */}
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">
                                Mezzi
                            </Typography>
                        </Grid>
                        {[...Array(15)].map((_, index) => {
                            const mezzoIndex = index + 1;
                            return (
                                <React.Fragment key={mezzoIndex}>
                                    <Grid item xs={12} sm={4}>
                                        <MuiTextField
                                            label={`Descrizione Mezzo ${mezzoIndex}`}
                                            name={`descrmezzo${mezzoIndex}`}
                                            value={createFormData[`descrmezzo${mezzoIndex}`]}
                                            onChange={handleCreateFormChange}
                                            fullWidth
                                            sx={{
                                                bgcolor: '#fff',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <MuiTextField
                                            type="number"
                                            label={`COS ${mezzoIndex}`}
                                            name={`cos${mezzoIndex}`}
                                            value={createFormData[`cos${mezzoIndex}`]}
                                            onChange={handleCreateFormChange}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                            }}
                                            sx={{
                                                bgcolor: '#fff',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <MuiTextField
                                            label={`Capienza Mezzo ${mezzoIndex}`}
                                            name={`capienzamezzo${mezzoIndex}`}
                                            value={createFormData[`capienzamezzo${mezzoIndex}`]}
                                            onChange={handleCreateFormChange}
                                            fullWidth
                                            sx={{
                                                bgcolor: '#fff',
                                            }}
                                        />
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ bgcolor: '#e8f5e9' }}>
                    <Button onClick={handleCloseCreateDialog} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleCreateTariffa} color="primary" variant="contained">
                        Crea
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog di Conferma per la Cancellazione */}
            <Dialog open={recordToDelete !== null} onClose={handleCloseDeleteDialog} fullWidth maxWidth="xs">
                <DialogTitle>Conferma Cancellazione</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Sei sicuro di voler cancellare la tariffa con ID: <strong>{recordToDelete?.id}</strong>?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" variant="contained">
                        Cancella
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TariffeAccordion;