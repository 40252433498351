
import { Show, List, SimpleShowLayout, TextField, DateField, NumberField, TopToolbar, EditButton, TabbedShowLayout } from 'react-admin';
import { makeStyles } from '@mui/styles';

// List e Typography
import Typography from "@material-ui/core/Typography";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Import CSS
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, deleteButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";

// Icone
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';

// Card
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';

// Grid
import Grid from '@mui/material/Grid';

/* CSS - BASE - FORM - START */
const styles = {
    TextInput: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // background: 'linear-gradient(45deg, #01b5d0 10%, #ffffff 90%)',
        border: 0,
        borderRadius: 1,
        // boxShadow: '0px 0px 3px 2px #01b5d0',
        color: 'green',
        height: 50,
        padding: '0 0px',
    },
};
const useStyles = makeStyles(styles);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);


export const LogsPaymentsShow = ({ id, ...props }) => {
    const classes = useStyles();

    return (
        <Show
            title={<>
                <Button>
                    <ReceiptLongIcon></ReceiptLongIcon>
                    &nbsp;Dettaglio Logs
                </Button>
            </>}
            {...props} actions={null}>
            <Card sx={{ minWidth: 275 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#01b5d0' }}>
                            <AssignmentIcon />
                        </Avatar>
                    }
                    title={<>Dati Anagrafici</>}
                    subheader={<>Dati Anagrafici</>}
                />
            </Card>
            <TabbedShowLayout sx={formCss} >
                <TabbedShowLayout.Tab label="Anagrafica Base">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} md={6}>
                            <List pagination="" actions={false}>

                                <ListItem>
                                    <ListItemText
                                        primary="id"
                                        secondary="id"
                                    />

                                    <NumberField source="id" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="level"
                                        secondary="level"
                                    />

                                    <TextField source="level" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="message"
                                        secondary="message"
                                    />

                                    <TextField source="message" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="data"
                                        secondary="data"
                                    />

                                    <TextField source="data" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="paymentId"
                                        secondary="paymentId"
                                    />

                                    <NumberField source="paymentId" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="userId"
                                        secondary="userId"
                                    />

                                    <NumberField source="userId" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="createdAt"
                                        secondary="createdAt"
                                    />

                                    <DateField source="createdAt" />

                                </ListItem>
                                ,
                                <ListItem>
                                    <ListItemText
                                        primary="updatedAt"
                                        secondary="updatedAt"
                                    />

                                    <DateField source="updatedAt" />

                                </ListItem>

                            </List>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
}
