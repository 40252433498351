import React, { useState, useRef } from 'react'; // Importa useRef
import { useNotify, useUpdate } from 'react-admin';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { uploadButtonCss } from '../shared/Styles/buttonStyles';
import config from '../../config/config.json';

const CreateImageLogo = ({ hotelsId, refresh, url }) => {
    const notify = useNotify();
    const [update, { isLoading, error }] = useUpdate();
    const [file, setFile] = useState(false);
    const fileInputRef = useRef(null); // Ref per l'input del file

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const fileType = file.type;
            const validTypes = ['image/png'];

            if (validTypes.includes(fileType)) {
                // console.log('File valido:', file.name);
                // Processa il file qui (ad esempio, leggi il file o caricalo)
                setFile(event.target.files[0]);
            } else {
                alert('Seleziona un file PNG.');
                event.target.value = ''; // Resetta il valore dell'input file
                setFile(false);
            }
        }
    };

    const uploadFile = async () => {
        let formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios({
                method: 'post',
                url: `${config.production.hostUploads}/uploads/hotels/${hotelsId}`,
                // url: `http://gsui.btechitalia.it:3003/uploads/hotels/${hotelsId}`,
                // url: `http://localhost:3003/uploads/hotels/${hotelsId}`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (error) {
            console.error("Errore nell'upload del file:", error);
            notify(error.message, 'warning');
            throw error;
        }
    };

    const handleClick = async () => {
        if (!file) return; // Previene l'upload se non c'è file selezionato

        try {
            const rispostaDopoUpload = await uploadFile();
            const data = {
                urlLogo: `${config.production.hostUploads}` + rispostaDopoUpload.metadati.percorsoConEstensione,
                // urlLogo: "http://gsui.btechitalia.it:3003" + rispostaDopoUpload.metadati.percorsoConEstensione,
                //urlLogo: "http://localhost:3003" + rispostaDopoUpload.metadati.percorsoConEstensione
            };

            await update('hotels', { id: hotelsId, data: data });
            notify('Upload eseguito con successo');
            setFile(null); // Reset dello stato del file
            fileInputRef.current.value = ''; // Pulisce l'input del file
            refresh(); // Potrebbe essere spostato dentro onSuccess se necessario
        } catch (error) {
            // L'errore è già notificato in uploadFile
        }
    };

    return (
        <Box
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
            }}
        >
            <input
                ref={fileInputRef} // Usa il ref qui
                accept=".png"
                type="file"
                onChange={handleFileChange}
                style={{ display: 'block', backgroundColor: 'green', color: 'white' }}
            />
            <Button
                onClick={handleClick}
                sx={uploadButtonCss}
                variant="contained"
                color="primary"
                disabled={!file}
            >
                Esegui upload
            </Button>
        </Box>
    );
};

export default CreateImageLogo;
