import React from 'react';
import { useGetList, Loading, useRecordContext } from 'react-admin';

const styles = {
    container: {
        height: '300px', // Altezza del contenitore
        overflowY: 'scroll', // Abilita lo scroll verticale
        border: '1px solid #ccc', // Bordo per il contenitore
        padding: '10px' // Padding interno al contenitore
    },
    item: {
        padding: '10px', // Padding interno agli elementi
        borderBottom: '1px solid #eee' // Bordo inferiore per separare gli elementi
    }
};

const GeneratoreIstanzeList = ({ generatoreIstanzeId }) => {


    const { data, total, isPending, error } = useGetList(
        'generatore-istanze',
        {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'createdAt', order: 'DESC' }/* ,
            filter: { id: generatoreIstanzeId } */
        }
    );


    if (isPending) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <>
            <h2>Ultime 10 generazioni globali</h2>
            <div style={styles.container}>
                {data && data.map(record =>
                    <div key={record.id} style={styles.item}>
                        [ID: {record.id}] - <strong>Esito: {record.payload}</strong>
                    </div>
                )}
            </div>
        </>
    );
};

export default GeneratoreIstanzeList;
