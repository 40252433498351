import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    ReferenceField,
    AutocompleteInput,
    ReferenceInput,
    SimpleForm,
    required,
    NumberInput,
    Toolbar,
    SaveButton,
    DateInput,
    SelectArrayInput,
    Loading,
    useGetManyReference,
    SelectInput,
    useRecordContext,
    FunctionField,
    EditButton,
    CreateButton,
    TopToolbar,
    Button,
    usePermissions,
    ShowButton
} from 'react-admin';

// Importo il css per i Bottoni
import { listCss } from '../shared/Styles/listStyles';
import { editButtonCss, saveButtonCss, createButtonCss, showButtonCss } from '../shared/Styles/buttonStyles';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { EditDialog, CreateDialog } from '@react-admin/ra-form-layout';

import { RowForm } from "@react-admin/ra-editable-datagrid";

import DirectionsBoat from '@mui/icons-material/PlaylistAdd';

import Typography from "@material-ui/core/Typography";

import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';

// Impost Custom List Page 
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';
import EmptyListCustomNoCreate from '../shared/emptyPageCustom/empryPageCustomNoCreate';

const optionRenderer = choice => `${choice.nome}`;

export const MotonaviToolbar = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva" />
    </Toolbar>
);

export const DecodificaTipologia = (source) => {

    const record = useRecordContext();


    return record[source] ? <>
        {record[source]}
    </> : <></>;

}

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={createButtonCss}></CreateButton>
    </TopToolbar>
);

export const MotonaviList = ({ id, ...props }) => {

    const { permissions } = usePermissions();

    return (
        <List
            title={<>
                <Button>
                    &nbsp;Lista Mezzi
                </Button>
            </>}
            empty={(permissions === 'admin')
                ? <EmptyListCustom titolo="Lista mezzi"></EmptyListCustom>
                : <EmptyListCustomNoCreate titolo="Lista mezzi"></EmptyListCustomNoCreate>}
            actions={(permissions === 'admin') ? <ListActions /> : null}
            filters={MotonaviFilters}
            exporter={false}
            perPage={100}
            {...props} id={id}
            filterDefaultValues={{ id: { $ne: 0 } }}
        >
            <Datagrid
                sx={listCss}
                bulkActionButtons={false}
                noDelete
            // rowClick="edit"

            >
                {/* {<TextField source="id" label="Id Motonave" />} */}
                {<TextField source="nome" label="Nome Mezzo" />}
                {/* {<TextField fullWidth source="tipoMezzo" label="Tipo" />} */}
                {/* {<DecodificaTipologia source="tipoMezzo" />} */}
                <FunctionField label="Tipo" sortBy="tipoMezzo" render={record => {

                    return (
                        <>{(record.tipoMezzo === 0 ? "Mare" : "Terra")}</>
                    );

                }} />
                {<ReferenceField
                    label="Fornitore"
                    perPage={100}
                    source="fornitoriId"
                    reference="fornitori"
                    link={false}
                >

                    {<TextField label="Fornitore" source="nome" />}
                </ReferenceField>}
                {/*                 {<ReferenceField
                    label="Porto"
                    perPage={100}
                    source="portiId"
                    reference="porti"
                >

                    {<TextField label="Porto" source="nome" />}
                </ReferenceField>} */}
                {/* {<TextField source="foto" label="Foto" />} */}
                {/* {<DirectionsBoat />} */}
                {<TextField source="partecipantiMax" label="Capienza Posti" />}
                {/* TOLGO LA DESCRIZIONE PER VIA DELLA LUNGHEZZA DEL TESTO */}
                {/* {<TextField fullWidth source="descrizione" label="Descrizione" />} */}
                {<TextField fullWidth source="nominativoReferente" label="Nominativo referente" />}
                {/* {<EditButton sx={editButtonCss} label="Modifica" />} */}
                {<ShowButton sx={showButtonCss} label="Dettaglio" textAlign="center" />}
                <ConfirmDialogDeleteButtonCustom

                    titolo={"Cancella Mezzo"}
                    messaggio={"Sei sicuro di vole cancellare questo mezzo ?"}
                    servizio={"mezzi"} />
            </Datagrid>
            <EditDialog title={<>
                <div style={{ fontWeight: 'bold' }}>
                    <BorderColorIcon sx={editButtonCss}></BorderColorIcon>&nbsp;&nbsp;Modifica Mezzo
                </div>
            </>} fullWidth maxWidth="ld">
                <MioSimpleForm />
            </EditDialog>
            <CreateDialog title={<>
                <div style={{ fontWeight: 'bold' }}>
                    <FiberNewIcon sx={createButtonCss}></FiberNewIcon>&nbsp;&nbsp;Crea Nuovo Mezzo
                </div>
            </>} fullWidth maxWidth="ld">
                <SimpleForm toolbar={<MotonaviToolbar />}>
                    {<ReferenceInput
                        source="fornitoriId"
                        reference="fornitori"
                        perPage={100}
                        sort={{ field: 'nome', order: 'ASC' }}
                    >
                        <AutocompleteInput
                            fullWidth
                            optionText={optionRenderer} /* optionText="titoloricerca" */
                            suggestionLimit={30}
                        />
                    </ReferenceInput>}
                    {<TextInput fullWidth source="nome" label="Nome Mezzo" validate={required()} />}
                    {/*  {<ReferenceInput
                        source="portiId"
                        reference="porti"
                        perPage={30}
                        sort={{ field: 'nome', order: 'ASC' }}
                    >
                        <AutocompleteInput
                            fullWidth
                            optionText={optionRenderer}
                            suggestionLimit={30}
                        />
                    </ReferenceInput>} */}
                    <SelectInput source="tipoMezzo" choices={[
                        { id: 0, name: 'Mare' },
                        { id: 1, name: 'Terra' }
                    ]} validate={[required()]} />
                    {<NumberInput source="partecipantiMax" label="Capienza Posti" validate={required()} />}
                    {/*   {<RichTextInput fullWidth source="descrizioneBreve" label="Descrizione breve" validate={required()} />}
                    {<RichTextInput fullWidth source="descrizione" label="Descrizione" validate={required()} />}
                    */} {<TextInput fullWidth source="nominativoReferente" label="Nominativo referente" validate={required()} />}
                </SimpleForm>
            </CreateDialog>
        </List>
    );
}

const MotonaviFilters = [
    <ReferenceInput alwaysOn
        source="fornitoriId"
        reference="fornitori"
        perPage={30}
        sort={{ field: 'nome', order: 'ASC' }}
    >
        <AutocompleteInput
            optionText={optionRenderer} /* optionText="titoloricerca" */
            suggestionLimit={30}
        />
    </ReferenceInput>,
    <TextInput label="Nome Mezzo" source="nome" alwaysOn />,
    <SelectInput label="Tipo" source="tipoMezzo" alwaysOn choices={[
        { id: 0, name: 'Mare' },
        { id: 1, name: 'Terra' }
    ]} />,
    /*     <ReferenceInput alwaysOn
            source="portiId"
            reference="porti"
            perPage={30}
            sort={{ field: 'nome', order: 'ASC' }}
        >
            <AutocompleteInput
                optionText={optionRenderer}
                suggestionLimit={30}
            />
        </ReferenceInput>, */
    <NumberInput label="Capienza Posti Max (Posti)" source="partecipantiMax" alwaysOn />,
];

const ListaPortiMotonave = () => {

    const { data, isLoading, error } = useGetManyReference(
        'porti',
        {}
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    console.log(data);

    return (
        <ul>
            {data.map(comment => (
                <li key={comment.id}>{comment.body}</li>
            ))}
        </ul>
    );
};



/* const CustomSelectArrayInput = ({ service, source, orderField, orderType, optionText, label, sortField }) => {
    const { data, isLoading, error } = useGetList(
        service,
        { pagination: { page: 1, perPage: 1000 }, sort: { field: orderField, order: orderType } }
    );

    if (isLoading) return <Loading />;
    if (error) return <p>ERROR</p>;

    // Utilizzo di `sortField` per determinare su quale campo ordinare
    const datiOrdinati = Object.values(data).sort((a, b) => a[sortField].localeCompare(b[sortField]));


    return (
        <SelectArrayInput
            label={label}
            source={source}
            choices={datiOrdinati}
            optionText={optionText}
            validate={required()}
            fullWidth
        />
    );
}; */

export const MioSimpleForm = (props) => {

    const record = useRecordContext();
    // console.log(">>>>>> !! " + record.id);
    var id = record.id;
    var tipoMezzo = record.tipoMezzo;

    return (

        <SimpleForm toolbar={<MotonaviToolbar />}>
            {<ReferenceInput
                source="fornitoriId"
                reference="fornitori"
                perPage={30}
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <AutocompleteInput
                    fullWidth
                    optionText={optionRenderer} /* optionText="titoloricerca" */
                    suggestionLimit={30}
                />
            </ReferenceInput>}
            {/* {<ReferenceInput
                source="portiId"
                reference="porti"
                perPage={30}
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <AutocompleteInput
                    fullWidth
                    optionText={optionRenderer}
                    suggestionLimit={30}
                />
                
            </ReferenceInput>} */}
            {/* VERSIONE BASE */}
            {<ReferenceManyToManyInput
                source="id"
                reference="puntiincontro"
                through="mezzi-puntiincontro"
                using="mezziId,puntiincontroId"
            >
                {<SelectArrayInput
                    label="Punti d'incontro"
                    validate={required()}
                    optionText="nome"
                    fullWidth
                />}
            </ReferenceManyToManyInput>}
            {/* VERSIONE CON VISTA */}
            {/* {<ReferenceManyToManyInput
                source="id"
                reference="puntiincontro"
                through="wapp-mezzi-mezzi-puntiincontro"
                using="mezziId,puntiincontroId"
                sort={{ field: "id", order: "DESC" }}
            >
                {<SelectArrayInput
                    label="Punti d'incontro"
                    validate={required()}
                    optionText="nome"
                    fullWidth
                />}
            </ReferenceManyToManyInput>} */}
            {/* <ReferenceManyToManyInput
                source="id"
                reference="puntiincontro"
                through="mezzi-puntiincontro"
                using="mezziId,puntiincontroId"
            // sort={{ field: 'nome', order: 'ASC' }}
            > */}
            {/*  {<SelectArrayInput
                    label="Punti d'incontro"
                    // Validation must be set on this component
                    validate={required()}
                    optionText="nome"
                    fullWidth
                />} */}
            {/* {<CustomSelectArrayInput service="puntiincontro" source="puntiIncontroId" orderField="nome" orderType="ASC" optionText="nome" label="Punti d'incontro" sortField="nome"></CustomSelectArrayInput>}
            </ReferenceManyToManyInput> */}
            {<TextInput fullWidth source="nome" label="Nome Mezzo" validate={required()} />}
            <Typography sx={{ mb: 1.5 }} color="text.secondary">

                {/* {<TextField fullWidth source="tipoMezzo" label="Tipo" />} */}

                {/* <DecodificaTipologia source="tipoMezzo"/> */}
                <FunctionField label="Tipo" render={record => {

                    return (
                        <><h2>{(record.tipoMezzo === 0 ? "Tipologia : Mare" : "Tipologia : Terra")}</h2></>
                    );

                }} />

            </Typography>
            {<NumberInput source="partecipantiMax" label="Capienza Posti" validate={required()} />}
            {/*             {<RichTextInput fullWidth source="descrizioneBreve" label="Descrizione breve" validate={required()} />}
            {<RichTextInput fullWidth source="descrizione" label="Descrizione" validate={required()} />}
             */}{<TextInput fullWidth source="nominativoReferente" label="Nominativo referente" validate={required()} />}
            {/* {<h3>Prezzi al pubblico per {<TextField source="nome" label="Nome Mezzo" />} </h3>}
            {<ReferenceManyField
                reference="tariffe"
                target="motonaviId"
                sort={{ field: 'id', order: 'ASC' }}
            >
                <EditableDatagrid
                    bulkActionButtons={false}
                    editForm={<TariffeRowForm defaultValues={{ motonaviId: id, tipoMezzo: tipoMezzo }} />}
                    createForm={<TariffeRowForm defaultValues={{ motonaviId: id, tipoMezzo: tipoMezzo }} />}
              
                >
                    {<DateField label="Dal" source="dataFrom" locales="it-IT" />}
                    {<DateField label="Al" source="dataTo" locales="it-IT" />}
                    {<ReferenceField
                        source="portiId"
                        reference="porti"
                    >
                        {<TextField label="Partenza" source="nome" />}
                    </ReferenceField>}
                    {<NumberField label="Tariffa Adulti" source="tarAdulti" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Tariffa Bambini" source="tarBambini" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Tariffa Infanti" source="tarInfanti" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Tariffa Esclusiva" source="tarEsclusiva" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Costi Adulti" source="cosAdulti" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Costi Bambini" source="cosBambini" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Costi Infanti" source="cosInfanti" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Costi Esclusiva" source="cosEsclusiva" options={{ style: 'currency', currency: 'EUR' }} />}
                </EditableDatagrid>
            </ReferenceManyField>} */}
        </SimpleForm>
    );
}

export const TariffeRowForm = (props) => {

    console.log(props.defaultValues);

    return (
        <RowForm {...props}>
            {<TextInput label="MotonaviId" source="motonaviId" disabled />}
            {/* {<TextField label="Mese" source="mese" />} */}
            {<DateInput label="Dal" source="dataFrom" locales="it-IT" />}
            {<DateInput label="Al" source="dataTo" locales="it-IT" />}
            {/*     {<ReferenceInput
                source="portoId"
                reference="motonavi-porti"
            >
                {<SelectField label="Porto" source="id" />}
            </ReferenceInput>} */}
            {/* <ListaPortiMotonave /> */}
            <ReferenceInput label="Porto" source="portiId" reference="porti">
                <SelectInput optionText="nome" />
            </ReferenceInput>
            {<NumberInput min={0} label="Tariffa Adulti" source="tarAdulti" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Tariffa Bambini" source="tarBambini" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Tariffa Infanti" source="tarInfanti" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Tariffa Esclusiva" source="tarEsclusiva" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Costi Adulti" source="cosAdulti" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Costi Bambini" source="cosBambini" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Costi Infanti" source="cosInfanti" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Costi Esclusiva" source="cosEsclusiva" defaultValue={0.00} validate={[required()]} />}
            {/* {<NumberInput min={2023} label="Anno" source="anno" />} */}
            {/* {<SelectInput source="mese" label="Mese" choices={[
                { id: 'gennaio', name: 'gennaio' },
                { id: 'febbraio', name: 'febbraio' },
                { id: 'marzo', name: 'marzo' },
                { id: 'aprile', name: 'aprile' },
                { id: 'maggio', name: 'maggio' },
                { id: 'giugno', name: 'giugno' },
                { id: 'luglio', name: 'luglio' },
                { id: 'agosto', name: 'agosto' },
                { id: 'settembre', name: 'settembre' },
                { id: 'ottobre', name: 'ottobre' },
                { id: 'novembre', name: 'novembre' },
                { id: 'dicembre', name: 'dicembre' },
            ]}></SelectInput> */}

        </RowForm>
    );
}
