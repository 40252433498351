import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings'; // Icona per le impostazioni
import Typography from '@mui/material/Typography';

function SettingsPage() {
    return (
        <Card sx={{ mt: 10, textAlign: 'center', backgroundColor: '#f3f6f9' }}>
            <Title title="Settings" />
            <CardContent>
                <SettingsIcon sx={{ fontSize: 60, color: '#00b5bf' }} />
                <Typography variant="h4" sx={{ margin: '20px 0', color: '#00b5bf' }}>
                    Settings
                </Typography>
                <Typography variant="subtitle1">
                    Pagina dei settings generali.
                </Typography>
                {/* Qui puoi aggiungere i componenti per le impostazioni, come switch, checkbox, etc. */}
            </CardContent>
        </Card>
    );
}

export default SettingsPage;
