import React, { useState } from 'react';
import {
    useRecordContext,
    useCreate,
    Confirm,
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin';
import axios from 'axios';
import UpdateIcon from '@mui/icons-material/Update';
import Button from '@mui/material/Button';
import { editButtonCss } from '../shared/Styles/buttonStyles';
import Alert from '@mui/material/Alert';
import configHost from '../../config/config.json';

export const InitPuntiPartenzaButtonCustom = ({ titolo, messaggio, servizio, fieldToUpdate, fieldValue, redirectPath }) => {
    const record = useRecordContext();


    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [create, { isLoading }] = useCreate();
    if (!record) return null;



    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        if (record && record.id) {

            const jwt = localStorage.getItem("feathers-jwt");
            console.log(jwt);

            let data = '';

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: configHost.production.host + "/mezzi-puntiincontro?mezziId=" + record.mezziId,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));

                    response.data.data.forEach(element => {
                        const diff = {
                            [fieldToUpdate]: fieldValue,
                            escursioniId: record.id,
                            puntiincontroId: element.puntiincontroId,
                            visibile: 0
                        };

                        create(servizio, { data: diff }, {
                            onSuccess: () => {
                                notify('Init eseguito.', 'info');
                                redirect(redirectPath || '/' + servizio);
                            },
                            onFailure: (error) => {
                                notify(`Errore: ${error.message}`, 'warning');
                            }
                        });
                    });

                })
                .catch((error) => {
                    console.log(error);
                });


        }
        setOpen(false);
    };

    if (!record || !record.id) {
        return null;
    }


    if (record.mezziId === 0 || record.mezziId === null) {
        return <>

            <Alert severity="warning">Punti non dispobili. Occorre selezionare il fornitore e mezzo prima di procedere.</Alert>
        </>
    } else {
        return (
            <>
                <Button
                    sx={editButtonCss}
                    onClick={handleClick}
                    variant="contained"
                    size="small"
                    endIcon={<UpdateIcon />}
                    component="label"
                    color="error"
                >
                    Set : {titolo}
                </Button>
                <Confirm
                    isOpen={open}
                    loading={isLoading}
                    title={titolo || "Elimina file"}
                    content={messaggio}
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                    confirm="Si"
                    cancel="No"
                />
            </>
        );
    }


};
