import React from 'react';
import Box from '@mui/material/Box';
import Typography from "@material-ui/core/Typography";
import { ReferenceInput, AutocompleteInput, required } from 'react-admin';
import { formCssDropdownMenu } from "../Styles/formCss";

const CustomAutocompleInputTipoUser = ({
    isFiltered,
    filterValue,
    filterField,
    source,
    reference,
    optionProperty,
    label,
    readOnly,
    onValueChange,
    isRequired = false
}) => {
    const optionRenderer = choice => `${choice[optionProperty]}`;

    const handleSelectionChange = (newValue) => {
        if (onValueChange) {
            onValueChange(newValue);
        }
    };

    const validate = isRequired ? [required('Selezione obbligatoria')] : undefined;

    return (
        <div>
            <Box display="flex" alignItems="center" sx={{ width: 700 }}>
                <Typography variant="body1" style={{ marginRight: '10px' }}>{label}: </Typography>
                <ReferenceInput
                    filter={isFiltered ? { [filterField]: filterValue } : undefined}
                    perPage={100}
                    source={source}
                    reference={reference}
                    validate={validate}
                >
                    <AutocompleteInput
                        readOnly={readOnly}
                        fullWidth
                        label={label}
                        optionText={optionRenderer}
                        suggestionLimit={100}
                        sx={{
                            ...formCssDropdownMenu,
                            '& .Mui-error': {
                                color: 'red',
                                borderColor: 'red',
                            },
                        }}
                        onChange={handleSelectionChange}
                        source={source}
                        validate={validate}
                    />
                </ReferenceInput>
            </Box>
        </div>
    );
};

export default CustomAutocompleInputTipoUser;