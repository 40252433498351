import * as React from "react";
import {
    List,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    Datagrid,
    BooleanInput,
    RichTextField,
    FilterList,
    FilterListItem,
    FunctionField,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    ShowButton,
    WrapperField,
    CreateButton,
    BooleanField,
    useRecordContext,
    TabbedForm,
    SaveButton,
    Toolbar,
    Show,
    required,
    ReferenceManyField,
    ReferenceField,
    useRefresh,
} from 'react-admin';

import { useNavigate } from 'react-router-dom';

import {
    numberFilter
} from '@react-admin/ra-form-layout';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { RichTextInput } from 'ra-input-rich-text';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import Typography from "@material-ui/core/Typography";

import Box from '@mui/material/Box';
import ContentFilter from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import { useFormContext } from 'react-hook-form';
import { useState } from "react";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import MapIcon from '@mui/icons-material/Map';
import Avatar from '@mui/material/Avatar';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import Alert from '@mui/material/Alert';

// Importo componenti input custom
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import CustomAutocompleInputUsers from '../shared/FormFields/CustomAutocompleInputUsers';


import GestoreLogoTourOperator from '../tour-operator/GestoreLogoTourOperator';

// Crea un link verso google maps
import GoogleMapsLink from "../shared/GoogleMapsLink";

// Impost Custom List Page 
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';

// Importo il css per i Bottoni
import { editButtonCss, saveButtonCss, showButtonCss, columnsButtonCss, createButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';

/* Parametri di default per il componente che si occupa della posizinoe gps  */
import MapPicker from "react-google-map-picker";
const DefaultLocation = { lat: 41.0523108, lng: 8.2095084 };
const DefaultZoom = 10;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

/* TODO: da creare componente custom */

const CustomAvatarImage = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;



    return (
        <Box height="80%" width="80%" display="flex" justifyContent="left" alignItems="top">
            {record[source] ? (
                // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                <Avatar alt={label} style={{ width: '100px', height: '100px' }} src={record[source]} />
            ) : (
                <p><Avatar alt="Immagine mancante" style={{ width: '100px', height: '100px' }}><ImageNotSupportedIcon /></Avatar></p>
            )}
        </Box>
    );
};

export function ResponsiveDialog2() {

    const { setValue } = useFormContext();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    function handleChangeLocation(lat, lng) {
        setValue('latitudine', lat.toString());
        setValue('longitudine', lng.toString());
        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    return (
        <>
            <div>
                <Button onClick={handleOpen}>Apri mappa e seleziona il punto di escursione</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <label>Latitute:</label><input type='text' value={location.lat} disabled />
                        <label>Longitute:</label><input type='text' value={location.lng} disabled />
                        <MapPicker defaultLocation={defaultLocation}
                            zoom={zoom}
                            mapTypeId="roadmap"
                            style={{ height: '700px' }}
                            onChangeLocation={handleChangeLocation}
                            onChangeZoom={handleChangeZoom}
                            /* apiKey='AIzaSyBNi7XqlJRL4aW-GiWKA_g3ssmM3OO8kqM'  */
                            apiKey='AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY'
                        />
                    </Box>
                </Modal>
            </div>

        </>
    );
}

const listFiltersCatena = {
    'id': numberFilter({ operators: ["eq", "neq", "gt", "lt"] }),
};

const ListActions = () => (
    <TopToolbar>

        <CreateButton label="Crea" sx={createButtonCss}></CreateButton>
        {/* <ExportButton label="Esporta" sx={exportButtonCss} /> */}
        <SelectColumnsButton sx={columnsButtonCss} />
        {/* <StackedFilters sx={filterButtonCss} config={listFiltersCatena} /> */}

    </TopToolbar>
);

export const TourOperatorListCustom = props => {

    return (
        <List title={<>
            <Button>
                &nbsp;Lista Tour Operator
            </Button>
        </>}
            exporter={false}
            // aside={<TourOperatorFilterSidebar />}
            {...props}
            perPage={50}
            empty={<EmptyListCustom titolo="Lista Tour Operator"></EmptyListCustom>}
        >
            {/* <CustomizableDatagrid> */}
            <DatagridConfigurable sx={listCss} bulkActionButtons={false}>
                {/* {<TextField label="Id" source="id" />} */}
                <WrapperField label="Logo">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                        <CustomAvatarImage source="urlLogo" label="Logo" ></CustomAvatarImage>
                    </Box>
                </WrapperField>
                {<TextField label="Nome" source="nome" />}
                {<TextField label="Indirizzo" source="indirizzo" />}
                {/* {<TextField label="Stato" source="statoTourOperator" />} */}
                {/* {<TextField label="Zona" source="zona" />} */}
                {<RichTextField label="Note" source="note" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
            </DatagridConfigurable>
            {/* </CustomizableDatagrid> */}
        </List>
    );
}

const MyToolbarCreate = () => (
    <Toolbar>
        <SaveButton sx={createButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Crea Tour Operator" /* alwaysEnable */ />
    </Toolbar>
);

export const TourOperatorCreate = (props) => (
    <Create redirect="show" title="Crea Nuovo Tour Operator" {...props}>
        <SimpleForm toolbar={<MyToolbarCreate />}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <TextInput
                                label="Nome Commerciale"
                                source="nome"
                                validate={required('Il campo Nome Commerciale è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Ragione Sociale Completa"
                                source="ragioneSociale"
                                validate={required('Il campo Ragione Sociale Completa è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Telefono"
                                source="telefono"
                                validate={required('Il campo Telefono è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Cellulare"
                                source="cellulare"
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Email"
                                source="email"
                                validate={required('Il campo Email è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Indirizzo"
                                source="indirizzo"
                                validate={required('Il campo Indirizzo è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            {/* <SelectInput
                                source="zona"
                                label="Zona"
                                validate={required('La selezione di una Zona è obbligatoria.')}
                                choices={[
                                    { id: 'n', name: 'N' },
                                    { id: 'no', name: 'NO' },
                                    { id: 'ne', name: 'NE' },
                                    { id: 'e', name: 'E' },
                                    { id: 'o', name: 'O' },
                                    { id: 'so', name: 'SO' },
                                    { id: 'se', name: 'SE' },
                                    { id: 's', name: 'S' },
                                ]}
                                fullWidth
                                sx={{ mb: 2 }}
                            /> */}
                            <BooleanInput
                                source="statoTourOperator"
                                label="Stato"
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <RichTextInput
                                label="Note"
                                source="note"
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {<TextInput label="Longitudine" source="longitudine" />}
                                <br></br>
                                {<TextInput label="Latitudine" source="latitudine" />}
                                <ResponsiveDialog2></ResponsiveDialog2>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

/* HOTELS */
const HotelsShowLingueDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="tourOperatorId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInput source="hotelsId" reference="hotels" optionProperty="nome" label="" ></CustomAutocompleInput>

        </RowForm>
    );

}

const GestioneHotelsInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<Datagrid
        bulkActionButtons={false}
    >
        <Card variant="outlined" sx={{ margin: 1, borderRadius: 2 }}>
            <CardContent>
                <ReferenceField source="hotelsId" reference="Hotels" link={false}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <CustomAvatarImage source="urlLogo" label="Logo" />
                    </Box>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                    <PersonIcon />
                                    Nome: <FunctionField source="nome" render={record => record && `${record.nome}`} />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    <HomeIcon />
                                    Ragione Sociale Completa: <TextField label="Ragione Sociale Completa" source="ragioneSociale" />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    <HomeIcon />
                                    Partita Iva: {<TextField label="Partita Iva" source="partitaIva" />}
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    <PersonIcon />
                                    Nome referente: {<TextField label="Nome referente" source="nomeReferente" />}
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    <PhoneIcon />
                                    Telefono: <TextField label="Telefono" source="telefono" />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    <PhoneIcon />
                                    Cellulare: <TextField label="Cellulare" source="cellulare" />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    <EmailIcon />
                                    Email: <TextField label="Email" source="email" />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    <HomeIcon />
                                    Indirizzo: <TextField label="Indirizzo" source="indirizzo" />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    <GpsFixedIcon />
                                    Zona: <TextField source="zona" label="Zona" />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    Stato: <BooleanField source="statoHotel" label="Stato" />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                    <NoteIcon />
                                    Note: <RichTextField label="Note" source="note" />
                                </Box>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                        <GpsFixedIcon />
                                        Longitudine: <TextField label="Longitudine" source="longitudine" />
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                        <GpsFixedIcon />
                                        Latitudine: <TextField label="Latitudine" source="latitudine" />
                                    </Box>
                                </Typography>
                                <Typography color="textSecondary" gutterBottom>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}>
                                        Visualizza su Google Maps
                                        <GoogleMapsLink />
                                    </Box>
                                </Typography>
                            </Typography>
                        </CardContent>
                    </Card>
                </ReferenceField>
            </CardContent>
        </Card>
    </Datagrid>);

}

/* ESCURSIONI */

const NavigateButton = ({ recordId, service, message }) => {
    const handleNavigate = () => {

        const url = `#/${service}/${recordId}/show`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Button sx={showButtonCss} onClick={handleNavigate}>{message}</Button>
    );
};

const EscursioniShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="tourOperatorId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInput source="escursioniId" reference="escursioni" optionProperty="titoloricerca" label="" ></CustomAutocompleInput>

        </RowForm>
    );

}
const GestioneEscursioniInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<Datagrid
        bulkActionButtons={false}
    >
        <Card variant="outlined" sx={{ margin: 1, borderRadius: 2 }}>
            <CardContent>
                <ReferenceField source="escursioniId" reference="Escursioni" link={false}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>
                            <MapIcon /> {/* Utilizza l'icona Map dentro l'Avatar */}
                        </Avatar>
                        <Typography variant="subtitle1" component="div">
                            ID Escursione : <TextField source="id" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Titolo ricerca : <TextField source="titoloricerca" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Città : <TextField source="citta" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            {<FunctionField
                                source="escursioniId"
                                render={record => {
                                    console.log(record);
                                    return <NavigateButton recordId={record.id} service="escursioni" message="Dettaglio escursione"></NavigateButton>;
                                }}
                            />}
                        </Typography>
                    </Box>
                </ReferenceField>
            </CardContent>
        </Card>
    </Datagrid>);

}

const UsersShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="tourOperatorId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInputUsers isFiltered={true} filterField="roles" filterValue="touroperator" source="usersId" reference="users" optionProperty="email" label="" ></CustomAutocompleInputUsers>

        </RowForm>
    );

}

const GestioneUsersInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<Datagrid
        bulkActionButtons={false}
    >
        <Card variant="outlined" sx={{ margin: 1, borderRadius: 2 }}>
            <CardContent>
                <ReferenceField source="usersId" reference="users" link={false}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>
                        </Avatar>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                        <Typography variant="subtitle1" component="div">
                            Nome : <strong><TextField source="nome" /></strong>
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                            Cognome : <strong><TextField source="cognome" /></strong>
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                            Email : <strong><TextField source="email" /></strong>
                        </Typography>
                    </Box>
                </ReferenceField>
            </CardContent>
        </Card>

    </Datagrid>);

}

export const TourOperatorShowCustom = (props) => {

    const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>

                &nbsp;Dettaglio Tour Operator
            </Button>
        </>} actions={<ShowActions />}>
            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dati Tour Operator">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <GestoreLogoTourOperator refresh={refresh}></GestoreLogoTourOperator>
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <PersonIcon />
                                            Nome: <FunctionField source="nome" render={record => record && `${record.nome}`} />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Ragione Sociale Completa: <TextField label="Ragione Sociale Completa" source="ragioneSociale" />
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Partita Iva: {<TextField label="Partita Iva" source="partitaIva" />}
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PersonIcon />
                                            Nome referente: {<TextField label="Nome referente" source="nomeReferente" />}
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Telefono: <TextField label="Telefono" source="telefono" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Cellulare: <TextField label="Cellulare" source="cellulare" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <EmailIcon />
                                            Email: <TextField label="Email" source="email" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Indirizzo: <TextField label="Indirizzo" source="indirizzo" />
                                        </Box>
                                        {/*                                         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <GpsFixedIcon />
                                            Zona: <TextField source="zona" label="Zona" />
                                        </Box> */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            Stato: <BooleanField source="statoTourOperator" label="Stato" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <NoteIcon />
                                            Note: <RichTextField label="Note" source="note" />
                                        </Box>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <GpsFixedIcon />
                                                Longitudine: <TextField label="Longitudine" source="longitudine" />
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <GpsFixedIcon />
                                                Latitudine: <TextField label="Latitudine" source="latitudine" />
                                            </Box>
                                        </Typography>
                                        <Typography color="textSecondary" gutterBottom>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}>
                                                Visualizza su Google Maps
                                                <GoogleMapsLink />
                                            </Box>
                                        </Typography>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Lista Hotels">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <ReferenceManyField
                                reference="tour-operator-hotels"
                                target="tourOperatorId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <GestioneHotelsInShow></GestioneHotelsInShow>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Escursioni">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <ReferenceManyField
                                reference="escursioni-tour-operator"
                                target="tourOperatorId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <GestioneEscursioniInShow></GestioneEscursioniInShow>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Users">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            {/* <Alert severity="info">Attenzione, in questo tab puoi selezionare solo user con ruolo <strong>Tour Operator</strong></Alert> */}
                            <ReferenceManyField
                                reference="users-tour-operator"
                                target="tourOperatorId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <GestioneUsersInShow></GestioneUsersInShow>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Accordi">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">In costruzione.</Alert>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            </TabbedForm>
        </Show>
    );
}

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" />
    </Toolbar>
);

export const TourOperatorEditCustom = (props) => (
    <Edit redirect="show" actions={<ShowActionsForEdit />} title="Modifica Tour Operator" {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <TextInput
                                label="Nome Commerciale"
                                source="nome"
                                validate={required('Il campo Nome Commerciale è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Ragione Sociale Completa"
                                source="ragioneSociale"
                                validate={required('Il campo Ragione Sociale Completa è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Telefono"
                                source="telefono"
                                validate={required('Il campo Telefono è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Cellulare"
                                source="cellulare"
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Email"
                                source="email"
                                validate={required('Il campo Email è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextInput
                                label="Indirizzo"
                                source="indirizzo"
                                validate={required('Il campo Indirizzo è obbligatorio.')}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            {/* <SelectInput
                                source="zona"
                                label="Zona"
                                validate={required('La selezione di una Zona è obbligatoria.')}
                                choices={[
                                    { id: 'n', name: 'N' },
                                    { id: 'no', name: 'NO' },
                                    { id: 'ne', name: 'NE' },
                                    { id: 'e', name: 'E' },
                                    { id: 'o', name: 'O' },
                                    { id: 'so', name: 'SO' },
                                    { id: 'se', name: 'SE' },
                                    { id: 's', name: 'S' },
                                ]}
                                fullWidth
                                sx={{ mb: 2 }}
                            /> */}

                            <RichTextInput
                                label="Note"
                                source="note"
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {<TextInput label="Longitudine" source="longitudine" />}
                                <br></br>
                                {<TextInput label="Latitudine" source="latitudine" />}
                                <ResponsiveDialog2></ResponsiveDialog2>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

// Filtri

const TourOperatorFilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <CatenaFilter />
            </CardContent>
        </Card>
    </Box>
);

/// Filtri
const CatenaFilter = () => (
    <Grid container spacing={2}>
        <Grid>
            <Box >
                {/*         <Box sx={{
        width: '12em',
    }}><h3></h3>
        <Card>
    <CardContent><FilterLiveSearch source="id" />
    </CardContent>
    </Card></Box> */}

                <FilterList label="Zone geografiche" icon={<ContentFilter />}>
                    <FilterListItem
                        label="Nord"
                        value={{
                            zona: "n"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Ovest"
                        value={{
                            zona: "no"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Est"
                        value={{
                            zona: "ne"
                        }}
                    />
                    <FilterListItem
                        label="Est"
                        value={{
                            zona: "e"
                        }}
                    />
                    <FilterListItem
                        label="Sud"
                        value={{
                            zona: "s"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Ovest"
                        value={{
                            zona: "so"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Est"
                        value={{
                            zona: "se"
                        }}
                    />
                    <FilterListItem
                        label="Ovest"
                        value={{
                            zona: "o"
                        }}
                    />
                </FilterList>
            </Box>
        </Grid>
        {/* {  <Grid>
    <Box>
    <FilterList label="Trimestre" icon={<ContentFilter />}>
    <FilterListItem
            label="Primo"
            value={{trimestre: 1
            }}
        />
         <FilterListItem
            label="Secondo"
            value={{trimestre: 2
            }}
        />
    <FilterListItem
            label="Terzo"
            value={{trimestre: 3
            }}
        />
         <FilterListItem
            label="Quarto"
            value={{trimestre: 4
            }}
        />
    </FilterList>
    </Box>
  </Grid>} */}
    </Grid>);

const catenaFilters = [
    <TextInput label="Cerca per nome" source="nome" alwaysOn />,
];