import * as React from "react";
import {
    List,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    RichTextField,
    FilterList,
    FilterListItem,
    SelectInput,
    NumberInput,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    Show,
    ShowButton,
    WrapperField,
    CreateButton,
    Toolbar,
    SaveButton,
    required,
    ReferenceField,
    AutocompleteInput,
    ReferenceInput,
    TabbedForm,
    ReferenceManyCount,
    ReferenceManyField,
    useRecordContext,
    FunctionField,
    Datagrid,
    usePermissions
} from 'react-admin';

import {
    StackedFilters,
    numberFilter
} from '@react-admin/ra-form-layout';

// Importo componenti input custom
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import CustomAutocompleInputUsers from '../shared/FormFields/CustomAutocompleInputUsers';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import { RichTextInput } from 'ra-input-rich-text';

import Typography from "@material-ui/core/Typography";

import Box from '@mui/material/Box';
import ContentFilter from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import WorkIcon from '@mui/icons-material/Work';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useFormContext } from 'react-hook-form';
import { useState } from "react";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import BusinessIcon from '@mui/icons-material/Business';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import DirectionsCarSharp from '@mui/icons-material/DirectionsCarSharp';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import MapIcon from '@mui/icons-material/Map';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

// Impost Custom List Page 
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';
import EmptyListCustomNoCreate from '../shared/emptyPageCustom/empryPageCustomNoCreate';

// Importo il css per i Bottoni
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, createButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';


/* Parametri di default per il componente che si occupa della posizinoe gps  */
import MapPicker from "react-google-map-picker";
const DefaultLocation = { lat: 41.0523108, lng: 8.2095084 };
const DefaultZoom = 10;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

/* TODO: da creare componente custom */

const CustomAvatarImage = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;



    return (
        <Box height="80%" width="80%" display="flex" justifyContent="left" alignItems="top">
            {record[source] ? (
                // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                <Avatar alt={label} style={{ width: '100px', height: '100px' }} src={record[source]} />
            ) : (
                <p><Avatar alt="Immagine mancante" style={{ width: '100px', height: '100px' }}><ImageNotSupportedIcon /></Avatar></p>
            )}
        </Box>
    );
};
const listFiltersVenditori = {
    'id': numberFilter({ operators: ["eq", "neq", "gt", "lt"] }),
};

const ListActions = () => (
    <TopToolbar>

        <CreateButton label="Crea" sx={createButtonCss}></CreateButton>
        {/* <ExportButton label="Esporta" sx={exportButtonCss} /> */}
        <SelectColumnsButton sx={columnsButtonCss} />
        {/* <StackedFilters sx={filterButtonCss} config={listFiltersVenditori} /> */}

    </TopToolbar>
);

export const VenditoriList = props => {

    const { permissions } = usePermissions();

    return (
        <List title={<>
            <Button>
                &nbsp;Venditori
            </Button>
        </>}
            perPage={100}
            sort={{ field: 'id', order: 'asc' }}
            exporter={false}
            // filters={fornitoriFilters}
            empty={(permissions === 'admin')
                ? <EmptyListCustom titolo="Lista"></EmptyListCustom>
                : <EmptyListCustomNoCreate titolo="Lista"></EmptyListCustomNoCreate>}
            /*aside={<VenditoriFilterSidebar />}*/
            {...props}
            actions={<ListActions />}
            filter={{ id: { $ne: 0 } }}
        >
            <DatagridConfigurable sx={listCss} bulkActionButtons={false}>
                {/* {(permissions === 'admin') ?? <TextField label="Id" source="id" textAlign="right" />} */}
                {<TextField label="Ragione Sociale" source="nome" />}
                {/* {<GetNomeCatenaAlberghiera source="catenaAlberghieraId"></GetNomeCatenaAlberghiera>} */}
                {<TextField label="Referente" source="referente" />}
                {<TextField label="Indirizzo" source="indirizzo" />}
                {/* {<GoogleMapsLink ></GoogleMapsLink>}
                {<RichTextField label="Note" source="note" />} */}
                {<EditButton sx={editButtonCss} label="Modifica" textAlign="center" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" textAlign="center" />}
                <WrapperField label="Elimina" textAlign="center">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Venditore"}
                        messaggio={"Sei sicuro di vole cancellare questo Venditore ?"}
                        servizio={"venditori"} />
                </WrapperField>

            </DatagridConfigurable>
        </List>
    );
}

export const VenditoriCreate = (props) => (
    <Create title={<>
        <Button>

            &nbsp;Crea Gruppo Venditori
        </Button>
    </>}  {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275, boxShadow: 3 }}>
                        <CardContent>
                            <Typography component="div">
                                <Grid container spacing={2}>

                                    {[

                                        { icon: BusinessIcon, label: 'Nome Gruppo Venditore', source: 'nome', component: TextInput },
                                        { icon: PersonIcon, label: 'Referente Gruppo', source: 'referente', component: TextInput },
                                        { icon: WorkIcon, label: 'Codice fiscale', source: 'codicefiscale', component: TextInput },
                                        { icon: PhoneIcon, label: 'Telefono', source: 'telefono', component: TextInput },
                                        { icon: PhoneIcon, label: 'Cellulare', source: 'cellulare', component: TextInput },
                                        { icon: EmailIcon, label: 'Email', source: 'email', component: TextInput },
                                        { icon: HomeIcon, label: 'Indirizzo', source: 'indirizzo', component: TextInput },
                                        { icon: NoteIcon, label: 'Note', source: 'note', component: RichTextInput },
                                    ].map((field, index) => (


                                        <Grid item xs={12} sm={index < 8 ? 6 : 12} key={field.label}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                {React.createElement(field.icon, { sx: { color: 'action.active', mr: 1, my: 0.5, fontSize: '1.25rem' } })}
                                                {React.createElement(field.component, {
                                                    label: field.label,
                                                    source: field.source,
                                                    ...(field.choices ? { choices: field.choices } : {}),
                                                    sx: { width: '100%' },
                                                })}
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);


/*  */
const MezziShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <Card variant="outlined" sx={{
                m: 1,
                borderRadius: 2,
                boxShadow: 3,  // Aggiunge un'ombra per una migliore profondità
                '&:hover': {
                    boxShadow: 6,  // Ombra più pronunciata al passaggio del mouse
                }
            }}>
                <CardContent>
                    <TextInput source="fornitoriId" defaultValue={parseInt(id)} disabled ></TextInput>
                    <br></br>
                    <TextInput source="nome"></TextInput>
                    <br></br>
                    <RichTextInput fullWidth source="descrizione"></RichTextInput>
                    <br></br>
                    <SelectInput source="tipoMezzo" choices={[
                        { id: 0, name: 'Mare' },
                        { id: 1, name: 'Terra' }
                    ]} validate={[required()]} />
                    <br></br>
                    {<TextInput source="nominativoReferente" label="Nominativo referente" validate={required()} />}
                    <br></br>
                    {<NumberInput source="partecipantiMax" label="Capienza Posti" validate={required()} />}

                </CardContent>
            </Card>

        </RowForm>
    );

}

const GestioneMezziInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        noDelete
        editForm={<MezziShowDisponibiliRowForm />}
        createForm={<MezziShowDisponibiliRowForm id={record.id} />}
    >
        <Card variant="outlined" sx={{
            m: 1,
            borderRadius: 2,
            boxShadow: 3,  // Aggiunge un'ombra per una migliore profondità
            '&:hover': {
                boxShadow: 6,  // Ombra più pronunciata al passaggio del mouse
            }
        }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="subtitle1" component="div">
                        <FunctionField label="Tipo" sortBy="tipoMezzo" render={record => (
                            <>
                                {record.tipoMezzo === 0 ?
                                    <DirectionsBoat sx={{ width: 56, height: 56, marginRight: 2, color: 'blue' }} /> :
                                    <DirectionsCarSharp sx={{ width: 56, height: 56, marginRight: 2, color: 'green' }} />
                                }
                            </>
                        )} />
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        Nome Mezzo: <TextField source="nome" variant="outlined" size="small" />
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        Tipo: <FunctionField label="Tipo" sortBy="tipoMezzo" render={record => (
                            <>
                                {record.tipoMezzo === 0 ?
                                    <strong style={{ color: 'blue' }}>Mare</strong> :
                                    <strong style={{ color: 'green' }}>Terra</strong>
                                }
                            </>
                        )} />
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        Posti disponibili: <TextField source="partecipantiMax" variant="outlined" size="small" />
                    </Typography>
                    {/*                     <Typography variant="subtitle1" component="div">
                        <FunctionField label="Modifica" render={record => <EditMezzoFornitore
                            doc={record} label={"Mezzo"}
                        />} />
                    </Typography> */}
                    <br></br>
                    <Typography variant="subtitle1" component="div">
                        <FunctionField label="" render={record => <ConfirmDialogDeleteButtonCustom
                            titolo={"Cancella Mezzo"}
                            messaggio={"Sei sicuro di vole cancellare questo Mezzo ?"}
                            servizio={"mezzi"}
                            redirectPath={`/fornitori/${record.fornitoriId}/show/1`}
                        />} />
                    </Typography>
                </Box>
            </CardContent>
        </Card>

    </EditableDatagrid>);

}

const UsersShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="venditoriId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInputUsers isFiltered={true} filterField="roles" filterValue="venditore" source="usersId" reference="users" optionProperty="email" label="" ></CustomAutocompleInputUsers>

        </RowForm>
    );

}

const GestioneUsersInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        editForm={<UsersShowDisponibiliRowForm />}
        createForm={<UsersShowDisponibiliRowForm id={record.id} />}
    >
        <Card variant="outlined"
            sx={{ margin: 1, borderRadius: 2 }}
        >
            <CardContent>
                <ReferenceField source="usersId" reference="users" link={false}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>

                        </Avatar>
                        <Typography variant="subtitle1" component="div">
                            ID User : <TextField source="id" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Email : <strong><TextField source="email" /></strong>
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            {<FunctionField
                                source="usersId"
                                render={record => {
                                    console.log(record);
                                    return <NavigateButton recordId={record.id} service="users" message="Dettaglio User"></NavigateButton>;
                                }}
                            />}
                        </Typography>
                    </Box>

                </ReferenceField>
            </CardContent>
        </Card>
    </EditableDatagrid>);

}

const NavigateButton = ({ recordId, service, message }) => {
    const handleNavigate = () => {

        const url = `#/${service}/${recordId}/show`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Button sx={showButtonCss} onClick={handleNavigate}>{message}</Button>
    );
};

const EscursioniShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="fornitoriId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInput source="escursioniId" reference="escursioni" optionProperty="titoloricerca" label="" ></CustomAutocompleInput>

        </RowForm>
    );

}

const GestioneEscursioniInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (
        <Datagrid
            bulkActionButtons={false}
        /*   editForm={<EscursioniShowDisponibiliRowForm />}
          createForm={<EscursioniShowDisponibiliRowForm id={record.id} />} */
        >
            <Card variant="outlined"
                sx={{ margin: 1, borderRadius: 2 }}
            >
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>
                            <MapIcon /> {/* Utilizza l'icona Map dentro l'Avatar */}
                        </Avatar>
                        <Typography variant="subtitle1" component="div">
                            ID Escursione : <TextField source="id" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Titolo ricerca : <TextField source="id" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Titolo ricerca : <TextField source="titoloricerca" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            Città : <TextField source="citta" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            {<FunctionField
                                source="escursioniId"
                                render={record => {
                                    console.log(record);
                                    return <NavigateButton recordId={record.id} service="escursioni" message="Dettaglio escursione"></NavigateButton>;
                                }}
                            />}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Datagrid>);

}

/* HOTELS */
const HotelsShowLingueDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextInput source="venditoriId" defaultValue={parseInt(id)} disabled ></TextInput>
            <CustomAutocompleInput source="hotelsId" reference="hotels" optionProperty="nome" label="" ></CustomAutocompleInput>

        </RowForm>
    );

}

/*  */
const GestioneHotelsInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        editForm={<HotelsShowLingueDisponibiliRowForm />}
        createForm={<HotelsShowLingueDisponibiliRowForm id={record.id} />}
    >
        <Card variant="outlined" sx={{ margin: 1, borderRadius: 2 }}>
            <CardContent>
                <ReferenceField source="hotelsId" reference="Hotels" link={false}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <CustomAvatarImage source="urlLogo" label="Logo" />
                        <Typography variant="subtitle1" component="div">
                            <TextField source="nome" />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1/* , color: 'primary.main' */ }}>
                        <Typography variant="subtitle1" component="div">
                            {<FunctionField
                                source="escursioniId"
                                render={record => {
                                    console.log(record);
                                    return <NavigateButton recordId={record.id} service="hotels" message="Dettaglio hotel"></NavigateButton>;
                                }}
                            />}
                        </Typography>
                    </Box>
                </ReferenceField>
            </CardContent>
        </Card>
    </EditableDatagrid>);

}

export const VenditoriShow = (props) => (
    <Show title={<>
        <Button>

            &nbsp;Dettaglio Gruppo Venditori
        </Button>
        <TextField label="Nome" source="nome" />
    </>} actions={<ShowActions />}>

        <TabbedForm toolbar={null}>
            <TabbedForm.Tab label="Dati Gruppo Venditori">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* TODO: manca , se necessario serve implementare le logiche                   
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                        <GestoreLogoVenditori refresh={refresh}></GestoreLogoVenditori>
                                    </Box>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid> */}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Nome Gruppo Venditori: <TextField label="Nome" source="nome" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <PersonIcon />
                                            Referente: <TextField label="Referente" source="referente" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <WorkIcon />
                                            Codice fiscale: <TextField label="Codice Fiscale" source="codicefiscale" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Telefono: <TextField label="Telefono" source="telefono" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Cellulare: <TextField label="Cellulare" source="cellulare" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <EmailIcon />
                                            Email: <TextField label="Email" source="email" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Indirizzo: <TextField label="Indirizzo" source="indirizzo" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <NoteIcon />
                                            Note: <RichTextField label="Note" source="note" />
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            {/* <TabbedForm.Tab label="Escursioni"
                count={<ReferenceManyCount
                    reference="escursioni"
                    target="fornitoriId" />}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Alert severity="info">Il fornitore può essere associato solo nel tab 'Venditori' presente nel dettagli dell'escursione.</Alert>

                        <ReferenceManyField
                            reference="escursioni"
                            target="fornitoriId"
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <GestioneEscursioniInShow></GestioneEscursioniInShow>
                        </ReferenceManyField>
                    </Grid>
                </Grid>
            </TabbedForm.Tab> */}
            <TabbedForm.Tab label="Users" count={<ReferenceManyCount reference="users-venditori"
                target="venditoriId" />}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Alert severity="info">Attenzione, in questo tab puoi selezionare solo user con ruolo <strong>Venditori</strong></Alert>
                        <ReferenceManyField
                            reference="users-venditori"
                            target="venditoriId"
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <GestioneUsersInShow></GestioneUsersInShow>
                        </ReferenceManyField>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Lista Hotels"
                count={<ReferenceManyCount
                    reference="venditori-hotels"
                    target="venditoriId" />}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <ReferenceManyField
                            reference="venditori-hotels"
                            target="venditoriId"
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <GestioneHotelsInShow></GestioneHotelsInShow>
                        </ReferenceManyField>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Accordi">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Alert severity="info">In costruzione.</Alert>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
        </TabbedForm>

    </Show>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

export const VenditoriEdit = (props) => (
    <Edit title={<>
        <Button>

            &nbsp;Modifica Gruppo Venditore
        </Button>
    </>} actions={<ShowActionsForEdit />} {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275, boxShadow: 3 }}>
                        <CardContent>
                            <Typography component="div">
                                <Grid container spacing={2}>
                                    {[

                                        { icon: BusinessIcon, label: 'Nome Gruppo Venditore', source: 'nome', component: TextInput },
                                        { icon: PersonIcon, label: 'Referente Gruppo', source: 'referente', component: TextInput },
                                        { icon: WorkIcon, label: 'Codice fiscale', source: 'codicefiscale', component: TextInput },
                                        { icon: PhoneIcon, label: 'Telefono', source: 'telefono', component: TextInput },
                                        { icon: PhoneIcon, label: 'Cellulare', source: 'cellulare', component: TextInput },
                                        { icon: EmailIcon, label: 'Email', source: 'email', component: TextInput },
                                        { icon: HomeIcon, label: 'Indirizzo', source: 'indirizzo', component: TextInput },
                                        { icon: NoteIcon, label: 'Note', source: 'note', component: RichTextInput },
                                    ].map((field, index) => (


                                        <Grid item xs={12} sm={index < 8 ? 6 : 12} key={field.label}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                {React.createElement(field.icon, { sx: { color: 'action.active', mr: 1, my: 0.5, fontSize: '1.25rem' } })}
                                                {React.createElement(field.component, {
                                                    label: field.label,
                                                    source: field.source,
                                                    ...(field.choices ? { choices: field.choices } : {}),
                                                    sx: { width: '100%' },
                                                })}
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

// Filtri

const VenditoriFilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <VenditoriFilter />
            </CardContent>
        </Card>
    </Box>
);

/// Filtri
const VenditoriFilter = () => (
    <Grid container spacing={2}>
        <Grid>
            <Box >
                {/*         <Box sx={{
        width: '12em',
    }}><h3></h3>
        <Card>
    <CardContent><FilterLiveSearch source="id" />
    </CardContent>
    </Card></Box> */}

                <FilterList label="Zone geografiche" icon={<ContentFilter />}>
                    <FilterListItem
                        label="Nord"
                        value={{
                            zona: "n"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Ovest"
                        value={{
                            zona: "no"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Est"
                        value={{
                            zona: "ne"
                        }}
                    />
                    <FilterListItem
                        label="Est"
                        value={{
                            zona: "e"
                        }}
                    />
                    <FilterListItem
                        label="Sud"
                        value={{
                            zona: "s"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Ovest"
                        value={{
                            zona: "so"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Est"
                        value={{
                            zona: "se"
                        }}
                    />
                    <FilterListItem
                        label="Ovest"
                        value={{
                            zona: "o"
                        }}
                    />
                </FilterList>
            </Box>
        </Grid>
        {/* {  <Grid>
    <Box>
    <FilterList label="Trimestre" icon={<ContentFilter />}>
    <FilterListItem
            label="Primo"
            value={{trimestre: 1
            }}
        />
         <FilterListItem
            label="Secondo"
            value={{trimestre: 2
            }}
        />
    <FilterListItem
            label="Terzo"
            value={{trimestre: 3
            }}
        />
         <FilterListItem
            label="Quarto"
            value={{trimestre: 4
            }}
        />
    </FilterList>
    </Box>
  </Grid>} */}
    </Grid>);


const fornitoriFilters = [
    <TextInput label="Cerca per Ragione Sociale" source="nome" alwaysOn />/* ,
    <TextInput label="Cerca per id" source="id" alwaysOn /> */
];