import React, { useState } from 'react';
import { useUpdate, Confirm, useNotify, useRefresh } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { deleteButtonCss } from '../shared/Styles/buttonStyles';
import axios from 'axios';
import config from '../../config/config.json';

const CustomDeleteImage = ({ recordId, urlLogo }) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const [update, { isLoading, error }] = useUpdate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {

        const data = {
            urlLogo: ""
        };

        update(
            'tour-operator',
            { id: recordId, data: data },
            {
                onSuccess: ({ data }) => {
                    handleClose();
                    notify('Logo eliminato');
                },
                onFailure: (error) => {
                    notify(error.message, 'warning');
                }
            }
        ).then(async () => {

            // Estrai il nome del file e l'estensione dall'URL
            const segments = urlLogo.split('/');
            const filename = segments.pop(); // Ottiene l'ultimo elemento dell'array, che è "3.PNG"
            // Ora puoi utilizzare `filename` nell'URL nuovo
            try {
                const response = await axios({
                    method: 'delete',
                    url: `${config.production.hostUploads}/uploads/tour-operator/${filename}`, // Utilizza il nome del file estratto
                    // url: `http://gsui.btechitalia.it:3003/uploads/azienda/${filename}`, // Utilizza il nome del file estratto
                    // url: `http://localhost:3003/uploads/azienda/${filename}`, // Utilizza il nome del file estratto
                });
                console.log("Risposta dell'eliminazione:", response.data);
                return response.data;
            } catch (error) {
                console.error("Errore nell'eliminazione del logo:", error);
                throw error;
            }

        });
    };

    return (
        <>
            <Button
                sx={deleteButtonCss}
                startIcon={<DeleteIcon />}
                onClick={handleClickOpen}
                disabled={isLoading}
                color="error"
            >
            </Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Conferma Eliminazione"
                content="Sei sicuro di voler eliminare questo logo?"
                onConfirm={handleDelete}
                onClose={handleClose}
                confirm="Sì"
                cancel="No"
            />
            {error && <p>ERROR</p>} {/* Gestione dell'errore al di fuori del dialogo di conferma */}
        </>
    );
};

export default CustomDeleteImage;
