import { useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material'; // Importa il componente Grid
import CustomCreateLogo from "../logo/CreateImageLogo";
import CustomDeleteLogo from "../logo/CustomDeleteLogo";
import Avatar from '@mui/material/Avatar';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

/* TODO: da creare componente custom */

const CustomAvatarImage = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;



    return (
        <Box height="80%" width="80%" display="flex" justifyContent="left" alignItems="top">
            {record[source] ? (
                // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                <Avatar alt={label} style={{ width: '200px', height: '200px' }} src={record[source]} />
            ) : (
                <p><Avatar alt="Immagine mancante" style={{ width: '200px', height: '200px' }}><ImageNotSupportedIcon /></Avatar></p>
            )}
        </Box>
    );
};

const GestoreLogoCategoria = ({ refresh }) => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CustomAvatarImage source="pathImage" label="Cover" />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomCreateLogo categorieId={record.id} refresh={refresh} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomDeleteLogo recordId={record.id} refresh={refresh} pathImage={record.pathImage} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default GestoreLogoCategoria;
