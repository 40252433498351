import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Toolbar,
    SaveButton,
    FunctionField,
    ReferenceField,
    useRefresh,
    TopToolbar,
    CreateButton,
    EditButton
} from 'react-admin';

import { editButtonCss, showMapButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, createButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { EditDialog, CreateDialog } from '@react-admin/ra-form-layout';
import CreatePuntiForm from "./CreatePuntiForm";
import EditPuntiForm from "./EditPuntiForm";
import GoogleMapsLink from "../shared/GoogleMapsLink";
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

export const PortiToolbar = () => (
    <Toolbar>
        <SaveButton label="Save" />
    </Toolbar>
);

const ListActions = () => (
    <TopToolbar>

        <CreateButton label="Crea nuovo punto" sx={createButtonCss}></CreateButton>

    </TopToolbar>
);

/* const PortiTitle = () => {

    const record = useRecordContext();

    if (!record) return null;

    // console.log(record);
    // return <span> {record ? (record.titolo + " >") : ' '}</span>;
    return <strong> {record ? (record.nome) : ''}</strong>;
}; */


export const PortiList = ({ id, ...props }) => {

    const refresh = useRefresh();

    return (
        <List title="Lista Punti Geografici"
            sort={{ field: 'id', order: 'DESC' }}
            hasCreate
            // filters={PortiFilters}
            exporter={false}
            {...props}
            perPage={100}
            actions={<ListActions />}
            filters={puntiincontroFilters}
            filterDefaultValues={{ id: { $ne: 0 } }}
        >
            <Datagrid
                bulkActionButtons={false}
                noDelete
            // rowClick="null"

            >
                {<TextField fullWidth source="nome" label="Nome del punto geografico" />}
                <ReferenceField label="Tipo punto incontro" source="tipoPuntoPartenza" reference="tipo-puntiincontro">
                    <FunctionField render={record => record && `${record.descrizione}`} />
                </ReferenceField>
                {<TextField label="Longitudine" source="longitudine" />}
                {<TextField label="Latitudine" source="latitudine" />}
                {<GoogleMapsLink ></GoogleMapsLink>}
                {<EditButton sx={editButtonCss} label="Modifica" />}
                {/* {<EditPuntiForm label="Modifica Punto" />} */}
                <ConfirmDialogDeleteButtonCustom

                    titolo={"Cancella Punto"}
                    messaggio={"Sei sicuro di vole cancellare questo punto ?"}
                    servizio={"puntiincontro"} />
            </Datagrid>
            <EditDialog title="Modfica Punto" fullWidth maxWidth="ld" id={id}>
                {/*                 <SimpleForm toolbar={<PortiToolbar />}>
                    {<TextInput fullWidth source="nome" label="Nome Porto" />}
                    <ReferenceInput label="Tipo punto incontro" source="tipoPuntoPartenza" reference="tipo-puntiincontro">
                        <SelectInput
                            label="Tipo punto di partenza"
                            source="tipoPuntoPartenza"
                            optionText="descrizione"
                        />
                    </ReferenceInput>
                    {<TextInput label="Longitudine" source="longitudine" />}
                    {<TextInput label="Latitudine" source="latitudine" />}
                </SimpleForm> */}
                {<EditPuntiForm label="Modifica Punto" refresh={refresh} />}
            </EditDialog>
            <CreateDialog title="Crea un nuovo Punto" fullWidth maxWidth="ld">
                {/*                 <SimpleForm toolbar={<PortiToolbar />}>
                    {<TextInput fullWidth source="nome" label="Nome Porto" />}
                    <ReferenceInput label="Tipo punto incontro" source="tipoPuntoPartenza" reference="tipo-puntiincontro">
                        <SelectInput
                            label="Tipo punto di partenza"
                            source="tipoPuntoPartenza"
                            optionText="descrizione"
                        />
                    </ReferenceInput>
                    {<TextInput label="Longitudine" source="longitudine" />}
                    {<TextInput label="Latitudine" source="latitudine" />} 
                    </SimpleForm>*/}
                <CreatePuntiForm refresh={refresh} ></CreatePuntiForm>
            </CreateDialog>
        </List>
    );
}

const puntiincontroFilters = [
    <TextInput label="Nome Punto" source="nome" alwaysOn />
];

