import {
    useGetOne,
    Loading,
    useRecordContext
} from 'react-admin';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Typography from "@material-ui/core/Typography";

const GetInformazioniAzienda = () => {
    const record = useRecordContext();
    const { data: azienda, isLoading, error } = useGetOne('azienda', { id: "1" });
    if (!record) return null;
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                <PersonIcon />
                                Ragione sociale: {azienda.ragioneSociale}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                <PhoneIcon />
                                Telefono: {azienda.telefono}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                <PhoneIcon />
                                Cellulare: {azienda.cellulare}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                <EmailIcon />
                                Email: {azienda.email}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                <HomeIcon />
                                Indirizzo: {azienda.indirizzo}
                            </Box>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    </>;
};

export default GetInformazioniAzienda;