
import { PaymentsEdit } from './paymentsEdit';
import { PaymentsList } from './paymentsList';
import { PaymentsShow } from './paymentsShow';
import { PaymentsCreate } from './paymentsCreate';
import MapIcon from '@material-ui/icons/Map';

export default {
    list: PaymentsList,
    // edit: PaymentsEdit,
    show: PaymentsShow,
    // create: PaymentsCreate,
    icon: MapIcon
};
