import * as React from "react";
import {
    TextInput,
    SimpleForm,
    TextField,
    Create,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    ReferenceManyField,
    SimpleShowLayout,
    DateInput,
    NumberInput,
    useDataProvider,
    Loading,
    Error,
    required,
    localStorageStore,
    DateTimeInput,
    FunctionField,
    RichTextField,
    TopToolbar,
    Toolbar,
    SaveButton,
    ShowButton,
    AutocompleteInput,
    SelectArrayInput
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import { useState, useEffect } from 'react';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';

import Box from '@mui/material/Box';
import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import { blue, red } from '@mui/material/colors';
import { RichTextInput } from "ra-input-rich-text";
/* import DirectionsCarSharp from '@mui/icons-material/DirectionsCarSharp';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat'; */
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import Button from '@mui/material/Button';

// Importo il css per i Bottoni
import { saveButtonCss, showButtonCss } from '../shared/Styles/buttonStyles';


// Init store
const storeGenerale = localStorageStore();

const FornitoriToolbar = () => (
    <Toolbar>
        <Toolbar>
            <SaveButton sx={saveButtonCss}
                label="Salva parametri" alwaysEnable />
        </Toolbar>
    </Toolbar>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForCreate = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const optionRenderer = choice => `${choice.nome}`;

export const MotonaviCreate = ({ id, ...props }) => {
    return (
        <Create title={<>
            <Button>
                &nbsp;Crea Nuovo Mezzo
            </Button>
            <TextField label="" source="nome" />
        </>}
            actions={<ShowActionsForCreate />}
            {...props}
            id={id}
            redirect="show"
        >
            <SimpleForm toolbar={<FornitoriToolbar />}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><strong>Cerca Fornitore:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <ReferenceInput
                                            source="fornitoriId"
                                            reference="fornitori"
                                            perPage={100}
                                            sort={{ field: 'nome', order: 'ASC' }}
                                            label="Cerca Fornitore"
                                        >
                                            <AutocompleteInput
                                                fullWidth
                                                optionText={optionRenderer}
                                                suggestionLimit={30}
                                                validate={required('Fornitore necessario.')}
                                            />
                                        </ReferenceInput>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><strong>Tipologia di mezzo:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SelectInput source="tipoMezzo" choices={[
                                            { id: 0, name: 'Mare' },
                                            { id: 1, name: 'Terra' }
                                        ]} validate={[required('Seleziona la tipologia del mezzo')]} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><strong>Nome Mezzo:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextInput fullWidth source="nome" variant="outlined" size="small" validate={required('Nome Mezzo necessario.')} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><strong>Referente:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <PersonIcon />
                                        <TextInput label="Nominativo Referente" source="nominativoReferente" validate={required('Nominativo Referente necessario.')} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><strong>Capienza posti:</strong></Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <WorkIcon />
                                        <NumberInput min={0} source="partecipantiMax" label="Capienza Posti" validate={required('Numero capienza posti necessario.')} />
                                    </Grid>
                                </Grid>

                                <hr />

                                <ReferenceManyToManyInput
                                    source="id"
                                    reference="puntiincontro"
                                    through="mezzi-puntiincontro"
                                    using="mezziId,puntiincontroId"
                                >
                                    <SelectArrayInput
                                        label="Punti d'incontro"
                                        validate={required()}
                                        optionText="nome"
                                        fullWidth
                                    />
                                </ReferenceManyToManyInput>

                                <hr />

                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6">Descrizione</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <RichTextInput label="" source="descrizione" />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant="h6">Descrizione Breve</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <RichTextInput label="" source="descrizioneBreve" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
}
