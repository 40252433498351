import * as React from 'react';
import {
    AppBar,
    useStore,
    TitlePortal,
    RefreshIconButton,
    useUserMenu,
    Logout,
    UserMenu,
    useLogout,
    usePermissions
} from 'react-admin';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { urlLogo } from '../../../utils/urlBase';
import { Button } from '@mui/material';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Avatar from '@mui/material/Avatar';

const SettingsButton = () => (
    <><Button sx={{ color: 'white' }} component={Link} to="/settingspage">
        <SettingsIcon />
    </Button>
        <RefreshIconButton /></>
);

const DatiUtente = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    return (
        <MenuItem
            onClick={onClose}
            ref={ref}
            {...props}
        >
            <ListItemIcon >
                <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Dati personali</ListItemText>
        </MenuItem>
    );
});

const AvatarCustom = () => (
    <Avatar
        sx={{
            height: 40,
            width: 40,
        }}
    // src="https://marmelab.com/images/avatars/adrien.jpg"
    />
);


const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            // It's important to pass the props to allow Material UI to manage the keyboard navigation
            {...props}
        >

            <ListItemIcon>
                <ExitIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Esci</ListItemText>
        </MenuItem>
    );
});

const useStyles = makeStyles({
    logo: {
        maxHeight: '50px',

    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#ffffff !important',  // Imposta il colore del testo a nero
    },
    spacer: {
        flex: 1,
    },
    appBar: {
        backgroundColor: '#00b5bf',  // Imposta il colore di sfondo a nero
        '& button': {
            color: '#ffffff',  // Imposta il colore del testo dei bottoni a nero
        }
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column', // Imposta i figli del contenitore in colonna
        alignItems: 'flex-start', // Allinea i figli a sinistra del contenitore
        marginRight: '20px', // Distanza dalla parte destra
    }
});

// https://www.npmjs.com/package/react-router-breadcrumbs-hoc
export const MyAppBar = props => {
    const classes = useStyles();
    const [nomeUtente] = useStore('profile.email');
    const [ruoloUtente] = useStore('profile.roles');
    return (
        <AppBar

            toolbar={
                <SettingsButton></SettingsButton>
            }

            userMenu={
                <UserMenu icon={<AvatarCustom />}>
                    {/* <DatiUtente /> */} {/* TODO: da creare la pagina */}
                    <MyLogoutButton />
                </UserMenu>
            }
            {...props}/*  userMenu={true} */ className={classes.appBar}>
            <Box component="span" sx={{
                mr: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'white',
                borderRadius: '8px',
                p: 2
            }}>
                <img src={urlLogo} alt="GO sardinia Logo" className={classes.logo} />

            </Box>
            <div className={classes.title}>GO Sardinia Backoffice</div>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: '30px', marginRight: '10px' }} />

            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            /><span className={classes.spacer} />
            <div className={classes.userInfo}>
                <strong>Utente: {nomeUtente}</strong>
                <Typography variant="body2" color="inherit">
                    Ruolo: {ruoloUtente}
                </Typography>
            </div>


        </AppBar>
    );
};
