import React, { useState, useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import axios from 'axios';
import config from '../../config/config.json';
import { Typography, Grid, Card, CardContent, Box, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#e8f5e9',
    border: '1px solid #4caf50',
    boxShadow: '0 4px 8px rgba(76, 175, 80, 0.2)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0 6px 12px rgba(76, 175, 80, 0.3)',
    },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: '#2e7d32',
    borderBottom: '2px solid #4caf50',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));

const ListaTransfer = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const record = useRecordContext();
    const jwt = localStorage.getItem("feathers-jwt");

    const fetchData = async () => {
        if (record && record.id) {
            try {
                setLoading(true);
                const response = await axios.get(`${config.production.host}/wapp-transfer-posti-occupati-aggregate/${record.id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Errore nel recupero dei dati:', error);
                setData(null);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 2000);
        return () => clearInterval(intervalId);
    }, [record]);

    /* if (loading) {
        return <Typography>Caricamento dati...</Typography>;
    } */

    if (!data || data.numMezzi === 0) {
        return (
            <StyledCard>
                <CardContent>
                    <Alert severity="info">
                        Non ci sono automezzi associati a questo transfer.
                        Aggiungi un automezzo per visualizzare i dettagli del transfer.
                    </Alert>
                </CardContent>
            </StyledCard>
        );
    }

    return (
        <StyledCard>
            <CardContent>
                <StyledTitle variant="h5" gutterBottom>
                    Dettagli Transfer (ID: {data.id})
                </StyledTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <Box component="span" fontWeight="bold" color="#1b5e20">Data Transfer:</Box> {new Date(data.dataTransfer).toLocaleDateString()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <Box component="span" fontWeight="bold" color="#1b5e20">Numero Mezzi:</Box> {data.numMezzi}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <Box component="span" fontWeight="bold" color="#1b5e20">Posti Occupati Totali:</Box> {data.postiOccupatiTot}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <Box component="span" fontWeight="bold" color="#1b5e20">Capienza Mezzo Totale:</Box> {data.capienzamezzoTot}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <Box component="span" fontWeight="bold" color="#1b5e20">Posti Disponibili Totali:</Box> {data.postiDisponibiliTot}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <Box component="span" fontWeight="bold" color="#1b5e20">Note Transfer:</Box>
                        </Typography>
                        <Box sx={{ backgroundColor: '#f1f8e9', padding: 1, borderRadius: 1 }}>
                            <div dangerouslySetInnerHTML={{ __html: data.noteTransfer }} />
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
    );
};

export default ListaTransfer;