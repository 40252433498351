import { defaultTheme } from "react-admin";
import createMuiTheme from "@material-ui/core/styles/createMuiStrictModeTheme";
import merge from "lodash/merge";
import blue from '@mui/material/colors/blue';
import indigo from '@mui/material/colors/indigo';
import pink from '@mui/material/colors/pink';
import red from '@mui/material/colors/red';

export const myTheme = createMuiTheme(
  merge({}, defaultTheme, {
    palette: {
      /* secondary: {
        main: "#0000FF",
      }, */
      primary: indigo,
      secondary: pink,
      error: red,
      contrastThreshold: 3,
      tonalOffset: 0.2,
      RaSidebar: {
        fixed: {
          position: 'inherit'
        }
      },
    },
  },
  )
);
