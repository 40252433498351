import * as React from "react";
import {
    List,
    Datagrid,
    EditButton,
    TextField,
    ReferenceField,
    FunctionField,
    Button,
    TopToolbar,
    CreateButton,
    ShowButton,
    WrapperField
} from 'react-admin';

// Importo il css per i Bottoni
import { editButtonCss, showButtonCss, createButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import EmptyListCustom from "../shared/emptyPageCustom/empryPageCustom";

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={createButtonCss}></CreateButton>
    </TopToolbar>
);

export const UsersListFiltered = ({ rolesCode, nomeLista, ...props }) => {

    return (
        <List
            title={<>
                <Button>
                    &nbsp;Lista Clienti
                </Button>
            </>}
            exporter={false}
            actions={<ListActions />}
            perPage={100}
            filter={{ id: { $ne: 0 }, rolesCode: rolesCode }}
            empty={<EmptyListCustom titolo={`Lista ${nomeLista}`}></EmptyListCustom>}
            {...props}>

            <Datagrid
                sx={listCss}
                bulkActionButtons={false}>
                {/* {<TextField label="Id" source="id" />} */}
                {<TextField label="Nome" source="nome" />}
                {<TextField label="Cognome" source="cognome" />}
                {<TextField label="Email" source="email" />}
                {<TextField label="Ruolo" source="roles" />}
                {<TextField label="Codice rivenditore" source="codiceRivenditore" />}
                <ReferenceField label="Hotel" source="hotelId" reference="hotels">
                    <FunctionField render={record => record && `${record.nome} ${record.zona} `} />
                </ReferenceField>
                {<TextField label="Indirizzo" source="indirizzo" />}
                {<TextField label="Cap" source="cap" />}
                {<TextField label="Telefono" source="telefono" />}
                {<EditButton sx={editButtonCss} label="Modifica" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
                <WrapperField label="Elimina">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Utente"}
                        messaggio={"Sei sicuro di vole cancellare questo utente ?"}
                        servizio={"users"} />
                </WrapperField>
            </Datagrid>
        </List>
    );
}