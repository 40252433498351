import { useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import CustomCreateImage from "../azienda/CreateImageLogo";
import CustomDeleteLogo from "../azienda/CustomDeleteLogo";
import Avatar from '@mui/material/Avatar';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

/* TODO: da creare componente custom */

const CustomAvatarImage = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;



    return (
        <Box height="80%" width="80%" display="flex" justifyContent="left" alignItems="top">
            {record[source] ? (
                // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                <Avatar alt={label} style={{ width: '200px', height: '200px' }} src={record[source]} />
            ) : (
                <p><Avatar alt="Immagine mancante" style={{ width: '200px', height: '200px' }}><ImageNotSupportedIcon /></Avatar></p>
            )}
        </Box>
    );
};

const GestoreLogoAzienda = ({ refresh }) => {
    const record = useRecordContext();
    // const userId = localStorage.getItem("RaStore.profile.idUser");
    // const emailUser = localStorage.getItem("RaStore.profile.email");
    if (!record) return null;
    return record ? (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                <CustomAvatarImage source="urlLogo" label="Logo" ></CustomAvatarImage>
            </Box>
            <CustomCreateImage aziendaId={record.id} /* userId={userId}  */ refresh={refresh}></CustomCreateImage>
            <CustomDeleteLogo recordId={record.id} refresh={refresh} urlLogo={record.urlLogo} />
        </>
    ) : null;
};

export default GestoreLogoAzienda;