export const iconStyle = {
    color: '#01b5d0'
};

export const iconSpecialStyle = {
    color: '#08ccc2'
};

export const iconBigStyle = {
    color: '#01b5d0',
    fontSize: '48px'
};

