import * as React from "react";
import {
    List,
    Datagrid,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    FunctionField,
    PasswordInput,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    useRecordContext,
    useGetOne,
    useGetManyReference,
    Loading,
    SearchInput,
    FilterButton,
    TopToolbar,
    DateField,
    ShowButton,
    AutocompleteInput,
    TabbedForm,
    BooleanField,
    refresh,
    RichTextField,
    useGetList,
    ExportButton,
    NumberField,
    ReferenceOneField
} from 'react-admin';

import CustomizableDatagrid from 'ra-customizable-datagrid';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import { showButtonCss, filterButtonCss, exportButtonCss, editButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';
import PaymentIcon from '@mui/icons-material/Payment';
import EventIcon from '@mui/icons-material/Event';
import EuroIcon from '@mui/icons-material/Euro';
import DoneIcon from '@mui/icons-material/Done';
import { Divider, fabClasses } from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Avatar from '@mui/material/Avatar';
import Typography from "@material-ui/core/Typography";
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ContentFilter from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardMedia } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import GetInformazioniAzienda from "../escursioni/GetInformazioniAzienda";
import UpdateIcon from '@mui/icons-material/Update';
import DownloadIcon from '@mui/icons-material/Download';
import ModificaNote from '../ordini/modificaNote';
import ModificaNoteVariazioni from '../ordini/modificaNoteVariazioni';

// Impost Custom List Page 
import EmptyListCustomNoCreate from '../shared/emptyPageCustom/empryPageCustomNoCreate';

// Crea un link verso google maps
import GoogleMapsLink from "../shared/GoogleMapsLink";
import MapPicker from "react-google-map-picker";
import { Person } from "@material-ui/icons";
import ListaPartecipanti from "../istanze-escursioni/partecipanti/ListaPartecipanti";

const SubReference = ({ translateChoice, children, ...props }) => (
    <ReferenceField {...props}>{children}</ReferenceField>
);

/* Utente PARTECIPANTE - Recupero dettgli di una singola occorrenza */
const DettagliUtente = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span><UserProfile id={record.userId}></UserProfile></span>;
};

const UserProfile = ({ id }) => {
    const { data: user, isLoading, error } = useGetOne('users', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <br></br>
                    Nome: {user.nome}
                    <br></br>
                    Cognome: {user.cognome}
                    <br></br>
                    Email: {user.email}
                    <br></br>
                    Telefono: {user.telefono}
                    <br></br>
                    Codice fiscale: {user.codiceFiscale}
                    <br></br>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                        <HomeIcon />
                        Indirizzo:  {user.indirizzo}
                    </Box>
                </Typography>
            </CardContent>
        </Card>
    </div>;
};
/*  */

/* Utente VENDITORE - Recupero dettgli di una singola occorrenza */
const DettagliVenditore = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span><VenditoreProfile id={record.venditoriId}></VenditoreProfile></span>;
};

const VenditoreProfile = ({ id }) => {
    const { data: user, isLoading, error } = useGetOne('users', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <br></br>
                    Nome: {user.nome}
                    <br></br>
                    Cognome: {user.cognome}
                    <br></br>
                    Email: {user.email}
                    <br></br>
                    Telefono: {user.telefono}
                    <br></br>
                    Codice fiscale: {user.codiceFiscale}
                    <br></br>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                        <HomeIcon />
                        Indirizzo:  {user.indirizzo}
                    </Box>
                </Typography>
            </CardContent>
        </Card>
    </div>;
};
/*  */

/* BLOCCO ESCURSIONE - START */
/* Escursione - Recupero dettgli di una singola occorrenza */
const DettagliIstanzaEscursione = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span><IstanzaEscursioneProfile id={record.istanzeEscursioniId}></IstanzaEscursioneProfile></span>;
};

/* ISTANZA ESCURSIONE */
const IstanzaEscursioneProfile = ({ id }) => {
    const { data: istanzaEscursione, isLoading, error } = useGetOne('istanze-escursioni', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const date = new Date(istanzaEscursione.data);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('it-IT', options);
    const formattedTime = date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
    const formattedDateTime = `${formattedDate}, ${formattedTime}`;

    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <EventIcon sx={{ fontSize: 40, color: 'primary.main', verticalAlign: 'middle', color: '#01b5d0' }} />
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                                        <strong>{formattedDate}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    {/* <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Grid item>
                                <EventIcon sx={{ fontSize: 40, color: 'primary.main', verticalAlign: 'middle', color: '#01b5d0' }} />
                            </Grid>
                            <Grid item>
                                <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                                    <strong>{istanzaEscursione.dataOraInizio}</strong>
                                </Typography>
                                <hr></hr>
                                <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                                    <strong>{istanzaEscursione.dataOraFine}</strong>
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card> */}


                    <EscursioneProfile id={istanzaEscursione.escursioniId}></EscursioneProfile>
                </Typography>
            </CardContent>
        </Card>
    </div>;
};

/* ESCURSIONE  */
const EscursioneProfile = ({ id }) => {
    const { data: escursione, isLoading, error } = useGetOne('escursioni', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <DurataEscursione durataId={escursione.durataId}></DurataEscursione>
                </Typography>
            </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <ImmagineEscursione escursioniId={escursione.id}></ImmagineEscursione>
                </Typography>
            </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <DettagliEscursioneLingue idEscursione={escursione.id}></DettagliEscursioneLingue>
                </Typography>
            </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <DettagliEscursioneInclusoLingue idEscursione={escursione.id}></DettagliEscursioneInclusoLingue>
                </Typography>
            </CardContent>
        </Card>
    </div>;
};

/* Escursione - Recupero foto */
const ImmagineEscursione = ({ escursioniId }) => {

    const { data, isLoading, error } = useGetList(
        'galleriaescursioni',
        {
            pagination: false,
            filter: { escursioniId: escursioniId, copertina: 1 },
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {data.map(risultato => (
                <Card sx={{ minWidth: 275 }}>
                    <CardMedia
                        component="img"
                        height="275"
                        image={risultato.urlFoto}
                        alt="Immagine copertina"
                    />
                    <CardContent>
                        {risultato.copetina}
                    </CardContent>
                </Card>

            ))}
        </ul>
    );
};

/* Escursione - Recupero durata */
const DurataEscursione = ({ durataId }) => {

    const { data, isLoading, error } = useGetList(
        'durata',
        {
            pagination: false,
            filter: { id: durataId },
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {data.map(risultato => (
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <UpdateIcon sx={{ fontSize: 40, color: 'primary.main', verticalAlign: 'middle', color: '#01b5d0' }} />
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontSize: 30, color: 'primary.main', fontWeight: 'bold' }}>
                            Durata: {risultato.descrizione}
                        </Typography>
                    </Grid>
                </Grid>

            ))}
        </ul>
    );
};

/* Escursione - Recupero i dettagli della lingua italiana */
const DettagliEscursioneLingue = ({ idEscursione }) => {
    /*     const record = useRecordContext();
        if (!record) return null; */
    return <span><EscursioneLingueProfile id={idEscursione}></EscursioneLingueProfile></span>;
};

const EscursioneLingueProfile = ({ id }) => {
    // fetch all comments related to the current record
    const { data, isLoading, error } = useGetManyReference(
        'escursioni-lingue',
        {
            target: 'escursioniId',
            id: id,
            filter: { lingueId: 1 }
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {data.map(risultato => (
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <h3>{risultato.titolo}</h3>
                            <h4>{risultato.short_title}</h4>
                            <div dangerouslySetInnerHTML={{ __html: risultato.descrizione }} />
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </ul>
    );
};
/*  */

/* Escursione - COSA è INCLUSO - Recupero i dettagli della lingua italiana */
const DettagliEscursioneInclusoLingue = ({ idEscursione }) => {
    /*     const record = useRecordContext();
        if (!record) return null; */
    return <span><EscursioneLingueInclusoProfile id={idEscursione}></EscursioneLingueInclusoProfile></span>;
};

const EscursioneLingueInclusoProfile = ({ id }) => {
    // fetch all comments related to the current record
    const { data, isLoading, error } = useGetManyReference(
        'escursioni-lingue',
        {
            target: 'escursioniId',
            id: id,
            filter: { lingueId: 1 }
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {data.map(risultato => (
                <Card key={risultato.id} sx={{ minWidth: 275, mb: 2, mt: 2, mx: 'auto' }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <strong>Cosa è incluso</strong>
                            <div dangerouslySetInnerHTML={{ __html: risultato.incluso }} />
                        </Typography>
                        <Divider variant="middle" sx={{ my: 2 }} />
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <strong>Cosa non è incluso</strong>
                            <div dangerouslySetInnerHTML={{ __html: risultato.nonIncluso }} />
                        </Typography>
                        <Divider variant="middle" sx={{ my: 2 }} />
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <strong>Porta con te</strong>
                            <div dangerouslySetInnerHTML={{ __html: risultato.cosaPortare }} />
                        </Typography>
                        <Divider variant="middle" sx={{ my: 2 }} />
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <strong>Informazioni utili</strong>
                            <div dangerouslySetInnerHTML={{ __html: risultato.altro }} />
                        </Typography>
                        <Divider variant="middle" sx={{ my: 2 }} />
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            <strong>Termini di cancellazione</strong>
                            <div dangerouslySetInnerHTML={{ __html: risultato.cancellazione }} />
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </ul>
    );
};
/*  */

/* Blocco relativo al recupero dei dati dei servizi aggiuntivi */
const ListaOridniIstanzeServiziAggiuntivi = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span><OridniIstanzeServiziAggiuntivi id={record.id}></OridniIstanzeServiziAggiuntivi></span>;
};

const OridniIstanzeServiziAggiuntivi = ({ id }) => {
    // fetch all comments related to the current record
    const { data, isLoading, error } = useGetManyReference(
        'ordini-istanze-servizi-aggiuntivi',
        {
            target: 'ordiniId',
            id: id
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <ul>{/* <h4>ordini-istanze-servizi-aggiuntivi</h4> */}
            {data.map(risultato => (
                <div>
                    <InfoServizioAggiuntivo id={risultato.id}></InfoServizioAggiuntivo>
                </div>
            ))}
        </ul>
    );
};

const InfoServizioAggiuntivo = ({ id }) => {
    const { data: servizi, isLoading, error } = useGetOne('servizi-aggiuntivi-lingue', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {/* {servizi.id}  */}
                    <strong>{servizi.nome}</strong>
                </Typography>
            </CardContent>
        </Card>
    </div>;
};

/* BLOCCO ESCURSIONE - STOP */

const ListActions = () => (
    <TopToolbar>
        <ExportButton label="Esporta" sx={exportButtonCss} />
        <FilterButton sx={filterButtonCss} />

    </TopToolbar>
);

export const OrdiniList = props => {

    return (
        <List
            title={<>
                <Button>
                    &nbsp;LISTA ORDINI
                </Button>
            </>}
            actions={<ListActions />}
            filters={ordiniFilters}
            empty={<EmptyListCustomNoCreate titolo="Lista ordini"></EmptyListCustomNoCreate>}
            {...props}>
            <Datagrid noDelete
                bulkActionButtons={null}
                expand={<OrdiniExpand />}
                sx={listCss}
            >
                {<TextField label="Id" source="id" />}
                {<TextField label="Status" source="status" />}
                {<DateField label="Data Prenotazione" source="dataPrenotazione" showTime options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }}></DateField>}

                {/* <ReferenceField label="istanzeEscursioniId" source="istanzeEscursioniId" reference="istanze-escursioni"> */}
                {/* <FunctionField render={record => record && `${record.escursioniId}`} /> */}
                {/*  <FunctionField render={record => record && (
                        <ReferenceField label="TEST" source={1} reference="escursioni">
                            <FunctionField render={record => record && `${record.id}`} />
                        </ReferenceField>
                    )} /> */}
                {/* {<FunctionField render={record => record &&  (<EscursioniLingueDetails escursioniId={record.escursioniId}></EscursioniLingueDetails>)} />} */}
                {/* </ReferenceField> */}

                {/* <ReferenceField label="TEST" source="id" reference="escursioni"> */}
                {/* <FunctionField render={record => record && `${record.escursioniId}`} /> */}
                {/* {<FunctionField render={record => record &&  (<EscursioniLingueDetails record={record}></EscursioniLingueDetails>)} />} */}
                {/* </ReferenceField> */}

                {/* https://stackoverflow.com/questions/51747361/nested-reference-field */}
                {/* <ReferenceField label="istanzeEscursioniId" source="istanzeEscursioniId" reference="istanze-escursioni"> */}
                {/* <SubReference source="escursioniId" reference="escursioni-lingue"> */}
                {/* <TextField source="titolo" link={false} /> */}
                {/* <TextField source="id" link={false} /> */}
                {/* </SubReference> */}
                {/* </ReferenceField> */}

                {/* {<TextField label="istanzeEscursioniId" source="istanzeEscursioniId" />} */}
                <FunctionField render={record => record && (
                    <ReferenceField label="escursioniId" source={714} reference="escursioni">
                        <FunctionField render={record => record && `${record.id}`} />
                    </ReferenceField>
                )} />
                {/* <ReferenceField
                    perPage={50}
                    source="istanzeEscursioniId"
                    reference="istanze-escursioni"
                    link={null}
                >
                    <FunctionField
                        source="titoloricerca"
                        render={record => {
                            return (<Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <span>{record.id}</span>
                                </Grid>
                                <Grid item>
                                    <Button
                                        component={Link}
                                        to={`/istanze-escursioni/${record.id}/show`}
                                        style={{ minWidth: 'auto', padding: 0 }}
                                    >
                                        <VisibilityIcon style={{ color: 'inherit' }} />
                                    </Button>
                                </Grid>
                            </Grid>)
                        }}
                    />
                </ReferenceField> */}
                {<ReferenceField textAlign="left" link={false} label="Partecipante" source="userId" reference="users" >
                    <FunctionField render={record => record &&
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <Avatar alt={`${record.nome} ${record.cognome}`} src={record.avatarUrl} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">{record.nome} {record.cognome}</Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    component={Link}
                                    to={`/users/${record.id}/show`}
                                    style={{ minWidth: 'auto', padding: 0 }}
                                >
                                    <VisibilityIcon style={{ color: 'inherit' }} />
                                </Button>
                            </Grid>
                        </Grid>} />
                </ReferenceField>}
                {<ReferenceField textAlign="left" link={false} label="Venditore" source="venditoriId" reference="users" >
                    <FunctionField render={record => record &&
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <Avatar alt={`${record.nome} ${record.cognome}`} src={record.avatarUrl} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">{record.nome} {record.cognome}</Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    component={Link}
                                    to={`/users/${record.id}/show`}
                                    style={{ minWidth: 'auto', padding: 0 }}
                                >
                                    <VisibilityIcon style={{ color: 'inherit' }} />
                                </Button>
                            </Grid>
                        </Grid>} />
                </ReferenceField>}
                {<ReferenceField textAlign="right" link={false} label="Hotel" source="hotelId" reference="hotels">
                    <FunctionField render={record => record &&

                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <Avatar alt={record.nome} src={record.urlLogo} />
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Button
                                        component={Link}
                                        to={`/hotels/${record.id}/show`}
                                        style={{ textTransform: 'none', padding: 0 }}
                                    >
                                        <Typography variant="h6">{record.nome}</Typography>
                                    </Button>
                                    <Typography variant="body2">Zona: {record.zona}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    } />
                </ReferenceField>}
                {<TextField label="Referente Contatto" source="referenteContatto" textAlign="right" />}

                {<DateField label="Data Creazione" source="createdAt" showTime options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }}></DateField>}
                {<DateField label="Data Ultima Modifica" source="updatedAt" showTime options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }}></DateField>}

                {<ShowButton sx={showButtonCss} label="Dettaglio" />}

            </Datagrid>
        </List >
    );
}

export const OrdiniCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {<TextInput label="Nome" source="nome" />}
                                <br></br>
                                {<TextInput label="Cognome" source="cognome" />}
                                <br></br>
                                {<TextInput label="Codice rivenditore" source="codiceRivenditore" />}
                                <br></br>
                                {<TextInput label="Codice fiscale" source="codiceFiscale" />}
                                <br></br>
                                <ReferenceInput abel="Hotel" source="hotelId" reference="hotels">
                                    <SelectInput optionText="nome" />
                                </ReferenceInput>
                                <br></br>
                                {<PasswordInput label="Password" source="password" />}
                                <br></br>
                                {<TextInput label="Email" source="email" />}
                                <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                <SelectInput source="roles" label="Ruolo" choices={[
                                    { id: 'venditore', name: 'venditore' },
                                    { id: 'user', name: 'cliente' },
                                ]}></SelectInput>
                                <br></br>
                                {<TextInput label="Commissione" source="commissione" />}
                                <br></br>
                                {<TextInput label="Indirizzo" source="indirizzo" />}
                                <br></br>
                                {<TextInput label="Cap" source="cap" />}
                                <br></br>
                                {<TextInput label="Telefono" source="telefono" />}
                                <br></br>
                                {<TextInput multiline source="note" />}
                                <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

export const OrdiniEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                Id Ordine <TextField source="id" label="ID Ordine" ></TextField>
                                <div>Modifica stato ordine</div>
                                <br></br>
                                <SelectInput source="status" label="Stato ordine" choices={[
                                    { id: 'Richiesta', name: 'Richiesta' },
                                    { id: 'Pagato', name: 'Pagato' },
                                    { id: 'Richiesta annullamento', name: 'Richiesta annullamento' },
                                    { id: 'Rimborsatato', name: 'Rimborsatato' }
                                ]}></SelectInput>
                                <br></br>
                                {/* {<TextInput label="Note" source="note" />} */}
                                {<TextInput label="Note variazioni" source="note_variazioni" />}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

export const OrdiniExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <div style={{ margin: '1em' }}>
                            <Typography variant="h6">Dettagli Escursione</Typography>
                            <DettagliIstanzaEscursione></DettagliIstanzaEscursione>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ margin: '1em' }}>
                            <Typography variant="h6">Dettagli Venditore</Typography>
                            {(
                                <Typography variant="body2">
                                    <DettagliVenditore></DettagliVenditore>
                                </Typography>
                            )}
                        </div>
                        <br></br>
                        <div style={{ margin: '1em' }}>
                            <Typography variant="h6">Dettagli Partecipante</Typography>
                            {(
                                <Typography variant="body2">
                                    <DettagliUtente></DettagliUtente>
                                </Typography>
                            )}
                        </div>
                        {/*                         <div style={{ margin: '1em' }}>
                            <Typography variant="h6">Servizi aggiuntivi</Typography>
                            {(
                                <Typography variant="body2">
                                    <ListaOridniIstanzeServiziAggiuntivi></ListaOridniIstanzeServiziAggiuntivi>
                                </Typography>
                            )}
                        </div> */}
                    </Grid>
                    <br></br>
                </Grid>

            </SimpleShowLayout>

        </Show>
    );
}

/* const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
); */

export const OrdiniShow = ({ id, ...props }) => {

    return (
        <Show
            title={<>
                <Button>

                    &nbsp;Dettaglio Ordine
                </Button>
                <TextField label="Id" source="id" />

            </>}
            {...props} id={id} actions={null} /* aside={<Aside />} */>
            <div style={{ margin: '1em' }}>
                <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                    <FunctionField
                        source="id"
                        label=""
                        render={record => record && (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="h5">
                                        Numero Ordine: {record.id}
                                    </Typography>
                                    <Typography variant="h5">
                                        Istanza Escursione: {record.istanzeEscursioniId}
                                    </Typography>
                                    <Typography variant="h5">
                                        Data Prenotazione: <DateField label="Data Prenotazione" source="dataPrenotazione" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }} />
                                    </Typography>
                                </Box>
                                {/*
                               TODO: servirà per il download di un unico pdf con tutto i voucher partecipanti
                               <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<DownloadIcon />}
                                        href={`/#/istanze-escursioni/${record.istanzeEscursioniId}/show/elencopersone`}
                                        // target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            backgroundColor: '#00bcd4',
                                            '&:hover': {
                                                backgroundColor: '#00a0b4',
                                            },
                                        }}
                                    >
                                        Scarica Voucher
                                    </Button>
                                </Box> */}
                            </Box>
                        )}
                    />
                </Box>
            </div>
            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dati Anagrafici">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={3}>
                            <div style={{ margin: '1em' }}>
                                <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                    <Typography variant="h6">Dettaglio Venditore
                                    </Typography>
                                </Box>
                                {(
                                    <Typography variant="body2">
                                        <DettagliVenditore></DettagliVenditore>
                                    </Typography>
                                )}
                            </div>
                            <br></br>
                            <div style={{ margin: '1em' }}>
                                <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                    <Typography variant="h6">Dettaglio Cliente
                                    </Typography>
                                </Box>
                                {(
                                    <Typography variant="body2">
                                        <DettagliUtente></DettagliUtente>
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    </Grid>

                </TabbedForm.Tab>
                <TabbedForm.Tab label="Partecipanti">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        <Card sx={{ minWidth: 275, m: 2 }}>
                            <CardContent>
                                <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                    <Typography variant="h6">
                                        Partecipanti
                                    </Typography>
                                </Box>
                                <Typography variant="h5" sx={{ mb: 1.5, color: 'text.secondary' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                        <Card sx={{ minWidth: 275 }}>
                                            <CardContent>
                                                <Typography variant="h6" sx={{ mb: 1.5 }} color="text.secondary">
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                        <Person />
                                                        Adulti : <NumberField source="adult" />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                        <Person />
                                                        Bambini : <NumberField source="child" />
                                                    </Box>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                        <Person />
                                                        Infanti : <NumberField source="infant" />
                                                    </Box>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Typography>
                            </CardContent>
                        </Card>

                        <FunctionField
                            source="id"
                            label=""
                            render={record => record && <ListaPartecipanti ordiniId={record.id}></ListaPartecipanti>}
                        />


                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Dati Escursione">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div style={{ margin: '1em' }}>
                                <Typography variant="h6">Dettagli Escursione</Typography>
                                <DettagliIstanzaEscursione></DettagliIstanzaEscursione>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ mb: 2, minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }}>
                                        <Typography sx={{ mb: 1.5 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <strong> Contatta l'operatore locale in caso di necessità!</strong>
                                            </Box>
                                        </Typography>

                                        <Typography sx={{ mb: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <TextField source="referenteContatto" />
                                            </Box>
                                        </Typography>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ mb: 2, minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }}>
                                        <Typography sx={{ mb: 1.5 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <strong> Hai bisogno di aiuto?</strong>
                                            </Box>
                                        </Typography>

                                        <Typography sx={{ mb: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>

                                                <GetInformazioniAzienda></GetInformazioniAzienda>
                                            </Box>
                                        </Typography>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Punto di incontro">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <ReferenceField source="istanzeEscursioniPuntiincontroId" reference="wapp-istanze-escursioni-puntiincontro">
                                <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                    <Typography variant="h6">
                                        <Typography variant="h5" component="div" gutterBottom>
                                            Indica il punto Geografico
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">


                                            <br></br>
                                            <TextField label="Longitudine" source="longitudine" />
                                            <br />
                                            <TextField label="Latitudine" source="latitudine" />
                                            <br></br>Apri in Google Maps :
                                            {<GoogleMapsLink ></GoogleMapsLink>}
                                            <hr></hr>
                                            <FunctionField
                                                source="id"
                                                label=""
                                                render={record => {
                                                    if (!record || !record.latitudine || !record.longitudine) {
                                                        return null;
                                                    }

                                                    const url = `https://maps.google.com/maps?q=${record.latitudine},${record.longitudine}&hl=es&z=15&output=embed`;

                                                    return (
                                                        <>
                                                            <iframe
                                                                width="800"
                                                                height="200"
                                                                frameBorder="0"
                                                                scrolling="no"
                                                                marginHeight="0"
                                                                marginWidth="0"
                                                                src={url}
                                                            >
                                                            </iframe>
                                                        </>
                                                    );
                                                }}
                                            />

                                            {/*  */}
                                            {/* <iframe
                                                width="600"
                                                height="450"
                                                // style="border:0"
                                                loading="lazy"
                                                allowfullscreen
                                                referrerpolicy="no-referrer-when-downgrade"
                                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY&q=Space+Needle,Seattle+WA">
                                            </iframe> */}
                                            {/* <MapPicker defaultLocation={{ lat: 41.0523108, lng: 8.2095084 }}
                                                zoom={10}
                                                mapTypeId="roadmap"
                                                style={{ height: '200' }}
                                                apiKey='AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY'
                                            /> */}
                                            <hr></hr>

                                        </Typography>
                                    </Typography>
                                </Box>
                            </ReferenceField>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <ReferenceField source="istanzeEscursioniPuntiincontroId" reference="wapp-istanze-escursioni-puntiincontro">
                                                <ReferenceManyField reference="wapp-istanze-escursioni-puntiincontro" target="id" label="">
                                                    <Datagrid bulkActionButtons={null}>
                                                        {/* <TextField source="id" /> */}
                                                        <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                                        <TextField source="nome" />
                                                        <TextField source="descrizione" />
                                                    </Datagrid>
                                                </ReferenceManyField>
                                            </ReferenceField>
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Specifiche Pagamento">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Card sx={{ minWidth: 275, m: 2 }}>
                                <CardContent>
                                    <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                        <Typography variant="h6">
                                            <ReferenceField source="tipoPagamentoId" reference="tipo-pagamento">
                                                Tipo Pagamento: <TextField source="descrizione" />
                                            </ReferenceField>
                                        </Typography>
                                    </Box>
                                    <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                        <Typography variant="h6">
                                            <ReferenceField source="statoPagamentoId" reference="stato-pagamento">
                                                Stato Pagamento: <TextField source="descrizione" />
                                            </ReferenceField>
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <Card sx={{ minWidth: 275 }}>
                                                <CardContent>
                                                    <Typography variant="h6" sx={{ mb: 1.5 }} color="text.secondary">
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                            <PaymentIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Incassato:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <NumberField label="" source="incassato" />
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                            <EventIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Data Pagamento:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <DateField label="" source="dataPagamento" showTime options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }} />
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                            <NoteIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Riferimento Pagamento:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <TextField label="" source="riferimentoPagamento" />
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                            <NoteIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Note Pagamento:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <TextField label="" source="notePagamento" />
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                            <PaymentIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Tipo Pagamento:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <ReferenceField label="" source="tipoPagamentoId" reference="tipo-pagamento">
                                                                        <TextField source="descrizione" />
                                                                    </ReferenceField>
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                            <EventIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Data Incasso:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <DateField label="" source="dataIncasso" showTime options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }} />
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                            <EuroIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Incassato:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <NumberField label="" source="incassato" />
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                            <EuroIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Totale da Incassare:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <NumberField label="" source="totaleDaIncassare" />
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                            <EuroIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Totale Incassato:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <NumberField label="" source="totaleIncassato" />
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                                                            <EuroIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Totale Saldo:</Typography>
                                                            {<ReferenceManyField reference="pagamenti" target="ordiniId" label="">
                                                                <Datagrid bulkActionButtons={false}>
                                                                    <NumberField label="" source="totaleSaldo" />
                                                                </Datagrid>
                                                            </ReferenceManyField>}
                                                        </Box>


                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>

                <TabbedForm.Tab label="Specifiche Braintree">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Card sx={{ minWidth: 275, m: 2 }}>
                                <CardContent>
                                    <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                        <Typography variant="h6">
                                            <ReferenceOneField target="orderId" reference="payments">
                                                Transaction ID: <TextField source="transactionId" />
                                            </ReferenceOneField >
                                        </Typography>
                                    </Box>
                                    <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                        <Typography variant="h6">
                                            <ReferenceOneField target="orderId" reference="payments">
                                                Status: <TextField source="status" />
                                            </ReferenceOneField >
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <Card sx={{ minWidth: 275 }}>
                                                <CardContent>
                                                    <Typography variant="h6" sx={{ mb: 1.5 }} color="text.secondary">
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                            <EuroIcon />
                                                            <Typography variant="h6" sx={{ minWidth: 200 }}>Incassato:</Typography>
                                                            <ReferenceOneField target="orderId" reference="payments">
                                                                Amount: <TextField source="amount" />
                                                            </ReferenceOneField >
                                                        </Box>

                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Note">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>

                            <Card sx={{ minWidth: 275 }}>

                                <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                    <Typography variant="h6">
                                        <Typography variant="h6">Note</Typography>
                                    </Typography>
                                    <ModificaNote />
                                </Box>
                                <CardContent>

                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <RichTextField source="note"></RichTextField>
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }}>

                                <Box sx={{ border: '1px solid', borderColor: 'grey.500', p: 2, mb: 2 }}>
                                    <Typography variant="h6">
                                        <Typography variant="h6">Note Variazioni</Typography>
                                    </Typography>
                                    <ModificaNoteVariazioni />
                                </Box>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <RichTextField source="note_variazioni"></RichTextField>
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            </TabbedForm>
        </Show >
    );
}

/* const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
}; */


const ordiniFilters = [
    <SearchInput source="codiceRivenditore" />,
    <TextInput label="Cerca per id" source="id" />,
    <ReferenceInput label="Venditore" source="venditoriId" reference="users" allowEmpty>
        <AutocompleteInput optionText="nome" />
    </ReferenceInput>,
    <ReferenceInput label="Cliente" source="usersId" reference="users" allowEmpty>
        <AutocompleteInput source="nome" optionText="nome" />
    </ReferenceInput>
];

const Aside = () => {


    return (
        <div style={{ width: 400, margin: '1em' }}>
            {/* <Typography variant="h6">Dettagli Ordine</Typography> */}

            <Typography variant="h5">
                Data Prenotazione:<br></br> {<DateField label="Data Prenotazione" source="dataPrenotazione" showTime options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }}></DateField>}
            </Typography>

            <br></br>

            <Typography variant="h5">
                Data di creazione:<br></br>  {<DateField label="Data Creazione" source="createdAt" showTime options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }}></DateField>}

            </Typography>

            <br></br>

            <Typography variant="h5">
                Data di ultima modifica: <br></br> {<DateField label="Data Ultima Modifica" source="updatedAt" showTime options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }}></DateField>}

            </Typography>


            <br></br>

        </div>
    );
}

const ValutaBandieraLingua = ({ source }) => {
    const record = useRecordContext();
    let lingua = "";

    switch (record[source]) {
        case 1:
            lingua = "http://imgm.btechitalia.it/flags/flagita.png"
            break;
        case 2:
            lingua = "http://imgm.btechitalia.it/flags/flaguk.png"
            break;
        case 3:
            lingua = "http://imgm.btechitalia.it/flags/flagpol.png"
            break;
        case 4:
            lingua = "http://imgm.btechitalia.it/flags/flagger.png"
            break;
        case 5:
            lingua = "http://imgm.btechitalia.it/flags/flagpor.png"
            break;
        case 6:
            lingua = "http://imgm.btechitalia.it/flags/flagspa.png"
            break;
        case 7:
            lingua = "http://imgm.btechitalia.it/flags/flagfra.png"
            break;
        default:
            lingua = ""
            break;
    }


    return (
        <><div>
            <img src={lingua} width="20" height="20" />
        </div></>
    );
};
