export const createButtonCss = {
    backgroundColor: '#01b5d0',
    color: 'white',
    '&:hover': {
        backgroundColor: '#01b5d0',
    },
};

export const editButtonCss = {
    backgroundColor: '#01b5d0',
    color: 'white',
    '&:hover': {
        backgroundColor: '#01b5d0',
    },
};

export const deleteButtonCss = {
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
        backgroundColor: 'red',
    },
};


export const saveButtonCss = {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
        backgroundColor: 'green',
    },
    borderRadius: '20px',
};

export const showButtonCss = {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
        backgroundColor: 'green',
    },
};

export const exportButtonCss = {
    backgroundColor: 'orange',
    color: 'white',
    '&:hover': {
        backgroundColor: 'orange',
    },
};

export const columnsButtonCss = {
    backgroundColor: 'orange',
    color: 'white',
    '&:hover': {
        backgroundColor: 'orange',
    },
};


export const filterButtonCss = {
    backgroundColor: 'cyan',
    color: 'white',
    '&:hover': {
        backgroundColor: 'gray',
    },
};

export const showMapButtonCss = {
    backgroundColor: 'green',
    color: 'white',
    borderRadius: '8px', // Aggiunge angoli arrotondati
    '&:hover': {
        backgroundColor: '#01b5d0',
    },
};

export const uploadButtonCss = {
    display: 'inline-block',
    padding: '10px',
    backgroundColor: 'green',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '30px',
    '&:hover': {
        backgroundColor: '#004d00',
    },
};

export const starButtonCss = {
    backgroundColor: 'yellow',
    color: 'orange',
    '&:hover': {
        backgroundColor: 'yellow',
    },
};


export const chipsCss = {
    backgroundColor: 'grey',
    color: 'white',
    '&:hover': {
        backgroundColor: 'grey',
    },
};


export const linkCss = {
    // backgroundColor: 'white',
    color: 'inherit',
    '&:hover': {
        backgroundColor: 'grey',
    },
};

export const bottoGeneratoreIstanzeCss = {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
        backgroundColor: 'grey',
        color: 'green',
    },
};

export const cancelButtonCss = {
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
        backgroundColor: 'red',
    },
};

export const mapButtonCss = {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
        backgroundColor: '#01b5d0',
    },
};

export const annullaButtonCss = {
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
        backgroundColor: 'red',
    },
};