
import { IstanzeEscursioniEdit } from '../istanze-escursioni/istanze-escursioniEdit';
import { IstanzeEscursioniList } from '../istanze-escursioni/istanze-escursioniList';
import { IstanzeEscursioniShow } from '../istanze-escursioni/istanze-escursioniDetails';
import MapIcon from '@material-ui/icons/Map';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: IstanzeEscursioniList,
    edit: IstanzeEscursioniEdit,
    show: IstanzeEscursioniShow,
    icon: MapIcon
};