// Importazioni necessarie
import * as React from "react";
import {
    TextInput,
    SimpleForm,
    TextField,
    Edit,
    ReferenceInput,
    ReferenceField,
    ReferenceManyField,
    SimpleShowLayout,
    DateInput,
    useDataProvider,
    Loading,
    Error,
    required,
    localStorageStore,
    AutocompleteInput,
    useGetList,
    Datagrid,
    List,
    Filter,
    useListContext,
    FunctionField,
    useNotify,
    Show,
    useGetOne,
    RichTextField,
    NumberInput,
    SaveButton,
    Toolbar,
    FormDataConsumer,
    useRecordContext,
    SelectInput,
    ReferenceOneField
} from 'react-admin';

import { useFormContext } from 'react-hook-form';


import { useState, useEffect } from 'react';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import axios from 'axios';
import config from '../../config/config.json';

import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { RichTextInput } from "ra-input-rich-text";

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useFormState } from 'react-final-form';

// Componenti necessari per la sezione degli ordini
import OrderDialog from './selectOrders/OrderDialog';
import TransferRighiRowFormCard from "./TransferRighiRowFormCard ";
import ListaTransfer from "./ListaTransfer";
import { Box, Divider } from "@mui/material";

// Init store
const storeGenerale = localStorageStore();

// Toolbar personalizzata
export const TransferToolbar = () => (
    <Toolbar>
        <SaveButton label="Save" />
    </Toolbar>
);

// Componente principale per l'editing del Transfer
export const TransferEdit = () => {
    return (
        <Edit title={<NotaTransfer />}>
            <SimpleForm toolbar={<TransferToolbar />}>
                <SimpleShowLayout>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <DateInput source="data" label="Data Transfer" validate={[required()]} />
                                        <br />
                                        <RichTextInput fullWidth source="note" label="Note Transfer" validate={required()} />
                                        <br />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Box per riepilogo transfer */}
                        <Grid item xs={6}>
                            <ListaTransfer></ListaTransfer>
                        </Grid>
                        <Grid item xs={10}>
                            <ListaTransferRighi />
                        </Grid>
                    </Grid>
                </SimpleShowLayout>
            </SimpleForm>
        </Edit>
    );
};

// NotaTransfer componente per mostrare l'ID del transfer
const NotaTransfer = () => {
    // const record = useRecordContext();
    // return <span>Id Transfer: {record ? `"${record.id}"` : ''}</span>;
    return (<></>);
};


// Lista dei Transfer Righi
const ListaTransferRighi = () => {
    const record = useRecordContext();

    return (
        <>
            <h2>Lista automezzi</h2>

            <ReferenceManyField
                reference="transfer-righi"
                target="transferId"
                fullWidth
                perPage={10}
                sort={{ field: 'id', order: 'ASC' }}
            >
                <EditableDatagrid
                    expand={<OrdiniCaricatiExpand />}
                    bulkActionButtons={false}
                    createForm={<TransferRighiRowFormCard defaultValues={{ transferId: record["id"] }} />}
                    editForm={<TransferRighiRowFormCard defaultValues={{ transferId: record["id"] }} />}
                >
                    <>
                        <Box
                            sx={{
                                padding: 3,
                                backgroundColor: '#f5f5f5',
                                borderRadius: 2,
                                boxShadow: 1,
                                marginTop: 2
                            }}
                        >
                            <Card sx={{ marginBottom: 2, boxShadow: 3 }}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        {/* ID */}
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography variant="h6">
                                                ID: <TextField source="id" />
                                            </Typography>
                                        </Grid>

                                        {/* Descrizione Mezzo */}
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography>
                                                Descrizione Mezzo: <TextField source="descrizionemezzo" />
                                            </Typography>
                                        </Grid>

                                        {/* Nominativo Autista */}
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography>
                                                Nominativo Autista: <TextField source="nominativoautista" />
                                            </Typography>
                                        </Grid>

                                        {/* Recapito Autista */}
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography>
                                                Recapito Autista: <TextField source="recapitoautista" />
                                            </Typography>
                                        </Grid>

                                        {/* Nominativo Guida */}
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography>
                                                Nominativo Guida: <TextField source="nominativoguida" />
                                            </Typography>
                                        </Grid>

                                        {/* Capienza Mezzo */}
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography>
                                                Capienza Mezzo: <TextField source="capienzamezzo" />
                                            </Typography>
                                        </Grid>

                                        {/* Posti Disponibili */}
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography>
                                                Posti Disponibili:{' '}
                                                <ReferenceOneField
                                                    reference="wapp-transfer-posti-occupati"
                                                    target="transferRighiId"
                                                >
                                                    <FunctionField
                                                        source="postiDisponibili"
                                                        render={relatedRecord => (relatedRecord ? relatedRecord.postiDisponibili : 'N/A')}
                                                    />
                                                </ReferenceOneField>
                                            </Typography>
                                        </Grid>

                                        {/* Posti Occupati */}
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography>
                                                Posti Occupati:{' '}
                                                <ReferenceOneField
                                                    reference="wapp-transfer-posti-occupati"
                                                    target="transferRighiId"
                                                >
                                                    <FunctionField
                                                        source="postiOccupati"
                                                        render={relatedRecord => (relatedRecord ? relatedRecord.postiOccupati : 'N/A')}
                                                    />
                                                </ReferenceOneField>
                                            </Typography>
                                        </Grid>

                                        {/* Costo */}
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography>
                                                Costo: <TextField source="costo" />
                                            </Typography>
                                        </Grid>

                                        {/* Note */}
                                        <Grid item xs={12}>
                                            <Typography>
                                                Note: <TextField source="note" />
                                            </Typography>
                                        </Grid>

                                        {/* Customized Dialogs */}
                                        <Grid item xs={12}>
                                            <CustomizedDialogs record={record} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                            </Card>
                        </Box>
                    </>
                </EditableDatagrid>
            </ReferenceManyField>
        </>
    );
};

// Componente per la gestione delle finestre di dialogo personalizzate
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <Button
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    Chiudi
                </Button>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export function CustomizedDialogs(record) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const recordRighi = useRecordContext();

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Seleziona Ordini
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Lista ordini per autobus
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Cerca e seleziona gli ordini da associare.
                    </Typography>
                    <Typography gutterBottom>
                        Id transfer : {record.record.id}
                        <br />
                        recordRighiId : {recordRighi.id}
                        <br />
                        fornitoriId : {recordRighi.fornitoriId}
                        <br />
                        <OrderDialog
                            record={record.record}
                            recordRighi={recordRighi.id}
                            fornitoriId={recordRighi.fornitoriId}
                            onClose={handleClose}
                        />
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

// Componente per espandere gli ordini caricati
export const OrdiniCaricatiExpand = ({ id, ...props }) => {
    const record = useRecordContext();

    return (
        <Show {...props} id={id}>
            <SimpleShowLayout>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <div style={{ margin: '1em' }}>
                            <Typography variant="h6">Lista ordini caricati</Typography>
                            <ReferenceManyField label="Ordini Transfer Righi" reference="ordini-transfer-righi" target="transferRighiId">
                                <EditableDatagrid
                                    editForm={<OrdiniTransferRighiRowFormCard defaultValues={{ transferRighiId: record["id"] }} />}
                                    bulkActionButtons={false}
                                >
                                    <TextField source="id" />
                                    <TextField source="ordiniId" />
                                    <TextField source="transferRighiId" />
                                    <TextField source="transferId" />
                                    <TextField source="istanzeEscursioniId" />
                                    <ReferenceField label="Istanza" source="ordiniId" reference="ordini" link={false}>
                                        <ReferenceField source="istanzeEscursioniId" reference="istanze-escursioni" link={false}>
                                            <TextField source="titoloricerca" />
                                        </ReferenceField>
                                    </ReferenceField>
                                    <TextField source="fornitoriId" />
                                    <TextField source="postioccupati" />
                                    <RichTextField source="note" />
                                    <ReferenceField label="Nome" source="ordiniId" reference="ordini" link={false}>
                                        <ReferenceField source="userId" reference="users" link={false}>
                                            <FunctionField
                                                label="Nominativo"
                                                render={record => record && `${record.nome} ${record.cognome}`} />
                                        </ReferenceField>
                                    </ReferenceField>
                                    <ReferenceField label="Telefono" source="ordiniId" reference="ordini" link={false}>
                                        <ReferenceField source="userId" reference="users" link={false}>
                                            <TextField source="telefono" />
                                        </ReferenceField>
                                    </ReferenceField>
                                    <ReferenceField label="Email" source="ordiniId" reference="ordini" link={false}>
                                        <ReferenceField source="userId" reference="users" link={false}>
                                            <TextField source="email" />
                                        </ReferenceField>
                                    </ReferenceField>
                                </EditableDatagrid>
                            </ReferenceManyField>
                        </div>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

// Componente per il form dei righi degli ordini transfer
export const OrdiniTransferRighiRowFormCard = ({ transferRighiId, ...props }) => {
    return (
        <RowForm {...props}>
            <TextField source="id" />
            <TextField source="ordiniId" />
            <TextField source="transferRighiId" />
            <TextField source="transferId" />
            <TextField source="istanzeEscursioniId" />
            <TextField source="fornitoriId" />
            <NumberInput source="postioccupati" />
            <RichTextInput source="note" />
        </RowForm>
    );
};
