import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Paper, Typography, Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles((theme) => ({
    logo: {
        marginBottom: '1rem',
        width: '150px',
        height: 'auto', // mantiene le proporzioni dell'immagine
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        padding: theme.spacing(3),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        backgroundColor: '#a8dadc',
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
    },
    containerbg: {
        backgroundColor: '#a8dadc',
        backgroundSize: 'cover',
        height: '100vh',
        // display: 'flex',
        alignItems: 'center',
    },
}));

const MyLoginPage = ({ theme }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const classes = useStyles();

    const handleSubmit = e => {
        e.preventDefault();
        login({ username: email, password }).catch(() =>
            notify('Email o password non valida')
        );
    };

    return (

        <Container maxWidth="xl" className={classes.containerbg}>
            <Container component="main" maxWidth="xs" className={classes.container}>
                <Paper className={classes.paper}>
                    {/* <LockOutlinedIcon className={classes.avatar} /> */}
                    <Typography component="h1" variant="h5">
                        {/* <img src="http://localhost:3000/logo.png" alt="Logo" className={classes.logo} /> */}
                        <img src="http://rtbackoffice.btechitalia.it/logo.png" alt="Logo" className={classes.logo} />
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Accedi
                        </Button>
                    </form>
                </Paper>
                <Notification />
            </Container>
        </Container>
    );
};

export default MyLoginPage;
