
import { PortiList } from '../porti/portiList';
import DockIcon from '@mui/icons-material/Dock';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: PortiList,
    icon: DockIcon,
    recordrappresentation: "nome"
};


