import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    ReferenceField,
    AutocompleteInput,
    ReferenceInput,
    SimpleForm,
    required,
    NumberInput,
    Toolbar,
    SaveButton,
    ReferenceManyField,
    NumberField,
    DateField,
    DateInput,
    SelectArrayInput,
    Loading,
    useGetManyReference,
    SelectInput,
    useRecordContext,
    FunctionField,
    EditButton,
    BulkExportButton,
    RichTextField
} from 'react-admin';

import { EditDialog, CreateDialog } from '@react-admin/ra-form-layout';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import DirectionsBoat from '@mui/icons-material/PlaylistAdd';
import { RichTextInput } from "ra-input-rich-text";

import Typography from "@material-ui/core/Typography";

import { MyEuro } from '../shared/MyEuro';

import { ReferenceManyInput, ReferenceManyToManyInput } from '@react-admin/ra-relationships';
import EmptyListCustom from "../shared/emptyPageCustom/empryPageCustom";

const optionRenderer = choice => `${choice.nome}`;

export const TransferToolbar = () => (
    <Toolbar>
        <SaveButton label="Save" />
    </Toolbar>
);

export const DecodificaTipologia = (source) => {

    const record = useRecordContext();


    return record[source] ? <>
        {record[source]}
    </> : <></>;

}

const PostBulkActionButtons = (props) => {


    console.log(props);

    return (
        <>
            {/* <ResetViewsButton label="Reset Views" /> */}
            {/* default bulk delete action */}
            {/* <BulkDeleteButton /> */}
            <BulkExportButton></BulkExportButton>
        </>
    );
};

export const TransferList = ({ id, ...props }) => {

    /*     const record = useRecordContext();
        console.log(record); */

    return (
        <List title="Lista dei Transfer"
            hasCreate
            filters={TransferFilters}
            exporter={false}
            empty={<EmptyListCustom titolo={`Lista dei transfer`}></EmptyListCustom>}
            {...props} id={id}>
            <Datagrid
                // bulkActionButtons={<PostBulkActionButtons />}
                bulkActionButtons={false}
                noDelete
                rowClick="edit"
            >
                {<TextField source="id" label="Id transfer" />}
                {<RichTextField source="note" label="Note transfer" />}
                {/* {<TextField fullWidth source="tipoMezzo" label="Tipo" />} */}
                {/* {<DecodificaTipologia source="tipoMezzo" />} */}
                {/* <FunctionField label="Tipo" sortBy="tipoMezzo" render={record => {

                    return (
                        <>{(record.tipoMezzo === 0 ? "Mare" : "Terra")}</>
                    );

                }} /> */}
                {/* {<ReferenceField
                    label="Fornitore"
                    perPage={100}
                    source="fornitoriId"
                    reference="fornitori"
                >

                    {<TextField label="Fornitore" source="nome" />}
                </ReferenceField>} */}
                {/*                 {<ReferenceField
                    label="Porto"
                    perPage={100}
                    source="portiId"
                    reference="porti"
                >

                    {<TextField label="Porto" source="nome" />}
                </ReferenceField>} */}
                {/* {<TextField source="foto" label="Foto" />} */}
                {/* {<DirectionsBoat />} */}
                {/* {<TextField source="partecipantiMax" label="Capienza Posti" />} */}
                {/* TOLGO LA DESCRIZIONE PER VIA DELLA LUNGHEZZA DEL TESTO */}
                {/* {<TextField fullWidth source="descrizione" label="Descrizione" />} */}
                {/* {<TextField fullWidth source="nominativoReferente" label="Nominativo referente" />} */}
                {<EditButton></EditButton>}
            </Datagrid>
        </List>
    );
}

const TransferFilters = [
    <ReferenceInput alwaysOn
        source="fornitoriId"
        reference="fornitori"
        perPage={30}
        sort={{ field: 'nome', order: 'ASC' }}
    >
        <AutocompleteInput
            optionText={optionRenderer} /* optionText="titoloricerca" */
            suggestionLimit={30}
        />
    </ReferenceInput>,
    <TextInput label="Nome Motonave" source="nome" alwaysOn />,
    <SelectInput label="Tipo" source="tipoMezzo" alwaysOn choices={[
        { id: 0, name: 'Mare' },
        { id: 1, name: 'Terra' }
    ]} />,
    /*     <ReferenceInput alwaysOn
            source="portiId"
            reference="porti"
            perPage={30}
            sort={{ field: 'nome', order: 'ASC' }}
        >
            <AutocompleteInput
                optionText={optionRenderer}
                suggestionLimit={30}
            />
        </ReferenceInput>, */
    <NumberInput label="Capienza Posti Max (Posti)" source="partecipantiMax" alwaysOn />,
];

const ListaPortiMotonave = () => {

    const { data, isLoading, error } = useGetManyReference(
        'porti',
        {}
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    console.log(data);

    return (
        <ul>
            {data.map(comment => (
                <li key={comment.id}>{comment.body}</li>
            ))}
        </ul>
    );
};

export const MioSimpleForm = (props) => {

    const record = useRecordContext();
    // console.log(">>>>>> !! " + record.id);
    var id = record.id;
    var tipoMezzo = record.tipoMezzo;

    return (

        <SimpleForm toolbar={<TransferToolbar />}>
            {<ReferenceInput
                source="fornitoriId"
                reference="fornitori"
                perPage={30}
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <AutocompleteInput
                    fullWidth
                    optionText={optionRenderer} /* optionText="titoloricerca" */
                    suggestionLimit={30}
                />
            </ReferenceInput>}
            {/* {<ReferenceInput
                source="portiId"
                reference="porti"
                perPage={30}
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <AutocompleteInput
                    fullWidth
                    optionText={optionRenderer}
                    suggestionLimit={30}
                />
            </ReferenceInput>} */}
            <ReferenceManyToManyInput
                source="id"
                reference="porti"
                through="motonavi-porti"
                using="motonaviId,portiId"
            >
                <SelectArrayInput
                    label="Porti"
                    // Validation must be set on this component
                    validate={required()}
                    optionText="nome"
                    fullWidth
                />
            </ReferenceManyToManyInput>
            {<TextInput fullWidth source="nome" label="Nome Motonave" validate={required()} />}
            <Typography sx={{ mb: 1.5 }} color="text.secondary">

                {/* {<TextField fullWidth source="tipoMezzo" label="Tipo" />} */}

                {/* <DecodificaTipologia source="tipoMezzo"/> */}
                <FunctionField label="Tipo" render={record => {

                    return (
                        <><h2>{(record.tipoMezzo === 0 ? "Tipologia : Mare" : "Tipologia : Terra")}</h2></>
                    );

                }} />

            </Typography>
            {<NumberInput source="partecipantiMax" label="Capienza Posti" validate={required()} />}
            {<RichTextInput fullWidth source="descrizioneBreve" label="Descrizione breve" validate={required()} />}
            {<RichTextInput fullWidth source="descrizione" label="Descrizione" validate={required()} />}
            {<TextInput fullWidth source="nominativoReferente" label="Nominativo referente" validate={required()} />}
            {<h3>Prezzi al pubblico per {<TextField source="nome" label="Nome Motonave" />} </h3>}
            {<ReferenceManyField
                reference="tariffe"
                target="motonaviId"

                // perPage={1}
                sort={{ field: 'id', order: 'ASC' }}
            >
                <EditableDatagrid
                    bulkActionButtons={false}
                    editForm={<TariffeRowForm defaultValues={{ motonaviId: id, tipoMezzo: tipoMezzo }} />}
                    createForm={<TariffeRowForm defaultValues={{ motonaviId: id, tipoMezzo: tipoMezzo }} />}
                /* noDelete */
                >
                    {/* {<TextField label="Mese" source="mese" />} */}
                    {<DateField label="Dal" source="dataFrom" locales="it-IT" />}
                    {<DateField label="Al" source="dataTo" locales="it-IT" />}
                    {<ReferenceField
                        source="portiId"
                        reference="porti"
                    >
                        {<TextField label="Partenza" source="nome" />}
                    </ReferenceField>}
                    {<NumberField label="Tariffa Adulti" source="tarAdulti" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Tariffa Bambini" source="tarBambini" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Tariffa Infanti" source="tarInfanti" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Tariffa Esclusiva" source="tarEsclusiva" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Costi Adulti" source="cosAdulti" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Costi Bambini" source="cosBambini" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Costi Infanti" source="cosInfanti" options={{ style: 'currency', currency: 'EUR' }} />}
                    {<NumberField label="Costi Esclusiva" source="cosEsclusiva" options={{ style: 'currency', currency: 'EUR' }} />}

                </EditableDatagrid>
            </ReferenceManyField>}
        </SimpleForm>
    );
}

export const TariffeRowForm = (props) => {

    console.log(props.defaultValues);

    return (
        <RowForm {...props}>
            {<TextInput label="TransferId" source="motonaviId" disabled />}
            {/* {<TextField label="Mese" source="mese" />} */}
            {<DateInput label="Dal" source="dataFrom" locales="it-IT" />}
            {<DateInput label="Al" source="dataTo" locales="it-IT" />}
            {/*     {<ReferenceInput
                source="portoId"
                reference="motonavi-porti"
            >
                {<SelectField label="Porto" source="id" />}
            </ReferenceInput>} */}
            {/* <ListaPortiMotonave /> */}
            <ReferenceInput label="Porto" source="portiId" reference="porti">
                <SelectInput optionText="nome" />
            </ReferenceInput>
            {<NumberInput min={0} label="Tariffa Adulti" source="tarAdulti" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Tariffa Bambini" source="tarBambini" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Tariffa Infanti" source="tarInfanti" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Tariffa Esclusiva" source="tarEsclusiva" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Costi Adulti" source="cosAdulti" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Costi Bambini" source="cosBambini" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Costi Infanti" source="cosInfanti" defaultValue={0.00} validate={[required()]} />}
            {<NumberInput min={0} label="Costi Esclusiva" source="cosEsclusiva" defaultValue={0.00} validate={[required()]} />}
            {/* {<NumberInput min={2023} label="Anno" source="anno" />} */}
            {/* {<SelectInput source="mese" label="Mese" choices={[
                { id: 'gennaio', name: 'gennaio' },
                { id: 'febbraio', name: 'febbraio' },
                { id: 'marzo', name: 'marzo' },
                { id: 'aprile', name: 'aprile' },
                { id: 'maggio', name: 'maggio' },
                { id: 'giugno', name: 'giugno' },
                { id: 'luglio', name: 'luglio' },
                { id: 'agosto', name: 'agosto' },
                { id: 'settembre', name: 'settembre' },
                { id: 'ottobre', name: 'ottobre' },
                { id: 'novembre', name: 'novembre' },
                { id: 'dicembre', name: 'dicembre' },
            ]}></SelectInput> */}

        </RowForm>
    );
}
