import React, { useState, useRef } from 'react'; // Importa useRef
import { useNotify, useUpdate } from 'react-admin';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { uploadButtonCss } from '../../shared/Styles/buttonStyles';
import config from '../../../config/config.json';

const CreateImageImage = ({ macroCategorieId, refresh, url }) => {
    const notify = useNotify();
    const [update, { isLoading, error }] = useUpdate();
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null); // Ref per l'input del file

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const uploadFile = async () => {
        let formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios({
                method: 'post',
                url: `${config.production.hostUploads}/uploads/macro-categorie-cover/${macroCategorieId}`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (error) {
            console.error("Errore nell'upload del file:", error);
            notify(error.message, 'warning');
            throw error;
        }
    };

    const handleClick = async () => {
        if (!file) return; // Previene l'upload se non c'è file selezionato

        try {
            const rispostaDopoUpload = await uploadFile();
            const data = {
                imageUrl: `${config.production.hostUploads}` + rispostaDopoUpload.metadati.percorsoConEstensione,
            };

            await update('macro-categorie', { id: macroCategorieId, data: data });
            notify('Upload eseguito con successo');
            setFile(null); // Reset dello stato del file
            fileInputRef.current.value = ''; // Pulisce l'input del file
            refresh(); // Potrebbe essere spostato dentro onSuccess se necessario
        } catch (error) {
            // L'errore è già notificato in uploadFile
        }
    };

    return (
        <Box
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
            }}
        >
            <input
                ref={fileInputRef} // Usa il ref qui
                type="file"
                onChange={handleFileChange}
                style={{ display: 'block', backgroundColor: 'green', color: 'white' }}
            />
            <Button
                onClick={handleClick}
                sx={uploadButtonCss}
                variant="contained"
                color="primary"
                disabled={!file}
            >
                Esegui upload
            </Button>
        </Box>
    );
};

export default CreateImageImage;
