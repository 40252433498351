import React from 'react';
import { TextField, useNotify, useRecordContext } from 'react-admin';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const CopyToClipboardField = ({ source }) => {
    const record = useRecordContext();

    const notify = useNotify();
    if (!record) return null;
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            notify('Copiato negli appunti!', 'info');
        } catch (err) {
            notify('Errore durante la copia!', 'warning');
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField source={source} record={record} />
            <IconButton onClick={() => copyToClipboard(record[source])} aria-label="copy">
                <FileCopyIcon />
            </IconButton>
        </div>
    );
};

export default CopyToClipboardField;
