
import { MotonaviList } from '../motonavi/motonaviList';
import { MezzoShow } from '../motonavi/motonaviDetails';
import { MotonaviEdit } from '../motonavi/motonaviEdit';
import { MotonaviCreate } from '../motonavi/motonaviCreate';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: MotonaviList,
    edit: MotonaviEdit,
    show: MezzoShow,
    create: MotonaviCreate,
    icon: DirectionsBoatIcon
};