import * as React from "react";
import {
    List,
    Datagrid,
    EditButton,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    FunctionField,
    PasswordInput,
    Button,
    Confirm,
    useListContext,
    useUpdateMany,
    ReferenceManyField,
    ImageField,
    Show,
    SimpleShowLayout,
    DateField,
    SingleFieldList,
    DateInput,
    NumberInput,
    AutocompleteInput,
    useDataProvider, 
    Loading, 
    Error, 
    usePermissions, 
    useAuthProvider,
    useRecordContext,
    RichTextField,
    required,
    useGetOne,
    useGetManyReference,
    useGetMany
} from 'react-admin';

import { useState, useEffect } from 'react';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const SubReference = ({ translateChoice, children, ...props }) => (
    <ReferenceField {...props}>{children}</ReferenceField>
);

const CustomUpdatePostsButton = () => {
    const { selectedIds } = useListContext();
    const [open, setOpen] = useState(false);

    const [updateMany, { isLoading }] = useUpdateMany(
        'posts',
        { ids: selectedIds, data: { views: 0 } }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        <>
            <Button label="Cancellazione utente" onClick={handleClick} />
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Cancellazione utente"
                content="Sei sicuro di voler cancellare l'utente ?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

/* const DescrizioneComponent = () => {
    return (
        <h3>Descrizione
        </h3>
    );
}; */

const TitoloComponent = () => {
    return (
        <h3>Titolo
        </h3>
    );
};


export const IstanzeServiziAggiuntiviList = props => {

    return (
    <List exporter={false} {...props}>
                <Datagrid  /* expand={<IstanzeServiziAggiuntiviExpand />} */
      noDelete>
                {<TextField label="Id" source="id" />}
                {<DateField source="data" title="data" />}
                {<TextField source="serviziAggiuntiviId" label="serviziAggiuntiviId" />}
                {<TextField source="istanzeEscursioniId" label="istanzeEscursioniId"/>}
                {<TextField label="Tariffa Adulti" source="costoAdult" />}
                {<TextField label="Tariffa Infanti" source="costoInfant" />}
                {<TextField label="Tariffa Bambini" source="costoChild" />}
                <EditButton></EditButton>
                </Datagrid>
    </List>
    );
}

// const redirect = (basePath, id, data) => `/author/${data.author_id}/show`;
const redirect = () => `/istanze-escursioni`;

export const IstanzeServiziAggiuntiviCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={redirect}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <br></br>
                        {<DateInput source="data" title="Data escursione" validate={[required()]} />}
                        <br></br>
                        {<TextInput source="serviziAggiuntiviId" label="serviziAggiuntiviId" validate={[required()]}/>}
                        <br></br>
                        {<TextInput source="istanzeEscursioniId" label="istanzeEscursioniId" validate={[required()]}/>}
                        <br></br> 
                        {<NumberInput min={0} label="Tariffa Adulti" source="costoAdult" defaultValue={0}  validate={[required()]}/>}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Infanti" source="costoInfant" defaultValue={0}  validate={[required()]}/>}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Bambini" source="costoChild" defaultValue={0}  validate={[required()]}/>}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">{/* 
                        <br></br>
                        <ReferenceInput 
                            label="Servizi aggiuntivi"
                            perPage={100} 
                            source="escursioniId" 
                            reference="servizi-aggiuntivi-lingue"
                            filter={{ lingueId: 1 }}
                            sort={{ field: 'nome', order: 'ASC' }}
                            >
                        <SelectInput validate={[required()]} optionText="nome" />  
                        </ReferenceInput> */}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);

export const IstanzeServiziAggiuntiviEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect={redirect}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <br></br>
                        {<DateInput source="data" title="Data escursione" validate={[required()]} />}
                        <br></br>
                        {<TextInput source="serviziAggiuntiviId" label="serviziAggiuntiviId" validate={[required()]}/>}
                        <br></br>
                        {<TextInput source="istanzeEscursioniId" label="istanzeEscursioniId" validate={[required()]}/>}
                        <br></br> 
                        {<NumberInput min={0} label="Tariffa Adulti" source="costoAdult" defaultValue={0}  validate={[required()]}/>}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Infanti" source="costoInfant" defaultValue={0}  validate={[required()]}/>}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Bambini" source="costoChild" defaultValue={0}  validate={[required()]}/>}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">{/* 
                        <br></br>
                        <ReferenceInput 
                            label="Servizi aggiuntivi"
                            perPage={100} 
                            source="escursioniId" 
                            reference="servizi-aggiuntivi-lingue"
                            filter={{ lingueId: 1 }}
                            sort={{ field: 'nome', order: 'ASC' }}
                            >
                        <SelectInput validate={[required()]} optionText="nome" />  
                        </ReferenceInput> */}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

// Verifica della Checklist
const CercaTitolo = ({ id, testo }) => {
    
    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('serviziitvista', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);
  
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;
  
    return (
        <p><h3>{testo}</h3>
        <h3>{risultato.nome}</h3></p>
    )
  }

  const CercaDescrizione = ({ id, testo }) => {
    
    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('serviziitvista', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);
  
    if (loading) return <Loading />;
    if (error) return <Error />; 
    if (!id) return null;
  
    return (
        <p><h3>{testo}</h3>
            {risultato.nome}
            {/* <RichTextField source={record[id].descrizione} ></RichTextField> */}
            </p>
    )
  }

/* 

                    label="Servizi aggiuntivi"
                    reference="servizi-aggiuntivi"
                    target="istanzeEscursioniId"
*/

const GetTitoloEscursione = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span><CercaServizioAggiuntivo id={record.id}></CercaServizioAggiuntivo></span>;
};

const CercaServizioAggiuntivo = ({ id }) => {
    const { data: servizi, isLoading, error } = useGetOne('servizi-aggiuntivi-lingue', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
                    <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                       {servizi.nome}
                        </Typography>
                    </CardContent>
                    </Card>
           </div>;
};

const CercaIstanzaServizioAggiuntivo = ({ id }) => {
    const { data: servizi, isLoading, error } = useGetOne('istanze-servizi-aggiuntivi', { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>
                    <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                       {servizi.nome}
                        </Typography>
                    </CardContent>
                    </Card>
           </div>;
};


/* INIZIO */
/* const DettagliIstanzeServiziAggiuntivi = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span><IstanzeServiziAggiuntiviProfile id={record.id}></IstanzeServiziAggiuntiviProfile></span>;
};

const IstanzeServiziAggiuntiviProfile = ({ id }) => {
    const { data, isLoading, error } = useGetMany(
        'istanze-servizi-aggiuntivi',
        { ids: [1, 2, 3] }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
         <ul>
             {data.map(tag => (
                 <li key={tag.id}>{tag.id}</li>
             ))}
         </ul>
     );
}; */

/* FINE */

export const IstanzeServiziAggiuntiviExpand = ({ id, ...props }) => { 

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>
            
            <SimpleShowLayout>


            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                {/* <TextField source="id" label="Id"></TextField> */}
                {/* Cerco in : istanze_servizi_aggiuntivi che possiede istanzeEscursioniId  */}
                {/* <DettagliIstanzeServiziAggiuntivi></DettagliIstanzeServiziAggiuntivi> */}
                </Grid>
                <Grid item xs={6}>
                <CercaTitolo id="id" testo="Titolo"></CercaTitolo>
                </Grid>
                <Grid item xs={6}>
                <CercaDescrizione id="id" testo="Descrizione"></CercaDescrizione>
                {/* <h2>Descrizione</h2>
                {<ReferenceManyField
                    reference="escursioni-lingue"
                    target="escursioniId"
                    
                    perPage={1}
                    sort={{ field: 'id', order: 'ASC' }}
                >
           
                    
                    
                    <SingleFieldList linkType={false}>
                    <TextField  label="Descrizione" source="descrizione" />
                </SingleFieldList>
                   
                </ReferenceManyField>  }   */}   
                </Grid>
                <br></br>
            </Grid>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6}>            
                </Grid>
            </Grid>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                
                </Grid>
            </Grid>
            </SimpleShowLayout>
           
        </Show>
    );
}