
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    TopToolbar,
    ExportButton,
    ShowButton
} from 'react-admin';

// Icone
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Button from '@mui/material/Button';

// Importa CSS
import { listCss } from '../shared/Styles/listStyles';
import { showButtonCss, exportButtonCss } from '../shared/Styles/buttonStyles';

const ListActions = () => (
    <TopToolbar>

        <ExportButton label="Esporta" sx={exportButtonCss} />


    </TopToolbar>
);

export const LogsPaymentsList = props => (
    <List
        title={<>
            <Button>
                <ReceiptLongIcon></ReceiptLongIcon>
                &nbsp;Logs Transazioni
            </Button>
        </>}
        sx={listCss}
        perPage={50}
        actions={<ListActions />}
        filterDefaultValues={{ id: { $ne: 0 } }}

        {...props}>
        <Datagrid bulkActionButtons={false}>
            <NumberField source="id" />
            <TextField source="level" />
            <TextField source="message" />
            <TextField source="data" />
            <NumberField source="paymentId" />
            <NumberField source="userId" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <ShowButton sx={showButtonCss} label="Dettaglio" />
        </Datagrid>
    </List>
);
