import React, { useState, useEffect } from 'react';
import {
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button
} from '@mui/material';
import { useUpdate, useRecordContext, TimeInput } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import { editButtonCss, saveButtonCss, cancelButtonCss } from "../shared/Styles/buttonStyles";

const ModificaOraInizio = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [newValue, setNewValue] = useState("");
    const [update, { loading, error }] = useUpdate();

    useEffect(() => {
        if (record) {
            console.log(record);
            setNewValue(record.dataOraInizio ?? "");
        }
    }, [record]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        update('istanze-escursioni',
            { id: record.id, data: { dataOraInizio: newValue }, previousData: record },
            {
                onSuccess: ({ data }) => {
                    setOpen(false);
                    // Se vuoi aggiornare il record nel genitore, potresti dover chiamare una funzione di refresh qui
                },
                onFailure: (error) => {
                    // Gestisci l'errore
                    console.error(error);
                }
            }
        );
    };

    return (
        <>
            <IconButton aria-label="Modifica" sx={editButtonCss} onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modifica Ora Inizio</DialogTitle>
                <DialogContent>
                    <TimeInput
                        // autoFocus
                        margin="dense"
                        source="dataOraInizio"
                        label="Ora Inizio"
                        fullWidth
                        value={newValue}
                        onChange={(e) => {
                            // console.log(record.data);
                            // console.log(e.target.value);
                            setNewValue(record.data + " " + e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" sx={cancelButtonCss}>
                        Annulla
                    </Button>
                    <Button onClick={handleSave} color="primary" disabled={loading} sx={saveButtonCss}>
                        Salva
                    </Button>
                </DialogActions>
                {error && <Typography color="error">{error.message}</Typography>}
            </Dialog>
        </>
    );
};

export default ModificaOraInizio;