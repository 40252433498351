import React from 'react';
import { useRecordContext } from 'react-admin';
import MapIcon from '@mui/icons-material/Map';
import { showMapButtonCss } from '../shared/Styles/buttonStyles';

const GoogleMapsLink = ({ sx }) => {
    const record = useRecordContext();

    if (!record || !record.id || !record.longitudine || !record.latitudine) {
        return null;
    }

    return (
        <a
            href={`https://www.google.com/maps/search/?api=1&query=${record.latitudine},${record.longitudine}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Apri in Google Maps"
        >
            <MapIcon sx={showMapButtonCss} />
        </a>
    );
};

export default GoogleMapsLink;
