import React from 'react';
import Box from '@mui/material/Box';
import Typography from "@material-ui/core/Typography";
import { ReferenceInput, AutocompleteInput, required } from 'react-admin';
import { formCssDropdownMenu } from "../Styles/formCss";

/**
 * Input personalizzato di autocompletamento che filtra e visualizza opzioni basate su una fonte e un riferimento.
 *
 * @param {{
 *   isFiltered: boolean,
 *   filter: string,
 *   source: string,
 *   reference: string,
 *   optionProperty: string,
 *   label: string,
 *   readOnly: boolean
 * }} props - Le props del componente.
 * @param {boolean} props.isFiltered - Indica se l'input filtra dinamicamente le opzioni.
 * @param {string} props.filter - Il filtro da applicare alle opzioni.
 * @param {string} props.source - La fonte dei dati per le opzioni.
 * @param {string} props.reference - Il riferimento all'entità da cui prelevare le opzioni.
 * @param {string} props.optionProperty - La proprietà degli oggetti da utilizzare per le opzioni.
 * @param {string} props.label - L'etichetta del campo di input.
 * @param {boolean} props.readOnly - Determina se l'input è in sola lettura.
 */
const CustomAutocompleInput = ({ isFiltered, filterValue, filterField, source, reference, optionProperty, label, readOnly, onValueChange }) => {
    const optionRenderer = choice => `${choice[optionProperty]}`;

    // Logica per gestire la selezione di un nuovo valore, ad esempio:
    const handleSelectionChange = (newValue) => {
        // Chiama la callback con il nuovo valore selezionato
        onValueChange(newValue);
    };


    return (
        <div>
            <Box display="flex" alignItems="center" sx={{ width: 700 }} >
                <Typography variant="body1" style={{ marginRight: parseInt(filterValue) }}>{label}: </Typography>

                {(readOnly) ?
                    <>
                        {((isFiltered) ? <ReferenceInput filter={{ [filterField]: parseInt(filterValue) }} perPage={100} source={source} reference={reference} validate={[required()]}>
                            <AutocompleteInput
                                readOnly
                                fullWidth
                                label={label}
                                optionText={optionRenderer}
                                suggestionLimit={100}
                                sx={formCssDropdownMenu}
                            />
                        </ReferenceInput>
                            : <ReferenceInput perPage={100} source={source} reference={reference} validate={[required()]}>
                                <AutocompleteInput
                                    readOnly
                                    fullWidth
                                    label={label}
                                    optionText={optionRenderer}
                                    suggestionLimit={100}
                                    sx={formCssDropdownMenu}
                                />
                            </ReferenceInput>)}
                    </>
                    :
                    <>
                        {((isFiltered) ? <ReferenceInput filter={{ [filterField]: parseInt(filterValue) }} perPage={100} source={source} reference={reference} validate={[required()]}>
                            <AutocompleteInput
                                fullWidth
                                label={label}
                                optionText={optionRenderer}
                                suggestionLimit={100}
                                sx={formCssDropdownMenu}
                                // onChange={handleSelectionChange}
                                source="puntiincontroId"
                            />
                        </ReferenceInput>
                            : <ReferenceInput perPage={100} source={source} reference={reference} validate={[required()]} >
                                <AutocompleteInput
                                    fullWidth
                                    label={label}
                                    optionText={optionRenderer}
                                    suggestionLimit={100}
                                    sx={formCssDropdownMenu}
                                />
                            </ReferenceInput>)}
                    </>}
            </Box>
        </div>
    );
};

export default CustomAutocompleInput;
