import * as React from "react";
import {
    TextInput,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    ReferenceManyField,
    SimpleShowLayout,
    DateInput,
    NumberInput,
    useDataProvider,
    Loading,
    Error,
    required,
    localStorageStore,
    DateTimeInput,
    TimeInput,
    FunctionField,
    CheckboxGroupInput
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import { useState, useEffect } from 'react';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import { blue, red } from '@mui/material/colors';
import { RichTextInput } from "ra-input-rich-text";

// Init store
const storeGenerale = localStorageStore();

const IstanzaEscursioneTitle = () => {

    const record = useRecordContext();

    if (!record) return null;

    // console.log(record);
    // return <span> {record ? (record.titolo + " >") : ' '}</span>;
    return <strong> {record ? `Modifica istanza escursione - id: ${record.id}` : ''}</strong>;
};


const SetCurrentIstanzaEscursione = ({ source, data }) => {
    const record = useRecordContext();
    // storeGenerale.setItem('profile.currentIstanzaEscursione', res);
    storeGenerale.setItem('profile.currentIstanzaEscursione', 0);
    storeGenerale.setItem('profile.currentIstanzaEscursioneData', 0);
    storeGenerale.setItem('profile.currentIstanzaEscursione', record[source]);
    storeGenerale.setItem('profile.currentIstanzaEscursioneData', record[data]);
    return record ? (
        <>
            {/* {record[source]} */}</>

    ) : <>
    </>;
}

// Preleva escrizioni ita da vista escursioniitaview
const CercaTitolo = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <p><h3>{testo}</h3>
            <h3>{risultato.short_title}</h3>
            {risultato.titolo}</p>
    )
}

// Preleva escrizioni ita da vista escursioniitaview
const CercaDescrizione = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <>
            <p><h3>{testo}</h3>
                {/* {risultato.descrizione} */}
                {(risultato.descrizione) ? <div dangerouslySetInnerHTML={{ __html: risultato.descrizione }} /> : ""}
                {/* <RichTextField source={record[id].descrizione} ></RichTextField> */}
            </p></>
    )
}


const FornitoriEMotonavi = () => {

    // TODO: DA MIGLIORARE - SE VIENE SELEZIONATO UN NUOVO FORNITORE RIMANE LA LISTA DELLE NAVI DEL PRECEDENTE FORNITORE

    const [fornitoriId, setFornitoriId] = useState(null);
    const [motonaviFilter, setMotonaviFilter] = useState(null);
    const [motonaviVisible, setMotonaviVisible] = useState(true);

    function handleChangeFornitoriId(value) {
        setMotonaviFilter("");
        setMotonaviFilter(value);
        setMotonaviVisible(true);
    }

    useEffect(() => {
        if (fornitoriId) {
            console.log(fornitoriId);
            setMotonaviFilter({ fornitoriId });
        } else {
            setMotonaviFilter({});
        }
    }, [fornitoriId]);

    return (
        <>
            <ReferenceInput
                perPage={100}
                source="fornitoriId"
                reference="fornitori"
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <SelectInput validate={[required()]} optionText="nome"
                    onChange={(event, key, newValue) => {
                        // console.log(event.target.value);
                        // setFornitoriId(newValue);
                        handleChangeFornitoriId(event.target.value)
                    }}

                />
            </ReferenceInput>


            {motonaviVisible && (
                <>
                    <br />
                    <ReferenceInput
                        perPage={100}
                        source="motonaviId"
                        reference="motonavi"
                        label="Mezzo"
                        filter={{ fornitoriId: motonaviFilter }}
                        sort={{ field: 'nome', order: 'ASC' }}
                    >
                        <SelectInput
                            resettable
                            validate={[required()]} optionText="nome" />
                    </ReferenceInput>
                </>
            )}
        </>
    );
};

export const ServiziAggiuntiviRowFormCard = ({ istanzeEscursioniId, serviziAggiuntiviId, ...props }) => {

    // console.log(storeGenerale.getItem('profile.currentIstanzaEscursione'));;
    const idRecordIstanzeEscursioni = storeGenerale.getItem('profile.currentIstanzaEscursione');
    const dataRecordIstanzeEscursioni = storeGenerale.getItem('profile.currentIstanzaEscursioneData');
    return (
        <RowForm {...props}>
            <Card mx={{ maxWidth: 345 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            S
                        </Avatar>
                    }
                    title="MODIFICA SERVIZIO AGGIUNTIVO"
                // subheader={new Date(record.data).toLocaleDateString("it-IT", options)}
                />

                {/*                         <CardMedia
                            component="img"
                            height="194"
                            image="PATH IMG SERVIZIO AGGIUNTIVO"
                            alt="TESTO ALTERNATIVO"
                        /> */}
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {<ReferenceInput
                            perPage={100}
                            source="serviziAggiuntiviId"
                            reference="servizi-aggiuntivi"
                        // filter={{ lingueId: 1 }}
                        // sort={{ field: 'titoloRicerca', order: 'ASC' }}
                        >
                            <SelectInput validate={[required()]} optionText="titoloricerca" />
                        </ReferenceInput>}
                        {<RichTextInput source="note" title="Note" defaultValue={""} /* validate={[required()]} */ />}
                        <br></br>
                        <br></br>
                        {<DateInput disabled source="data" title="Data escursione" defaultValue={dataRecordIstanzeEscursioni} validate={[required()]} />}
                        <br></br>
                        {<TextInput disabled source="istanzeEscursioniId" label="istanzeEscursioniId" defaultValue={idRecordIstanzeEscursioni} validate={[required()]} />}
                        <br></br>
                        {<TextInput disabled source="serviziAggiuntiviId" label="serviziAggiuntiviId" defaultValue={serviziAggiuntiviId} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Adulti" source="costoAdult" defaultValue={0} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Infanti" source="costoInfant" defaultValue={0} validate={[required()]} />}
                        <br></br>
                        {<NumberInput min={0} label="Tariffa Bambini" source="costoChild" defaultValue={0} validate={[required()]} />}
                    </Typography>
                </CardContent>
            </Card>

        </RowForm>
    );
}

const FormatNote = ({ source }) => {

    const record = useRecordContext();
    return record ? (
        <div dangerouslySetInnerHTML={{ __html: record[source] }} />

    ) : <>
    </>;
}

/* ---------------------------------------- INIZIO BLOCCO prese in carico */

const ListaPreseInCarico = ({ source }) => {

    const record = useRecordContext();

    return (record[source] && record[source] === 1) ? <>
        <h2>Lista prese in carico</h2>

        <ReferenceManyField
            reference="prese-in-carico"
            target="istanzeEscursioniId"
            fullWidth
            perPage={10}
            sort={{ field: 'id', order: 'ASC' }}
        >

            <EditableDatagrid

                bulkActionButtons={false}
                createForm={<PresaInCaricoRowFormCard defaultValues={{ istanzeEscursioniId: record["id"] }} />}
                editForm={<PresaInCaricoRowFormCard defaultValues={{ istanzeEscursioniId: record["id"] }} />}
            >

                <GetOrario source="orarioPresaInCarico" />
                <TextField source="indirizzo" />
                <TextField source="note" />
            </EditableDatagrid>
        </ReferenceManyField>
    </>
        : <>
        </>;
}

export const PresaInCaricoRowFormCard = ({ istanzeEscursioniId, ...props }) => {
    return (
        <RowForm {...props}>
            <TimeInput options={{ format: 'HH:mm:ss' }} fullWidth label="Orario presa in carico" source="orarioPresaInCarico" validate={[required()]}></TimeInput>
            <TextInput source="indirizzo" />
            <TextInput source="note" />
        </RowForm>
    );
}

const GetOrario = ({ source }) => {

    const record = useRecordContext();

    const date = new Date(record[source]); // Inserisci la data desiderata
    const timezoneOffset = date.getTimezoneOffset(); // Calcola l'offset del fuso orario in minuti
    const hoursOffset = -Math.floor(timezoneOffset / 60); // Calcola l'offset in ore arrotondando verso il basso
    const minutesOffset = Math.abs(timezoneOffset) % 60; // Calcola i minuti di offset rimanenti

    const localHours = date.getUTCHours() + hoursOffset; // Calcola le ore locali aggiungendo l'offset in ore
    const localMinutes = date.getUTCMinutes() + minutesOffset; // Calcola i minuti locali aggiungendo l'offset in minuti

    const localTime = `${localHours}:${localMinutes < 10 ? '0' : ''}${localMinutes}`; // Formatta l'ora locale come "hh:mm"

    // console.log(localTime); // Stampa l'ora locale


    return record[source] ?
        <>{localTime}
        </>
        : <>
        </>;
}
/* ---------------------------------------- FINE BLOCCO prese in carico */

/* ---------------------------------------- INIZIO BLOCCO assegnazione hotel */

const ListaHotelsRowFormCard = () => {

    const record = useRecordContext();

    return <>
        <h2>Lista hotel abilitati alla vendita</h2>

        <ReferenceManyField
            reference="hotels-istanze-escursioni"
            target="istanzeEscursioniId"
            fullWidth
            perPage={10}
            sort={{ field: 'id', order: 'ASC' }}
        >

            <EditableDatagrid

                bulkActionButtons={false}
                createForm={<HotelsRowFormCard defaultValues={{ istanzeEscursioniId: record["id"] }} />}
                editForm={<HotelsRowFormCard defaultValues={{ istanzeEscursioniId: record["id"] }} />}
            >
                {<ReferenceField
                    source="hotelId"
                    reference="hotels"
                >
                    {<TextField label="Nome hotel" source="nome" />}
                </ReferenceField>}
                <TextField source="note" />
            </EditableDatagrid>
        </ReferenceManyField></>;
}

/*N.b: In questa funzione potrebbe servire l'inserimento dell'indirizzo oltre il nome */
const FullHotel = () => {
    const record = useRecordContext();
    return <span>{record.nome}</span>;
}

export const HotelsRowFormCard = ({ istanzeEscursioniId, ...props }) => {
    return (
        <RowForm {...props}>
            <ReferenceInput label="Hotel" source="hotelId" reference="hotels">
                <SelectInput optionText={<FullHotel></FullHotel>} />
            </ReferenceInput>
            <TextInput source="note" />
        </RowForm>
    );
}

/* ---------------------------------------- FINE BLOCCO assegnazione hotel */

export const IstanzeEscursioniEdit = ({ id, ...props }) => {
    return (
        <Edit title={<IstanzaEscursioneTitle />} {...props} id={id}>
            <SimpleForm>
                <SimpleShowLayout>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <FunctionField label="Tipo" render={record => {

                                            return (
                                                <><h2>{(record.tipoEscursione === 0 ? "Tipologia istanza: Individuali" : "Tipologia istanza: Gruppo")}</h2></>
                                            );

                                        }} />
                                        {/* Input per la data ed ora */}
                                        {<DateTimeInput source="dataOraInizio" title="Data Inizio escursione" validate={[required()]} />}
                                        <br></br>
                                        {<DateTimeInput source="dataOraFine" title="Data Fine escursione" validate={[required()]} />}
                                        <br></br>
                                        {<DateInput source="data" title="Data Giornaliera escursione" validate={[required()]} />}
                                        <br></br>
                                        <TimeInput fullWidth label="Orario inizio imbarco" source="orarioInizioImbarco" validate={[required()]}></TimeInput>
                                        <br></br>
                                        <TimeInput fullWidth label="Orario Partenza" source="orarioPartenza" validate={[required()]}></TimeInput>
                                        <br></br>
                                        <TimeInput fullWidth label="Orario Rientro" source="orarioRientro" validate={[required()]}></TimeInput>
                                        <br></br>
                                        <FornitoriEMotonavi></FornitoriEMotonavi>
                                        <br></br>
                                        {<NumberInput min={0} label="Partecipanti" source="partecipanti" validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={1} label="PartecipantiMax" source="partecipantiMax" helperText="Valore minimo 1" validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} options={{ style: 'currency', currency: 'EUR' }} label="Tariffa Adulti" source="tarAdulti" defaultValue={0} validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} label="Tariffa Infanti" source="tarInfanti" defaultValue={0} validate={[required()]} />}
                                        <br></br>
                                        {<NumberInput min={0} label="Tariffa Bambini" source="tarBambini" defaultValue={0} validate={[required()]} />}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        {<Grid item xs={6}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <CheckboxGroupInput choices={[
                                            { id: 'On', name: 'Replica istanza escursione nel tempo' }
                                        ]} source="replica"></CheckboxGroupInput>
                                        <br></br>
                                        {<DateInput source="dataReplicaDal" title="Dal" />}
                                        - {<DateInput source="dataReplicaAl" title="Al" />}
                                        <CheckboxGroupInput choices={[
                                            { id: 'LUN', name: 'Lunedì' },
                                            { id: 'MAR', name: 'Martedì' },
                                            { id: 'MER', name: 'Mercoledì' },
                                            { id: 'GIO', name: 'Giovedì' },
                                            { id: 'VEN', name: 'Venerdì' },
                                            { id: 'SAB', name: 'Sabato' },
                                            { id: 'DOM', name: 'Domenica' }
                                        ]
                                        } source="giorniSettimana"></CheckboxGroupInput>
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <br></br>
                                        <CercaTitolo id="id" testo="Titolo"></CercaTitolo>


                                        <CercaDescrizione id="id" testo="Descrizione"></CercaDescrizione>
                                        {/*                         <ReferenceInput perPage={100} source="escursioniId" reference="escursioni">
                        <SelectInput optionText="id" />
                        </ReferenceInput> */}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>}
                        <Grid item xs={10}>
                            {/* <h2>ID istanzeEscursioniId corrente</h2> */}
                            {/* <TextField source="id"></TextField> */}
                            <SetCurrentIstanzaEscursione source="id" data="data"></SetCurrentIstanzaEscursione>
                            <h2>Lista Servizi Aggiuntivi</h2>
                            {/* {<ReferenceInput
                        perPage={100}
                        source="istanze-servizi-aggiuntivi"
                        reference="escursioni"
                    >
                        <SelectInput validate={[required()]} optionText="nome" />
                    </ReferenceInput>} */}
                            <ReferenceManyField
                                reference="istanze-servizi-aggiuntivi"
                                target="istanzeEscursioniId"
                                fullWidth
                                perPage={10}
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                {/* <List filter={{ istanzeEscursioniId: 35 }}> */}
                                <EditableDatagrid /* noDelete */
                                    bulkActionButtons={false}
                                    editForm={<ServiziAggiuntiviRowFormCard defaultValues={{ serviziAggiuntiviId: id }} />}
                                    createForm={<ServiziAggiuntiviRowFormCard defaultValues={{ serviziAggiuntiviId: id }} />}

                                >
                                    {/* <TextField source="id"></TextField> */}
                                    {/* <TextField source="serviziAggiuntiviId"></TextField> */}
                                    {/* Creo una card per ogni istanza servizio aggiuntivo */}

                                    <Card mx={{ maxWidth: 345 }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                                                    S
                                                </Avatar>
                                            }
                                            title="DETTAGLIO SERVIZIO AGGIUNTIVO"
                                        // subheader={new Date(record.data).toLocaleDateString("it-IT", options)}
                                        />
                                        {/*                         <CardMedia
                            component="img"
                            height="194"
                            image="PATH IMG SERVIZIO AGGIUNTIVO"
                            alt="TESTO ALTERNATIVO"
                        /> */}
                                        <CardContent>
                                            {<ReferenceField
                                                perPage={100}
                                                source="serviziAggiuntiviId"
                                                reference="servizi-aggiuntivi"
                                            // filter={{ lingueId: 1 }}
                                            // sort={{ field: 'titoloRicerca', order: 'ASC' }}
                                            >
                                                Titolo servizio : <TextField source="titoloricerca" />
                                            </ReferenceField>}
                                            <Typography variant="body2" color="text.primary">
                                                {/* <TextField source="note"></TextField> */}
                                                <FormatNote source="note"></FormatNote>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {/* istanzeEscursioniId : <TextField source="istanzeEscursioniId"></TextField>
                                                <br></br>
                                                serviziAggiuntiviId : <TextField source="serviziAggiuntiviId"></TextField>
                                                <br></br> */}
                                                Costo Adulto : <TextField source="costoAdult"></TextField>
                                                <br></br>
                                                Costo Infante : <TextField source="costoInfant"></TextField>
                                                <br></br>
                                                Costo Child : <TextField source="costoChild"></TextField>
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                </EditableDatagrid>

                            </ReferenceManyField>

                            <ListaPreseInCarico source="tipoEscursione" ></ListaPreseInCarico>

                            <ListaHotelsRowFormCard ></ListaHotelsRowFormCard>
                            {/* <ListaServiziAggiuntivi istanzeEscursioniId="istanzeEscursioniId"></ListaServiziAggiuntivi> */}
                            {/* <ListaServiziAggiuntiviCard></ListaServiziAggiuntiviCard> */}
                        </Grid>
                    </Grid>
                </SimpleShowLayout>
            </SimpleForm>
        </Edit>
    );
}
