import * as React from "react";
import { useState } from "react";
import { useFormContext } from 'react-hook-form';
import {
    TextField,
    RichTextField,
    FunctionField,
    Show,
    BooleanField,
    useRefresh,
    usePermissions,
    TabbedForm,
    ReferenceManyField,
    useRecordContext,
    Datagrid,
    ReferenceField,
    TopToolbar,
    EditButton,
    Edit,
    ShowButton,
    SimpleForm,
    TextInput,
    BooleanInput,
    SelectInput,
    SaveButton,
    Toolbar,
    List,
    DatagridConfigurable,
    WrapperField
} from 'react-admin';

import { RichTextInput } from 'ra-input-rich-text';

import Modal from '@mui/material/Modal';
import Typography from "@material-ui/core/Typography";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Button from '@mui/material/Button';
import GestoreLogoHotel from "../hotels/GestoreLogoHotel";
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import BusinessIcon from '@mui/icons-material/Business';
import PublicIcon from '@mui/icons-material/Public';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

// Importo il css per i Bottoni
import { editButtonCss, showButtonCss, saveButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';

// Crea un link verso google maps
import GoogleMapsLink from "../shared/GoogleMapsLink";

import GetNomeCatenaAlberghiera from "../hotels/GetNomeCatenaAlberghiera";

/* Parametri di default per il componente che si occupa della posizinoe gps  */
import MapPicker from "react-google-map-picker";
const DefaultLocation = { lat: 41.0523108, lng: 8.2095084 };
const DefaultZoom = 10;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

/* TODO: da creare componente custom */

const CustomAvatarImage = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;



    return (
        <Box height="80%" width="80%" display="flex" justifyContent="left" alignItems="top">
            {record[source] ? (
                // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                <Avatar alt={label} style={{ width: '100px', height: '100px' }} src={record[source]} />
            ) : (
                <p><Avatar alt="Immagine mancante" style={{ width: '100px', height: '100px' }}><ImageNotSupportedIcon /></Avatar></p>
            )}
        </Box>
    );
};

const GestioneUsersInShowNoEdit = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<Datagrid
        bulkActionButtons={false}
    >
        <Card variant="outlined" sx={{ margin: 1, borderRadius: 2 }}>
            <CardContent>
                <ReferenceField source="usersId" reference="users" link={false}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>
                        </Avatar>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                        <Typography variant="subtitle1" component="div">
                            Nome : <strong><TextField source="nome" /></strong>
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                            Cognome : <strong><TextField source="cognome" /></strong>
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                            Email : <strong><TextField source="email" /></strong>
                        </Typography>
                    </Box>
                </ReferenceField>
            </CardContent>
        </Card>

    </Datagrid>);

}

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

export const HotelCustomDatiPage = (props) => {

    const { permissions } = usePermissions();
    const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>

                &nbsp;Dettaglio Hotel
            </Button>
        </>} actions={<ShowActions />}>
            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Accordi">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <GestoreLogoHotel refresh={refresh}></GestoreLogoHotel>
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                                            <PersonIcon />
                                            Nome: <FunctionField source="nome" render={record => record && `${record.nome}`} />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Ragione Sociale Completa: <TextField label="Ragione Sociale Completa" source="ragioneSociale" />
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Partita Iva: {<TextField label="Partita Iva" source="partitaIva" />}
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PersonIcon />
                                            Nome referente: {<TextField label="Nome referente" source="nomeReferente" />}
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Telefono: <TextField label="Telefono" source="telefono" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <PhoneIcon />
                                            Cellulare: <TextField label="Cellulare" source="cellulare" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <EmailIcon />
                                            Email: <TextField label="Email" source="email" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <HomeIcon />
                                            Indirizzo: <TextField label="Indirizzo" source="indirizzo" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <GpsFixedIcon />
                                            Zona: <TextField source="zona" label="Zona" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            Stato: <BooleanField source="statoHotel" label="Stato" />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                            <NoteIcon />
                                            Note: <RichTextField label="Note" source="note" />
                                        </Box>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <GpsFixedIcon />
                                                Longitudine: <TextField label="Longitudine" source="longitudine" />
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <GpsFixedIcon />
                                                Latitudine: <TextField label="Latitudine" source="latitudine" />
                                            </Box>
                                        </Typography>
                                        <Typography color="textSecondary" gutterBottom>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}>
                                                Visualizza su Google Maps
                                                <GoogleMapsLink />
                                            </Box>
                                        </Typography>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Users">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            {/* <Alert severity="info">Attenzione, in questo tab puoi selezionare solo user con ruolo <strong>Hotel</strong></Alert> */}
                            <ReferenceManyField
                                reference="users-hotels"
                                target="hotelsId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <GestioneUsersInShowNoEdit></GestioneUsersInShowNoEdit>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Accordi">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">In costruzione.</Alert>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            </TabbedForm>


        </Show>
    );
}


export const HotelCustomListPage = props => {

    return (
        <List title={<>
            <Button>
                &nbsp;Hotels
            </Button>
        </>}
            exporter={false}
            {...props}
            actions={null}
            perPage={100}
        >
            <DatagridConfigurable
                sx={listCss}
                bulkActionButtons={false}>
                <WrapperField label="Logo Hotel">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'primary.main' }}>
                        <CustomAvatarImage source="urlLogo" label="Logo" ></CustomAvatarImage>
                    </Box>
                </WrapperField>
                {<TextField label="Nome" source="nome" />}
                {<GetNomeCatenaAlberghiera source="catenaAlberghieraId"></GetNomeCatenaAlberghiera>}
                {<TextField label="Indirizzo" source="indirizzo" />}
                {<TextField label="Stato" source="statoHotel" />}
                {<TextField label="Zona" source="zona" />}
                <WrapperField label="Link Google Maps">
                    {<GoogleMapsLink ></GoogleMapsLink>}
                </WrapperField>
                {<RichTextField label="Note" source="note" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
            </DatagridConfigurable>
        </List>
    );
}

/* ***************** EDIT  */
const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

function ResponsiveDialog2() {

    const { setValue } = useFormContext();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    function handleChangeLocation(lat, lng) {
        setValue('latitudine', lat.toString());
        setValue('longitudine', lng.toString());
        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    return (
        <>
            <div>
                <Button onClick={handleOpen}>Apri mappa e seleziona il punto di escursione</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <label>Latitudine:</label><input type='text' value={location.lat} disabled />
                        <label>Longitudine:</label><input type='text' value={location.lng} disabled />
                        <MapPicker defaultLocation={defaultLocation}
                            zoom={zoom}
                            mapTypeId="roadmap"
                            style={{ height: '700px' }}
                            onChangeLocation={handleChangeLocation}
                            onChangeZoom={handleChangeZoom}
                            /* apiKey='AIzaSyBNi7XqlJRL4aW-GiWKA_g3ssmM3OO8kqM'  */
                            apiKey='AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY'
                        />
                    </Box>
                </Modal>
            </div>

        </>
    );
}

export const HotelCustomEditPage = (props) => (
    <Edit actions={<ShowActionsForEdit />} title="Modifica Hotel" redirect="show" {...props}>
        <SimpleForm toolbar={<MyToolbarEdit />}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275, boxShadow: 3 }}>
                        <CardContent>
                            <Typography component="div">
                                <Grid container spacing={2}>
                                    {[
                                        { icon: PersonIcon, label: 'Nome', source: 'nome', component: TextInput },
                                        { icon: BusinessIcon, label: 'Ragione Sociale Completa', source: 'ragioneSociale', component: TextInput },
                                        { icon: BusinessIcon, label: 'Partita Iva', source: 'partitaIva', component: TextInput },
                                        { icon: PersonIcon, label: 'Nome referente', source: 'nomeReferente', component: TextInput },
                                        { icon: PhoneIcon, label: 'Telefono', source: 'telefono', component: TextInput },
                                        { icon: PhoneIcon, label: 'Cellulare', source: 'cellulare', component: TextInput },
                                        { icon: EmailIcon, label: 'Email', source: 'email', component: TextInput },
                                        { icon: HomeIcon, label: 'Indirizzo', source: 'indirizzo', component: TextInput },
                                        {
                                            icon: PublicIcon, label: 'Zona', source: 'zona', component: SelectInput, choices: [
                                                { id: 'n', name: 'Nord' }, { id: 'no', name: 'Nord-Ovest' }, { id: 'ne', name: 'Nord-Est' },
                                                { id: 'e', name: 'Est' }, { id: 'o', name: 'Ovest' }, { id: 'so', name: 'Sud-Ovest' },
                                                { id: 'se', name: 'Sud-Est' }, { id: 's', name: 'Sud' },
                                            ]
                                        },
                                        { icon: ToggleOnIcon, label: 'Stato', source: 'statoHotel', component: BooleanInput },
                                        { icon: NoteIcon, label: 'Note', source: 'note', component: RichTextInput },
                                        { icon: GpsFixedIcon, label: 'Longitudine', source: 'longitudine', component: TextInput },
                                        { icon: GpsFixedIcon, label: 'Latitudine', source: 'latitudine', component: TextInput },
                                    ].map((field, index) => (
                                        <Grid item xs={12} sm={index < 8 ? 6 : 12} key={field.label}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                {React.createElement(field.icon, { sx: { color: 'action.active', mr: 1, my: 0.5, fontSize: '1.25rem' } })}
                                                {React.createElement(field.component, {
                                                    label: field.label,
                                                    source: field.source,
                                                    ...(field.choices ? { choices: field.choices } : {}),
                                                    sx: { width: '100%' },
                                                })}
                                            </Box>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                        <Box sx={{ pt: 2 }}>
                                            <ResponsiveDialog2 />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);
