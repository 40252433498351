import React from 'react';
import { /* useForm, FormProvider, */ useFormContext } from "react-hook-form"
import { useState, /* useEffect */ } from 'react';
import { useRecordContext, SimpleForm, Edit, SaveButton, Toolbar, /* useRedirect, */ useUpdate, useNotify } from 'react-admin';
import { Dialog, DialogTitle } from '@material-ui/core';
import { Button } from '@mui/material';
import CustomAutocompleInput from './CustomAutocompleInput';
import { Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

// import { useNavigate } from 'react-router-dom';
// Importo il css per i Bottoni
// import { MyToolbarEdit } from './MyToolbarEdit';// TODO: DA RIVEDERE

// Importo il css per i Bottoni
import { saveButtonCss } from '../Styles/buttonStyles';

/**
 * Toolbar per le operazioni di modifica che accetta riferimenti per salvataggi, ID del record e un percorso di navigazione.
 *
 * @param {{ referenceToSave: any, recordId: string | number, tabPath: string }} props - Le props del componente.
 * @param {any} props.referenceToSave - Riferimento usato per salvare le modifiche. Può essere una funzione o un oggetto.
 * @param {string | number} props.recordId - L'ID univoco del record da modificare.
 * @param {string} props.tabPath - Il percorso per navigare dopo aver salvato o modificato.
 */
const MyToolbarEdit = ({ referenceToSave, recordId, tabPath }) => {
    const [update] = useUpdate();
    const { getValues } = useFormContext();
    // const redirect = useRedirect();
    const notify = useNotify();

    const handleClick = e => {
        e.preventDefault(); // necessary to prevent default SaveButton submit logic
        const { id, ...data } = getValues();
        update(
            referenceToSave,
            { id, data },
            {
                onSuccess: () => {
                    // console.log("REDIRECT");
                    // redirect('show', 'escursioni', 1);
                    window.location.hash = '/' + referenceToSave + '/' + recordId + '/show/' + tabPath;
                    notify('Salvataggio eseguito. Clicca fuori dalla Dialog per chiudere la modifica', 'info');
                }
            }
        );
    };


    return (
        <Toolbar>
            <SaveButton sx={saveButtonCss} label="Salva parametri" onClick={handleClick}/* alwaysEnable */ />
        </Toolbar>
    );
}

/**
 * Campo di testo selezionabile che supporta la logica di filtraggio e selezione basata su un riferimento.
 * 
 * @param {{ 
 *   isFiltered: boolean, 
 *   filterField: string, 
 *   label: string, 
 *   source: string, 
 *   tabPath: string, 
 *   reference: string, 
 *   referenceToSave: any, 
 *   optionProperty: string 
 * }} props - Le props del componente.
 * @param {boolean} props.isFiltered - Indica se il campo applica un filtro.
 * @param {string} props.filterField - Il campo utilizzato per il filtraggio.
 * @param {string} props.label - L'etichetta visuale per il campo.
 * @param {string} props.source - La fonte dei dati per il campo.
 * @param {string} props.tabPath - Il percorso del tab associato.
 * @param {string} props.reference - Il riferimento all'entità da selezionare.
 * @param {any} props.referenceToSave - Riferimento usato per salvare la selezione.
 * @param {string} props.optionProperty - La proprietà degli oggetti da visualizzare.
 * 
 * @example
 * ```jsx
      <SelectableTextField 
         isFiltered={true} 
         filterField="fornitoriId" 
         source="mezziId" 
         label="Mezzo" 
         tabPath="fornitore" 
         reference="mezzi" 
         referenceToSave="escursioni" 
         optionProperty="nome" />
 * ```
 */
export const SelectableTextField = ({
    isFiltered,
    filterField,
    label,
    source,
    tabPath,
    reference,
    referenceToSave,
    optionProperty }) => {
    const record = useRecordContext();

    const [open, setOpen] = useState(false);

    if (!record) return null;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    return (
        <>
            {/*TODO: in button source - da verificare ed eventualmente eliminarlo */}
            <Button source={source} onClick={handleOpen} style={{ cursor: 'pointer' }} >Modifica {label}</Button>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle><EditIcon></EditIcon> Modifica {label}</DialogTitle>
                <Edit actions={null} title={<>
                    <Button>
                        &nbsp;Modifica {label}
                    </Button>
                </>}>
                    <SimpleForm
                        toolbar={<MyToolbarEdit referenceToSave={referenceToSave} recordId={record.id} tabPath={tabPath} ></MyToolbarEdit>}
                    >
                        <Divider variant="middle" />
                        <CustomAutocompleInput isFiltered={isFiltered} filterField={filterField} filterValue={record[filterField]} source={source} reference={reference} referenceToSave={referenceToSave} optionProperty={optionProperty} label={label} readOnly={false} ></CustomAutocompleInput>
                        <Divider variant="middle" />
                    </SimpleForm>
                </Edit>
            </Dialog>
        </>
    );
};
