import * as React from "react";
import {
    List,
    EditButton,
    TextField,
    Show,
    SimpleShowLayout,
    DateField,
    DateInput,
    useDataProvider,
    Loading,
    Error,
    ReferenceField,
    AutocompleteInput,
    ReferenceInput,
    Datagrid,
    TopToolbar,
    CreateButton,
    SelectInput,
    FunctionField,
    TextInput,
    ShowButton,
    WrapperField,
    usePermissions,
    ReferenceOneField
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

import Typography from "@material-ui/core/Typography";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';

// import { AccordionForm } from '@react-admin/ra-form-layout';

import { createButtonCss, linkCss, editButtonCss, showButtonCss } from "../shared/Styles/buttonStyles";
import { listCss } from '../shared/Styles/listStyles';

// Import dei componenti custom
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

// Impost Custom List Page 
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';
import EmptyListCustomNoCreate from '../shared/emptyPageCustom/empryPageCustomNoCreate';

// Verifica della Checklist
const CercaTitolo = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <p><h3>{testo}</h3>
            <h3>{risultato.short_title}</h3>
            {risultato.titolo}</p>
    )
}

const CercaDescrizione = ({ id, testo }) => {

    const dataProvider = useDataProvider();
    const [risultato, setSetCheckList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const record = useRecordContext();
    useEffect(() => {
        dataProvider.getOne('escursioniitaview', { id: record[id] })
            .then(({ data }) => {
                setSetCheckList(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!id) return null;

    return (
        <>
            <p><h3>{testo}</h3>
                {/* {risultato.descrizione} */}
                {(risultato.descrizione) ? <div dangerouslySetInnerHTML={{ __html: risultato.descrizione }} /> : ""}
                {/* <RichTextField source={record[id].descrizione} ></RichTextField> */}
            </p></>
    )
}

const optionRenderer = choice => `${choice.titoloricerca}`;
const optionRendererFornitori = choice => `${choice.nome}`;

/* const ListActions = () => (
    <TopToolbar>
        <CreateButton></CreateButton>
        <FilterButton></FilterButton>
        <ExportButton />
        <SelectColumnsButton />
    </TopToolbar>
); */
const ListActions = () => {

    const { permissions } = usePermissions();

    return (
        <TopToolbar>

            {(permissions === 'admin') && <CreateButton label="Crea" sx={createButtonCss}></CreateButton>}

        </TopToolbar>
    );
}

const GetTitoloEscursione = () => {

    const record = useRecordContext();
    if (!record) return null;

    console.log(record);

    return (
        <>
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <span>{record.titoloricerca}</span>
                </Grid>
                <Grid item>
                    <Link to={`/escursioni/${record.escursioniId}/show`}>
                        <VisibilityIcon style={linkCss} />
                    </Link>
                </Grid>
            </Grid>
        </>
    );
}


export const IstanzeEscursioniList = props => {

    const { permissions } = usePermissions();

    return (
        <List
            title={<>
                <Button>
                    &nbsp;Lista delle Istanze
                </Button>
            </>}
            empty={(permissions === 'admin')
                ? <EmptyListCustom titolo="Lista istanze escursioni"></EmptyListCustom>
                : <EmptyListCustomNoCreate titolo="Lista istanze escursioni"></EmptyListCustomNoCreate>}
            filters={(permissions === 'admin') ? IstanzeEscursioniFiltersAdmin : IstanzeEscursioniFilters}
            exporter={false}
            actions={null}/* <ListActions></ListActions> */
            perPage={100}
            sort={{ field: 'id', order: 'DESC' }}
            {...props}>
            <Datagrid
                sx={listCss}
                noDelete
                bulkActionButtons={false}

            >
                {(permissions === 'admin') && <TextField label="Id" source="id" />}
                {<DateField source="data" title="data" />}
                <FunctionField
                    source="tipoEscursione"
                    label="Tipologia"
                    render={record => {
                        if (!record || !record.tipoEscursione) return 'N/A';

                        switch (record.tipoEscursione) {
                            case 0:
                                return `NON DEFINITO (${record.tipoEscursione})`;
                            case 1:
                                return `ESCURSIONE SEMPLICE (${record.tipoEscursione})`;
                            case 2:
                                return `ESCURSIONE DI GRUPPO (${record.tipoEscursione})`;
                            default:
                                return `TIPO SCONOSCIUTO (${record.tipoEscursione})`;
                        }
                    }}
                />
                {<DateField source="dataOraInizio" showTime showDate={false}
                    options={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }} />}
                {<DateField source="dataOraFine" showTime showDate={false}
                    options={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }} />}

                {((permissions === 'admin') ? <ReferenceField
                    perPage={50}
                    source="escursioniId"
                    reference="escursioni"
                    link={null}
                >
                    <FunctionField
                        source="titoloricerca"
                        render={record => {
                            return (<Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <span>{record.titoloricerca}</span>
                                </Grid>
                                <Grid item>
                                    <Link to={`/escursioni/${record.id}/show`}>
                                        <VisibilityIcon style={linkCss} />
                                    </Link>
                                </Grid>
                            </Grid>)
                        }}
                    />
                </ReferenceField>
                    :
                    <GetTitoloEscursione></GetTitoloEscursione>)}

                {/*  <FunctionField label="Tipo" sortBy="tipoEscursione" render={record => {
                    return (
                        <><h2>{(record.tipoEscursione === 0 ? "Individuali" : "Gruppo")}</h2></>
                    );

                }} /> */}
                <FunctionField
                    source="id" // used for sorting
                    label="Dati Posti disponibili"
                    render={record => record &&

                        <Card sx={{ minWidth: 275, mb: 2 }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" justifyContent="flex-start">
                                    <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary" style={{ marginRight: '8px' }}>
                                        <strong>Partecipanti Max : </strong>
                                        <FunctionField
                                            source="partecipantiMax"
                                            label="Name"
                                            render={record => record && `${record.partecipantiMax ?? 0}`}
                                        />
                                    </Typography>
                                </Box>
                                <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                    <ReferenceOneField label="" target="istanzeEscursioniId" reference="wapp-istanze-posti-occupati">


                                        <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                            <strong>Totale Partecipanti : </strong>
                                            <TextField source="totalePartecipanti" />
                                        </Typography>
                                        <hr></hr>
                                        <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                            <strong>Adult : </strong>
                                            <TextField source="adult" />
                                        </Typography>
                                        <hr></hr>
                                        <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                            <strong>Child : </strong>
                                            <TextField source="child" />
                                        </Typography>
                                        <hr></hr>
                                        <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                            <strong>Infant : </strong>
                                            <TextField source="infant" />
                                        </Typography>
                                        <hr></hr>
                                        <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                            <strong>Posti disponibili ( ordini ) : </strong>
                                            <TextField source="postiDisponibili" />
                                        </Typography>
                                        <hr></hr>
                                        <Typography sx={{ fontSize: 24, mb: 1.5 }} color="text.secondary">
                                            <strong>Ordini count : </strong>
                                            <TextField source="numOrdini" />
                                        </Typography>


                                    </ReferenceOneField>
                                </Typography>
                            </CardContent>
                        </Card>
                    }
                />
                {<ShowButton sx={showButtonCss} label="Dettaglio" textAlign="center" />}
                {/* {<EditButton sx={editButtonCss} label="Modifica"  textAlign="center"/>} */}
                {(permissions === 'admin') && <WrapperField label="Elimina" textAlign="center">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Escursione"}
                        messaggio={"Sei sicuro di vole cancellare questa istanza ?"}
                        servizio={"istanze-escursioni"} />
                </WrapperField>}
            </Datagrid>
        </List>
    );
}


export const IstanzeEscursioniExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* <Grid item xs={6}> */}
                    {/* <TextField source="id" label="Id"></TextField> */}
                    {/* Cerco in : istanze_servizi_aggiuntivi che possiede istanzeEscursioniId  */}
                    {/* <DettagliIstanzeServiziAggiuntivi></DettagliIstanzeServiziAggiuntivi> */}
                    {/* </Grid> */}
                    <Grid item xs={6}>
                        <CercaTitolo id="id" testo="Titolo"></CercaTitolo>
                    </Grid>
                    <Grid item xs={6}>
                        <CercaDescrizione id="id" testo="Descrizione"></CercaDescrizione>
                        {/* <h2>Descrizione</h2>
                {<ReferenceManyField
                    reference="escursioni-lingue"
                    target="escursioniId"
                    
                    perPage={1}
                    sort={{ field: 'id', order: 'ASC' }}
                >
           
                    
                    
                    <SingleFieldList linkType={false}>
                    <TextField  label="Descrizione" source="descrizione" />
                </SingleFieldList>
                   
                </ReferenceManyField>  }   */}
                    </Grid>
                    <br></br>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}

const IstanzeEscursioniFiltersAdmin = [
    <TextInput label="Cerca per id" source="id" alwaysOn />,
    <SelectInput source="tipoEscursione" label="TIPOLOGIA" choices={[
        { id: '1', name: 'ESCURSIONE SEMPLICE' },
        { id: '2', name: 'ESCURSIONE DI GRUPPO' }
    ]} alwaysOn ></SelectInput>,
    <DateInput source="data" label="Seleziona la data" alwaysOn  /* defaultValue={getDataOdierna()} */ />
];

const IstanzeEscursioniFilters = [
    <SelectInput source="tipoEscursione" label="TIPOLOGIA" choices={[
        { id: '1', name: 'ESCURSIONE SEMPLICE' },
        { id: '2', name: 'ESCURSIONE DI GRUPPO' }
    ]} alwaysOn ></SelectInput>,
    <DateInput source="data" label="Seleziona la data" alwaysOn  /* defaultValue={getDataOdierna()} */ />
];

const IstanzeEscursioniFiltersOld = [
    <ReferenceInput
        source="escursioniId"
        reference="escursioni"
        perPage={30}
        sort={{ field: 'titoloricerca', order: 'ASC' }}
    >
        <AutocompleteInput
            optionText={optionRenderer} /* optionText="titoloricerca" */
            suggestionLimit={30}
        />
    </ReferenceInput>,
    <ReferenceInput
        source="fornitoriId"
        reference="fornitori"
        perPage={30}
    // sort={{ field: 'titoloricerca', order: 'ASC' }}
    >
        <AutocompleteInput
            optionText={optionRendererFornitori} /* optionText="titoloricerca" */
            suggestionLimit={30}
        />
    </ReferenceInput>,
    // <TextInput label="Titolo" source="titoloricerca" alwaysOn />,
    <DateInput source="data" label="Seleziona la data" /* defaultValue={getDataOdierna()} */ />,
    // <TextInput label="Titolo escursione" source="titoloricercaInEscursioni" alwaysOn />
    <SelectInput source="tipoEscursione" choices={[
        { id: 0, name: 'Escursioni semplici' },
        { id: 1, name: 'Escursioni di Gruppo' }
    ]} />
];
