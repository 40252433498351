
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    TopToolbar,
    ExportButton,
    ShowButton
} from 'react-admin';

// Icone
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';

// Importa CSS
import { listCss } from '../shared/Styles/listStyles';
import { showButtonCss, exportButtonCss } from '../shared/Styles/buttonStyles';

const ListActions = () => (
    <TopToolbar>

        <ExportButton label="Esporta" sx={exportButtonCss} />


    </TopToolbar>
);

export const PaymentsList = props => (
    <List
        title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Pagamenti
            </Button>
        </>}
        sx={listCss}
        perPage={50}
        actions={<ListActions />}
        filterDefaultValues={{ id: { $ne: 0 } }}
        {...props}>
        <Datagrid bulkActionButtons={false}>
            <NumberField source="id" />
            <TextField source="transactionId" />
            <TextField source="amount" />
            <TextField source="status" />
            <NumberField source="orderId" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <NumberField source="ordiniId" />
            <ShowButton sx={showButtonCss} label="Dettaglio" />
        </Datagrid>
    </List>
);
