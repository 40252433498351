
// import { LogsPaymentsEdit } from './logs_paymentsEdit';
import { LogsPaymentsList } from './logs_paymentsList';
import { LogsPaymentsShow } from './logs_paymentsShow';
// import { LogsPaymentsCreate } from './logs_paymentsCreate';
import MapIcon from '@material-ui/icons/Map';

export default {
    list: LogsPaymentsList,
    // edit: LogsPaymentsEdit,
    show: LogsPaymentsShow,
    // create: LogsPaymentsCreate,
    icon: MapIcon
};
