import React, { useState } from 'react';
import {
    useRecordContext,
    useUpdate,
    Confirm,
    useNotify,
    useRedirect,
} from 'react-admin';

import UpdateIcon from '@mui/icons-material/Update';
import Button from '@mui/material/Button';
import { editButtonCss } from '../shared/Styles/buttonStyles';

export const ConfirmDialogUpdateButtonCustom = ({ titolo, messaggio, servizio, fieldToUpdate, fieldValue, redirectPath }) => {
    const record = useRecordContext();
    const diff = { [fieldToUpdate]: fieldValue };
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();

    const [update, { isLoading }] = useUpdate();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        if (record && record.id) {
            update(servizio, { id: record.id, data: diff, previousData: record }, {
                // undoable: true, // TODO FIX: Non funziona. Abilita l'azione di annullamento
                onSuccess: () => {
                    notify('Aggiornamento eseguito.', 'info');
                    redirect(redirectPath || '/' + servizio);
                },
                onFailure: (error) => {
                    notify(`Errore: ${error.message}`, 'warning');
                }
            });
        }
        setOpen(false);
    };

    if (!record || !record.id) {
        return null;
    }

    return (
        <>
            <Button
                sx={editButtonCss}
                onClick={handleClick}
                variant="contained"
                size="small"
                endIcon={<UpdateIcon />}
                component="label"
                color="error"
            >
                Set : {titolo}
            </Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={titolo || "Elimina file"}
                content={messaggio}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                confirm="Si"
                cancel="No"
            />
        </>
    );
};
