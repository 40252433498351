import { useListContext, Title, CreateButton } from 'react-admin';

// Importo il css per i Bottoni
import { createButtonCss } from '../Styles/buttonStyles';

const EmptyListCustom = ({ titolo }) => {
    const { total } = useListContext();

    if (total === 0) {
        return (
            <div>
                <Title title="Nessun Elemento Disponibile" />
                <h1>{titolo}</h1>
                <p>Nessun Elemento Disponibile</p>
                <p><CreateButton label="Crea" sx={createButtonCss}></CreateButton>
                </p>
            </div>
        );
    }

    return null;
};

export default EmptyListCustom;