import * as React from 'react';
import { useState } from 'react';
import { DashboardMenuItem, Menu, MenuItemLink, usePermissions } from 'react-admin';

import Divider from '@mui/material/Divider';
import PeopleIcon from '@material-ui/icons/People';
import EuroIcon from '@mui/icons-material/Euro';
import MapIcon from '@material-ui/icons/Map';
import DirectionsCarSharp from '@mui/icons-material/DirectionsCarSharp';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DockIcon from '@mui/icons-material/Dock';
import CategoryIcon from '@mui/icons-material/Category';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BedIcon from '@mui/icons-material/Bed';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LandscapeIcon from '@mui/icons-material/Landscape';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PaymentsIcon from '@mui/icons-material/Payments';

import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

// import SvgIcon from '@mui/material/SvgIcon';
// import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
// import Terrain from '@mui/icons-material/Terrain';
// import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import { MultiLevelMenu, MenuItemNode, Menu  } from "@react-admin/ra-navigation";

/* Foglio stile icons */
import { iconStyle, iconSpecialStyle } from '../Styles/iconsCss';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  menuFont: {
    fontFamily: '"Times New Roman", Times, serif',
    fontWeight: 'bold',
  },
});

// TODO: crea le icone su file a parte
/* ICONE CUSTOM */
/* function CustomHotelIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M21,11.5V3h-3v2H6V3H3v8.5C2,11.5,2,20,2,20h20C22,20,21,11.5,21,11.5z M18,7h-1v1h1V7z M18,9h-1v1h1V9z M15,7h-1v1h1V7z M15,9h-1v1h1V9z M12,7H8v3h4V7z M7,7H6v1h1V7z M7,9H6v1h1V9z M20,18H4v-6h1v5h14v-5h1V18z" />
    </SvgIcon>
  );
}

function CustomCateneHotelIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M21,11.5V3h-3v2H6V3H3v8.5C2,11.5,2,20,2,20h6.5c0-2.76,2.24-5,5-5s5,2.24,5,5H22C22,20,21,11.5,21,11.5z M18,7h-1v1h1V7z M18,9h-1v1h1V9z M15,7h-1v1h1V7z M15,9h-1v1h1V9z M12,7H8v3h4V7z M7,7H6v1h1V7z M7,9H6v1h1V9z M13.5,18c-1.38,0-2.5-1.12-2.5-2.5S12.12,13,13.5,13S16,14.12,16,15.5S14.88,18,13.5,18z M8.5,13C7.12,13,6,14.12,6,15.5S7.12,18,8.5,18S11,16.88,11,15.5S9.88,13,8.5,13z" />
    </SvgIcon>
  );
} */



export const MyMenu = (props) => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  // Componente per gestire le sezioni espandibili
  const ExpandableSection = ({ title, children, icon }) => {
    const [open, setOpen] = useState(true); // Expand di default aperto quindi in true

    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <>
        <ListItem onClick={handleClick} className={classes.sectionTitle}>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </>
    );
  };

  return (
    <><Menu className={classes.menuFont}>
      <DashboardMenuItem />

      {(permissions === 'admin') &&
        <>
          <MenuItemLink name="hotels" to="/hotels" primaryText="Hotels" leftIcon={<BedIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="catenaAlberghiera" to="/catena-alberghiera" primaryText="Catene Alberghiere" leftIcon={<HomeWorkIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="tourOperator" to="/tour-operator" primaryText="Tour Operator" leftIcon={<HolidayVillageIcon style={iconStyle} />}>
          </MenuItemLink>

          <Divider></Divider>

          <ExpandableSection title="Gestione Users" icon={<PeopleIcon style={iconSpecialStyle} />}>
            <MenuItemLink name="users" to="/users/create" primaryText="Crea user" leftIcon={<AddCircleIcon style={iconStyle}></AddCircleIcon>} />
            <MenuItemLink name="users" to="/users" primaryText="Users Generale" leftIcon={<PeopleIcon style={iconStyle} />} />
            <MenuItemLink name="users" to="/users/clienti" primaryText="Users Clienti" leftIcon={<PeopleIcon style={iconStyle} />} />
            <MenuItemLink name="users" to="/users/hotels" primaryText="Users Hotels" leftIcon={<PeopleIcon style={iconStyle} />} />
            <MenuItemLink name="users" to="/users/admin" primaryText="Users Admin" leftIcon={<PeopleIcon style={iconStyle} />} />
            <MenuItemLink name="users" to="/users/touroperator" primaryText="Users Tours Ope." leftIcon={<PeopleIcon style={iconStyle} />} />
            <MenuItemLink name="users" to="/users/catenaalberghiera" primaryText="Users Catena Alb." leftIcon={<PeopleIcon style={iconStyle} />} />
          </ExpandableSection>

          <Divider></Divider>
          <MenuItemLink name="venditori" to="/venditori" primaryText="Venditori" leftIcon={<PersonOutlineIcon style={iconStyle} />}>
          </MenuItemLink>
          <Divider></Divider>

          <MenuItemLink name="ordini" to="/ordini" primaryText="Ordini" leftIcon={<EuroIcon style={iconStyle} />}>
          </MenuItemLink>

          {/*           <MenuItemLink name="payments" to="/payments" primaryText="Transazioni" leftIcon={<PaymentsIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="logs-payments" to="/logs-payments" primaryText="Logs Transazioni" leftIcon={<ReceiptLongIcon style={iconStyle} />}>
          </MenuItemLink> */}



          <Divider></Divider>

          <MenuItemLink name="escursioni" to="/escursioni" primaryText="Escursioni" leftIcon={<MapIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="istanze" to="/istanze-escursioni" primaryText="Istanze" leftIcon={<LandscapeIcon style={iconStyle} />}>
          </MenuItemLink>

          <Divider></Divider>

          <MenuItemLink name="macro-categorie" to="/macro-categorie" primaryText="Macro Categorie" leftIcon={<CategoryIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="categorie" to="/categorie" primaryText="Categorie" leftIcon={<CategoryIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="servizi" to="/servizi" primaryText="Servizi" leftIcon={<PlaylistAddIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="fornitori" to="/fornitori" primaryText="Fornitori" leftIcon={<PeopleAltIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="puntiincontro" to="/puntiincontro" primaryText="Punti d'incontro" leftIcon={<DockIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="mezzi" to="/mezzi" primaryText="Mezzi" leftIcon={<DirectionsCarSharp style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="transfer" to="/transfer" primaryText="Gestione Transfer" leftIcon={<DirectionsBusIcon style={iconStyle} />}>
          </MenuItemLink>

          <Divider></Divider>
          <MenuItemLink name="azienda" to="/azienda/1/show" primaryText="Opzioni Azienda" leftIcon={<SettingsSuggestIcon style={iconStyle} />}>
          </MenuItemLink>

        </>
      }


      {permissions === 'hotel' && (
        <>
          <MenuItemLink name="escursioni" to="/escursioni" primaryText="Escursioni" leftIcon={<MapIcon style={iconStyle} />}>
          </MenuItemLink>
          <MenuItemLink name="istanze" to="/istanze-escursioni" primaryText="Istanze Escursioni" leftIcon={<LandscapeIcon style={iconStyle} />}>
          </MenuItemLink>
          <Divider></Divider>
          <MenuItemLink name="hotels" to="/hotels" primaryText="Dati Hotel" leftIcon={<BedIcon style={iconStyle} />}>
          </MenuItemLink>
        </>
      )}

      {permissions === 'catenaalberghiera' && (
        <>
          <MenuItemLink name="escursioni" to="/escursioni" primaryText="Escursioni" leftIcon={<MapIcon style={iconStyle} />}>
          </MenuItemLink>
          <MenuItemLink name="istanze" to="/istanze-escursioni" primaryText="Istanze Escursioni" leftIcon={<LandscapeIcon style={iconStyle} />}>
          </MenuItemLink>
          <Divider></Divider>
          <MenuItemLink name="catena-alberghiera" to="/catena-alberghiera" primaryText="Dati Catena Alberghiera" leftIcon={<HomeWorkIcon style={iconStyle} />}>
          </MenuItemLink>
        </>
      )}

      {permissions === 'touroperator' && (
        <>
          <MenuItemLink name="escursioni" to="/escursioni" primaryText="Escursioni" leftIcon={<MapIcon style={iconStyle} />}>
          </MenuItemLink>
          <MenuItemLink name="istanze" to="/istanze-escursioni" primaryText="Istanze Escursioni" leftIcon={<LandscapeIcon style={iconStyle} />}>
          </MenuItemLink>
          <Divider></Divider>
          <MenuItemLink name="tour-operator" to="/tour-operator" primaryText="Dati Tour Operator" leftIcon={<HolidayVillageIcon style={iconStyle} />}>
          </MenuItemLink>
        </>
      )}

      {permissions === 'fornitore' && (
        <>
          <MenuItemLink name="escursioni" to="/escursioni" primaryText="Escursioni" leftIcon={<MapIcon style={iconStyle} />}>
          </MenuItemLink>
          <MenuItemLink name="istanze" to="/istanze-escursioni" primaryText="Istanze Escursioni" leftIcon={<LandscapeIcon style={iconStyle} />}>
          </MenuItemLink>
          <MenuItemLink name="mezzi" to="/mezzi" primaryText="Mezzi" leftIcon={<DirectionsCarSharp style={iconStyle} />}>
          </MenuItemLink>
          <MenuItemLink name="fornitori" to="/fornitori" primaryText="Dati Fornitore" leftIcon={<PeopleAltIcon style={iconStyle} />}>
          </MenuItemLink>
        </>
      )}

      {(permissions === 'venditore') &&
        <>
          <MenuItemLink name="hotels" to="/hotels" primaryText="Hotels" leftIcon={<BedIcon style={iconStyle} />}>
          </MenuItemLink>

          <Divider></Divider>

          <MenuItemLink name="ordini" to="/ordini" primaryText="Ordini" leftIcon={<EuroIcon style={iconStyle} />}>
          </MenuItemLink>

          <Divider></Divider>

          <MenuItemLink name="escursioni" to="/escursioni" primaryText="Escursioni" leftIcon={<MapIcon style={iconStyle} />}>
          </MenuItemLink>

          <MenuItemLink name="istanze" to="/istanze-escursioni" primaryText="Istanze" leftIcon={<LandscapeIcon style={iconStyle} />}>
          </MenuItemLink>

          <Divider></Divider>
        </>
      }


    </Menu></>
  );
}
