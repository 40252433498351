import axios from "axios"

import configHost from '../config/config.json';

const axiosBackendInstance = axios.create({
    baseURL: configHost.production.host,
    // baseURL: "http://192.168.170.198:3030/",
    // baseURL: "http://172.16.150.107:3030", // Ufficio Wifis
    // baseURL: "http://212.35.216.226:3030/", // TODO : PRODUZIONE
})
export default axiosBackendInstance