import { useListContext, Title } from 'react-admin';


const EmptyListCustomNoCreate = ({ titolo }) => {
    const { total } = useListContext();

    if (total === 0) {
        return (
            <div>
                <Title title="Nessun Elemento Disponibile" />
                <h1>{titolo}</h1>
                <p>Nessun Elemento Disponibile</p>
            </div>
        );
    }

    return null;
};

export default EmptyListCustomNoCreate;