import React, { useState, useCallback, useEffect } from 'react';
import {
    useRecordContext,
    useDelete,
    Confirm,
    useRedirect,
    useNotify,
    useDataProvider
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { deleteButtonCss } from '../shared/Styles/buttonStyles';

export const ConfirmDialogDeleteButtonCustom = ({ titolo, messaggio, servizio, redirectPath }) => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorInfo, setErrorInfo] = useState({ message: '', details: '' });
    const [copyTooltip, setCopyTooltip] = useState('Copia');
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleErrorDialogClose = () => setErrorDialogOpen(false);

    const handleConfirm = useCallback(async () => {
        if (record && record.id) {
            try {
                await dataProvider.delete(servizio, { id: record.id });
                notify('Elemento eliminato con successo', { type: 'success' });
                redirect(redirectPath || '/' + servizio);
            } catch (error) {
                console.log('Errore durante la cancellazione:', error);
                let message = 'Si è verificato un errore imprevisto durante la cancellazione.';
                if (error.message && error.message.includes('foreign key constraint fails')) {
                    message = 'Impossibile eliminare questo record perché è referenziato da altri dati nel sistema. Rimuovi prima le dipendenze e riprova.';
                }
                setErrorInfo({
                    message: message,
                    details: error.message || 'Nessun dettaglio disponibile'
                });
                setErrorDialogOpen(true);
            }
        }
        setOpen(false);
    }, [record, dataProvider, servizio, notify, redirect, redirectPath]);

    useEffect(() => {
        const handleCloseDialog = () => {
            document.body.style.overflow = 'unset';
        };

        if (!errorDialogOpen) {
            setTimeout(handleCloseDialog, 0);
        }

        return () => {
            handleCloseDialog();
        };
    }, [errorDialogOpen]);

    const handleCopyError = () => {
        const errorText = `${errorInfo.message}\n\nDettagli dell'errore:\n${errorInfo.details}`;
        navigator.clipboard.writeText(errorText).then(() => {
            setCopyTooltip('Copiato!');
            setTimeout(() => setCopyTooltip('Copia negli appunti'), 2000);
        });
    };

    if (!record || !record.id) {
        return null;
    }

    return (
        <>
            <Button
                sx={deleteButtonCss}
                onClick={handleClick}
                variant="contained"
                size="small"
                endIcon={<DeleteIcon />}
                component="label"
                color="error"
            >
                Elimina
            </Button>
            <Confirm
                isOpen={open}
                title={titolo || "Elimina file"}
                content={messaggio}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                confirm="Si"
                cancel="No"
            />
            <Dialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                aria-labelledby="error-dialog-title"
                aria-describedby="error-dialog-description"
                maxWidth="sm"
                fullWidth
                disableScrollLock
            >
                <DialogTitle id="error-dialog-title" style={{ color: 'red', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Errore di Cancellazione
                    <Tooltip title={copyTooltip}>
                        <IconButton onClick={handleCopyError} size="small">
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="error-dialog-description">
                        <Typography variant="body1" style={{ marginBottom: '1rem' }}>
                            {errorInfo.message}
                        </Typography>
                        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                            Dettagli dell'errore:
                        </Typography>
                        <Typography variant="body2" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                            {errorInfo.details}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleErrorDialogClose} color="primary" autoFocus>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};