import { useGetOne, Loading, useRecordContext } from 'react-admin';


const GetNomeCatenaAlberghiera = ({ source }) => {
    const record = useRecordContext();
    const { data: recordCatenaAlberghiera, isLoading, error } = useGetOne('catena-alberghiera', { id: record[source] });
    if (!record) return null;
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <>{recordCatenaAlberghiera.nome}</>;
};

export default GetNomeCatenaAlberghiera;