import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextInput,
    Create,
    SimpleForm,
    TextField,
    Edit,
    SelectInput,
    ReferenceManyField,
    ImageField,
    Show,
    SimpleShowLayout,
    RichTextField,
    FunctionField,
    useRecordContext,
    FilterList,
    FilterListItem,
    ShowButton,
    TabbedForm,
    ReferenceField,
    NumberField,
    useCreate,
    useNotify,
    useRefresh,
    Toolbar,
    SaveButton,
    useUpdate,
    DateField,
    DateInput,
    NumberInput,
    WrapperField,
    TopToolbar,
    CreateButton,
    SingleFieldList,
    ChipField,
    usePermissions
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import { ReferenceManyToManyField } from '@react-admin/ra-relationships';

import axios from 'axios';
import config from '../../config/config.json';

// Impost Custom List Page 
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';
import EmptyListCustomNoCreate from '../shared/emptyPageCustom/empryPageCustomNoCreate';

// import { EditInDialogButton, CreateInDialogButton } from '@react-admin/ra-form-layout';

// import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Chip from '@material-ui/core/Chip';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ContentFilter from '@mui/icons-material/FilterList';
import CardContent from '@mui/material/CardContent';
// import { useController } from 'react-hook-form';
// import Details from "./details";
import { Dialog, DialogTitle } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EditIcon from '@mui/icons-material/Edit';
import TopicIcon from '@mui/icons-material/Topic';
import Avatar from '@mui/material/Avatar';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import Alert from '@mui/material/Alert';
import EventIcon from '@material-ui/icons/Event';
import EuroIcon from '@mui/icons-material/Euro';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import FiberNewIcon from '@mui/icons-material/FiberNew';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


/* Dettaglio Escursione */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Importo componenti input custom
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import CustomSelectMezziPuntiincontro from '../shared/FormFields/CustomSelectMezziPuntiincontro';
import { SelectableTextField } from '../shared/FormFields/SelectableTextField';
import CopyToClipboardButton from '../shared/FormFields/CopyToClipboardButton'
import GestioneImmagini from "../escursioni/galleria-immagini/GestioneImmagini";
import GetInformazioniAzienda from "../escursioni/GetInformazioniAzienda";
// import GestioneIstanze from "../escursioni/istanze/GestioneIstanze";

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

/* 
https://www.youtube.com/watch?v=iP3DnhCUIsE
https://github.com/trulymittal/google-maps-directions-tutorial
https://www.npmjs.com/package/@react-google-maps/api

*/

// Crea un link verso google maps
import GoogleMapsLink from "../shared/GoogleMapsLink";

import { fieldAccordion,/*  fieldAccordionColor1  */ } from '../../components/shared/Styles/fieldsStyles';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import { ConfirmDialogUpdateButtonCustom } from "../shared/UpdateButtonCustom";

import { InitPuntiPartenzaButtonCustom } from "../shared/InitPuntiPartenzaButtonCustom";

// Importo il css per i Bottoni
import { listCss } from '../shared/Styles/listStyles';

/* Parametri di default per il componente che si occupa della posizinoe gps  */
import MapPicker from "react-google-map-picker";
import { linkCss, createButtonCss, deleteButtonCss, showButtonCss, chipsCss, bottoGeneratoreIstanzeCss } from "../shared/Styles/buttonStyles";
const DefaultLocation = { lat: 41.0523108, lng: 8.2095084 };
const DefaultZoom = 10;

/*  */
/* function DatePicker() {
    const [date, setDate] = useState('');

    // Funzione per gestire il cambiamento di data
    const handleChange = (event) => {
        // Formattazione della data in gg/mm/aaaa
        const formattedDate = event.target.value.split('-').reverse().join('/');
        setDate(formattedDate);
    };

    return (
        <div>
            <input
                type="date"
                onChange={handleChange}
                value={date.split('/').reverse().join('-')} // Converte la data in formato aaaa-mm-gg per l'input
            />
            <p>Data selezionata: {date}</p>
        </div>
    );
} */

/* TODO: da creare file esterno */

function parseDate(dateStr) {
    const parts = dateStr.split('/');
    return new Date(parts[2], parts[1] - 1, parts[0]);  // Anno, mese (0-based), giorno
}

function CloneExcursionTab({ record }) {
    const notify = useNotify();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const jwt = localStorage.getItem("feathers-jwt");

    const [selectedDays, setSelectedDays] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');
    const [canClone, setCanClone] = useState(false); // Stato per abilitare il pulsante di clonazione

    useEffect(() => {
        // Controlla se le date e i giorni sono validi per abilitare il pulsante
        const start = parseDate(startDate);
        const end = parseDate(endDate);
        if (selectedDays.length > 0 && start && end && start <= end) {
            setCanClone(true);
            setErrorMessage('');
        } else {
            setCanClone(false);
            if (startDate && endDate && start > end) {
                setErrorMessage("Attenzione: hai selezionato una data di inizio successiva alla data di fine.");
            }
        }
    }, [startDate, endDate, selectedDays]); // Dipendenze dell'effetto


    const daysEnum = [
        { id: 'LUN', name: 'Lunedì' },
        { id: 'MAR', name: 'Martedì' },
        { id: 'MER', name: 'Mercoledì' },
        { id: 'GIO', name: 'Giovedì' },
        { id: 'VEN', name: 'Venerdì' },
        { id: 'SAB', name: 'Sabato' },
        { id: 'DOM', name: 'Domenica' }
    ];

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        setSelectedDays(prev => {
            if (checked) {
                // Aggiunge il giorno se è selezionato
                return [...prev, id];
            } else {
                // Rimuove il giorno se non è più selezionato
                return prev.filter(day => day !== id);
            }
        });
    };


    const handleChangeStart = (event) => {
        const formattedDate = event.target.value.split('-').reverse().join('/');
        setStartDate(formattedDate);
    };

    const handleChangeEnd = (event) => {
        const formattedDate = event.target.value.split('-').reverse().join('/');
        setEndDate(formattedDate);
    };

    const handleClone = async () => {

        const data = {
            'escursioniId': record.id,
            'titoloricerca': record.titoloricerca,
            'dataReplicaDal': startDate,
            'dataReplicaAl': endDate,
            'giorniSettimana': selectedDays,
            'replica': 'On',
            'mezziId': record.mezziId
        };

        try {
            const response = await axios({
                method: 'post',
                url: `${config.production.host}/generatore-istanze`,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
            });
            return response.data;
        } catch (error) {
            console.error("Errore con la replica delle istanze", error);
            notify(error.message, 'warning');
            throw error;
        }
    };

    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    <br />
                    <div>
                        <input
                            type="date"
                            onChange={handleChangeStart}
                            value={startDate.split('/').reverse().join('-')}
                        />
                        <p>Data Inizio selezionata: {startDate}</p>
                    </div>
                    <br />
                    <div>
                        <input
                            type="date"
                            onChange={handleChangeEnd}
                            value={endDate.split('/').reverse().join('-')}
                        />
                        <p>Data Fine selezionata: {endDate}</p>
                    </div>
                    <br />
                    <div>
                        {daysEnum.map(day => (
                            <div key={day.id}>
                                <label>
                                    <input
                                        type="checkbox"
                                        id={day.id}
                                        checked={selectedDays.includes(day.id)}
                                        onChange={handleCheckboxChange}
                                    />
                                    {day.name}
                                </label>
                            </div>
                        ))}
                        <p>Giorni selezionati: <div style={{ color: 'green' }}><strong>{selectedDays.join(', ')}</strong></div></p>
                        <p>{selectedDays.length === 0 && <p style={{ color: 'red' }}>Seleziona almeno un giorno della settimana</p>}
                        </p>
                    </div>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                </Typography>
                {canClone && (
                    <Button sx={createButtonCss} onClick={handleClone}>Replica Escursione</Button>
                )}
            </CardContent>
        </Card>
    );
}

function ExcursionInstanceManagementDialog({ open, onClose, refresh }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Generatore delle Istanze</DialogTitle>
            <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Box sx={{ pt: 2 }}>
                            <Typography>
                                <Alert severity="info">Dialog per la generazione automatico di istanze escursione nel tempo.</Alert>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <GestioneIstanzeInShow refresh={refresh} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Chiudi Dialog</Button>
            </DialogActions>
        </Dialog>
    );
}

function BottoneGeneratoreIstanze() {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Button sx={bottoGeneratoreIstanzeCss} variant="outlined" onClick={handleOpenDialog}>Genera Istanze</Button>
            <ExcursionInstanceManagementDialog open={dialogOpen} onClose={handleCloseDialog} refresh={() => { }} />
        </>
    );
}



/* ****************** */

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

/* TODO: da creare componente custom */

const CustomAvatarImage = ({ source, label }) => {
    const record = useRecordContext();
    if (!record) return null;



    return (
        <>
            <Box
                height="80%"
                width="80%"
                display="flex"
                justifyContent="left"
                alignItems="top"
                style={{ marginRight: '20px' }}
            >
                {record[source] ? (
                    // <img src={customerImageUrl} alt="Logo cliente" style={{ maxHeight: '80%', maxWidth: '80%', marginTop: '25px', marginBottom: '25px' }} />
                    <Avatar alt={label} style={{ width: '100px', height: '100px' }} src={record[source]} />
                ) : (
                    <p><Avatar alt="Immagine mancante" style={{ width: '100px', height: '100px' }}><ImageNotSupportedIcon /></Avatar></p>
                )}
            </Box></>
    );
};

const useStyles = makeStyles({
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: '0.5em',
        height: '0.5em',
        paddingLeft: 2,
    },
});

const MyPhotoUrlField = ({ source }) => {
    const record = useRecordContext();
    const classes = useStyles();
    return record ? (
        // <a href="http://google.com" target="_blank" className={classes.link}>
        <a href={record[source]} target="_blank" className={classes.link} rel="noreferrer">
            {/* {record[source]} */}
            Apri
            <LaunchIcon className={classes.icon} />
        </a>
    ) : null;
}

export function ResponsiveDialog2() {

    const { setValue } = useFormContext();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [defaultLocation,/*  setDefaultLocation */] = useState(DefaultLocation);

    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    function handleChangeLocation(lat, lng) {
        setValue('latitudine', lat.toString());
        setValue('longitudine', lng.toString());
        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    return (
        <>
            <div>
                <Button onClick={handleOpen}>Apri mappa e seleziona il punto di escursione</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <label>Latitute:</label><input type='text' value={location.lat} disabled />
                        <label>Longitute:</label><input type='text' value={location.lng} disabled />
                        <MapPicker defaultLocation={defaultLocation}
                            zoom={zoom}
                            mapTypeId="roadmap"
                            style={{ height: '700px' }}
                            onChangeLocation={handleChangeLocation}
                            onChangeZoom={handleChangeZoom}
                            /* apiKey='AIzaSyBNi7XqlJRL4aW-GiWKA_g3ssmM3OO8kqM'  */
                            apiKey='AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY'
                        />
                    </Box>
                </Modal>
            </div>

        </>
    );
}

export function ResponsiveDialog3() {

    const record = useRecordContext();
    const { setValue } = useFormContext();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [defaultLocation, /* setDefaultLocation */] = useState(DefaultLocation);

    const [/* location, */ setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    function handleChangeLocation(lat, lng) {
        setValue('latitudine', lat.toString());
        setValue('longitudine', lng.toString());
        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    return (
        <>
            <div>
                <Button onClick={handleOpen}>Apri mappa e seleziona il punto di escursione</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <MapPicker defaultLocation={{ lat: record.latitudine, lng: record.longitudine }}
                            zoom={zoom}
                            mapTypeId="roadmap"
                            style={{ height: '700px' }}
                            onChangeLocation={handleChangeLocation}
                            onChangeZoom={handleChangeZoom}
                            apiKey='AIzaSyBNi7XqlJRL4aW-GiWKA_g3ssmM3OO8kqM' />
                    </Box>
                </Modal>
            </div>

        </>
    );
}

export default function ResponsiveDialog() {

    // TODO: da sistemare
    const { setValue } = useFormContext();

    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    function handleChangeLocation(lat, lng) {
        setValue('latitudine', lat.toString());
        setValue('longitudine', lng.toString());

        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }
    function handleResetLocation() {
        setDefaultLocation({ ...DefaultLocation });
        setZoom(DefaultZoom);
    }

    return (
        <>
            {<TextInput label="Longitudine" source="longitudine" value={location.lng} defaultValues={location.lng.toString} />}
            <br></br>
            {<TextInput label="Latitudine" source="latitudine" value={location.lat} defaultValues={location.lat.toString} />}
            <br></br>

            <br></br>

            <MapPicker
                defaultLocation={defaultLocation}
                zoom={zoom}
                style={{ height: "500px" }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                apiKey="AIzaSyBNi7XqlJRL4aW-GiWKA_g3ssmM3OO8kqM"
            />
        </>
    );
}

/* const SubReference = ({ translateChoice, children, ...props }) => (
    <ReferenceField {...props}>{children}</ReferenceField>
); */

/* const CustomUpdatePostsButton = () => {
    const { selectedIds } = useListContext();
    const [open, setOpen] = useState(false);

    const [updateMany, { isLoading }] = useUpdateMany(
        'posts',
        { ids: selectedIds, data: { views: 0 } }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        <>
            <Button label="Cancellazione utente" onClick={handleClick} />
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Cancellazione utente"
                content="Sei sicuro di voler cancellare l'utente ?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
}; */

const ListActions = () => (
    <TopToolbar>

        <CreateButton label="Crea" sx={createButtonCss}></CreateButton>

    </TopToolbar>
);

export const EscursioniListNoEdit = props => {

    const { permissions } = usePermissions();

    return (
        <List title={<>
            <Button>
                &nbsp;Lista escursioni
            </Button>
        </>}
            empty={(permissions === 'admin')
                ? <EmptyListCustom titolo="Lista escursioni"></EmptyListCustom>
                : <EmptyListCustomNoCreate titolo="Lista escursioni"></EmptyListCustomNoCreate>}
            perPage={100}
            aside={<EscursioniFilterSidebar />}
            actions={(permissions === 'admin') && <ListActions />}
            exporter={false}
            filters={(permissions === 'admin') ? escursioniFiltersAdmin : escursioniFilters}
            {...props}>
            <Datagrid sx={listCss}/*  expand={<EscursioniExpand />} */
                noDelete
                bulkActionButtons={false}

            >
                {(permissions === 'admin') && <TextField label="Id" source="id" />}
                {<TextField label="Nome" source="titoloricerca" />}
                {/* <ImageField source="foto" title="Foto" /> */}
                {<TextField label="Zona" source="zona" />}
                {<TextField label="Citta" source="citta" />}
                {/* <ReferenceField label="Titolo" source="id" reference="escursioni-lingue"> */}
                {/* <SubReference source="escursioniId" reference="lingue"> */}
                {/* <TextField source="titolo" link={false} /> */}
                {/* </SubReference> */}
                {/* </ReferenceField> */}
                {/*                 <ReferenceManyField
                    reference="escursioni-lingue"
                    target="escursioniId"
                    
                    // perPage={1}
                    sort={{ field: 'id', order: 'ASC' }}
                >
                    <Datagrid>
                    
                    <TextField  label="Titolo" source="titolo" />
                    </Datagrid>
                </ReferenceManyField> */}
                {/* {<TextField label="Email" source="email" />} */}
                {/* {<TextField label="Ruolo" source="roles" />} */}
                {/* {<TextField label="Codice rivenditore" source="codiceRivenditore" />} */}
                {/* <ReferenceField label="Hotel" source="hotelId" reference="hotels"> */}
                {/* <FunctionField render={record => record && `${record.nome} ${record.zona} `} /> */}
                {/* </ReferenceField> */}
                {/* {<TextField label="Indirizzo" source="indirizzo" />} */}
                {/* {<TextField label="Cap" source="cap" />} */}
                {/* {<TextField label="Telefono" source="telefono" />} */}
                {/* {<EditButton sx={editButtonCss} label="Modifica" />} */}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
                {(permissions === 'admin') && <WrapperField label="Elimina">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Escursione"}
                        messaggio={"Sei sicuro di vole cancellare questa escursione ?"}
                        servizio={"escursioni"} />
                </WrapperField>}
                {/* <CustomUpdatePostsButton></CustomUpdatePostsButton> */}
                {/* <CloneButton></CloneButton> */}
            </Datagrid>
        </List>
    );
}

const redirect = () => `/escursioni`;

const GeneraBloccoUpload = (numero) => {
    const record = useRecordContext();
    // console.log(record);
    return (<>
        <TableRow
            /* key={row.name} */
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell align="left">{<TextField label="" source={"note" + numero} />}</TableCell>
            <TableCell align="right">
                <TableContainer component={Paper}>
                    <Table /* sx={{ minWidth: 650 }} size="small"  */ aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">{<ImageField source={"pathDocumento" + numero} title=" " sx={{
                                    '& .RaImageField-image': { width: 200 }
                                }} />}</TableCell>
                                <TableCell align="right"><MyPhotoUrlField source={"pathDocumento" + numero}></MyPhotoUrlField></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/*                             <TableRow>
                                <TableCell align="left"><Typography variant="h7"><UploadImage numero={numero} ></UploadImage></Typography></TableCell>
                            </TableRow> */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableCell>
        </TableRow>
    </>);
}

export const EscursioniCreate = ({ id, ...props }) => {

    return (
        <Create redirect="list" {...props}>
            <SimpleForm /*  redirect={redirect} */>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <br></br>
                                    {/* {<TextInput label="Foto" source="foto" />} */}
                                    {/* <GeneraBloccoUpload></GeneraBloccoUpload> */}
                                    <br></br>
                                    <SelectInput source="zona" label="Zona" choices={[
                                        { id: 'n', name: 'n' },
                                        { id: 'no', name: 'no' },
                                        { id: 'ne', name: 'ne' },
                                        { id: 'e', name: 'e' },
                                        { id: 'o', name: 'o' },
                                        { id: 'so', name: 'so' },
                                        { id: 'se', name: 'se' },
                                        { id: 's', name: 's' },
                                    ]}></SelectInput>
                                    <br></br>
                                    {<TextInput label="Citta" source="citta" />}
                                    <br></br>
                                    {<TextInput label="Testo Ricerca" source="titoloricerca" />}
                                    <br></br>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <br></br>
                                    {<TextInput label="Longitudine" source="longitudine" />}
                                    <br></br>
                                    {<TextInput label="Latitudine" source="latitudine" />}
                                    <ResponsiveDialog2></ResponsiveDialog2>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
}

export const EscursioniEditOLD = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {<TextInput label="Foto" source="foto" />}
                                <br></br>
                                <SelectInput source="zona" label="Zona" choices={[
                                    { id: 'n', name: 'n' },
                                    { id: 'no', name: 'no' },
                                    { id: 'ne', name: 'ne' },
                                    { id: 'e', name: 'e' },
                                    { id: 'o', name: 'o' },
                                    { id: 'so', name: 'so' },
                                    { id: 'se', name: 'se' },
                                    { id: 's', name: 's' },
                                ]}></SelectInput>
                                <br></br>
                                {<TextInput label="Citta" source="citta" />}
                                <br></br>
                                {<TextInput label="Testo Ricerca" source="titoloricerca" />}
                                <br></br>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {<TextInput label="Longitudine" source="longitudine" />}
                                <br></br>
                                {<TextInput label="Latitudine" source="latitudine" />}
                                <br></br>
                                <ResponsiveDialog2></ResponsiveDialog2>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* TODO: Edit lingue info aggiuntive */}
                {/* <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <ReferenceManyField
                                reference="escursioni-lingue"
                                target="escursioniId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <EditableDatagrid noDelete
                                    bulkActionButtons={false}
                                    editForm={<EscursioniDetailRowForm />}
                                >
                                    <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                    <TextField label="Titolo" source="titolo" />
                                    <TextField label="Titolo corto" source="short_title" />
                                    {<TextField label="Url file pdf" source="urlPdf" />}
                                    {<RichTextField label="Prezzi al pubblico" source="prezziAlPubblico" />}
                                    {<RichTextField label="Incluso" source="incluso" />}
                                    {<RichTextField label="Non incluso" source="nonIncluso" />}
                                    {<RichTextField label="Cosa portare" source="cosaPortare" />}
                                    {<RichTextField label="Quando prenotare" source="quandoPrenotare" />}
                                    {<RichTextField label="N. minimo partecipanti" source="numMinimoPartecipanti" />}
                                    {<RichTextField label="Voucher digitale" source="voucherDigitale" />}
                                    {<RichTextField label="Animali da compagnia" source="animaliDaCompagnia" />}
                                    {<RichTextField label="Domande frequenti" source="domandeFrequenti" />}
                                    {<RichTextField label="Cancellazione" source="cancellazione" />}
                                    {<RichTextField label="Altro" source="altro" />}
                                </EditableDatagrid>
                            </ReferenceManyField>

                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
        </SimpleForm>
    </Edit>
);

/* Nuovo edit 05 02 2024 */
export const EscursioniEdit = (props) => {
    return (
        <Edit title={<>
            <Button>
                &nbsp;Modifica Escursione
            </Button>
        </>}  {...props}>
            <SimpleForm>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <br></br>
                                    {/* {<TextInput label="Foto" source="foto" />}
                                    <br></br> */}
                                    <SelectInput source="zona" label="Zona" choices={[
                                        { id: 'n', name: 'n' },
                                        { id: 'no', name: 'no' },
                                        { id: 'ne', name: 'ne' },
                                        { id: 'e', name: 'e' },
                                        { id: 'o', name: 'o' },
                                        { id: 'so', name: 'so' },
                                        { id: 'se', name: 'se' },
                                        { id: 's', name: 's' },
                                    ]}></SelectInput>
                                    <br></br>
                                    {<TextInput label="Citta" source="citta" />}
                                    <br></br>
                                    {<TextInput label="Testo Ricerca" source="titoloricerca" />}
                                    <br></br>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <br></br>
                                    {<TextInput label="Longitudine" source="longitudine" />}
                                    <br></br>
                                    {<TextInput label="Latitudine" source="latitudine" />}
                                    <br></br>
                                    <ResponsiveDialog2></ResponsiveDialog2>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* TODO: Edit lingue info aggiuntive */}
                    {/* <Grid item xs={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <ReferenceManyField
                                reference="escursioni-lingue"
                                target="escursioniId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <EditableDatagrid noDelete
                                    bulkActionButtons={false}
                                    editForm={<EscursioniDetailRowForm />}
                                >
                                    <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                    <TextField label="Titolo" source="titolo" />
                                    <TextField label="Titolo corto" source="short_title" />
                                    {<TextField label="Url file pdf" source="urlPdf" />}
                                    {<RichTextField label="Prezzi al pubblico" source="prezziAlPubblico" />}
                                    {<RichTextField label="Incluso" source="incluso" />}
                                    {<RichTextField label="Non incluso" source="nonIncluso" />}
                                    {<RichTextField label="Cosa portare" source="cosaPortare" />}
                                    {<RichTextField label="Quando prenotare" source="quandoPrenotare" />}
                                    {<RichTextField label="N. minimo partecipanti" source="numMinimoPartecipanti" />}
                                    {<RichTextField label="Voucher digitale" source="voucherDigitale" />}
                                    {<RichTextField label="Animali da compagnia" source="animaliDaCompagnia" />}
                                    {<RichTextField label="Domande frequenti" source="domandeFrequenti" />}
                                    {<RichTextField label="Cancellazione" source="cancellazione" />}
                                    {<RichTextField label="Altro" source="altro" />}
                                </EditableDatagrid>
                            </ReferenceManyField>

                        </CardContent>
                    </Card>
                </Grid> */}
                </Grid>
            </SimpleForm>
        </Edit>
    );
}


const ValutaBandieraLingua = ({ source }) => {
    const record = useRecordContext();
    let lingua = "";

    switch (record[source]) {
        case 1:
            lingua = "http://imgm.btechitalia.it/flags/flagita.png"
            break;
        case 2:
            lingua = "http://imgm.btechitalia.it/flags/flaguk.png"
            break;
        case 3:
            lingua = "http://imgm.btechitalia.it/flags/flagpol.png"
            break;
        case 4:
            lingua = "http://imgm.btechitalia.it/flags/flagger.png"
            break;
        case 5:
            lingua = "http://imgm.btechitalia.it/flags/flagpor.png"
            break;
        case 6:
            lingua = "http://imgm.btechitalia.it/flags/flagspa.png"
            break;
        case 7:
            lingua = "http://imgm.btechitalia.it/flags/flagfra.png"
            break;
        default:
            lingua = ""
            break;
    }


    return (
        <><div>
            <img src={lingua} width="20" height="20" />
        </div></>
    );
};
// http://imgm.btechitalia.it/testita.png
export const EscursioniExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} /* aside={<Aside />} */>

            <SimpleShowLayout>

                {/* <GeneraBloccoUpload></GeneraBloccoUpload> */}
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={10}>
                        <h2>Lista titoli</h2>
                        <ReferenceManyField
                            reference="escursioni-lingue"
                            target="escursioniId"

                            // perPage={1}
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <EditableDatagrid noDelete
                                bulkActionButtons={false}
                                editForm={<EscursioniRowForm />}
                            >

                                {/* <TextField  label="Id Lingua" source="lingueId" /> */}
                                <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                {/* <ImageField  source="urlBandierina" label="Nazione" /> */}
                                <TextField label="Titolo" source="titolo" />
                                <TextField label="Titolo corto" source="short_title" />
                                {/* <TextField label="Url file pdf" source="urlPdf" /> */}
                            </EditableDatagrid>
                        </ReferenceManyField>
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={10}>
                        <h2>Lista Descrizioni</h2>
                        <ReferenceManyField
                            reference="escursioni-lingue"
                            target="escursioniId"

                            // perPage={1}
                            sort={{ field: 'id', order: 'ASC' }}
                        >
                            <EditableDatagrid noDelete
                                bulkActionButtons={false}
                                editForm={<EscursioniDescrizioniRowForm />} >
                                <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                <RichTextField label="Descrizione" source="descrizione" />
                            </EditableDatagrid>
                        </ReferenceManyField>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>

                        {/* {<RichTextField label="Consegna" source="consegna" />} */}

                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}

/* SHOW ESCURSIONE */
export const EscursioniShowDescrizioneRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="descrizione"></RichTextInput>
    </RowForm>
);

export const EscursioniShowDescrizioneCompletaRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="descrizioneCompleta"></RichTextInput>
    </RowForm>
);

export const EscursioniShowInclusoRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="incluso"></RichTextInput>
    </RowForm>
);

export const EscursioniShowNonInclusoRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="nonIncluso"></RichTextInput>
    </RowForm>
);

export const EscursioniShowCosaPortareRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="cosaPortare"></RichTextInput>
    </RowForm>
);

export const EscursioniShowQuandoPrenotareRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="quandoPrenotare"></RichTextInput>
    </RowForm>
);

export const EscursioniShowCancellazioneRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="cancellazione"></RichTextInput>
    </RowForm>
);

export const EscursioniShowAltroRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="altro"></RichTextInput>
    </RowForm>
);

export const EscursioniShowNumMinimoPartecipantiRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="numMinimoPartecipanti"></RichTextInput>
    </RowForm>
);

export const EscursioniShowDurataRowForm = (props) => (
    <RowForm {...props}>

        <ReferenceField
            source="durataId" reference="durata"
            sort={{ field: 'id', order: 'ASC' }}
        >


            <SelectInput source="descrizione"></SelectInput>

        </ReferenceField>

    </RowForm>
);

/* *********************** */

const GestioneIstanzeInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<CloneExcursionTab record={record}></CloneExcursionTab>);

}

const EscursioniShowIstanzeRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled ></TextInput>

        {/* {<ReferenceField link={null} source="escursioniId" reference="escursioni" >
            <TextField source="id"></TextField>
        </ReferenceField>}
 */}



        <FunctionField
            source="Titolo"
            render={record => {
                return (<Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <span>{record.titoloricerca}</span>
                    </Grid>
                    <Grid item>
                        <Link to={`/istanze-escursioni/${record.id}/show`}>
                            <VisibilityIcon style={linkCss} />
                        </Link>
                    </Grid>
                </Grid>)
            }}
        />

        <DateInput source="data" ></DateInput>


    </RowForm>
);

const EscursioniShowIstanzeRowFormCreate = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled ></TextInput>

        <DateField source="data" ></DateField>
        {<ReferenceField link={null} source="escursioniId" reference="escursioni" >
            <TextField source="id"></TextField>
        </ReferenceField>}
        {/*         {<ReferenceInput source="lingueGuidaId" reference="lingue" >
            <SelectInput source="descrizioneLingua"></SelectInput>
        </ReferenceInput>} */}
        <CustomAutocompleInput readOnly={true} source="escursioniId" reference="escursioni" optionProperty="titoloricerca" label="" ></CustomAutocompleInput>

    </RowForm>
);

const ListIstanzeInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;



    return (<ReferenceManyField
        reference="istanze-escursioni"
        target="escursioniId"
        sort={{ field: 'id', order: 'ASC' }}
        label=""
    ><EditableDatagrid /* noDelete */
        bulkActionButtons={false}
        editForm={<EscursioniShowIstanzeRowForm />}
        /* createForm={<EscursioniShowIstanzeRowFormCreate id={record.id} />} */>
            {/* <TextField source="id" label="Id Istanza"></TextField> */}
            {/* {<ReferenceField link={null} source="escursioniId" reference="escursioni" >
                <TextField source="id"></TextField>
            </ReferenceField>} */}
            <DateField source="data" ></DateField>

            <FunctionField
                source="Titolo"
                render={record => {
                    return (<Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <span>{record.titoloricerca}</span>
                        </Grid>
                        <Grid item>
                            <Link to={`/istanze-escursioni/${record.id}/show`}>
                                <VisibilityIcon style={linkCss} />
                            </Link>
                        </Grid>
                    </Grid>)
                }}
            />
        </EditableDatagrid></ReferenceManyField>);

}

/* *********************** */
const GestioneServziDisponibiliInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<Datagrid /* noDelete */
        bulkActionButtons={false}>
        {<ReferenceField link={null} source="serviziId" reference="servizi" >
            <TextField source="descrizione"></TextField>
        </ReferenceField>}
    </Datagrid>);

}

const GestioneLingueDisponibiliInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<Datagrid noDelete
        bulkActionButtons={false}>
        <ValutaBandieraLingua source="lingueGuidaId" label="Nazione" />

        {<ReferenceField source="lingueGuidaId" reference="lingue" >
            <TextField source="descrizioneLingua"></TextField>
        </ReferenceField>}
    </Datagrid>);

}

const EscursioniShowLingueDisponibiliRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled ></TextInput>

        {/*         {<ReferenceInput source="lingueGuidaId" reference="lingue" >
            <SelectInput source="descrizioneLingua"></SelectInput>
        </ReferenceInput>} */}
        <CustomAutocompleInput readOnly={true} source="lingueGuidaId" reference="lingue" optionProperty="descrizioneLingua" label="" ></CustomAutocompleInput>

    </RowForm>
);

const EscursioniShowServiziLingueDisponibiliRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled ></TextInput>

        {/*         {<ReferenceInput source="lingueGuidaId" reference="lingue" >
            <SelectInput source="descrizioneLingua"></SelectInput>
        </ReferenceInput>} */}
        <CustomAutocompleInput readOnly={true} source="serviziId" reference="servizi" optionProperty="descrizione" label="" ></CustomAutocompleInput>

    </RowForm>
);

const EscursioniEditEscursioniCategorieRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled ></TextInput>
        {/*             {<ReferenceInput source="categorieId" reference="categorie" >
                <SelectInput source="titoloricerca"></SelectInput>
            </ReferenceInput>} */}
        <CustomAutocompleInput readOnly={true} source="categorieId" reference="categorie" optionProperty="titoloricerca" label="" ></CustomAutocompleInput>
    </RowForm>
);

const EscursioniEditEscursioniHotelsRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled ></TextInput>
        {/*             {<ReferenceInput source="categorieId" reference="categorie" >
                <SelectInput source="titoloricerca"></SelectInput>
            </ReferenceInput>} */}
        <CustomAutocompleInput readOnly={true} source="hotelsId" reference="hotels" optionProperty="nome" label="" ></CustomAutocompleInput>
    </RowForm>
);

const EscursioniEditEscursioniCateneAlberghiereRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled ></TextInput>
        {/*             {<ReferenceInput source="categorieId" reference="categorie" >
                <SelectInput source="titoloricerca"></SelectInput>
            </ReferenceInput>} */}
        <CustomAutocompleInput readOnly={true} source="catenaAlberghieraId" reference="catena-alberghiera" optionProperty="nome" label="" ></CustomAutocompleInput>
    </RowForm>
);

const EscursioniEditEscursioniTourOperatorRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
        <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled ></TextInput>
        {/*             {<ReferenceInput source="categorieId" reference="categorie" >
                <SelectInput source="titoloricerca"></SelectInput>
            </ReferenceInput>} */}
        <CustomAutocompleInput readOnly={true} source="tourOperatorId" reference="tour-operator" optionProperty="nome" label="" ></CustomAutocompleInput>
    </RowForm>
);

const EscursioniCreateEscursioniCategorieRowForm = ({ id, ...props }) => {
    return (
        <RowForm {...props}>
            {/* <ValutaBandieraLingua source="lingueId" label="Nazione" /> */}
            <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled ></TextInput>
            {/*             {<ReferenceInput source="categorieId" reference="categorie" >
                <SelectInput source="titoloricerca"></SelectInput>
            </ReferenceInput>} */}
            <CustomAutocompleInput readOnly={true} source="categorieId" reference="categorie" optionProperty="titoloricerca" label="" ></CustomAutocompleInput>
        </RowForm>
    );
}

const EscursioniPuntiincontroLingueRowForm = ({ id, ...props }) => {
    return (
        <RowForm {...props}>
            <RichTextInput
                toolbar={<RichTextInputToolbar size="large" />}
                label="Descrizione" source="descrizione" />
        </RowForm>
    );
}

const EscursioniPuntiincontroTariffeRowForm = ({ id, puntiincontroId, mezziId, ...props }) => {
    return (
        <RowForm {...props}>

            <DateInput label="Data from" source="dataFrom" />
            <DateInput label="Data to" source="dataTo" />
            <NumberInput label="Tar Adulti" source="tarAdulti" min={0} defaultValue={0.00} />
            <NumberInput label="Tar Bambini" source="tarBambini" min={0} defaultValue={0.00} />
            <NumberInput label="Tar Infanti" source="tarInfanti" min={0} defaultValue={0.00} />

            <NumberInput label="Cos Adulti" source="cosAdulti" min={0} defaultValue={0.00} />
            <NumberInput label="Cos Bambini" source="cosBambini" min={0} defaultValue={0.00} />
            <NumberInput label="Cos Infanti" source="cosInfanti" min={0} defaultValue={0.00} />

            <NumberInput label="Tar Esclusiva" source="tarEsclusiva" min={0} defaultValue={0.00} />
            <NumberInput label="Tar Costo Esclusiva" source="cosEsclusiva" min={0} defaultValue={0.00} />

            <NumberInput label="Età Infanti" source="criterioEtaInfanti" min={0} defaultValue={0.00} />
            <NumberInput label="Età Bambini" source="criterioEtaBambini" min={0} defaultValue={0.00} />

            <TextInput label="Note" source="note" />

            <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled style={{ width: '0px', height: '0px', position: 'absolute', padding: '0', border: 'none' }} />
            <TextInput source="puntiincontroId" defaultValue={parseInt(puntiincontroId)} disabled style={{ width: '0px', height: '0px', position: 'absolute', padding: '0', border: 'none' }} />
            <TextInput source="mezziId" defaultValue={parseInt(mezziId)} disabled style={{ width: '0px', height: '0px', position: 'absolute', padding: '0', border: 'none' }} />


        </RowForm>
    );
}

const MyToolbarCreate = () => (
    <Toolbar>
        <SaveButton sx={createButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" /* alwaysEnable */ />
    </Toolbar>
);


const EscursioniPuntiincontroTariffeRowFormCreate = ({ id, puntiincontroId, mezziId, ...props }) => {
    const [open, setOpen] = useState(false);
    // const translate = useTranslate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Avvia creazione Tariffa
            </Button>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><div style={{ fontWeight: 'bold' }}>
                    <FiberNewIcon sx={createButtonCss}></FiberNewIcon>&nbsp;&nbsp;Crea Nuova Tariffa
                </div></DialogTitle>
                <DialogContent>
                    <SimpleForm toolbar={<MyToolbarCreate />} {...props}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} md={6}>
                                <Box display="flex" alignItems="center" marginBottom={2}>
                                    <EventIcon color="action" style={{ marginRight: 8 }} />
                                    <DateInput label="" source="dataFrom" style={{ marginRight: 15 }} />
                                    <DateInput label="" source="dataTo" />
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    <EuroIcon color="action" style={{ marginBottom: 8 }} />
                                    <Box display="flex" marginBottom={1}>
                                        <NumberInput label="Tar Adulti" source="tarAdulti" min={0} defaultValue={0.00} style={{ marginRight: 15 }} />
                                        <NumberInput label="Tar Bambini" source="tarBambini" min={0} defaultValue={0.00} style={{ marginRight: 15 }} />
                                        <NumberInput label="Tar Infanti" source="tarInfanti" min={0} defaultValue={0.00} />
                                    </Box>
                                    <Box display="flex" marginBottom={1}>
                                        <NumberInput label="Cos Adulti" source="cosAdulti" min={0} defaultValue={0.00} style={{ marginRight: 15 }} />
                                        <NumberInput label="Cos Bambini" source="cosBambini" min={0} defaultValue={0.00} style={{ marginRight: 15 }} />
                                        <NumberInput label="Cos Infanti" source="cosInfanti" min={0} defaultValue={0.00} />
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" marginBottom={2}>
                                    <NumberInput label="Tar Esclusiva" source="tarEsclusiva" min={0} defaultValue={0.00} style={{ marginRight: 15 }} />
                                    <NumberInput label="Tar Costo Esclusiva" source="cosEsclusiva" min={0} defaultValue={0.00} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={6}>

                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Box display="flex" flexDirection="column">
                                    <ChildFriendlyIcon color="action" style={{ marginBottom: 8 }} />
                                    <Box display="flex">
                                        <NumberInput label="Età Infanti" source="criterioEtaInfanti" min={0} defaultValue={0.00} style={{ marginRight: 15 }} />
                                        <NumberInput label="Età Bambini" source="criterioEtaBambini" min={0} defaultValue={0.00} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput label="Note" source="note" fullWidth multiline rows={4} style={{ marginTop: 8 }} />
                                <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled style={{ width: '0px', height: '0px', position: 'absolute', padding: '0', border: 'none' }} />
                                <TextInput source="puntiincontroId" defaultValue={parseInt(puntiincontroId)} disabled style={{ width: '0px', height: '0px', position: 'absolute', padding: '0', border: 'none' }} />
                                <TextInput source="mezziId" defaultValue={parseInt(mezziId)} disabled style={{ width: '0px', height: '0px', position: 'absolute', padding: '0', border: 'none' }} />

                            </Grid>
                        </Grid>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
}

/* const EscursioniPuntiincontroTariffeRowFormCreate = ({ id, puntiincontroId, mezziId, ...props }) => {
    return (
        <RowForm {...props}>

            <DateInput label="Data from" source="dataFrom" />
            <br></br>
            <DateInput label="Data to" source="dataTo" />
            <br></br>
            <NumberInput label="Tar Adulti" source="tarAdulti" min={0} defaultValue={0.00} />
            <NumberInput label="Tar Bambini" source="tarBambini" min={0} defaultValue={0.00} />
            <NumberInput label="Tar Infanti" source="tarInfanti" min={0} defaultValue={0.00} />

            <NumberInput label="Cos Adulti" source="cosAdulti" min={0} defaultValue={0.00} />
            <NumberInput label="Cos Bambini" source="cosBambini" min={0} defaultValue={0.00} />
            <NumberInput label="Cos Infanti" source="cosInfanti" min={0} defaultValue={0.00} />

            <NumberInput label="Tar Esclusiva" source="tarEsclusiva" min={0} defaultValue={0.00} />
            <NumberInput label="Tar Costo Esclusiva" source="cosEsclusiva" min={0} defaultValue={0.00} />

            <NumberInput label="Età Infanti" source="criterioEtaInfanti" min={0} defaultValue={0.00} />
            <NumberInput label="Età Bambini" source="criterioEtaBambini" min={0} defaultValue={0.00} />

            <TextInput label="Note" source="note" />

            <TextInput source="escursioniId" defaultValue={parseInt(id)} disabled style={{ width: '0px', height: '0px', position: 'absolute', padding: '0', border: 'none' }} />
            <TextInput source="puntiincontroId" defaultValue={parseInt(puntiincontroId)} disabled style={{ width: '0px', height: '0px', position: 'absolute', padding: '0', border: 'none' }} />
            <TextInput source="mezziId" defaultValue={parseInt(mezziId)} disabled style={{ width: '0px', height: '0px', position: 'absolute', padding: '0', border: 'none' }} />


        </RowForm>
    );
} */


/* const optionRendererEscursioniShow = choice => `${choice.descrizione}`; */

const GestioneCategorieInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<Datagrid
        bulkActionButtons={false}
    >
        {<ReferenceField source="categorieId" reference="categorie" >
            <ImageField sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} source="coverImg"></ImageField>
            <ImageField sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} source="pathImage"></ImageField>
            <TextField source="titoloricerca"></TextField>
        </ReferenceField>}
    </Datagrid>);

}

const GestioneHotelsListInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        mutationMode='undoable'
        editForm={<EscursioniEditEscursioniHotelsRowForm />}
        createForm={<EscursioniEditEscursioniHotelsRowForm id={record.id} />}
    >
        {<ReferenceField source="hotelsId" reference="hotels" >
            <ImageField sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} source="urlLogo"></ImageField>
            <TextField source="nome"></TextField>
        </ReferenceField>}
    </EditableDatagrid>);

}

const GestioneCateneAlberghiereListInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        mutationMode='undoable'
        editForm={<EscursioniEditEscursioniCateneAlberghiereRowForm />}
        createForm={<EscursioniEditEscursioniCateneAlberghiereRowForm id={record.id} />}
    >
        {<ReferenceField source="catenaAlberghieraId" reference="catena-alberghiera" >
            <ImageField sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} source="urlLogo"></ImageField>
            <TextField source="nome"></TextField>
        </ReferenceField>}
    </EditableDatagrid>);

}

const GestioneTourOperatorsListInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid
        bulkActionButtons={false}
        mutationMode='undoable'
        editForm={<EscursioniEditEscursioniTourOperatorRowForm />}
        createForm={<EscursioniEditEscursioniTourOperatorRowForm id={record.id} />}
    >
        {<ReferenceField source="tourOperatorId" reference="tour-operator" >
            <ImageField sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} source="urlLogo"></ImageField>
            <TextField source="nome"></TextField>
        </ReferenceField>}
    </EditableDatagrid>);

}

/* GESTIONE PUNTI INCONTRO */

/* Tipo raduno STATICO */
const tipoRadunoDescrizione = {
    0: 'Punto d\'incontro',
    1: 'Punto di partenza',
    2: 'Incontro/partenza',
};

/* ******************** */
const MyToolbarEdit = ({ referenceToSave, recordId, tabPath }) => {
    const [update] = useUpdate();
    const { getValues } = useFormContext();
    // const redirect = useRedirect();
    const notify = useNotify();

    const handleClick = e => {
        e.preventDefault(); // necessary to prevent default SaveButton submit logic
        const { id, ...data } = getValues();
        update(
            referenceToSave,
            { id, data },
            {
                onSuccess: () => {
                    // console.log("REDIRECT");
                    // redirect('show', 'escursioni', 1);
                    window.location.hash = '/' + referenceToSave + '/' + recordId + '/show/' + tabPath;
                    notify('Salvataggio eseguito. Clicca fuori dalla Dialog per chiudere la modifica', 'info');
                }
            }
        );
    };


    return (
        <Toolbar>
            <SaveButton /* sx={saveButtonCss}  */ label="Aggiungi" onClick={handleClick}/* alwaysEnable */ />
        </Toolbar>
    );
}

const AddPuntoOld = ({ redirectPath }) => {
    const record = useRecordContext(); // Record escursione
    console.log(record);
    const notify = useNotify();
    const refresh = useRefresh();
    const payload = { escursioniId: 1, puntiincontroId: 17, tipoRaduno: 0 };
    const [create, { isLoading, error }] = useCreate('escursioni-puntiincontro', { data: payload },
        {
            onSuccess: () => {
                notify('Punto aggiunto.', 'info');
                refresh();
            },
            onFailure: (error) => {
                notify(`Errore: ${error.message}`, 'warning');
            }
        });
    const handleClick = () => {
        create()
    }


    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    if (error) {
        // return <div></div>;
        console.log("ERRORE");
    }
    // return <button disabled={isLoading} onClick={handleClick}>Aggiungi</button>;
    return <div>{/* <Button
        sx={createButtonCss}
        onClick={handleClick}
        variant="contained"
        size="small"
        endIcon={<AddCircleOutlineIcon />}
        component="label"
        color="error"
    >
        Aggiungi
    </Button> */}
        {/* <SelectableTextField
            isFiltered={false}
            source="puntiincontroId"
            label="Punto"
            tabPath="puntiincontropartenza"
            reference="puntiincontro"
            referenceToSave="escursioni"
            optionProperty="nome" /> */}
        <Button onClick={handleOpen} style={{ cursor: 'pointer' }} >Aggiungi</Button>
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle><EditIcon></EditIcon> Aggiungi</DialogTitle>
            <Edit actions={null} title={<>
                <Button>
                    &nbsp;Aggiungi
                </Button>
            </>}>
                <SimpleForm
                    toolbar={<MyToolbarEdit referenceToSave="escursioni-puntiincontro" recordId={record.id} tabPath="puntiincontropartenza" ></MyToolbarEdit>}
                >
                    <Divider variant="middle" />
                    {/*                     {{/* <SelectableTextField
                        isFiltered={true}
                        filterValue={record.mezziId}
                        filterField="mezziId"
                        source="puntiincontroId"
                        label="Punto"
                        tabPath="puntiincontropartenza"
                        reference="wapp-mezzi-mezzi-puntiincontro"
                        referenceToSave="escursioni-puntiincontro"
                        optionProperty="puntiincontroNome" />} */}
                    <CustomAutocompleInput readOnly={true} isFiltered={true} filterField="mezziId" filterValue={record.mezziId} source="puntiincontroId" reference="wapp-mezzi-mezzi-puntiincontro" referenceToSave="escursioni-puntiincontro" optionProperty="puntiincontroNome" label="puntiincontroNome" ></CustomAutocompleInput>

                    {/*  <CustomAutocompleInput readOnly={true}
                        isFiltered={true}
                        filterValue={record.mezziId}
                        filterField="mezziId"
                        source="puntiincontroId"
                        reference="wapp-mezzi-mezzi-puntiincontro"
                        referenceToSave="puntiincontropartenza"
                        optionProperty="puntiincontroNome" label="Punto" readOnly={false} ></CustomAutocompleInput> */}

                    <Divider variant="middle" />
                </SimpleForm>
            </Edit>
        </Dialog>

    </div>;
};

const AddPunto = ({ escursioniId, mezziId, redirectPath }) => {
    const record = useRecordContext(); // Record escursione
    console.log(record);
    const notify = useNotify();
    const refresh = useRefresh();
    const [selectedValue, setSelectedValue] = useState({ puntiincontroId: null, tipoRaduno: 0 }); // Aggiungi lo stato per memorizzare il valore selezionato

    // const payload = { escursioniId: 1, puntiincontroId: 17, tipoRaduno: 0 };
    const payload = {
        escursioniId: 1, // Assumo che questo possa essere statico o dinamico
        puntiincontroId: selectedValue.puntiincontroId,
        tipoRaduno: selectedValue.tipoRaduno, // Assumo che questo valore possa essere determinato in qualche modo
    };
    const [create, { isLoading, error }] = useCreate('escursioni-puntiincontro', { data: payload },
        {
            onSuccess: () => {
                notify('Punto aggiunto.', 'info');
                console.log(payload);
                refresh();
            },
            onFailure: (error) => {
                notify(`Errore: ${error.message}`, 'warning');
            }
        });
    const handleClick = () => {
        const updatedPayload = {
            escursioniId: escursioniId,
            puntiincontroId: selectedValue.puntiincontroId,
            tipoRaduno: selectedValue.tipoRaduno,
        };
        console.log('Payload prima di create:', updatedPayload);
        create('escursioni-puntiincontro', { data: updatedPayload },
            {
                onSuccess: () => {
                    notify('Punto aggiunto.', 'info');
                    console.log(payload);
                    refresh();
                },
                onFailure: (error) => {
                    notify(`Errore: ${error.message}`, 'warning');
                }
            }).catch((e) => {
                notify(`Errore: ${e}`, 'warning');
            });
    }


    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleValueChange = (newValue) => {
        console.log("--------------------------------");
        console.log(newValue);
        setSelectedValue(prevState => ({
            ...prevState,
            puntiincontroId: newValue.puntiincontroId,
            tipoRaduno: newValue.tipoRaduno
        }));
    };



    if (error) {
        // return <div></div>;
        console.log("ERRORE");
    }
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button sx={createButtonCss} disabled={isLoading} onClick={handleClick}>
            Seleziona {selectedValue.puntiincontroId}
        </Button>
        <CustomSelectMezziPuntiincontro mezziId={mezziId} onValueChange={handleValueChange} />
    </div>
        ;

};

const AddPuntoDialog = ({ escursioniId, mezziId, redirectPath }) => {
    const record = useRecordContext(); // Record escursione
    console.log(record);
    const notify = useNotify();
    const refresh = useRefresh();
    const [selectedValue, setSelectedValue] = useState({ puntiincontroId: null, tipoRaduno: 0 }); // Aggiungi lo stato per memorizzare il valore selezionato

    // const payload = { escursioniId: 1, puntiincontroId: 17, tipoRaduno: 0 };
    const payload = {
        escursioniId: 1, // Assumo che questo possa essere statico o dinamico
        puntiincontroId: selectedValue.puntiincontroId,
        tipoRaduno: selectedValue.tipoRaduno, // Assumo che questo valore possa essere determinato in qualche modo
    };
    const [create, { isLoading, error }] = useCreate('escursioni-puntiincontro', { data: payload },
        {
            onSuccess: () => {
                notify('Punto aggiunto.', 'info');
                console.log(payload);
                refresh();
            },
            onFailure: (error) => {
                notify(`Errore: ${error.message}`, 'warning');
            }
        });
    // Gestione dello stato e delle funzioni per la Dialog
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // Modifica la funzione handleClick per aprire la Dialog
    const handleClick = () => {
        handleDialogOpen(); // Apri la Dialog per la conferma
    };

    // Funzione per gestire la conferma dell'aggiunta del punto
    const handleConfirmAddPunto = () => {
        console.log(selectedValue.puntiincontroId);
        if (selectedValue.puntiincontroId == null) {
            notify(`Seleziona un punto`, 'warning');
        }
        else {
            const updatedPayload = {
                escursioniId: escursioniId,
                puntiincontroId: selectedValue.puntiincontroId,
                tipoRaduno: selectedValue.tipoRaduno,
            };
            console.log('Payload prima di create:', updatedPayload);
            create('escursioni-puntiincontro', { data: updatedPayload }).then(() => {
                handleDialogClose(); // Chiudi la Dialog dopo la creazione
            }).catch((e) => {
                notify(`Errore: ${e}`, 'warning');
                handleDialogClose(); // Chiudi la Dialog in caso di errore
            });
        }
    };

    const handleValueChange = (newValue) => {
        console.log("--------------------------------");
        console.log(newValue);
        setSelectedValue(prevState => ({
            ...prevState,
            puntiincontroId: newValue.puntiincontroId,
            tipoRaduno: newValue.tipoRaduno
        }));
    };

    return (
        <div>
            <Button sx={createButtonCss} disabled={isLoading} onClick={handleClick}>Aggiungi {/* - {selectedValue.puntiincontroId} */}</Button>


            {/* Dialog per la conferma dell'aggiunta del punto */}
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Conferma Aggiunta Punto</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Seleziona un punto :
                        <CustomSelectMezziPuntiincontro mezziId={mezziId} onValueChange={handleValueChange} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={deleteButtonCss} onClick={handleDialogClose} color="primary">
                        Annulla
                    </Button>
                    <Button sx={createButtonCss} onClick={handleConfirmAddPunto} color="primary">
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};

/* ***************************** */

const EscursioniPuntiincontroInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    var redirectPath = '/escursioni/' + record.id + '/show/puntiincontropartenza';

    return (
        <ReferenceManyField
            reference="escursioni-puntiincontro"
            target="escursioniId"
            sort={{ field: 'id', order: 'ASC' }}
            label=""
        >
            {/* TASTO PER L'AGGIUNTA DI UN PUNTO */}

            <Datagrid
                noDelete
                bulkActionButtons={false} s
            >

                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            Dettaglio punto
                            {/* <br></br>
                            <TextField source="id" />
                            <br></br>
                            <TextField source="puntiincontroId" /> */}
                        </Typography>

                        {/* <div><strong>"Tipo: "<strong>{tipoRadunoDescrizione[record.tipoRaduno]}</strong> */}

                        <FunctionField
                            render={record => (<div>TIPO :<strong>{tipoRadunoDescrizione[record.tipoRaduno]}</strong></div>)}
                        />
                        <ReferenceField source="puntiincontroId" reference="puntiincontro">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>

                                    <Chip
                                        label={
                                            <Typography color="textSecondary" variant="h6" component="div" gutterBottom>
                                                <TextField source="nome" />
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" gutterBottom>
                                        <Box sx={{
                                            display: 'flex', // Usa flexbox per allineare gli elementi
                                            alignItems: 'center', // Allinea gli elementi verticalmente al centro
                                            gap: 1, // Aggiunge uno spazio tra gli elementi
                                        }}>
                                            Visualizza su Google Maps
                                            <GoogleMapsLink />
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" gutterBottom>
                                        Lat: <TextField source="latitudine" />
                                        <br></br>
                                        Lon: <TextField source="longitudine" />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ReferenceField>
                    </CardContent>
                </Card>
                <ReferenceManyField
                    reference="escursioni-puntiincontro-lingue"
                    target="escursioniPuntiincontroId"
                    sort={{ field: 'id', order: 'ASC' }}
                >

                    <Datagrid noDelete
                        bulkActionButtons={false}
                    /* NB: non serve un create */
                    >
                        <ValutaBandieraLingua source="lingueId" label="Nazione" />
                        <RichTextField label="Descrizione" source="descrizione" />
                    </Datagrid>
                </ReferenceManyField>
            </Datagrid>
        </ReferenceManyField>);
}

/* *** */

const EscursioniPuntiincontroTariffeInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    var redirectPath = '/escursioni/' + record.id + '/show/tariffe';

    return (
        <ReferenceManyField
            reference="escursioni-puntiincontro"
            target="escursioniId"
            sort={{ field: 'id', order: 'ASC' }}
            label=""
        >
            {/* TASTO PER L'AGGIUNTA DI UN PUNTO */}

            <Datagrid
                /*  noDelete*/
                bulkActionButtons={false}
            // editForm={null}

            >

                <EscursioniPuntiincontroTariffeInShowSub recordIdEscursioni={record.id} recordMezziIdEscursioni={record.mezziId}></EscursioniPuntiincontroTariffeInShowSub>

            </Datagrid>

        </ReferenceManyField>);
}

const EscursioniPuntiincontroTariffeInShowSub = ({ id, recordIdEscursioni, recordMezziIdEscursioni, ...props }) => {


    const record = useRecordContext();
    if (!record) return null;

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Dettaglio punto {/* ------------------------- {record.puntiincontroId} */}
                        {/* <br></br>
                            <TextField source="id" />
                            <br></br>
                            <TextField source="puntiincontroId" /> */}
                    </Typography>

                    {/* <div><strong>"Tipo: "<strong>{tipoRadunoDescrizione[record.tipoRaduno]}</strong> */}

                    <FunctionField
                        render={record => (<div>TIPO :<strong>{tipoRadunoDescrizione[record.tipoRaduno]}</strong></div>)}
                    />



                    <ReferenceField source="puntiincontroId" reference="puntiincontro">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>

                                <Typography color="textSecondary" gutterBottom>
                                    <TextField source="nome" />
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={6}>
                                <Typography color="textSecondary" gutterBottom>
                                    <TextField source="longitudine" />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color="textSecondary" gutterBottom>
                                    <TextField source="latitudine" />
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </ReferenceField>
                </CardContent>
            </Card>
            <ReferenceManyField
                reference="tariffe"
                target="puntiincontroId"
                source="puntiincontroId"
                sort={{ field: 'id', order: 'ASC' }}
                filter={{ escursioniId: recordIdEscursioni }}
            >

                <EditableDatagrid
                    // noDelete
                    bulkActionButtons={false}
                    mutationMode='undoable'
                    editForm={<EscursioniPuntiincontroTariffeRowForm id={record.escursioniId} puntiincontroId={record.puntiincontroId} mezziId={recordMezziIdEscursioni} />}
                    createForm={<EscursioniPuntiincontroTariffeRowFormCreate id={record.escursioniId} puntiincontroId={record.puntiincontroId} mezziId={recordMezziIdEscursioni} />}
                >

                    <DateField label="Data from" source="dataFrom" />
                    <DateField label="Data to" source="dataTo" />

                    <NumberField label="Tar Adulti" source="tarAdulti" />
                    <NumberField label="Tar Bambini" source="tarBambini" />
                    <NumberField label="Tar Infanti" source="tarInfanti" />

                    <NumberField label="Cos Adulti" source="cosAdulti" />
                    <NumberField label="Cos Bambini" source="cosBambini" />
                    <NumberField label="Cos Infanti" source="cosInfanti" />

                    <NumberField label="Tar Esclusiva" source="tarEsclusiva" />
                    <NumberField label="Tar Costo Esclusiva" source="cosEsclusiva" />

                    <NumberField label="Età Infanti" source="criterioEtaInfanti" />
                    <NumberField label="Età Bambini" source="criterioEtaBambini" />

                    <TextField label="Note" source="note" />

                    {/* <TextField source="id" style={{ display: 'none' }} />
                    <TextField source="escursioniId" style={{ display: 'none' }}></TextField>
                    <TextField source="puntiincontroId" style={{ display: 'none' }}></TextField>
                    <TextField source="mezziId" style={{ display: 'none' }}></TextField> */}


                </EditableDatagrid>
            </ReferenceManyField>
        </>);

}

/* HOTELS LOGHI */
const GestioneHotelsInShow = ({ id, ...props }) => {

    // Record dell'escursione
    const record = useRecordContext();
    if (!record) return null;

    /*  return <ReferenceField source="id" reference="hotels" >
         <ImageField sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} source="urlLogo"></ImageField>
     </ReferenceField>; */
    return <>{record.id}</>;

}

export const EscursioniShowNoEdit = ({ id, ...props }) => {

    const { permissions } = usePermissions();
    const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>
                &nbsp;Dettaglio Escursione
            </Button>
        </>} {...props} id={id} /* aside={<Aside />} */>

            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dati base escursione" icon={<TopicIcon></TopicIcon>}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <h2>Titolo ricerca</h2>
                                        {/* {<TextField label="Id" source="id" />} */}
                                        {/* {<TextField label="Testo Ricerca" source="titoloricerca" />} */}
                                        {<CopyToClipboardButton source="titoloricerca" />}
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <h3>Zona : {<TextField label="Zona" source="zona" />}</h3>
                                        <h3>Città : {<TextField label="Citta" source="citta" />}</h3>
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom>
                                        <Box sx={{
                                            display: 'flex', // Usa flexbox per allineare gli elementi
                                            alignItems: 'center', // Allinea gli elementi verticalmente al centro
                                            gap: 1, // Aggiunge uno spazio tra gli elementi
                                        }}>
                                            Visualizza su Google Maps
                                            <GoogleMapsLink />
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Accordion sx={fieldAccordion}>
                                <AccordionSummary
                                    expandIcon={<ArrowDownwardIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography><PostAddIcon></PostAddIcon>&nbsp;Categorie</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={10}>
                                            <ReferenceManyField
                                                reference="escursioni-categorie"
                                                target="escursioniId"

                                                // perPage={1}
                                                sort={{ field: 'id', order: 'ASC' }}
                                            >
                                                <GestioneCategorieInShow></GestioneCategorieInShow>
                                            </ReferenceManyField>
                                        </Grid>
                                        <Grid item xs={10}>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Informazioni sull'attività" path="informazioniAttivita" icon={<TopicIcon></TopicIcon>}>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Partecipanti</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false} >
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            <RichTextField label="Numero Minimo Partecipanti" source="numMinimoPartecipanti" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Durata</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <Divider variant="middle" />
                                    <CustomAutocompleInput readOnly={true} source="durataId" reference="durata" optionProperty="descrizione" label="" ></CustomAutocompleInput>
                                    <Divider variant="middle" />
                                    {/* {/* <SelectableTextField source="durataId" label="Durata" tabPath="informazioniAttivita" reference="durata" referenceToSave="escursioni" optionProperty="descrizione" /> */}
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Orario</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <Divider variant="middle" />
                                    <CustomAutocompleInput readOnly={true} source="orarioId" reference="orario" optionProperty="descrizione" label="" ></CustomAutocompleInput>
                                    <Divider variant="middle" />
                                    {/* <SelectableTextField source="orarioId" label="Orario" tabPath="informazioniAttivita" reference="orario" referenceToSave="escursioni" optionProperty="descrizione" />
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Livello di difficoltà</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <Divider variant="middle" />
                                    <CustomAutocompleInput readOnly={true} source="livellodifficoltaId" reference="livellodifficolta" optionProperty="descrizione" label=""  ></CustomAutocompleInput>
                                    <Divider variant="middle" />
                                    {/* <SelectableTextField source="livellodifficoltaId" label="Livello di difficoltà" tabPath="informazioniAttivita" reference="livellodifficolta" referenceToSave="escursioni" optionProperty="descrizione" /> */}
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Lingue disponibili</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="lingue-escursioni"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        {/* <EditableDatagrid noDelete
                                            bulkActionButtons={false}
                                            editForm={<EscursioniShowLingueDisponibiliRowForm />}
                                            createForm={<EscursioniShowLingueDisponibiliRowForm />}>
                                            <ValutaBandieraLingua source="lingueGuidaId" label="Nazione" />

                                            {<ReferenceField source="lingueGuidaId" reference="lingue" >
                                                <TextField source="descrizioneLingua"></TextField>
                                            </ReferenceField>}
                                        </EditableDatagrid> */}
                                        <GestioneLingueDisponibiliInShow></GestioneLingueDisponibiliInShow>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Servizi disponibili</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-servizi"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        {/* <EditableDatagrid noDelete
                                            bulkActionButtons={false}
                                            editForm={<EscursioniShowLingueDisponibiliRowForm />}
                                            createForm={<EscursioniShowLingueDisponibiliRowForm />}>
                                            <ValutaBandieraLingua source="lingueGuidaId" label="Nazione" />

                                            {<ReferenceField source="lingueGuidaId" reference="lingue" >
                                                <TextField source="descrizioneLingua"></TextField>
                                            </ReferenceField>}
                                        </EditableDatagrid> */}
                                        <GestioneServziDisponibiliInShow></GestioneServziDisponibiliInShow>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="La tua esperienza" path="lingue" icon={<TopicIcon></TopicIcon>}>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Titolo lungo e breve</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false}
                                        >

                                            {/* <TextField  label="Id Lingua" source="lingueId" /> */}
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            {/* <ImageField  source="urlBandierina" label="Nazione" /> */}
                                            <TextField label="Titolo" source="titolo" />
                                            <TextField label="Titolo corto" source="short_title" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={2}>

                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Attività in breve</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false} >
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            <RichTextField label="Descrizione" source="descrizione" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Descrizione completa</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false} >
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            <RichTextField label="Descrizione Completa" source="descrizioneCompleta" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Cosa è incluso</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false} >
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            <RichTextField label="Incluso" source="incluso" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Cosa non è incluso</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false} >
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            <RichTextField label="Non Incluso" source="nonIncluso" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {/*                     <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Punto e orario di incontro</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        </AccordionDetails>
                    </Accordion> */}
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Quando prenotare</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false} >
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            <RichTextField label="Quando prenotare" source="quandoPrenotare" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Cosa portare con se</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false} >
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            <RichTextField label="Cosa portare" source="cosaPortare" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Termini di cancellazione</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false}>
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            <RichTextField label="Cancellazione" source="cancellazione" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Informazioni utili</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-lingue"
                                        target="escursioniId"

                                        // perPage={1}
                                        sort={{ field: 'id', order: 'ASC' }}
                                    >
                                        <Datagrid noDelete
                                            bulkActionButtons={false} >
                                            <ValutaBandieraLingua source="lingueId" label="Nazione" />
                                            <RichTextField label="Altro" source="altro" />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Ufficio prenotazioni</Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <GetInformazioniAzienda></GetInformazioniAzienda>
                        </AccordionDetails>
                    </Accordion>

                </TabbedForm.Tab>
                <TabbedForm.Tab label="Fornitore" path="fornitore" icon={<TopicIcon></TopicIcon>}>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Fornitore</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <Divider variant="middle" />
                                    <CustomAutocompleInput readOnly={true} source="fornitoriId" reference="fornitori" optionProperty="nome" label="" ></CustomAutocompleInput>
                                    <Divider variant="middle" />
                                    {/* <SelectableTextField isFiltered={false} source="fornitoriId" label="Fornitore" tabPath="fornitore" reference="fornitori" referenceToSave="escursioni" optionProperty="nome" /> */}
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography><PostAddIcon></PostAddIcon>&nbsp;Mezzo</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <Divider variant="middle" />
                                    <CustomAutocompleInput readOnly={true} source="mezziId" reference="mezzi" optionProperty="nome" label="" ></CustomAutocompleInput>
                                    <Divider variant="middle" />
                                    {/* <SelectableTextField isFiltered={true} filterField="fornitoriId" source="mezziId" label="Mezzo" tabPath="fornitore" reference="mezzi" referenceToSave="escursioni" optionProperty="nome" /> */}
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Punti d'incontro e di partenza" path="puntiincontropartenza" icon={<TopicIcon></TopicIcon>}>
                    <Accordion expanded={true} sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>
                                <PostAddIcon></PostAddIcon>&nbsp;Punti d'incontro e di partenza
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={10}>
                                    <ReferenceManyField
                                        reference="escursioni-puntiincontro"
                                        target="escursioniId"
                                        sort={{ field: 'id', order: 'ASC' }}
                                        filter={{ visibile: true }}
                                    >

                                        <EscursioniPuntiincontroInShow></EscursioniPuntiincontroInShow>

                                    </ReferenceManyField>
                                </Grid>
                                <Grid item xs={10}>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Galleria Immagini" path="galleria" icon={<TopicIcon></TopicIcon>}>
                    <Accordion expanded={true} sx={fieldAccordion}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>
                                <PostAddIcon></PostAddIcon>&nbsp;Galleria Immagini
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                {(permissions === 'admin') && <Grid item xs={10}>
                                    <Box sx={{ pt: 2 }}>
                                        <Typography>
                                            <Alert severity="info">Attenzione, per un corretto funzionamento in App serve selezionare un'immagine di copertina. Sono ammesse solo immagini <strong>JPEG/JPG</strong>.</Alert>
                                        </Typography>
                                    </Box>

                                </Grid>}
                                <Grid item xs={10}>
                                    <GestioneImmagini refresh={refresh} ></GestioneImmagini>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabbedForm.Tab>
            </TabbedForm>
        </Show >
    );
}

export const EscursioniRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput label="Titolo" source="titolo" />
        <TextInput label="Titolo corto" source="short_title" />
        {/* <TextInput label="Url file pdf" source="urlPdf" /> */}
    </RowForm>
);

export const EscursioniDetailRowForm = (props) => (
    <RowForm {...props}>
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <TextInput label="Titolo" source="titolo" />
        <TextInput label="Titolo corto" source="short_title" />

        {/* {<TextInput label="Url file pdf" source="urlPdf" />} */}

        {<RichTextInput label="Prezzi al pubblico" source="prezziAlPubblico" />}

        {<RichTextInput label="Incluso" source="incluso" />}

        {<RichTextInput label="Non incluso" source="nonIncluso" />}

        {<RichTextInput label="Cosa portare" source="cosaPortare" />}

        {<RichTextInput label="Quando prenotare" source="quandoPrenotare" />}

        {<RichTextInput label="N. minimo partecipanti" source="numMinimoPartecipanti" />}

        {<RichTextInput label="Voucher digitale" source="voucherDigitale" />}

        {<RichTextInput label="Animali da compagnia" source="animaliDaCompagnia" />}

        {<RichTextInput label="Domande frequenti" source="domandeFrequenti" />}

        {<RichTextInput label="Cancellazione" source="cancellazione" />}

        {<RichTextInput label="Altro" source="altro" />}

    </RowForm>
);


export const EscursioniDescrizioniRowForm = (props) => (
    <RowForm {...props}>
        {/* <TextInput label="Descrizione" source="descrizione" /> */}
        <ValutaBandieraLingua source="lingueId" label="Nazione" />
        <RichTextInput source="descrizione"></RichTextInput>
    </RowForm>
);

// Filtri

const EscursioniFilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <EscursioniFilter />
            </CardContent>
        </Card>
    </Box>
);

/// Filtri
const EscursioniFilter = () => (
    <Grid container spacing={2}>
        <Grid>
            <Box >
                {/*         <Box sx={{
        width: '12em',
    }}><h3></h3>
        <Card>
    <CardContent><FilterLiveSearch source="id" />
    </CardContent>
    </Card></Box> */}

                <FilterList label="Zone geografiche" icon={<ContentFilter />}>
                    <FilterListItem
                        label="Nord"
                        value={{
                            zona: "n"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Ovest"
                        value={{
                            zona: "no"
                        }}
                    />
                    <FilterListItem
                        label="Nord-Est"
                        value={{
                            zona: "ne"
                        }}
                    />
                    <FilterListItem
                        label="Est"
                        value={{
                            zona: "e"
                        }}
                    />
                    <FilterListItem
                        label="Sud"
                        value={{
                            zona: "s"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Ovest"
                        value={{
                            zona: "so"
                        }}
                    />
                    <FilterListItem
                        label="Sud-Est"
                        value={{
                            zona: "se"
                        }}
                    />
                    <FilterListItem
                        label="Ovest"
                        value={{
                            zona: "o"
                        }}
                    />
                </FilterList>
            </Box>
        </Grid>
        {/* {  <Grid>
    <Box>
    <FilterList label="Trimestre" icon={<ContentFilter />}>
    <FilterListItem
            label="Primo"
            value={{trimestre: 1
            }}
        />
         <FilterListItem
            label="Secondo"
            value={{trimestre: 2
            }}
        />
    <FilterListItem
            label="Terzo"
            value={{trimestre: 3
            }}
        />
         <FilterListItem
            label="Quarto"
            value={{trimestre: 4
            }}
        />
    </FilterList>
    </Box>
  </Grid>} */}
    </Grid>

);

const escursioniFiltersAdmin = [
    <TextInput label="Cerca per città" source="citta" alwaysOn />,
    <TextInput label="Cerca per id" source="id" alwaysOn />
];

const escursioniFilters = [
    <TextInput label="Cerca per città" source="citta" alwaysOn />
];