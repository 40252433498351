import * as React from "react";
import {
    TextInput,
    Create,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    DateInput,
    NumberInput,
    required,
    DateTimeInput,
    useCreateSuggestionContext,
    useCreate,
    AutocompleteInput,
    useGetList,
    Loading,
    Toolbar,
    SaveButton
} from 'react-admin';
import { useState, useEffect } from 'react';

import { RichTextInput } from "ra-input-rich-text";

import Typography from "@material-ui/core/Typography";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';

// import Escursione from '../../types/Escursione';

const redirect = () => `/istanze-escursioni`;

const FornitoriEMotonavi = () => {

    const [fornitoriId, setFornitoriId] = useState(null);
    const [motonaviFilter, setMotonaviFilter] = useState(null);
    const [motonaviVisible, setMotonaviVisible] = useState(false);

    function handleChangeFornitoriId(value) {
        setMotonaviFilter("");
        setMotonaviFilter(value);
        setMotonaviVisible(true);
    }

    useEffect(() => {
        if (fornitoriId) {
            console.log(fornitoriId);
            setMotonaviFilter({ fornitoriId });
        } else {
            setMotonaviFilter({});
        }
    }, [fornitoriId]);

    return (
        <>
            <ReferenceInput
                perPage={100}
                source="fornitoriId"
                reference="fornitori"
                sort={{ field: 'nome', order: 'ASC' }}
            >
                <SelectInput validate={[required()]} optionText="nome"
                    onChange={(event, key, newValue) => {
                        // console.log(event.target.value);
                        // setFornitoriId(newValue);
                        handleChangeFornitoriId(event.target.value)
                    }}

                />
            </ReferenceInput>


            {motonaviVisible && (
                <>
                    <br />
                    <ReferenceInput
                        perPage={100}
                        source="motonaviId"
                        reference="motonavi"
                        filter={{ fornitoriId: motonaviFilter }}
                        sort={{ field: 'nome', order: 'ASC' }}
                    >
                        <SelectInput
                            resettable
                            validate={[required()]} optionText="nome" />
                    </ReferenceInput>
                </>
            )}
        </>
    );
};

export const TransferToolbar = () => (
    <Toolbar>
        <SaveButton label="Save" />
    </Toolbar>
);

export const CreateTransfer = () => {/* 
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [create] = useCreate();
    const [value, setValue] = React.useState(filter || '');

    const handleSubmit = event => {
        event.preventDefault();
        create(
            'escursioni',
            { data: { titoloricerca: value } },
            {
                onSuccess: (data) => {
                    setValue('');
                    onCreate(data);
                },
            }
        );
    }; */

    return (
        /*         <Dialog open onClose={onCancel}>
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <TextField
                                label="Titolo ricerca"
                                value={value}
                                onChange={event => setValue(event.target.value)}
                                autoFocus
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit">Salva</Button>
                            <Button onClick={onCancel}>Annulla</Button>
                        </DialogActions>
                    </form>
                </Dialog> */
        <Create>
            <SimpleForm toolbar={<TransferToolbar />}>
                {<DateInput source="data" title="Data Transfer" validate={[required()]} />}
                {<RichTextInput fullWidth source="note" label="Note Transfer" validate={required()} />}
            </SimpleForm>
        </Create>
    );
};

const CustomAutocomplete = () => {

    const { data, isLoading, error } = useGetList(
        'escursioni',
        {
            sort: { field: 'titoloricerca', order: 'ASC' }
        }
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const optionRenderer = choice => `${choice.id} ${choice.titoloricerca}`;

    return (<>
        {/* <ReferenceInput source="escursioniId"
            reference="escursioni"> */}
        <AutocompleteInput choices={data} openText={optionRenderer} /* create={<CreateTransfer />}  */ />
        {/* </ReferenceInput> */}
    </>);
}

const optionRenderer = choice => `${choice.titoloricerca}`;


export const Motonavi = (props) => (
    <Create {...props}>
        <SimpleForm redirect={redirect}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {/* Input per la data ed ora */}
                                {<DateTimeInput source="dataOraInizio" title="Data Inizio escursione" validate={[required()]} />}
                                <br></br>
                                {<DateTimeInput source="dataOraFine" title="Data Fine escursione" validate={[required()]} />}
                                <br></br>
                                {<DateInput source="data" title="Data Giornaliera escursione" validate={[required()]} />}
                                <br></br>
                                {/* {<TextInput source="fornitore" label="Fornitore" validate={[required()]} />} */}
                                {/*   {<ReferenceInput
                                    perPage={100}
                                    source="fornitoriId"
                                    reference="fornitori"
                                    // filter={{ lingueId: 1 }}
                                    sort={{ field: 'nome', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="nome" />

                                </ReferenceInput>}
                                <br></br>
                                {<ReferenceInput
                                    perPage={100}
                                    source="motonaviId"
                                    reference="motonavi"
                                    filter={{ fornitoriId: 3 }}
                                    sort={{ field: 'nome', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="nome" />

                                </ReferenceInput>} */}
                                <br></br>
                                <FornitoriEMotonavi></FornitoriEMotonavi>
                                <br></br>
                                {<TextInput source="note_arrivo" label="Note Arrivo" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Partecipanti" source="partecipanti" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={1} label="PartecipantiMax" source="partecipantiMax" helperText="Valore minimo 1" validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Adulti" source="tarAdulti" defaultValue={0} validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Infanti" source="tarInfanti" defaultValue={0} validate={[required()]} />}
                                <br></br>
                                {<NumberInput min={0} label="Tariffa Bambini" source="tarBambini" defaultValue={0} validate={[required()]} />}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <br></br>
                                {/*                         <ReferenceOneField label="Escursione" reference="istanze-escursioni" target="idEscursione">
                            <TextField source="genre" />
                        </ReferenceOneField> */}
                                {/*  <ReferenceInput label="Escursione" source="escursioneId" reference="istanze-escursioni">
                            <SelectInput optionText="id" />
                        </ReferenceInput> */}
                                {/* <ReferenceInput perPage={100} source="escursioniId" reference="escursioni">
                        <SelectInput  optionText="citta" />
                        </ReferenceInput> */}
                                {/*  {<ReferenceInput
                                    perPage={100}
                                    source="escursioniId"
                                    reference="escursioni"
                                    sort={{ field: 'titoloricerca', order: 'ASC' }}
                                >
                                    <SelectInput validate={[required()]} optionText="titoloricerca" />
                                </ReferenceInput>} */}
                                {/* <CustomAutocomplete /> */}

                                <ReferenceInput
                                    source="escursioniId"
                                    reference="escursioni"
                                    perPage={30}
                                    sort={{ field: 'titoloricerca', order: 'ASC' }}
                                >
                                    <AutocompleteInput
                                        optionText={optionRenderer} /* optionText="titoloricerca" */
                                        suggestionLimit={30}
                                    />
                                </ReferenceInput>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
