export const formCss = {
    backgroundColor: '#f5f5f5', // sfondo arancione
    // color: 'white', // testo bianco
    border: 1,
    borderRadius: 3,
    padding: 1
};

export const formCssTextInput = {
    border: 2,
    borderRadius: 3,
    color: 'white',
    height: 50,
    padding: '0 0px',
};

export const formCssDropdownMenu = {
    '.MuiAutocomplete-input': {
        color: 'green', // Sostituisci 'blue' con il colore desiderato per il testo
        fontWeight: 'bold', // Rende il testo in neretto
        fontSize: '1.05em', // Aumenta la dimensione del testo del 5%
    },
};